import React, { useContext, useEffect, useRef, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { getPlanStarted, getPlanTier } from '../../../api/plan';
import { CheckIcon as CheckIconSolid, } from '@heroicons/react/solid';
import { AuthenticatedRoutesContext } from '../../../contexts';
import { useToastAction } from '../../../hooks/useToastAction';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useCustomerCurrency } from '../../../hooks/useCustomerCurrency';

export default function PurchaseSuccess() {
    const authContext = useContext(AuthenticatedRoutesContext);
    const { customerCurrency } = useCustomerCurrency();
    const [currencyPrice, setCurrencyPrice] = useState();
    const { planId } = useParams();
    const [planTier, setPlanTier] = useState();
    const history = useHistory();
    const loadAction = useToastAction();
    const [pollCounter, setPollCounter] = useState(0);
    const pollCountRef = useRef(0)
    const intervalRef = useRef(null)
    const [isAwaitingConfirmation, setIsAwaitingConfirmation] = useState(true);
    const paymentIntentClientSecretQueryKey = "payment_intent_client_secret";
    const paymentIntentQueryKey = "payment_intent";
    const redirectStatusQueryKey = "redirect_status";

    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    // const param = searchParams.get("param");


    const loadPlanTier = async () => {
        loadAction.execute(async () => {
            const plan = await getPlanTier(planId);
            const price = plan.planPrices.find(x => x.currency === customerCurrency);
            setCurrencyPrice(price);
            setPlanTier(plan);
            setIsAwaitingConfirmation(false);
        }, 'Failed to load plan')
    }

    const isCompletingPayment = () => {
        return searchParams.has(paymentIntentQueryKey) && searchParams.get(redirectStatusQueryKey) === "succeeded";
    }

    const awaitPlanStart = async () => {

        const intervalId = setInterval(async () => {
            await checkIfPlanIsStarted(pollCountRef.current)
            setPollCounter(pollCountRef.current + 1);
        }, 500);

        intervalRef.current = intervalId;
    }

    const checkIfPlanIsStarted = async (count) => {

        if (count > 20) {
            clearInterval(intervalRef.current)
            setTimeout(() => authContext.triggerContextChange(), 500);
            return;
        }

        const res = await getPlanStarted({ paymentIntentId: searchParams.get(paymentIntentQueryKey), planId });
        if (res.planStarted) {
            clearInterval(intervalRef.current)
            authContext.triggerContextChange();
            loadPlanTier();
        }
    }


    useEffect(() => {
        pollCountRef.current = pollCounter;
    }, [pollCounter]);

    useEffect(() => {
        //console.log(searchParams.keys());
        if (isCompletingPayment()) {
            awaitPlanStart()
        } else {
            loadPlanTier();
        }
    }, [planId])

    return (
        <>
            <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                <div className="bg-gradient-to-b from-white to-gray-50">
                    <div className="pt-12 md:pt-18 px-4 sm:px-6 lg:px-8 max-w-2xl mx-auto divide-solid divide-y">
                        {
                            isAwaitingConfirmation ? (<div className="max-w-3xl mx-auto pb-2 sm:pb-4">
                                <h2 className="text-3xl font-extrabold text-gray-900">Thank you for subscribing</h2>
                                <div className="max-w-3xl mx-auto mt-8">
                                    <h2 className="text-xl font-extrabold text-gray-900">Processing your order...</h2>
                                </div>
                            </div>) : (
                                <div className="max-w-3xl mx-auto pb-2 sm:pb-4">
                                    <h2 className="text-3xl font-extrabold text-gray-900">Thank you for subscribing</h2>
                                    <div className="max-w-3xl mx-auto mt-8">
                                        <h2 className="text-xl font-extrabold text-gray-900">Order Details</h2>
                                    </div>
                                </div>
                            )
                        }
                        {
                            planTier && (<>
                                <div className="pt-2 sm:pt-4 md:pt-8 divide-solid divide-y">
                                    <div className="grid grid-cols-2 gap-2 pb-2 sm:pb-4">
                                        <div><h2 className="text-lg leading-6 font-medium text-gray-900">Product</h2></div>
                                        <div><h2 className="text-lg leading-6 font-medium text-gray-900">Price</h2></div>
                                        <div>{planTier.planName}</div>
                                        <div>
                                            <span className="text-gray-900">
                                                {currencyPrice?.price} <span className="text-gray-500">{currencyPrice?.currency}</span> </span>{' '}
                                            <span className="text-gray-500">/mo</span>
                                        </div>
                                    </div>
                                    <div className="pt-2 sm:pt-4 md:pt-8 pb-2 sm:pb-4">
                                        <h2 className="text-lg leading-6 font-medium text-gray-900">Product Features</h2>
                                        <ul className="inline-block mt-2 sm:mt-4 md:mt-6 space-y-4 sm:mx-auto">
                                            {planTier.whatsIncluded.map((feature) => (
                                                <li key={feature} className="flex space-x-3">
                                                    <CheckIconSolid className="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
                                                    <span className="text-sm text-gray-500">{feature}</span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                <div className="pt-2 sm:pt-4 md:pt-8 pb-2 sm:pb-4">
                                    <div className="max-w-xs mx-auto">
                                        <button
                                            className="inline-flex justify-center items-center bg-brand-blue border w-full border-transparent rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-brand-blue-light mt-3"
                                            onClick={() => history.push(`/`)}
                                        >
                                            Go to Dashboard
                                        </button>
                                    </div>
                                </div>
                            </>
                            )
                        }
                    </div>
                </div>

            </div>
        </>
    )
}
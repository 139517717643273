export const useTryAwaitAction = () => {

    const tryAwaitAction = async (awaitable, defaultReturnValue = {}) => {
        try {
            return await awaitable();
        } catch (error) {

        }

        return defaultReturnValue;
    }

    return {
        tryAwaitAction: tryAwaitAction
    }
}
import { useClassNames } from "../../hooks/useClassNames";
import { useState } from 'react'
import { Switch } from '@headlessui/react'
import React from "react";


const CheckboxField = ({ label, name, value, onChange, disabled, error }) => {
  const { classNames } = useClassNames();

  return (
    <div className="space-y-1 sm:space-y-0 sm:grid sm:grid-cols-1 sm:gap-3">
      <div className="sm:col-span-2">
        <div className="relative flex items-start">
          <div className="flex items-center h-5">
            <input
              type="checkbox"
              name={name}
              checked={value}
              disabled={disabled}
              onChange={onChange}
              className={classNames(
                "block focus:ring-brand-pink focus:border-brand-pink h-4 w-4 text-brand-pink border-gray-300 rounded disabled:opacity-50 ",
                error
                  ? "placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 border-red-300"
                  : ""
              )}
            />
          </div>
          <div className="ml-3 text-sm">
            <label htmlFor="centraldistribution" className="font-medium text-gray-700">
              {label}
            </label>
          </div>
        </div>
        {
          error &&
          <p className="mt-2 text-sm text-red-600" id="email-error">
            {error}
          </p>
        }
      </div>
    </div>
  )
}

export default CheckboxField;

export function ToggleSwitchField({ label, name, value, onChange, disabled }) {
  // const [enabled, setEnabled] = useState(false)
  const { classNames } = useClassNames();
  const handleOnChange = (e) => {
    onChange({ target: { name: name, value: e } });
  }
  return (
    <Switch.Group>
      <div className="flex flex-col items-start mb-2">
        <Switch.Label className="py-2 text-sm font-medium text-blue-gray-900">{label}</Switch.Label>

        <Switch
          checked={value}
          onChange={handleOnChange}
          className={classNames(
            value ? 'bg-brand-pink' : 'bg-gray-200',
            'relative inline-flex h-5 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
          )}
        >

          <span className="sr-only">Use setting</span>
          <span
            className={classNames(
              value ? 'translate-x-6' : 'translate-x-0',
              'pointer-events-none relative inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
            )}
          >
            <span
              className={classNames(
                value ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in',
                'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
              )}
              aria-hidden="true"
            >
              <svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                <path
                  d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <span
              className={classNames(
                value ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out',
                'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
              )}
              aria-hidden="true"
            >
              <svg className="h-3 w-3 text-brand-pink" fill="currentColor" viewBox="0 0 12 12">
                <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
              </svg>
            </span>

          </span>

        </Switch>
      </div>
    </Switch.Group>
  )
}
export const featureFlags = {
    manageTeam: true,
    attributeStore: true,
    mfdxAdmin: true,
    workflow: true,
    settings:true,
    viewPoint:true,
    digitalArchive: (window._env_.REACT_APP_FEATURE_ENABLED_ARCHIVE && window._env_.REACT_APP_FEATURE_ENABLED_ARCHIVE === "true"),
    audit: true,
    billing: true,
    interact: true,
    integration: true,
    integrationArcive: (window._env_.REACT_APP_FEATURE_ENABLED_INEGRATIONARCHIVE && window._env_.REACT_APP_FEATURE_ENABLED_INEGRATIONARCHIVE === "true"),
    support: (window._env_.REACT_APP_FEATURE_ENABLED_SUPPORT && window._env_.REACT_APP_FEATURE_ENABLED_SUPPORT === "true"),
    pingDoxAdmin: (window._env_.REACT_APP_FEATURE_ENABLED_PINGDOX_ADMIN && window._env_.REACT_APP_FEATURE_ENABLED_PINGDOX_ADMIN === "true"),
    interactAdmin: (window._env_.REACT_APP_FEATURE_ENABLED_INTERACT_ADMIN && window._env_.REACT_APP_FEATURE_ENABLED_INTERACT_ADMIN === "true")
  }
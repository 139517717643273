import { ClockIcon } from '@heroicons/react/outline'
import React from 'react';
import { ActionTile } from './actionTile';

export const AmountDueTile = ({amountDue, currency, numberOfOpenInvoices}) => {

    const title = 'Amount due: ' + amountDue + ' ' + currency;
    let text = 'You have no open invoices';
    let href = '#';
    const iconForeground = 'text-purple-700';
    const iconBackground = 'bg-purple-50'

    if (numberOfOpenInvoices !== 0)
    {
        text = 'View your ' + numberOfOpenInvoices + ' open invoice(s)';
        href = "invoices?statusId=2"
    }

    return (
        <ActionTile title={title} text={text} href={href} isExternal={false} icon={ClockIcon} iconBackground={iconBackground} iconForeground={iconForeground} actionIdx={0}/>
    )
}
export default AmountDueTile
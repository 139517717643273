import { Formik, Form } from 'formik';
import React, { useEffect, useState } from 'react';
import FormikInput from '../../../../components/Form/Formik/FormikInput';
import { useToastAction } from '../../../../hooks/useToastAction';
import {
  getCustomerNotificationEmails,
  postCustomerNotificationEmails
} from '../../../../api/customer';
import { FormikSubmitButton } from '../../../../components/Form/Formik/FormikSubmit';
import * as Yup from 'yup';
import { Select } from '../../../../components/Form/Select';
import { ContentWrapper, CoreButton, PageHeader } from '@metaforcelabs/metaforce-core';

export default function VersionCustomersSettings() {
  const notificationsOptions = [
    {
      value: true,
      name: 'Send e-mail notifications on version updates'
    },
    {
      value: false,
      name: 'Do not send e-mail notifications on version updates'
    }
  ];
  const initialValues = { emails: ['', '', '', '', ''], sendReleasePackageNotifications: 'false' };
  const [formValues, setFormValues] = useState(initialValues);
  const loadCustomerNotificationEmailsAction = useToastAction();
  const updateCustomerNotificationEmailsAction = useToastAction();

  const validationSchema = Yup.object().shape({
    emails: Yup.array().of(Yup.string().email('Invalid e-mail'))
  });

  const fetchNotoficationEmailsData = async () => {
    loadCustomerNotificationEmailsAction.execute(async () => {
      const response = await getCustomerNotificationEmails();
      console.debug(formValues);
      setFormValues(response);
    }, 'Failed to load information about notification settings');
  };

  useEffect(() => {
    fetchNotoficationEmailsData();
  }, []);

  const handleOnSubmit = (values, actions) => {
    updateCustomerNotificationEmailsAction.execute(
      async () => {
        const response = await postCustomerNotificationEmails(values);
        setFormValues(response);
        actions.setSubmitting(false);
      },
      'Failed to update e-mails',
      'Version Notification Settings have been saved'
    );
  };
  return (
    <>
      <ContentWrapper>
          <PageHeader 
            title="Version Nofitications" 
            description={
              <>Configure up to 5 e-mail adresses to receive notifications about new versions of
              Metaforce software</>
            } 
          />
          <section className="pt-5">
            <Formik
              initialValues={formValues}
              onSubmit={handleOnSubmit}
              enableReinitialize={true}
              validationSchema={validationSchema}
            >
              {(props) => (
                <Form>
                  <div className="max-w-md mb-5">
                    <Select
                      name="sendReleasePackageNotifications"
                      options={notificationsOptions}
                      selectedValue={props.values.sendReleasePackageNotifications}
                      onChange={(v) => {
                        props.setFieldValue('sendReleasePackageNotifications', v);
                      }}
                    />
                  </div>
                  {props.values.sendReleasePackageNotifications.toString() === 'true' && (
                    <>
                      <div className="max-w-md pt-5 mb-5">
                        <FormikInput
                          label="E-mail adress no. 1"
                          name={'emails[0]'}
                          isAutoCompleteOnlyOnFocus={true}
                        />
                      </div>
                      <div className="max-w-md mb-5">
                        <FormikInput
                          label="E-mail adress no. 2"
                          name={'emails[1]'}
                          isAutoCompleteOnlyOnFocus={true}
                        />
                      </div>
                      <div className="max-w-md mb-5">
                        <FormikInput
                          label="E-mail adress no. 3"
                          name={'emails[2]'}
                          isAutoCompleteOnlyOnFocus={true}
                        />
                      </div>
                      <div className="max-w-md mb-5">
                        <FormikInput
                          label="E-mail adress no. 4"
                          name={'emails[3]'}
                          isAutoCompleteOnlyOnFocus={true}
                        />
                      </div>
                      <div className="max-w-md mb-5">
                        <FormikInput
                          label="E-mail adress no. 5"
                          name={'emails[4]'}
                          isAutoCompleteOnlyOnFocus={true}
                        />
                      </div>
                    </>
                  )}

                  <div>
                    <div className="pt-5">
                      <FormikSubmitButton
                        id="save"
                        text="Save"
                        className="px-6 bg-brand-pink hover:bg-brand-pink-hover justify-center"
                        formikProps={props}
                      />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </section>
      </ContentWrapper>
    </>
  );
}

import { PresentationChartLineIcon, XCircleIcon } from "@heroicons/react/outline";
import { useParams } from "react-router-dom";
import { SidebarSelect } from "../../../../components/SidebarSelect";
import { SidebarTextArea } from "../../../../components/SidebarTextArea";
import { SidebarTextbox } from "../../../../components/SidebarTextbox";
import { txtInput } from "./Components/txtInput";
import { checkBoxInput } from "./Components/checkBoxInput";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useRef, useEffect } from "react";
import { useState } from "react/cjs/react.development";
import Properties from "./properties";
import { SearchListBox } from "../Components/countrieslistbox";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { getCustomerDetails } from "../../../../api/customer";
import React from "react";




const validationSchema = Yup.object().shape({
  tenantKey: Yup.string()
    .min(42, 'Tenant key must be exactly 42 characters!')
    .max(42, 'Tenant key must be exactly 42 characters!')
    .required('Required')
});


export default function KivraServiceForm({ setting: service, onSubmit, reference: myRef, closeHandler: closeHandler, formId  }) {

  return (

    <div className="w-full">
      <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200 px-3">
        <Formik


          initialValues={{

            settingsId: service.settingsId,
            displayName: service.displayName,
            organisationNumber: service.organisationNumber,
            countryCodes: Array.from(service.countryCodes),
            tenantKey: service.tenantKey,
            description: service.description,
            isActive: service.isActive,
            updatedBy: service.updatedBy,
            insertTimeStamp: service.insertTimeStamp,
            isDirty: service.isDirty,
            isDraft: service.isDraft


          }}
          onSubmit={(values, actions) => {
            values.insertTimeStamp = new Date();
            values.isDirty = true;
            values.isDraft = true;
            values.updatedBy = "pending...";
            onSubmit(values);
            closeHandler(true);
          }}
          validationSchema={validationSchema}
        >
          {({ errors, touched, values, handleClick, submitForm }) => (

            handleClick = () => {
              submitForm();
            },

            myRef.current.handleClick = handleClick,

            <Form id="serviceform">
              <div className={"py-5"}>
                <SidebarTextbox
                  value={service.displayName}
                  label={"Service"}
                  disabled={true}
                />
                <SidebarTextbox
                  value={service.organisationNumber}
                  label={"Organisation number"}
                  disabled={true}
                />
                <SidebarTextbox
                  value={service.countryCodes}
                  label={"Available in following countries (* = everywhere) :"}
                  disabled={true}
                />
                {txtInput(errors.tenantKey, touched.tenantKey, "Tenant key", "tenantKey")}
                {txtInput(errors.description, touched.description, "Description", "description")}

                <div className="px-5 mt-8">
                  <label for="isActive" className="text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">Active</label>
                  <Field name="isActive" type="checkBox" className="form-checkbox text-green-500 ml-4" name="isActive" checked={values.isActive} >
                  </Field>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}



import { Form, Formik } from 'formik';
import { TrashIcon } from '@heroicons/react/outline';
import FormikInput from '../../../../../components/Form/Formik/FormikInput';
import DateTimeParser from '../../../../../utils/DateTimeParser';
import DateField from '../../../../../components/DateField';
import { CoreButton } from '@metaforcelabs/metaforce-core';

export const formInitialValues = {
  dateFrom: DateTimeParser.subtractYears(1),
  dateTo: DateTimeParser.toLocaleDateString(new Date())
};

const Filter = ({ onSubmit, maximumQuantity }) => {
  const handleOnSubmitForm = (values, setSubmitting) => {
    onSubmit(
      {
        dateFrom: values.dateFrom
          ? DateTimeParser.toLocalJSONFormat(DateTimeParser.setSOD(values.dateFrom))
          : null,
        dateTo: values.dateTo
          ? DateTimeParser.toLocalJSONFormat(DateTimeParser.setEOD(values.dateTo))
          : null,
        packageState: 1,
        searchString: values.searchString,
        quantity: maximumQuantity
      },
      setSubmitting
    );
  };

  const onClickDeleteDateRequirment = (fieldName, setValueFunction) => {
    setValueFunction(fieldName, '');
  };

  return (
    <>
      <Formik
        initialValues={formInitialValues}
        onReset={(values, actions) => {
          handleOnSubmitForm(values, actions.setSubmitting);
        }}
        onSubmit={(values, actions) => {
          handleOnSubmitForm(values, actions.setSubmitting);
        }}
      >
        {(props) => (
          <Form>
            <div className="w-full mt-5 mb-10 pb-5 border-b border-gray-200">
              <div className="mt-6 grid grid-cols-1 gap-6 sm:grid-cols-4 lg:grid-cols-6">
                <div className="col-span-1 sm:col-span-2">
                  <FormikInput label="Search query" name="searchString" formikProps={props} />
                </div>
                <div className="col-span-1 sm:col-span-18 grid grid-cols-6">
                  <div className="col-span-5">
                    <DateField
                      navbar={true}
                      dataCy="createdFrom"
                      label="Date from"
                      name="dateFrom"
                      value={props.values.dateFrom}
                      maxDate={props.values.dateTo}
                      onChange={(v, e) => {
                        props.setFieldValue('dateFrom', v);
                      }}
                    />
                  </div>
                  <div>
                    <button
                      type="button"
                      className="mt-8 ml-2"
                      onClick={() => onClickDeleteDateRequirment('dateFrom', props.setFieldValue)}
                      aria-label="Disable Date From filter"
                    >
                      <TrashIcon className="h-5 w-5 text-gray-500" />
                    </button>
                  </div>
                </div>

                <div className="col-span-1 sm:col-span-1 grid grid-cols-6 cursor-pointer">
                  <div className="col-span-5">
                    <DateField
                      navbar={true}
                      dataCy="createdTo"
                      label="Date to"
                      name="dateTo"
                      value={props.values.dateTo}
                      minDate={props.values.dateFrom}
                      onChange={(v, e) => {
                        props.setFieldValue('dateTo', v);
                      }}
                    />
                  </div>
                  <div>
                    <button
                      type="button"
                      className="mt-8 ml-2"
                      onClick={() => onClickDeleteDateRequirment('dateTo', props.setFieldValue)}
                      aria-label="Disable Date To filter"
                    >
                      <TrashIcon className="h-5 w-5 text-gray-500" />
                    </button>
                  </div>
                </div>

                <div className="col-span-1 sm:col-span-1 grid grid-cols-6 cursor-pointer">
                  <div className="my-6 ml-10">
                    <CoreButton 
                      htmlType="submit"
                      label={<>Search</>}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Filter;

import React from 'react';
import { useClassNames } from '../../hooks/useClassNames';

const TextField = ({
  placeholder,
  value,
  defaultValue,
  name,
  onChange,
  onBlur,
  disabled,
  error,
  allTouched = {},
  touched = true,
  type = 'text'
}) => {
  const classNames = useClassNames();
  return (
    <>
      <input
        type={type}
        name={name}
        value={value}
        defaultValue={defaultValue}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        className={classNames.classNames(
          'block w-full shadow-sm sm:text-sm focus:ring-brand-pink focus:border-brand-pink border-gray-300 rounded-md disabled:opacity-50 ',
          error && touched
            ? 'placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 border-red-300 text-red-900 '
            : ''
        )}
      />
      {error && touched && <p className="mt-2 text-sm text-red-600">{error}</p>}
    </>
  );
};

export default TextField;

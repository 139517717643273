import { ClipboardCopyIcon } from "@heroicons/react/outline";
import React, { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useClassNames } from "../../hooks/useClassNames";

const CopyToClipboardField = ({value, onCopy}) => {
    const [isMouseOver, setIsMouseOver] = useState(false);
    const { classNames } = useClassNames();
    return (
        <CopyToClipboard
            text={value}
            onCopy={onCopy}
        >
            <div
                title="Click to copy"
                className="mt-1 p-2 hover:cursor-pointer relative shadow-sm focus:ring-indigo-500 focus:border-indigo-500 disabled:opacity-50 block w-full sm:text-sm border border-gray-300 rounded-md"
                onClick={(e) => { }}
                onMouseEnter={(e) => {
                    setIsMouseOver(true);
                }}
                onMouseLeave={(e) => {
                    setIsMouseOver(false);
                }}
            >
                <>
                    {value}
                    {isMouseOver && (
                        <div
                            className={classNames('absolute fade text-brand-pink top-1.5 right-0')}
                        >
                            <ClipboardCopyIcon className="animate-bounce h-4 w-4 sm:h-6 md:w-6" />
                        </div>
                    )}
                </>
            </div>
        </CopyToClipboard>
    )
}

export default CopyToClipboardField;
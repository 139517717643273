import React, { useEffect, useState } from 'react'
import { getBillingOverview, getInvoiceStatuses } from '../../../../api/billing';
import { useToastAction } from '../../../../hooks/useToastAction';
import { AmountDueTile } from './Tiles/amountDueTile';
import BillingConfigurationTile from './Tiles/billingConfigurationTile';
import InvoiceOverviewTile from './Tiles/InvoiceOverviewTile';
import { LastInvoiceTile } from './Tiles/lastInvoiceTile';
import SkeletonTile from './Tiles/skeletonTile';
import { ContentWrapper, PageHeader } from '@metaforcelabs/metaforce-core';



export default function Overview() {
    const [isLoaded, setIsLoaded] = useState(false);
    const [billingOverview, setBillingOverview] = useState({ amountDue: 0, currency: '' });
    const [invoiceStatuses, setInvoiceStatuses] = useState([]);
    const loadAction = useToastAction()


    const loadDefaultData = async () => {
        setIsLoaded(false);
        loadAction.execute(async () => {
            const billingOverviewData = await getBillingOverview();
            setBillingOverview(billingOverviewData);
            const invoiceStatusesData = await getInvoiceStatuses();
            setInvoiceStatuses(invoiceStatusesData);            
            setIsLoaded(true);
        }, 'Failed to load')
    }

    useEffect(() => {
        if (isLoaded) {
            return;
        }
        loadDefaultData();
    }, []);

    return (


        <ContentWrapper>
          <PageHeader title="Overview" />
          <div className="mt-10">
              <div className="rounded-lg bg-gray-200 overflow-hidden shadow divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px">

                  {
                      loadAction.isExecuting && 
                      (
                          <>
                              <SkeletonTile actionIdx={0} />
                              <SkeletonTile actionIdx={1} />
                              <SkeletonTile actionIdx={2} />
                              <SkeletonTile actionIdx={3} />
                          </>
                      )
                  }
                  {
                      !loadAction.isExecuting && isLoaded && (
                          <>
                              <AmountDueTile {...billingOverview} />
                              <LastInvoiceTile invoiceStatuses={invoiceStatuses} {...billingOverview} />
                              <InvoiceOverviewTile {...billingOverview} />
                              <BillingConfigurationTile {...billingOverview } />
                          </>
                      )
                  }
              </div>
              {/* <div className="grid grid-cols-2 gap-4">
                      <AmountDueTile {...billingOverview} />
                      <LastInvoiceTile {...billingOverview} />
                      <InvoiceOverviewTile {...billingOverview} />
                      <BillingConfigurationTile {...billingOverview} />
                  </div> */}
          </div>
          </ContentWrapper>
    )

}
import ListItem from './ListItem';

const List = ({parentId, options, folders, searchValue, onChange, ...props}) => {
  // const handleOnRootOptionClick = (option) => {
  //   const index = props.values.findIndex((v) => v === option.value);
  //   let foldersIds = [...props.values];
  //
  //   if (index >= 0) {
  //     // if (hasChildren.length > 0) {
  //     //   values = values.filter((v) => ![...hasChildren, option.value].includes(v));
  //     // } else {
  //     foldersIds = foldersIds.filter((v) => v !== option.value);
  //     // }
  //   } else {
  //     // values = [...values, option.value, ...hasChildren];
  //     foldersIds = [...foldersIds, option.value];
  //   }
  //
  //   onChange({
  //     parentId: null,
  //     foldersIds
  //   });
  // };

  return (
    <ul className="flex-grow max-h-80 overflow-y-auto">
      {/*{!parentId && (*/}
      {/*  <li*/}
      {/*    className="flex items-center py-1.5 px-3 cursor-pointer"*/}
      {/*    onClick={() =>*/}
      {/*      handleOnRootOptionClick({*/}
      {/*        name: 'Root',*/}
      {/*        value: null*/}
      {/*      })*/}
      {/*    }*/}
      {/*  >*/}
      {/*    {props.values.includes(null) ? (*/}
      {/*      <CheckIcon className="mr-2 h-4 w-4 border border-gray-500 rounded" />*/}
      {/*    ) : (*/}
      {/*      <span className="mr-2 block border h-4 w-4 text-indigo-600 border-gray-500 rounded focus:ring-indigo-500" />*/}
      {/*    )}*/}
      {/*    Root folder*/}
      {/*  </li>*/}
      {/*)}*/}
      {options &&
        options
          .filter((o) =>
            searchValue.length > 0 ? o.name.toLowerCase().includes(searchValue) : true
          )
          .map((o) => (
            <ListItem
              key={o.value}
              parentId={parentId}
              option={o}
              folders={folders}
              values={props.values}
              onChange={onChange}
            />
          ))}
    </ul>
  );
};

export default List;

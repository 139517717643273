import { useContext } from "react";
import { AuthenticatedRoutesContext } from "../contexts";
import { adminProductId } from "../utils/constants";

export const useHasFeature = () => {
    const authContext = useContext(AuthenticatedRoutesContext);
    return {
        hasFeature: (featureCode) => {
            if (!featureCode)
                return true;

            const product = authContext.centerPointUser.customerUserAccess?.productFeatureAccessSelections?.find(x => x.productId === adminProductId);
            const ret = product?.featureSelections.find(x => x.featureCode === featureCode)?.hasAccess;
            return !!ret;
        },
        hasUsageFeature: (productCode, featureCode) => {
            const product = authContext.centerPointUser.customerUserAccess?.productFeatureAccessSelections?.find(x => x.productCode === productCode);
            const ret = product?.usageFeature.featureCode === featureCode && product?.usageFeature.hasAccess;
            return ret;
        },
        hasPlan: () => {
            return authContext.customerInfo?.hasPlan;
        },
        billingDisabled: ()=> {
            return authContext.customerInfo?.billingDisabled;
        }
    }

}
import { CheckIcon, DocumentAddIcon, PencilAltIcon } from "@heroicons/react/outline"
import { useEffect, useState } from "react";
import { compareDocument } from "../../../../api/attributeStore";
import { useClassNames } from "../../../../hooks/useClassNames";
import Changes from "./changes";

export const Compare = ({ document, setOpen, onSubmit }) => {
  const classNames = useClassNames();
  const [changes, setChanges] = useState();
  const [openChanges, setOpenChanges] = useState(false);
  
  useEffect(() => {
    fetchCompare()
  }, [document]);

  const fetchCompare = async () => {
    const data = await compareDocument(document);
    setChanges(data)
  }
  
  return (
    <>
      <div className="flex w-full overflow-y-auto h-full">
        <div className="w-full flow-root py-5 px-5">
        <table className="min-w-full divide-y divide-gray-200 border border-gray-200 sm:rounded-lg">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Property
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Development
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Test
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Production
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {changes?.map((change) => (
                  <tr key={change.property}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{change.property}</td>
                    <td 
                      className={
                        classNames.classNames(
                          change.development !== change.production
                            ? 'bg-yellow-100'
                            : '',
                          'px-6 py-4 whitespace-nowrap text-sm text-gray-500'
                        )}
                    >
                      {change.development}
                    </td>
                    <td 
                      className={
                        classNames.classNames(
                          change.test !== change.production
                            ? 'bg-yellow-100'
                            : '',
                          'px-6 py-4 whitespace-nowrap text-sm text-gray-500'
                        )}
                    >
                      {change.test}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{change.production}</td>
                  </tr>
                ))}
              </tbody>
            </table>
        </div>
      </div>

      <div className="flex-shrink-0 px-4 border-t bg-gray-50 border-gray-200 py-5 sm:px-6">
        <div className="space-x-3 flex justify-end">
          <button
            type="button"
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 "
            onClick={() => setOpen(false)}
          >
            Cancel
          </button>
          <button
            type="button"
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-brand-pink hover:bg-brand-pink-hover"
            disabled={true}
          >
            Ok
          </button>
        </div>
      </div>

    </>
  )
}
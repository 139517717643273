import { DocumentIcon } from "@heroicons/react/outline";
import React from "react";
import ActionTile from "./actionTile";


export const LastInvoiceTile = ({ invoiceStatuses, lastInvoiceStatusId, lastInvoiceAmountDue, currency, lastInvoiceDateDue, lastInvoiceUrl }) => {

    const title = 'Last invoice: ' + lastInvoiceAmountDue + ' ' + currency;
    let text = 'You have no invoices';
    let href = '#';
    let isExternal = false
    const iconForeground = 'text-green-700';
    const iconBackground = 'bg-green-50'

    const lastInvoiceDateDueDisplay = new Intl.DateTimeFormat('en-GB', {
        year: "numeric",
        month: "short",
        day: "2-digit"
    }).format(new Date(lastInvoiceDateDue))

    if (lastInvoiceUrl) {
        href = lastInvoiceUrl;
        isExternal = true
        text = 'View last invoice. Due date ' + lastInvoiceDateDueDisplay;
    }

    return (
        <ActionTile title={title} text={text} href={href} isExternal={isExternal} icon={DocumentIcon} iconBackground={iconBackground} iconForeground={iconForeground} lastInvoiceStatus={invoiceStatuses?.find(s => s.id === lastInvoiceStatusId)?.name} lastInvoiceDateDue={lastInvoiceDateDue} actionIdx={1}></ActionTile>
    )
}
export default LastInvoiceTile
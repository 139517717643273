import { CheckIcon, DocumentAddIcon, PencilAltIcon, TableIcon, TerminalIcon, ThumbUpIcon, UserIcon, ViewListIcon } from "@heroicons/react/outline"
import { useEffect } from "react"
import { useClassNames } from "../../../../hooks/useClassNames";
import { format } from "date-fns";
import { useParams } from "react-router";

export const History = ({ history }) => {
  const historyList = useParams();
  const classNames = useClassNames();
  history.sort(function (a, b) {
    var dateA = Date.parse(a.updatedAt), dateB = Date.parse(b.updatedAt);
    return dateB - dateA;
  });
  const renderIcon = (event) => {
    if (event.type === 2)
      return <PencilAltIcon className="h-5 w-5 text-white" aria-hidden="true" />;
    else
      return <DocumentAddIcon className="h-5 w-5 text-white" aria-hidden="true" />
  }
  return (
    <div className="w-full flow-root mt-10 px-10">
      <ul className="-mb-8">
        {history.map((event, eventIdx) => (
          <li key={event.id}>
            <div className="relative pb-8">
              <div className="relative flex space-x-3">
                <div>
                  <span
                    className={classNames.classNames(
                      'bg-blue-500',
                      'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
                    )}
                  >
                    {renderIcon(event)}
                  </span>
                </div>
                <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                  <div>
                    <p className="text-sm text-gray-500">
                      <span className="text-black">{event.updatedByName}</span> {' '}{event.content}
                    </p>
                  </div>
                  <div className="text-right text-sm whitespace-nowrap text-gray-500">
                    {format(Date.parse(event.updatedAt), "MM/dd/yyyy - HH:mm")}
                  </div>
                  <br />
                </div>
              </div>
              <div className={event.type === 2 ? "ml-8" : "ml-8 invisible"} >
                {event.changes.map((change) => (
                  <ul className="list-disc list-inside">
                    <li key={change.id}><span className="text-sm text-gray-500">{change.type}:</span><span className="text-sm"> '{change.oldValue}'</span><span className="text-sm text-gray-500"> to </span><span className="text-sm">'{change.newValue}'</span></li>
                  </ul>
                ))}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}
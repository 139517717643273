import React, { useEffect, useState } from 'react';
import { getTeamPendingAndAcceptedInvites, getUserRoles, removePendingInvites, } from '../../../../api/manageTeam';
import { tailwindToast } from '../../../../components/Toast/tailwindToast';
import { ManageTeamContext } from '../../../../contexts';
import CreateInvite from './createInvite';
import InvitesTable from './pendingInvitesTable';
import { ContentWrapper, CoreButton, PageHeader } from '@metaforcelabs/metaforce-core';

export default function Invite() {
    const [roles, setRoles] = useState([]);
    const [allInvites, setAllInvites] = useState([]);
    const [selectedInvites, setSelectedInvites] = useState([])
    const [initialValues, setInitialValues] = useState({ email: '', customerUserRoleId: '' });
    const [loading, setLoading] = useState(true)

    const loadAllInvites = async () => {
        const allInvitesData = await getTeamPendingAndAcceptedInvites(1);
        setAllInvites(allInvitesData);
    }
    const loadData = async () => {
        try {
            loadAllInvites();
            const userRoles = await getUserRoles();
            setRoles(userRoles);
            setInitialValues(prev => {
                return { ...prev, customerUserRoleId: userRoles[0].roleId };
            })
            setLoading(false);
        } catch (error) {
            tailwindToast.error("Failed to load");
        }
    };
    useEffect(() => {
        loadData();
    }, []);

    const toggleSelectInvite = (email) => {
        setSelectedInvites(prev => {
            const idx = selectedInvites.findIndex(x => x === email);
            if (idx === -1) {
                return [...prev, email];
            }
            return [...prev.filter(item => item !== email)];
        })
    }
    const toggleAllInvites = () => {
        const pendingInvites = allInvites.filter((f) => { return !f.acceptedDate });
        selectedInvites.length === pendingInvites.length ?
            setSelectedInvites([]) : setSelectedInvites(pendingInvites.map(x => x.email))

    }
    const removeSelectedInvites = async () => {
        const data = { emails: selectedInvites };
        await removePendingInvites(data);
        setSelectedInvites([]);
        loadAllInvites();
    }
    return (
        <>
            <ContentWrapper>
                <PageHeader title='Manage Invites' />
                <div className="grid grid-cols-6 gap-8 pt-8">
                    <ManageTeamContext.Provider value={{ roles: roles, statuses: [] }}>
                        <section
                            className="col-span-6 2xl:col-span-2">
                            <h2 className="mt-2 text-md font-bold leading-7 text-gray-900 sm:text-xl sm:truncate mb-4 sm:mb-6 md:mb-8">
                                New Invite
                            </h2>
                            <CreateInvite
                                loading={loading}
                                roles={roles}
                                initialValues={initialValues}
                                onInviteCreated={() => loadAllInvites()}
                            />
                        </section>
                        <section className="col-span-6 2xl:col-span-4 2xl:ml-10">
                            <h2 className="mt-2 text-md font-bold leading-7 text-gray-900 sm:text-xl sm:truncate mb-4 sm:mb-6 md:mb-8">
                                Pending Invites
                            </h2>
                            <InvitesTable invites={allInvites} selectedInvites={selectedInvites}
                                onToggleInvite={toggleSelectInvite} onToggleAllInvites={() => toggleAllInvites()} />
                            <div className="flex justify-end py-4">
                                {
                                    selectedInvites.length > 0 && (

                                        <CoreButton
                                            label={<div>Remove Selected</div>}
                                            htmlType="submit"
                                            disabled={loading}
                                            onClick={e => removeSelectedInvites()}
                                        />)
                                }
                            </div>
                        </section>
                    </ManageTeamContext.Provider>
                </div>
            </ContentWrapper>
        </>
    )
}

import { get, BaseUrl, post, put, destroy, } from './api'

export const getApiClients = async () => {
    return await get(`api/Customer/ApiClient`, null,
        BaseUrl.Centerpoint);
}

export const createApiClient = async (values) => {
    return await post(`api/Customer/ApiClient`, values, null,
        BaseUrl.Centerpoint);
}

export const enableApiClient = async (clientId) => {
    return await put(`api/Customer/ApiClient/${clientId}/enable`, {}, null,
        BaseUrl.Centerpoint);
}

export const disableApiClient = async (clientId) => {
    return await put(`api/Customer/ApiClient/${clientId}/disable`, {}, null,
        BaseUrl.Centerpoint);
}

export const deleteApiClient = async (clientId) => {
    return await destroy(`api/Customer/ApiClient/${clientId}`, null,
        BaseUrl.Centerpoint);
}
import { useClassNames } from "@metaforcelabs/metaforce-core";
import React from "react";
import { useContext } from "react";
import { DefaultLayoutV2Context } from "../../contexts";

export const sectionVariants = {
    primary: "primary",
    secondary: "secondary"
}
export const Section = ({ children, title = null, className = null, variant = null, requireFeature = null }) => {
    const { hasProductAccess } = useContext(DefaultLayoutV2Context) || {};
    const { classNames } = useClassNames();

    let sectionStyle = "";

    const variants = {
        primary: "",
        secondary: "bg-gray-50"
    }

    sectionStyle = variant && variants[variant] ? variants[variant] : variants.primary;

    const canSee = requireFeature ? (hasProductAccess && hasProductAccess(requireFeature)) ? true : false : true;

    return (
        <div className={classNames(sectionStyle, className)}>

            <section className='max-w-7xl mx-auto py-4 sm:py-6 px-4 sm:px-6 lg:px-8'>
                {
                    title && (
                        <div className="font-medium text-gray-800 border-b pb-2 mb-2 border-gray-300">{title}</div>
                    )
                }
                {
                    canSee ? (children) : (<></>)
                }
            </section>
        </div>
    )
}
import { Field } from 'formik';
import FormikErrorMessage from './FormikErrorMessage';

const errorClasses = 'ring-red-500 border-red-500 focus:ring-red-500 focus:border-red-500';

export default function FormikClientSecret({
  name,
  label,
  placeholder = null,
  onChange,
  required = false,
  disabled = false,
  formikProps,
  isCredentialsSet,
}) {
  return (
    <div>
      {label && (
        <label htmlFor={name} className="block text-sm font-medium text-gray-700">
          {label}
          {required === true && <span className="text-red-400"> *</span>}
        </label>
      )}

      <Field name={name}>
        {({ field, meta }) => (
          <div className={label ? 'mt-1' : ''}>
            <input
              type="password"
              id={field.name}
              name={field.name}
              value={field.value}
              disabled={disabled}
              onChange={e => {
                field.onChange(e)
                if (onChange) {
                  onChange(e);
                }
              }}
              placeholder={placeholder}
              className={`shadow-sm block w-full sm:text-sm border-gray-300 rounded-md focus:ring-gray-400 focus:border-gray-400 ${meta.touched && meta.error ? errorClasses : ''
                }`}
            />

            <div>
              <span className="text-sm text-gray-500">
                {isCredentialsSet && !field.value && 'This value is hidden'}
              </span>
            </div>

            <FormikErrorMessage name={name} />
          </div>
        )}
      </Field>
    </div>
  );
}

import { useEffect, useState } from "react";
import { configuredProducts } from "../layouts/DefaultLayout/products";
import useIsNotActiveCustomer from "./useIsNotActiveCustomer";
import { useTryAwaitAction } from "./useTryAwaitAction";
import { getCustomerDetails } from "../api/customer";
import { getCustomerUserAccess } from "../api/customerUser";
import { useToastAction } from "@metaforcelabs/metaforce-core";

export const useProductAccess = (oidcUser) => {
    const [products, setProducts] = useState(configuredProducts);
    const { tryAwaitAction } = useTryAwaitAction();
    const [customerUserAccess, setCustomerUserAccess] = useState({});
    const isNotActiveCustomer = useIsNotActiveCustomer();
    const loadAction = useToastAction();
    
    const updateProducts = () => {
        if (!customerUserAccess?.productFeatureAccessSelections) {
            return;
        }

        setProducts((previousProducts) => {
            return previousProducts.map((previousProduct) => {
                if (isNotActiveCustomer) {
                    previousProduct.hasLicense = true;
                } else {
                    previousProduct.hasLicense = customerUserAccess.productFeatureAccessSelections.find(
                        (x) => x.productCode === previousProduct.productCode
                    )?.usageFeature?.hasAccess;
                }

                return previousProduct;
            });
        });
    };

    const loadCustomer = async () => {
        await loadAction.executeAsync(async () => {
            const [customerDetailsData, customerUserAccessCollection] = await Promise.all([
                tryAwaitAction(() => getCustomerDetails(oidcUser.access_token)),
                tryAwaitAction(() => getCustomerUserAccess())
            ]);

            // setCustomerDetails(customerDetailsData);
            setCustomerUserAccess(customerUserAccessCollection);
        }, "Failed to load user access")

    };

    useEffect(() => {
        updateProducts();
    }, [customerUserAccess])

    useEffect(() => {
        loadCustomer();
    }, []);

    const createActions = products?.filter((x) => x.hasCreateAction && x.hasLicense && x.featureEnabled);
    const footerPages = [{
        title: 'Home',
        href: '/home',
        isExternal: false
    }, ...products?.filter((x) => x.showInFooter && x.hasLicense && x.featureEnabled)];

    const hasProductAccess = (productCode) => {
        return products.find((x) => x.productCode === productCode && x.featureEnabled)?.hasLicense;
    }

    return {
        products,
        createActions,
        footerPages,
        hasProductAccess: hasProductAccess
    };

}
import React, { useEffect, useState } from 'react';
import ReleasePackagesTable from '../../Dashboard/releasePackagesTable';
import { useToastAction } from '../../../../hooks/useToastAction';
import { GetReleasePackages } from '../../../../api/version';
import Filter from './components/Filter';
import DateTimeParser from '../../../../utils/DateTimeParser';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import { ContentWrapper, PageHeader } from '@metaforcelabs/metaforce-core';

export default function VersionReleasePackages() {
  const [releasePackages, setReleasepackages] = useState([]);
  const loadReleasePackagesData = useToastAction();
  const maximumNumberOfPackages = 20;

  const fetchReleasePackagesData = async (filterData, setSubmitting) => {
    let request = {
      dateFrom: DateTimeParser.toLocalJSONFormat(
        DateTimeParser.setSOD(DateTimeParser.subtractYears(1))
      ),
      dateTo: DateTimeParser.toLocalJSONFormat(
        DateTimeParser.setEOD(DateTimeParser.toLocaleDateString(new Date()))
      ),
      searchString: '',
      packageState: 1,
      quantity: maximumNumberOfPackages
    };
    if (filterData !== undefined) {
      request = filterData;
    }
    loadReleasePackagesData.execute(async () => {
      const releasePackages = await GetReleasePackages(request);
      if (setSubmitting !== undefined) {
        setSubmitting(false);
      }
      setReleasepackages(releasePackages || []);
    }, 'Failed to load release packages data');
  };

  useEffect(() => {
    fetchReleasePackagesData();
  }, []);

  return (
    <>
      <ContentWrapper>
        <PageHeader title="Version Release Packages" description={<>Information about published Release Packages</>}/>
        {releasePackages.length === maximumNumberOfPackages && (
          <div className="rounded-md bg-yellow-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <ExclamationCircleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
              </div>
              <div className="ml-3">
                <p className="text-sm text-yellow-700">
                  This page is showing only {maximumNumberOfPackages} release packages that were most recently published within set filter criteria.
                </p>
              </div>
            </div>
          </div>
        )}
        <section>
          <div className="pt-5 max-w-7xl">
            <Filter onSubmit={fetchReleasePackagesData} maximumQuantity={maximumNumberOfPackages}/>

            <ReleasePackagesTable releasePackages={releasePackages} />
          </div>
        </section>
        </ContentWrapper>
    </>
  );
}

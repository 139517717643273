import scene1 from '../../assets/images/scenes/1-min.jpg';
import scene2 from '../../assets/images/scenes/2-min.jpg';
import scene3 from '../../assets/images/scenes/3-min.jpg';
import scene4 from '../../assets/images/scenes/4-min.jpg';
import scene5 from '../../assets/images/scenes/5-min.jpg';
import scene6 from '../../assets/images/scenes/6-min.jpg';
import scene7 from '../../assets/images/scenes/7-min.jpg';
import scene8 from '../../assets/images/scenes/8-min.jpg';
import scene9 from '../../assets/images/scenes/9-min.jpg';
import scene10 from '../../assets/images/scenes/10-min.jpg';
import scene11 from '../../assets/images/scenes/11-min.jpg';
import scene12 from '../../assets/images/scenes/12-min.jpg';


export const scenes = [
    scene1,
    scene2,
    scene3,
    scene4,
    scene5,
    scene6,
    scene7,
    scene8,
    scene9,
    scene10,
    scene11,
    scene12
];
import {Badge, badgeTypes} from "../../../../../components/Badge";
import {format} from "date-fns";
import * as DigitalArchiveApi from "../../../../../api/digitalarchive";
import {useEffect, useState} from "react";
import {useToastAction} from "../../../../../hooks/useToastAction";
import {useClassNames} from "../../../../../hooks/useClassNames";
import Sidebar from "../sidebar";
import GenericModal from "../../../../../components/Modals/genericModal";
import { CoreButton, PageHeader } from "@metaforcelabs/metaforce-core";

const Webhooks = () => {
  const [selectedItems, setSelectedItems] = useState([])
  const [allItems, setAllItems] = useState([]);
  const [activeModelItem, setActiveModalItem] = useState();
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [openSidebar, setOpenSidebar] = useState(false);

  const saveAction = useToastAction();
  const loadAction = useToastAction();
  const deleteAction = useToastAction();

  const {classNames} = useClassNames();

  const handleCreate = async () => {
    const newWebhookSettings = {
      isActive: true,
      url: '',
      name: ''
    }
    setActiveModalItem(newWebhookSettings);
    setOpenSidebar(true);
  }

  const handleSave = async () => {
    setOpenSidebar(false)

    saveAction.execute(async () => {
      if (activeModelItem.id) {
        await DigitalArchiveApi.updateWebhook(activeModelItem);
      } else {
        await DigitalArchiveApi.createWebhook(activeModelItem);
      }
      await loadBaseData();
    }, "Failed to save Webhook settings", "Webhook settings saved")
  }

  const toggleAllItems = () => {
    selectedItems.length === allItems.length ?
      setSelectedItems([]) : setSelectedItems(allItems)
  }

  const toggleSelectItem = (settings) => {
    setSelectedItems(prev => {
      const idx = selectedItems.findIndex(x => x === settings);
      if (idx === -1) {
        return [...prev, settings];
      }
      return [...prev.filter(item => item !== settings)];
    })
  }

  const handleSidebar = () => {
    if (openSidebar) {
      setActiveModalItem(null);
    }
    setOpenSidebar(!openSidebar);
  };

  const loadBaseData = async () => {
    loadAction.execute(async () => {
      const webhooks = await DigitalArchiveApi.getWebhooksByCustomerId();
      setAllItems(webhooks);
    }, "Failed to load")
  }

  const handleRowItem = (item) => {
    setOpenSidebar(true);
    setActiveModalItem(item);
  };

  const handleDelete = async () => {
    deleteAction.execute(async () => {
      setOpenDeleteConfirmation(false);
      var idsToDelete = selectedItems.map(item => item.id);
      setSelectedItems([]);
      await DigitalArchiveApi.deleteWebhooksDefinitions(idsToDelete)
      await loadBaseData();
    }, "Failed to delete selected items", "Webhook deleted")
  }

  useEffect(() => {
    loadBaseData();
  }, [])

  return (
    <div>
      <PageHeader title="Webhooks" description={<div>You can manage all webhooks that will be called when supported operations will be made by using Digital Archive</div>} type="secondary" />

      {/* Start main area*/}
      <div className="mt-6">
        <div className="shadow-sm border border-gray-200 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                <input
                  id="selectAll"
                  name="selectAll"
                  type="checkbox"
                  onClick={e => toggleAllItems()}
                  checked={allItems.length > 0 && selectedItems.length === allItems.length}
                  className="focus:ring-brand-pink h-4 w-4 text-brand-pink border-gray-300 rounded"
                />
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Status
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Name
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Url
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Created
              </th>
            </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
            {allItems &&
              allItems.map((item) => (
                <tr
                  key={item.id}
                  className={classNames(
                    item.id === activeModelItem?.id
                      ? "bg-gray-100"
                      : "",
                    "hover:bg-gray-100 cursor-pointer"
                  )}
                >
                  <td className="px-6 w-12 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    <input
                      id="selected"
                      name="selected"
                      type="checkbox"
                      checked={selectedItems.findIndex(i => i === item) !== -1}
                      onChange={() => toggleSelectItem(item)}
                      className="focus:ring-brand-pink h-4 w-4 text-brand-pink border-gray-300 rounded"
                    />
                  </td>
                  <td className="px-6 w-12 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    <Badge text={item.isActive ? 'Active' : 'Deactivated'}
                           type={item.isActive ? badgeTypes.success : badgeTypes.error}/>
                  </td>
                  <td className="px-6 py-4 text-sm font-medium text-gray-900" onClick={() => handleRowItem(item)}>
                    {item.name}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-500" onClick={() => handleRowItem(item)}>
                    {item.url}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-500" onClick={() => handleRowItem(item)}>
                    {format(
                      new Date(item.createdDate),
                      "MM/dd/yyyy - HH:mm"
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className='mt-3 flex items-center'>
          <div className="flex-1"/>
          {
            selectedItems.length > 0 && (
              <CoreButton label={<>Delete</>} htmlType="button" type="secondary" onClick={() => setOpenDeleteConfirmation(true)} htmlClassNames="mr-3"/>
            )
          }
          <CoreButton label={<>New</>} htmlType="button" onClick={handleCreate}/>
        </div>
      </div>
      {/* End main area */}

      <Sidebar
        open={openSidebar}
        setOpen={handleSidebar}
        webhookSettings={activeModelItem}
        setActiveModalItem={setActiveModalItem}
        onSubmit={handleSave}
      />


      <GenericModal open={openDeleteConfirmation} setOpen={setOpenDeleteConfirmation}
                    onConfirm={handleDelete}
                    onCancel={() => setOpenDeleteConfirmation(false)}
                    showCancelButton={true}
                    confirmButtonText={'Delete'}
                    title="Delete Webhooks Definitions">
        <div className="mt-4">
          <p className="text-sm text-gray-500">
            Are you sure you want to delete the selected webhooks definitions?
          </p>
        </div>
      </GenericModal>

    </div>
  )
}

export default Webhooks;
import React, { useEffect, useState } from 'react';
import MultipleSelectField from '../../../../components/MultipleSelectField';
import GenericSidebar from '../../../../components/Modals/genericSidebar';

const SelectFolder = ({ folders, selectedFolders, open, toggleOpen, onSave }) => {
  const [foldersIds, setFoldersIds] = useState(selectedFolders || []);
  const [parentId, setParentId] = useState(null);
  const [options, setOptions] = useState([]);

  const handleSidebar = () => {
    toggleOpen(!open);
  };

  const handleOnSave = () => {
    onSave(foldersIds);
  };

  useEffect(() => {
    if (folders) {
      let result = folders
        .filter((f) => f.parentId === parentId)
        .map((f) => ({
          name: f.name,
          value: f.id
        }));

      setOptions(result);
    }
  }, [folders, parentId]);

  useEffect(() => {
    setFoldersIds(selectedFolders);
  }, [selectedFolders]);

  return (
    <GenericSidebar open={open} width="max-w-2xl" setOpen={handleSidebar} title="Select folders">
      <div className="flex w-full overflow-y-auto h-full">
        <div className="w-full">
          <div className="py-6 space-y-6 sm:py-0 sm:divide-y sm:divide-gray-200">
            <div className="pt-4 px-4 sm:px-6">
              <MultipleSelectField
                label="Folders"
                name="foldersIds"
                customClassNames=""
                parentId={parentId}
                values={foldersIds}
                folders={folders}
                options={options}
                onChange={(values) => {
                  setParentId(values.parentId);
                  setFoldersIds(values.foldersIds);
                }}
                onParentIdChange={() => {
                  const folder = folders.find((f) => f.id === parentId);
                  setParentId(folder.parentId);
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex-shrink-0 px-4 border-t bg-gray-50 border-gray-200 py-5 sm:px-6">
        <div className="space-x-3 flex justify-end">
          <button
            type="button"
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 "
            onClick={handleSidebar}
          >
            Cancel
          </button>
          <button
            type="button"
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-brand-pink hover:bg-brand-pink-hover"
            onClick={handleOnSave}
          >
            Submit
          </button>
        </div>
      </div>
    </GenericSidebar>
  );
};

export default SelectFolder;
import { get, BaseUrl, post, destroy, put } from './api';

export const getRoles = async () => {
    return await get(`api/CustomerRole/Roles`, null,
        BaseUrl.Centerpoint);
}

export const getRole = async (roleId) => {
    return await get(`api/CustomerRole/Role/${roleId}`, null,
        BaseUrl.Centerpoint);
}

export const getUsersInRole = async (roleId) => {
    return await get(`api/CustomerRole/Users/${roleId}`, null,
        BaseUrl.Centerpoint);
}

export const getInvitesInRole = async (roleId) => {
    return await get(`api/CustomerRole/Invites/${roleId}`, null,
        BaseUrl.Centerpoint);
}

export const getRoleTemplate = async () => {
    return await get(`api/CustomerRole/Role`, null,
        BaseUrl.Centerpoint);
}

export const saveRole = async (role) => {
    return await post(`api/CustomerRole/Role`, role , null,
        BaseUrl.Centerpoint);
}

export const deleteRole = async (roleId) => {
    return await destroy(`api/CustomerRole/Role/${roleId}`, null,
        BaseUrl.Centerpoint);
}

export const moveUsersToRole = async (fromRoleId, toRoleId) => {
    return await put(`api/CustomerRole/Role/Move/${fromRoleId}/${toRoleId}`, null,
        BaseUrl.Centerpoint);
}


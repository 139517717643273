import { useState, useEffect } from "react";
import * as yup from "yup";
import { useToastAction } from "../../../../../hooks/useToastAction";

import { SidebarSelect } from "../../../../../components/SidebarSelect";
import { SidebarTextbox } from "../../../../../components/SidebarTextbox";
import { SidebarFileUpload } from "../../../../../components/SidebarFileUpload";
import { SidebarPassword } from "../../../../../components/SidebarPassword";

export default function Properties({ soapSettings, allSoapSettings, allCustomerEnvironments, setOpen, onChange, onFileUpload, onSubmit }) {
    const [errors, setErrors] = useState({});
    const [environmentOptions, setEnvironmentOptions] = useState([]);

    const loadAction = useToastAction();

    const loadBaseData = async () => {
        loadAction.execute(async () => {
            const environments = allCustomerEnvironments.filter(({ value }) =>
                allSoapSettings.find(settings => settings.customerEnvironment === value) === undefined ||
                soapSettings.customerEnvironment === value);
            setEnvironmentOptions(environments);
            if (!soapSettings.id) {
                soapSettings.customerEnvironment = environments[0].value;
            }
        }, "Failed to load")
    }

    useEffect(() => {
        loadBaseData();
    }, [])

    let schema = yup.object().shape({
        endpoint: yup.string().url().required("Endpoint is required"),
        certificate: yup.string().required("Certificate is required"),
        certificatePassword: yup.string().required("Certificate Password is required"),
        customerEnvironment: yup.string().required("Customer Environment is required"),
    });

    const validate = async () => {
        var isValid = false

        await schema
            .validate(soapSettings, { abortEarly: false })
            .then(function () {
                isValid = true;
                setErrors({});
            })
            .catch(function (err) {
                var newErrors = {}

                err?.inner?.forEach(element => {
                    newErrors[element.path] = `${element.errors[0]}`
                });

                setErrors(newErrors);
            });

        return isValid;
    }

    const handleSubmit = async () => {
        var isValid = await validate();
        if (isValid) {
            soapSettings.customerEnvironment = Number(soapSettings.customerEnvironment);
            onSubmit();
        }
    }

    return (
        <>
            <div className="flex w-full overflow-y-auto h-full">
                <div className="w-full">

                    <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                        <div className={"py-5"}>
                            <SidebarSelect
                                name="customerEnvironment"
                                value={soapSettings.customerEnvironment}
                                label={"Customer Environment"}
                                onChange={onChange}
                                error={errors.customerEnvironment}
                                options={environmentOptions}
                            />

                            <SidebarTextbox
                                name="endpoint"
                                value={soapSettings.endpoint}
                                label={"Endpoint"}
                                onChange={onChange}
                                error={errors.endpoint}
                            />

                            <SidebarFileUpload
                                name="certificate"
                                label={"Certificate"}
                                fileExtension=".pfx"
                                onChange={onFileUpload}
                                error={errors.certificate}
                            />
                            {soapSettings.certificate &&
                                <div className="text-sm text-gray-500 grid grid-cols-3 gap-y-1 gap-x-1 px-4 sm:px-6 sm:py-1">
                                    <div className="font-medium">Certificate Uploaded!</div><div className="col-span-2"></div>
                                </div>
                            }

                            <SidebarPassword
                                name="certificatePassword"
                                value={soapSettings.certificatePassword}
                                label={"Certificate Password"}
                                onChange={onChange}
                                error={errors.certificatePassword}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* Action buttons */}
            <div className="flex-shrink-0 px-4 border-t bg-gray-50 border-gray-200 py-5 sm:px-6">
                <div className="space-x-3 flex justify-end">
                    <button
                        type="button"
                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 "
                        onClick={() => setOpen(false)}
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-brand-pink hover:bg-brand-pink-hover"
                        onClick={handleSubmit}
                    >
                        Ok
                    </button>
                </div>
            </div>
        </>
    );
}

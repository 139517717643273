import { useState } from 'react'
import { Switch } from '@headlessui/react'

export function Toggle({enabled:enabled,onToggleChange:onToggleChange}) {
  return (
    <Switch
      checked={enabled}
      onChange={onToggleChange}
      className={`${
        enabled ? 'bg-green-400' : 'bg-gray-200'
      } relative inline-flex items-center h-6 rounded-full w-11 m-2`}
    >
      <span
        className={`${
          enabled ? 'translate-x-6' : 'translate-x-1'
        } inline-block w-4 h-4 transform bg-white rounded-full`}
      />
    </Switch>
  )
}
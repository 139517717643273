import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { deleteGroup, getUsersInGroup } from '../../../../api/groups';
import { deleteRole, getInvitesInRole, getUsersInRole, moveUsersToRole } from '../../../../api/roles';
import Loader from '../../../../components/Loader';
import MenuContextList from '../../../../components/MenuContextList';
import GenericModal from '../../../../components/Modals/genericModal';
import { useToastAction } from '../../../../hooks/useToastAction';

export default function GroupsTable({ groups, loading, onEditEvent, onDeleteEvent }) {

    const loadActionUpdate = useToastAction()
    const [confirmDeleteGroupModalOpen, setConfirmDeleteGroupModalOpen] = useState(false)
    const [activeRow, setActiveRow] = useState()
    const [usersInGroup, setUsersInGroup] = useState([]);
    const [confirmDeleteButtonText, setConfirmDeleteButtonText] = useState("Delete group")

    const handleDelete = async (group) => {
        loadActionUpdate.execute(async () => {
            const usersInGroupCollection = await getUsersInGroup(group.id);
            setUsersInGroup(usersInGroupCollection || []);
            if (usersInGroupCollection.length > 0)
                setConfirmDeleteButtonText("Delete group");
            else {
                setConfirmDeleteButtonText("Delete group");
            }
        }, 'Failed fetch users connected to group')
        setActiveRow(group);
        setConfirmDeleteGroupModalOpen(true);
    }

    const deleteDonfirmed = async () => {

        loadActionUpdate.execute(async () => {
            await deleteGroup(activeRow.id);
            setConfirmDeleteGroupModalOpen(false);
            setActiveRow(null);
            onDeleteEvent();
        }, 'Failed to delete group')
    }

    const TableRow = ({ group }) => {
        return (
            <tr key={group.id}>
                <td className="px-6 py-4 text-sm text-gray-500">
                    <Link to={`/admin/team/group/${group.id}`} className="font-semibold text-indigo-600 hover:text-indigo-900 cursor-pointer">{group.name}</Link>
                </td>
                <td className="px-6 py-4 text-right text-sm font-medium">
                    {
                        (
                            group && (
                                <MenuContextList element={group} actions={[
                                    {
                                        name: "Edit",
                                        href: `/admin/team/group/${group.id}`
                                    }, {
                                        name: "Delete",
                                        onClick: () => handleDelete(group)
                                    }
                                ]
                                } />
                            )
                        )
                    }

                </td>
            </tr>
        );
    }

    return (
        <>
            <div className="relative z-0">
                <div className="-my-2 sm:-mx-6 lg:-mx-8 overflow-x-auto">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow-sm border border-gray-200 sm:rounded-lg overflow-hidden">
                            <table className="min-w-full divide-y divide-gray-200">
                                <tbody className="bg-white divide-y divide-gray-20">
                                    {
                                        !loading && (groups.map((u, i) => {
                                            return <TableRow group={u} key={u.id} />
                                        }))
                                    }
                                    {
                                        loading && (<tr>
                                            <td colSpan="5">
                                                <div className="text-center py-6"><Loader /></div>
                                            </td>
                                        </tr>)
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <GenericModal
                title={`Do you want to delete this group ?`}
                setOpen={setConfirmDeleteGroupModalOpen}
                open={confirmDeleteGroupModalOpen}
                confirmButtonText={confirmDeleteButtonText}
                showCancelButton={true}
                onConfirm={e => {
                    deleteDonfirmed();
                }}
                onCancel={e => {
                    setConfirmDeleteGroupModalOpen(false);
                }}
            >
                <div className="flex flex-row justify-center py-6">
                    <div className="ml-4">
                        <div className="text-sm text-gray-600 flex items-center">
                            <div>
                                <div className="flex flex-col">
                                    <div>Please confirm that you want to delete the group: <b> {activeRow?.name}</b></div>
                                    {
                                        usersInGroup.length !== 0 && (
                                            <div>There is {usersInGroup.length} users in this group</div>
                                        )
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </GenericModal>
        </>
    );
}

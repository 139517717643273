import React, { useContext, useEffect, useState } from 'react'
import {
    CheckIcon as CheckIconOutline,
} from '@heroicons/react/outline';
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { getCustomerPlanCurrent, getPlanTiers } from '../../api/plan';
import { DefaultLayoutContext } from '../../contexts';
// import PlanTier from './planTier';
import { useHistory } from 'react-router-dom';
import PlanTier from './planTier';
import { tailwindToast } from '../../components/Toast/tailwindToast';
export default function Plans() {
    const { oidcUser, logout } = useReactOidc();
    const [planTiers, setPlanTiers] = useState([]);
    const [currentPlan, setCurrentPlan] = useState();
    const [loading, setLoading] = useState(true);
    const history = useHistory();
    const defaultLayoutContext = useContext(DefaultLayoutContext);
    const features = [
        {
            name: 'Invite team members',
            description: 'Tempor tellus in aliquet eu et sit nulla tellus. Suspendisse est, molestie blandit quis ac. Lacus.',
        },
        {
            name: 'Notifications',
            description: 'Ornare donec rhoncus vitae nisl velit, neque, mauris dictum duis. Nibh urna non parturient.',
        },
        {
            name: 'List view',
            description: 'Etiam cras augue ornare pretium sit malesuada morbi orci, venenatis. Dictum lacus.',
        },
        // {
        //     name: 'Boards',
        //     description: 'Interdum quam pulvinar turpis tortor, egestas quis diam amet, natoque. Mauris sagittis.',
        // }
    ];

    const loadPlansData = async () => {
        try {
            const currentPlan = await getCustomerPlanCurrent();
            const tiers = await getPlanTiers();
            setCurrentPlan(currentPlan);
            setPlanTiers(tiers);
        } catch (error) {
            tailwindToast.error('Failed to load data');
        } finally {
            setLoading(false);
        }
    }
    const isNewCustomer = () => {
        return defaultLayoutContext?.profileOverview.orgNumber && defaultLayoutContext.customerDetails.licenses.length === 0;
    }
    useEffect(() => {
        loadPlansData();
    }, []);
    const handleBuyPlanTier = (event, planId) => {
        event.preventDefault();
        history.push(`/plans/purchase/${planId}`)
    }
    return (
        <>
            {
                isNewCustomer() &&
                (<header>
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="flex-1 min-w-0 mb-5 sm:mb-8 md:mb-10">
                            <h2 className="mb-4 text-2xl font-bold leading-7 text-gray-900 sm:text-4xl sm:truncate">Welcome</h2>
                            <h3 className="text-lg leading-6 font-medium text-gray-900">To get started, pick a plan that suits you</h3>
                        </div>
                    </div>
                </header>)
            }
            <div className="mx-auto max-w-7xl">
                {/* Pricing with four tiers and toggle */}
                <div className="">
                    <div className="pt-12 sm:pt-18 md:pt-24 px-4 sm:px-6 lg:px-8">
                        <div className="sm:flex sm:flex-col sm:align-center">
                            <h1 className="text-5xl font-extrabold text-gray-900 sm:text-center">Pricing Plans</h1>
                            <p className="mt-5 text-xl text-gray-500 sm:text-center">
                                Start building for free, then add a site plan to go live. Account plans unlock additional features.
                            </p>
                        </div>
                        <div className="flex items-stretch justify-center flex-wrap mt-12 sm:mt-16 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0">
                            {(planTiers.map((tier) => (
                                <PlanTier key={tier.planName} tier={tier} currentPlan={currentPlan}/>
                            )))}
                        </div>
                    </div>

                    {/* Feature list */}
                    {/* <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8"> */}
                        {/* <div className="max-w-3xl mx-auto text-center"> */}
                            {/* <h2 className="text-3xl font-extrabold text-gray-900">All-in-one platform</h2> */}
                            {/* <p className="mt-4 text-lg text-gray-500">
                                Ac euismod vel sit maecenas id pellentesque eu sed consectetur. Malesuada adipiscing sagittis vel nulla
                                nec.
                            </p> */}
                        {/* </div> */}
                        {/* <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-3 lg:gap-x-8"> */}
                            {/* {features.map((feature) => ( */}
                                {/* <div key={feature.name} className="relative mx-auto"> */}
                                    {/* <dt> */}
                                        {/* <CheckIconOutline className="absolute h-6 w-6 text-green-500" aria-hidden="true" /> */}
                                        {/* <p className="ml-9 text-lg leading-6 font-medium text-gray-900">{feature.name}</p> */}
                                    {/* </dt> */}
                                    {/* <dd className="mt-2 ml-9 flex text-base text-gray-500 lg:py-0 lg:pb-4">{feature.description}</dd> */}
                                {/* </div> */}
                            {/* ))} */}
                        {/* </dl> */}
                    {/* </div> */}
                </div>

            </div>
        </>
    )
}

import React, { useState, Fragment } from "react";
import { useParams } from "react-router-dom";
import { format } from "date-fns";

import "./styles.scss";
import Properties from "./Tabs/properties";
import { XIcon } from "@heroicons/react/outline";
import { Dialog, Transition } from "@headlessui/react";
import { useClassNames } from "../../../hooks/useClassNames";
import GenericSidebar from "../../../components/Modals/genericSidebar";

export default function Sidebar({ open, setOpen, privateConnection, allPrivateConnections, setPrivateConnection, allCustomerEnvironments, onSubmit }) {
  const [activeTab, setActiveTab] = useState("properties");
  const classNames = useClassNames();

  const handleChange = e => {
    const { name, value } = e.target;
    console.log(value)

    setPrivateConnection(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleOpen = () => {
    if (open)
      setActiveTab('properties')

    setOpen(!open)
  }

  const renderActiveTab = () => {
    switch (activeTab) {
      case "properties":
        return <Properties allPrivateConnections={allPrivateConnections} privateConnection={privateConnection} allCustomerEnvironments={allCustomerEnvironments}
          onChange={handleChange} setOpen={handleOpen} onSubmit={onSubmit} />;
      //   case "history":
      //     return <History document={document} setOpen={handleOpen} onSubmit={onSubmit} />;
      default:
        return <></>;
    }
  };

  return (
    <>
      {privateConnection && (
        <GenericSidebar
          open={open}
          setOpen={handleOpen}
          title={`Editing - ${privateConnection.name}`}
          subTitle={
            privateConnection.modifiedByName && privateConnection.modifiedTime && (
              <p className="text-sm text-white">
                Last updated by{" "}
                <span className={"font-bold"}>
                  {privateConnection.modifiedByName}{" "}
                </span>{" "}
                at{" "}
                <span className={"font-bold"}>
                  {format(
                    new Date(privateConnection.modifiedTime),
                    "MM/dd/yyyy - HH:mm"
                  )}
                </span>
              </p>
            )
          }
        >
          <form className="h-full flex flex-col">
            <div className="flex-shrink-0">
              <div className="mt-5 border-b border-gray-200">
                <div className="px-6">
                  <nav
                    className="-mb-px flex space-x-6"
                    x-descriptions="Tab component"
                  >
                    {[
                      {
                        name: "Properties",
                        tab: "properties",
                        current: activeTab === "properties",
                      },
                    ]
                      .filter((m) => !m.hide)
                      .map((tab) => (
                        <a
                          key={tab.name}
                          onClick={() => setActiveTab(tab.tab)}
                          className={classNames.classNames(
                            tab.current
                              ? "border-black text-black"
                              : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                            "py-4 w-1/2 px-1 text-center border-b-2 font-medium text-sm cursor-pointer"
                          )}
                        >
                          {tab.name}
                        </a>
                      ))}
                  </nav>
                </div>
              </div>
            </div>

            {renderActiveTab()}

          </form>
        </GenericSidebar>

      )}
    </>
  );
}

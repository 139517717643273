import { ChevronDownIcon, ChevronUpIcon, XIcon } from '@heroicons/react/outline';
import SelectFolder from '../SelectFolder';
import React, { useState } from 'react';
import { useClassNames } from '../../../../../hooks/useClassNames';
import { Field } from 'formik';

const StoringTypes = {
  None: 0,
  Optional: 1,
  Mandatory: 2
};

const Storage = ({ folders, initialFolderIds, formikProps, onStoringTypeChange }) => {
  const [openSidebar, setOpenSidebar] = useState(false);

  const { classNames } = useClassNames();

  const handleAddNew = () => {
    setOpenSidebar(true);
  };

  const handleOnSaveFolder = (values) => {
    formikProps.setFieldValue('foldersIds', values);
    setOpenSidebar(false);
  };

  const handleToggleOpen = (value) => {
    setOpenSidebar(value);
  };

  const handleOnDeleteFolder = (f) => {
    const foldersIdsResult = [...formikProps.values.foldersIds];
    const index = foldersIdsResult.indexOf(f.id);

    if (index > -1) {
      foldersIdsResult.splice(index, 1);

      formikProps.setFieldValue('foldersIds', foldersIdsResult);
    }
  };

  const swapOrder = (selectedFolder, newIndex) => {
    const currentItemIndex = formikProps.values.foldersIds.indexOf(selectedFolder.id);
    let newOrderingMappings = [...formikProps.values.foldersIds];

    if (newIndex > 0) {
      let selectedEl = newOrderingMappings[currentItemIndex];

      newOrderingMappings[currentItemIndex] = newOrderingMappings[currentItemIndex + 1];
      newOrderingMappings[currentItemIndex + 1] = selectedEl;
    } else {
      let selectedEl = newOrderingMappings[currentItemIndex];

      newOrderingMappings[currentItemIndex] = newOrderingMappings[currentItemIndex - 1];
      newOrderingMappings[currentItemIndex - 1] = selectedEl;
    }

    formikProps.setFieldValue('foldersIds', newOrderingMappings);
  };

  const handleOnStoringTypeChange = (e) => {
    if (parseInt(e.target.value) !== StoringTypes.None) {
      formikProps.setFieldValue('foldersIds', initialFolderIds);
    } else {
      formikProps.setFieldValue('foldersIds', []);
    }

    onStoringTypeChange(e.target.value);
  };

  return (
    <>
      <div className="space-y-6 sm:space-y-5">
        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4">
          <label htmlFor="" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
            Storage of signed files
          </label>

          <div className="mt-1 sm:col-span-2 sm:mt-0">
            <Field name="storingType">
              {({ field }) => (
                <select
                  onChange={(e) => {
                    field.onChange(e);
                    handleOnStoringTypeChange(e);
                  }}
                  name={field.name}
                  value={field.value.toString()}
                  className="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md focus:ring-gray-400 focus:border-gray-400"
                >
                  <option value="0">None</option>
                  <option value="1">Optional</option>
                  <option value="2">Mandatory</option>
                </select>
              )}
            </Field>
          </div>
        </div>

        {parseInt(formikProps.values.storingType) !== StoringTypes.None && (
          <>
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5">
              <label
                htmlFor=""
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Selected folders in Archive
              </label>

              <div className="mt-1 sm:col-span-2 sm:mt-0">
                {folders.length > 0 ? (
                  <>
                    <div className="mb-4">
                      <button
                        type="button"
                        onClick={handleAddNew}
                        className="flex whitespace-nowrap justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-pink sm:mt-0 sm:col-start-1 sm:text-sm"
                      >
                        Add folder
                      </button>

                      {formikProps.touched?.foldersIds && formikProps.errors?.foldersIds && (
                        <p className="mt-2 text-sm text-red-600">{formikProps.errors.foldersIds}</p>
                      )}
                    </div>

                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      {formikProps.values.foldersIds.length > 0 && (
                        <ul className="col-span-12 sm:col-span-8 mt-2 sm:mt-0 bg-white divide-y divide-gray-20 rounded-md shadow-sm border border-gray-300 border-solid max-h-80 overflow-auto">
                          {formikProps.values.foldersIds.map((el) => {
                            const f = folders.find((f) => f.id === el);
                            const path = f.parentId
                              ? f.fullPath.split('/').slice(0, 2).join('/')
                              : '/';
                            return (
                              <li
                                key={f.id}
                                className="grid grid-cols-8 gap-4 px-4 py-2 text-sm text-gray-700"
                              >
                                <span className="col-span-6 block w-full sm:text-sm ">
                                  {f.name} <span className="ml-2 text-gray-400">{path}</span>
                                </span>

                                <div className="flex justify-around col-span-2">
                                  <button
                                    type="button"
                                    className={classNames(
                                      'ml-3 flex whitespace-nowrap justify-center rounded-md border-0 shadow-sm px-2 py-1 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none  sm:mt-0 sm:col-start-1 sm:text-sm disabled:opacity-25 disabled:cursor-default'
                                    )}
                                    disabled={formikProps.values.foldersIds.indexOf(f.id) < 1}
                                    onClick={() => swapOrder(f, -1)}
                                  >
                                    <ChevronUpIcon className="w-4 h-4 cursor-pointer" />
                                  </button>

                                  <button
                                    type="button"
                                    className="ml-3 flex whitespace-nowrap justify-center rounded-md border-0 shadow-sm px-2 py-1 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none  sm:mt-0 sm:col-start-1 sm:text-sm disabled:opacity-25 disabled:cursor-default"
                                    disabled={
                                      formikProps.values.foldersIds.indexOf(f.id) ===
                                      formikProps.values.foldersIds.length - 1
                                    }
                                    onClick={() => swapOrder(f, 1)}
                                  >
                                    <ChevronDownIcon className="w-4 h-4 cursor-pointer" />
                                  </button>
                                  <button
                                    type="button"
                                    className="ml-3 flex whitespace-nowrap justify-center rounded-md border-0 shadow-sm px-2 py-1 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none  sm:mt-0 sm:col-start-1 sm:text-sm"
                                    onClick={() => handleOnDeleteFolder(f)}
                                  >
                                    <XIcon className="w-4 h-4" />
                                  </button>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </div>
                    <SelectFolder
                      open={openSidebar}
                      folders={folders}
                      selectedFolders={formikProps.values.foldersIds}
                      onSave={handleOnSaveFolder}
                      toggleOpen={handleToggleOpen}
                    />
                    <div></div>
                  </>
                ) : (
                  <>
                    <p className="sm:mt-px sm:pt-2 max-w-4xl text-sm text-gray-500">
                      There is no folders. Please add folder in digital archive integration part.
                    </p>

                    {formikProps.touched?.foldersIds && formikProps.errors?.foldersIds && (
                      <p className="mt-2 text-sm text-red-600">{formikProps.errors.foldersIds}</p>
                    )}
                  </>
                )}
              </div>
            </div>
          </>
        )}

        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5">
          <label htmlFor="" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
            Show download signed file from provider when status is completed
          </label>

          <div className="sm:col-span-2">
            <Field name="showDownloadSignedFileFromProviderWhenStatusIsCompleted">
              {({ field }) => (
                <input
                  name={field.name}
                  type="checkbox"
                  checked={field.value}
                  onChange={field.onChange}
                  className="focus:ring-brand-pink h-4 w-4 text-brand-pink border-gray-300 rounded"
                />
              )}
            </Field>
          </div>
        </div>
      </div>
    </>
  );
};

export default Storage;

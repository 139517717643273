import { post, get } from './api';

export const GetReleasePackagesSimplifiedList = async () => {
  return await get(`Version/ReleasePackagesSimplifiedList`);
};

export const GetReleasePackages = async (request) => {
  return await post(`Version/ReleasePackages`, request);
};

export const GetReleasePackage = async (packageId) => {
  return await get(`Version/ReleasePackages/${packageId}`);
};

export const SendNotification = async (request) => {
  return await post(`Version/SendNotification`, request);
};
import { Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react'
import { getLanguages } from '../../../api/language';
import { getMyUser, updateUserProfile } from '../../../api/myUser';
import { tailwindToast } from '../../../components/Toast/tailwindToast';
import { AuthenticatedRoutesContext } from '../../../contexts';
import { Link } from 'react-router-dom';
import { Section, sectionVariants } from '../../../layouts/DefaultLayoutV2/section';
import { ButtonV2, CoreButtonTypes } from '@metaforcelabs/metaforce-core';

export default function UserProfile() {
    const authContext = useContext(AuthenticatedRoutesContext);
    const [languages, setLanguages] = useState([]);
    const [userProfile, setUserProfile] = useState({
        firstName: '', lastName: '', userProfileImage: '', phoneNumber: '', email: '', languageCode: ''
    });
    const loadData = async () => {
        const { firstName, lastName, userProfileImage, phoneNumber, email, languageCode } = await getMyUser();
        const langData = await getLanguages();
        setLanguages(langData);
        setUserProfile({
            firstName, lastName, userProfileImage, phoneNumber, email, languageCode
        });
    }

    useEffect(() => {
        console.log(authContext);
        loadData();
    }, []);
    const handleUserImageChange = (event, setFieldValue) => {
        const file = event.target.files[0];
        if (file.size > 550000) {
            tailwindToast.error("Maximum file size 500 KB");
            return;
        }
        var reader = new FileReader();
        reader.onloadend = () => {
            const base64String = reader.result
                .replace('data:', '')
                .replace(/^.+,/, '');
            const imageData = `data:${file.type};base64,${base64String}`
            setFieldValue(event.target.name, imageData);
        };
        reader.readAsDataURL(file);

    }

    const handleSubmitForm = async (values) => {
        try {
            await updateUserProfile(values);
            setUserProfile(values);
            authContext.triggerContextChange();
            tailwindToast.success("User profile updated");
        } catch (error) {
            tailwindToast.error("Failed to update user profile");
        }
    }
    return (
        <main>
            <Section className={"sm:py-6"} variant={sectionVariants.secondary}>
                <div className='bg-gray-50'>
                    <h1 className="text-3xl font-extrabold text-blue-gray-900">Personal Settings</h1>
                </div>
            </Section>
            <Section>
                <Formik
                    initialValues={userProfile}
                    enableReinitialize={true}
                    onSubmit={async (values, { setSubmitting }) => {
                        await handleSubmitForm(values);
                        setSubmitting(false);
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        resetForm,
                        validateForm,
                        setFieldValue
                        /* and other goodies */
                    }) => (
                        <form className="mt-6 space-y-8 divide-y divide-y-blue-gray-200"
                            onSubmit={e => {
                                e.preventDefault();
                                handleSubmit(e)
                            }}
                            onReset={resetForm} >
                            <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
                                <div className="sm:col-span-6">
                                    <h2 className="text-xl font-medium text-blue-gray-900">Person</h2>
                                    {/* <p className="mt-1 text-sm text-blue-gray-500">
                            This information will be displayed publicly so be careful what you share.
                        </p> */}
                                </div>

                                <div className="sm:col-span-3">
                                    <label htmlFor="firstName" className="block text-sm font-medium text-blue-gray-900">
                                        First name
                                    </label>
                                    <input
                                        type="text"
                                        name="firstName"
                                        id="firstName"
                                        autoComplete="given-name"
                                        value={values.firstName}
                                        onChange={handleChange}
                                        required
                                        disabled={isSubmitting}
                                        className="disabled:opacity-50 mt-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 disabled:opacity-50 block w-full sm:text-sm border-gray-300 rounded-md"
                                    />
                                </div>
                                <div className="sm:col-span-3">
                                    <label htmlFor="lastName" className="block text-sm font-medium text-blue-gray-900">
                                        Last name
                                    </label>
                                    <input
                                        type="text"
                                        name="lastName"
                                        id="lastName"
                                        autoComplete="family-name"
                                        value={values.lastName}
                                        onChange={handleChange}
                                        required
                                        disabled={isSubmitting}
                                        className="disabled:opacity-50 mt-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                    />
                                </div>
                                <div className="sm:col-span-3">
                                    <label htmlFor="firstName" className="block text-sm font-medium text-blue-gray-900">
                                        Language
                                    </label>
                                    <select
                                        name="languageCode"
                                        id="languageCode"
                                        value={values?.languageCode}
                                        onChange={handleChange}
                                        required
                                        disabled={isSubmitting}
                                        className="disabled:opacity-50 mt-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 disabled:opacity-50 block w-full sm:text-sm border-gray-300 rounded-md">
                                        {
                                            languages?.map(l => (<option value={l.languageCode}>{l.name}</option>))
                                        }
                                    </select>
                                </div>

                                <div className="sm:col-span-6">
                                    <label htmlFor="photo" className="block text-sm font-medium text-blue-gray-900">
                                        Photo
                                    </label>
                                    <div className="mt-1 flex items-center">
                                        {
                                            !values.userProfileImage && (
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                                                </svg>
                                            )
                                        }
                                        {
                                            values.userProfileImage && (
                                                <img
                                                    className="inline-block h-12 w-12 rounded-full"
                                                    src={values.userProfileImage}
                                                    alt=""
                                                />
                                            )
                                        }
                                        <div className="ml-4 flex">
                                            <div className="relative bg-white py-2 px-3 border border-blue-gray-300 rounded-md shadow-sm flex items-center cursor-pointer hover:bg-blue-gray-50 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-blue-gray-50 focus-within:ring-blue-500">
                                                <label
                                                    htmlFor="user-photo"
                                                    className="cursor-pointer relative text-sm font-medium text-blue-gray-900"
                                                >
                                                    <span className="cursor-pointer">Change</span>
                                                    <span className="sr-only"> user photo</span>
                                                </label>
                                                <input
                                                    id="userProfileImage"
                                                    name="userProfileImage"
                                                    type="file"
                                                    onChange={e => {
                                                        handleUserImageChange(e, setFieldValue);
                                                    }}
                                                    disabled={isSubmitting}
                                                    className="cursor-pointer absolute inset-0 w-full h-full opacity-0 border-gray-300 rounded-md"
                                                />
                                            </div>
                                            <button
                                                type="button"
                                                onClick={e => {
                                                    setFieldValue("userProfileImage", null)
                                                }}
                                                disabled={isSubmitting}
                                                className="disabled:opacity-50 hover:text-red-600 ml-3 bg-transparent py-2 px-3 border border-transparent rounded-md text-sm font-medium text-blue-gray-900 hover:text-blue-gray-700 focus:outline-none focus:border-blue-gray-300 focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-gray-50 focus:ring-blue-500"
                                            >
                                                Remove
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="pt-8 grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
                                <div className="sm:col-span-6">
                                    <h2 className="text-xl font-medium text-blue-gray-900">Contact</h2>
                                    {/* <p className="mt-1 text-sm text-blue-gray-500">
                                        This information will be displayed publicly so be careful what you share.
                                    </p> */}
                                </div>

                                <div className="sm:col-span-3">
                                    <label htmlFor="email-address" className="block text-sm font-medium text-blue-gray-900">
                                        Email address
                                    </label>
                                    <input
                                        type="text"
                                        name="email-address"
                                        id="email-address"
                                        autoComplete="email"
                                        readOnly
                                        title="Read only"
                                        value={values.email}
                                        disabled={isSubmitting}
                                        className="disabled:opacity-50 mt-1 shadow-sm opacity focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                    />
                                </div>

                                <div className="sm:col-span-3">
                                    <label htmlFor="phoneNumber" className="block text-sm font-medium text-blue-gray-900">
                                        Phone number
                                    </label>
                                    <input
                                        type="text"
                                        name="phoneNumber"
                                        id="phoneNumber"
                                        autoComplete="tel"
                                        value={values.phoneNumber}
                                        onChange={handleChange}
                                        required
                                        disabled={isSubmitting}
                                        className="disabled:opacity-50 mt-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                    />
                                </div>
                            </div>

                            <div className="pt-8 flex justify-end space-x-4">

                                <Link
                                    to={'/'}
                                    key={'Dashboard'}
                                >
                                    <ButtonV2
                                        htmlType='button'
                                        type={CoreButtonTypes.white}
                                        label={<>
                                            Cancel
                                        </>}

                                    >

                                    </ButtonV2>
                                </Link>
                                <ButtonV2
                                    htmlType='submit'
                                    type={CoreButtonTypes.cta}
                                    label={<>
                                        {
                                            isSubmitting && (
                                                <svg className="animate-spin -ml-1 mr-3 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>
                                            )
                                        }
                                        Save
                                    </>}

                                >

                                </ButtonV2>
                            </div>
                        </form>)}
                </Formik>
            </Section>
        </main>
    )
}

import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';
import { Switch, Transition } from '@headlessui/react'
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { toast } from "react-toastify";
import { tailwindToast } from '../../../../components/Toast/tailwindToast';
import Sidebar from "./sidebar";
import { format } from "date-fns";
import { useToastAction } from '../../../../hooks/useToastAction';

import * as InteractApi from "../../../../api/interact";
import GenericModal from '../../../../components/Modals/genericModal';

import "./styles.scss";
import { useClassNames } from "../../../../hooks/useClassNames";
import { ContentWrapper, CoreButton, PageHeader } from "@metaforcelabs/metaforce-core";

export default function Soap() {
    const [openSidebar, setOpenSidebar] = useState(false);
    const [allSoapSettings, setAllSoapSettings] = useState([]);
    const [activeSoapSettings, setActiveSoapSettings] = useState();
    const [selectedSoapSettings, setSelectedSoapSettings] = useState([])
    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
    const [allCustomerEnvironments, setAllCustomerEnvironments] = useState([])

    const loadAction = useToastAction();
    const saveAction = useToastAction();
    const deleteAction = useToastAction();

    const { classNames } = useClassNames();

    const loadBaseData = async () => {
        loadAction.execute(async () => {
            const environments = await InteractApi.getInteractEnvironments();
            setAllCustomerEnvironments(environments);

            const soapSettings = await InteractApi.getSoapSettingsByCustomerId();
            setAllSoapSettings(soapSettings);
        }, "Failed to load")
    }

    useEffect(() => {
        loadBaseData();
    }, [])

    const handleSidebar = () => {
        if (openSidebar) {
            setActiveSoapSettings(null);
        }

        setOpenSidebar(!openSidebar);
    };

    const toggleSelectSoapSettings = (settings) => {
        setSelectedSoapSettings(prev => {
            const idx = selectedSoapSettings.findIndex(x => x === settings);
            if (idx === -1) {
                return [...prev, settings];
            }
            return [...prev.filter(item => item !== settings)];
        })
    }

    const toggleAllSoapSettings = () => {
        selectedSoapSettings.length === allSoapSettings.length ?
            setSelectedSoapSettings([]) : setSelectedSoapSettings(allSoapSettings)
    }

    const handleCreate = async () => {
        const newSoapSettings = {
            endpoint: "",
            certificate: "",
            certificatePassword: "",
            customerEnvironment: "",
            forcePDFAOnAllDocuments: false,
            selected: false
        }

        if (allCustomerEnvironments.find(({ value }) => allSoapSettings.find(settings => settings.customerEnvironment === value) === undefined)) {
            setActiveSoapSettings(newSoapSettings);
            setOpenSidebar(true);
        } else {
            toast.error("It can not create a new Interact SOAP Client settings because all customer environments are already used.");
        }
    }

    const handleSave = async () => {
        if (activeSoapSettings.id) {
            const soapSettings = await InteractApi.getSoapSettings(activeSoapSettings.id);
            if (soapSettings && soapSettings.updatedDate !== activeSoapSettings.updatedDate) {
                tailwindToast.error("It can not save because Interact SOAP Client settings has been updated by others. Please refresh the page.");
                return;
            }
        }

        setOpenSidebar(false)

        saveAction.execute(async () => {
            if (activeSoapSettings.id) {
                await InteractApi.updateSoapSettings(activeSoapSettings);
            } else {
                await InteractApi.createSoapSettings(activeSoapSettings);
            }
            await loadBaseData();
        }, "Failed to save Interact SOAP Client settings", "Interact SOAP Client settings saved")
    }

    const openDelete = async () => {
        setOpenDeleteConfirmation(true);
    }

    const handleDelete = async () => {
        deleteAction.execute(async () => {
            setOpenDeleteConfirmation(false);
            var soapSettingsIdsToDelete = selectedSoapSettings.map(soapSettings => soapSettings.id);
            setSelectedSoapSettings([]);
            await InteractApi.deleteSoapSettings(soapSettingsIdsToDelete)
            await loadBaseData();
        }, "Failed to delete Interact SOAP Client settings", "Interact SOAP Client settings deleted")
    }

    const handleSoapSettingsRow = (soapSettings) => {
        setOpenSidebar(true);
        setActiveSoapSettings(soapSettings);
    };

    return (
        <>
            <ContentWrapper>
              <PageHeader title="Interact SOAP Client Setup" description="The SOAP Client connections to specific Interact environments."/>

              {/* Start main area*/}

              <div className="mt-10 shadow-sm overflow-hidden border border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                          <tr>
                              <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                  <input
                                      id="selectAll"
                                      name="selectAll"
                                      type="checkbox"
                                      onClick={e => toggleAllSoapSettings()}
                                      checked={allSoapSettings.length > 0 && selectedSoapSettings.length === allSoapSettings.length}
                                      className="focus:ring-brand-pink h-4 w-4 text-brand-pink border-gray-300 rounded"
                                  />
                              </th>
                              <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                  Customer Environment
                              </th>
                              <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                  Created
                              </th>
                              <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                  Created by
                              </th>
                          </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                          {allSoapSettings &&
                              allSoapSettings.map((soapSettings) => (
                                  <tr
                                      key={soapSettings.id}
                                      className={classNames(
                                          soapSettings.id === activeSoapSettings?.id
                                              ? "bg-gray-100"
                                              : "",
                                          "hover:bg-gray-100 cursor-pointer"
                                      )}
                                  >
                                      <td className="px-6 w-12 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                          <input
                                              id="selected"
                                              name="selected"
                                              type="checkbox"
                                              checked={selectedSoapSettings.findIndex(i => i === soapSettings) !== -1}
                                              onChange={() => toggleSelectSoapSettings(soapSettings)}
                                              className="focus:ring-brand-pink h-4 w-4 text-brand-pink border-gray-300 rounded"
                                          />
                                      </td>
                                      <td className="px-6 py-4 text-sm font-medium text-gray-900" onClick={() => handleSoapSettingsRow(soapSettings)}>
                                          {allCustomerEnvironments.find(({ value }) => value === soapSettings.customerEnvironment)?.name}
                                      </td>
                                      <td className="px-6 py-4 text-sm text-gray-500" onClick={() => handleSoapSettingsRow(soapSettings)}>
                                          {format(
                                              new Date(soapSettings.createdDate),
                                              "MM/dd/yyyy - HH:mm"
                                          )}
                                      </td>
                                      <td className="px-6 py-4 text-sm text-gray-500" onClick={() => handleSoapSettingsRow(soapSettings)}>
                                          {soapSettings.createdByName}
                                      </td>
                                  </tr>
                              ))}
                      </tbody>
                  </table>
              </div>
              <div className='mt-5 flex items-center'>
                  <div className="flex-1" />
                  {
                      selectedSoapSettings.length > 0 && (
                        <CoreButton
                        type="secondary"
                        htmlType="button"
                        label={<div>Delete</div>}
                        htmlClassNames="mr-3"
                        onClick={() => openDelete()}
                      />
                    )
                  }
                  <CoreButton
                    htmlType="button"
                    label={<div>New</div>}
                    onClick={handleCreate}
                  />
              </div>
              {/* End main area */}
            </ContentWrapper>

            <Sidebar
                open={openSidebar}
                setOpen={handleSidebar}
                soapSettings={activeSoapSettings}
                allSoapSettings={allSoapSettings}
                setSoapSettings={setActiveSoapSettings}
                allCustomerEnvironments={allCustomerEnvironments}
                onSubmit={handleSave}
            />

            <GenericModal open={openDeleteConfirmation} setOpen={setOpenDeleteConfirmation}
                onConfirm={handleDelete}
                onCancel={() => setOpenDeleteConfirmation(false)}
                showCancelButton={true}
                confirmButtonText={'Delete'}
                title="Delete Interact SOAP Client Settings">
                <div className="mt-4">
                    <p className="text-sm text-gray-500">
                        Are you sure you want to delete the selected Interact SOAP Client settings?
                    </p>
                </div>
            </GenericModal>
        </>
    );
}

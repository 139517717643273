import { useRouteMatch } from "react-router";
import { NavLink } from "react-router-dom";
import { useClassNames } from "../../hooks/useClassNames";

/**
 * @param {*} SidebarTextbox 
 */
export const SidebarSelect = ({ label, name, value, options, onChange, disabled, error }) => {
  const classNames = useClassNames();

  return (
    <>
      <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-1 sm:gap-3 sm:px-6 sm:py-1">
          <div>
            <label
              htmlFor="project_name"
              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
            >
              {label}
            </label>
          </div>
          <div className="sm:col-span-2">
            <select
              name={name}
              disabled={disabled}
              className={classNames.classNames(
                "block w-full shadow-sm sm:text-sm focus:ring-brand-pink focus:border-brand-pink border-gray-300 rounded-md disabled:opacity-50 ",
                  error 
                  ? "placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 border-red-300 text-red-900 "
                  : ""
              )}
              value={value}
              onChange={onChange}
            >
              {
                options.map((option, index) => {
                  return (<option key={`${option.name}${option.value}`} value={option.value}>{option.name}</option>)
                })
              }
            </select>
            {
              error && 
              <p className="mt-2 text-sm text-red-600" id="email-error">
                {error}
              </p>
            }
          </div>
        </div>
    </>
  );
}
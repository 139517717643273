import React from 'react'
import { cdnImages } from '../../utils/cdnImages'

export default function StudioDisplayArt() {
  return (
    <div className='w-full flex justify-end'>
      <img src={cdnImages.learnSmartform} className="h-48 rounded-3xl" alt="sample" />
    </div>
  )
}


import { get, post, BaseUrl } from "./api";

export const getConfiguration = async () => {
    return await get(
        `api/ViewpointConfiguration`,
        null,
        BaseUrl.Centerpoint
    );
}

export const createConfiguration = async (configuration) => {
    return await post(
        `api/ViewpointConfiguration`,
        configuration,
        null,
        BaseUrl.Centerpoint
    );
}

export const updateConfiguration = async (configuration) => {
    return await post(
        `api/ViewpointConfiguration/update`,
        configuration,
        null,
        BaseUrl.Centerpoint
    );
}

export const getConnectionsByCustomerId = async () => {
    return await get(
        `api/ViewpointConnection/all`,
        null,
        BaseUrl.Centerpoint
    );
}

export const getConnection = async (id) => {
    return await get(
        `api/ViewpointConnection/${id}`,
        null,
        BaseUrl.Centerpoint
    );
}

export const createConnection = async (connection) => {
    return await post(
        `api/ViewpointConnection`,
        connection,
        null,
        BaseUrl.Centerpoint
    );
}

export const updateConnection = async (connection) => {
    return await post(
        `api/ViewpointConnection/update`,
        connection,
        null,
        BaseUrl.Centerpoint
    );
}

export const deleteConnections = async (ids) => {
    return await post(
        `api/ViewpointConnection/delete`,
        ids,
        null,
        BaseUrl.Centerpoint
    );
}

export const checkConnection = async (connection) => {
    return await post(
        `api/ViewpointConnection/check`,
        connection,
        null,
        BaseUrl.Centerpoint
    );
}
import { CheckIcon, DocumentAddIcon, PencilAltIcon } from "@heroicons/react/outline"
import { useState } from "react";
import { useClassNames } from "../../../../hooks/useClassNames";
import { format } from "date-fns";
import Changes from "./changes";

export const History = ({ document,setOpen,onSubmit }) => {
  const classNames = useClassNames();
  const [changes, setChanges] = useState();
  const [openChanges, setOpenChanges] = useState(false);
  let timeline = []

  const iconFromType = (type) => {
    switch (type) {
      case 2:
        return PencilAltIcon
      case 3:
        return CheckIcon  
      
      default:
      case 1:
        return DocumentAddIcon        
    }
  }

  const backgroundFromType = (type) => {
    switch (type) {
      case 2:
        return 'bg-blue-500'
      case 3:
        return 'bg-green-500'  
      
      default:
      case 1:
        return 'bg-gray-400'        
    }
  }

  const contentFromType = (type) => {
    switch (type) {
      case 2:
        return 'updated document'
      case 3:
        return 'published document'  
      
      default:
      case 1:
        return 'created document'        
    }
  }

  const handleOpenChanges = (changes) => {
    setOpenChanges(true);
    setChanges(changes);
  }

  document?.history?.forEach(item => {
    console.log(item.updatedAt)
    timeline.push({
      id: item.Id,
      content: contentFromType(item.type),
      target: item.updatedByName,
      changes: item.changes,
      date: format(
        new Date(item.updatedAt),
        "MM/dd/yyyy - HH:mm"
      ),
      datetime: item.updatedAt,
      icon: iconFromType(item.type),
      iconBackground: backgroundFromType(item.type),
    })
  });
  
  return (
    <>
      <div className="flex w-full overflow-y-auto h-full">
        <div className="w-full flow-root mt-10 px-10">
          <ul className="-mb-8">
            {timeline.reverse().map((event, eventIdx) => (
              <li key={event.id}>
                <div className="relative pb-8">
                  {eventIdx !== timeline.length - 1 ? (
                    <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                  ) : null}
                  <div className="relative flex space-x-3">
                    <div>
                      <span
                        className={classNames.classNames(
                          event.iconBackground,
                          'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
                        )}
                      >
                        <event.icon className="h-5 w-5 text-white" aria-hidden="true" />
                      </span>
                    </div>
                    <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                      <div>
                        <p className="text-sm text-gray-500">
                          <span className="text-black">{event.target}</span> {' '}{event.content}
                        </p>
                        {
                          event?.changes?.length > 0 &&
                            <span onClick={() => handleOpenChanges(event.changes)} className="cursor-pointer">
                              See changes
                            </span>
                        }
                      </div>
                      <div className="text-right text-sm whitespace-nowrap text-gray-500">
                        <time dateTime={event.datetime}>{event.date}</time>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="flex-shrink-0 px-4 border-t bg-gray-50 border-gray-200 py-5 sm:px-6">
        <div className="space-x-3 flex justify-end">
          <button
            type="button"
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 "
            onClick={() => setOpen(false)}
          >
            Cancel
          </button>
          <button
            type="button"
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-brand-pink hover:bg-brand-pink-hover"
            disabled={true}
          >
            Ok
          </button>
        </div>
      </div>

      {
        changes && changes.length > 0 &&
          <Changes changes={changes} open={openChanges} setOpen={setOpenChanges} />
      }

    </>
  )
}
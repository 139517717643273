import React from 'react'
import { useClassNames } from '../../hooks/useClassNames'

export default function DropDownField({ value, name, onChange, disabled, error, options }) {
    const { classNames } = useClassNames();
    return (
        <>
            <select
                className={classNames(
                    "block w-full shadow-sm sm:text-sm focus:ring-brand-pink focus:border-brand-pink border-gray-300 rounded-md disabled:opacity-50 cursor-pointer",
                    error
                        ? "placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 border-red-300 text-red-900 "
                        : ""
                )}
                id={name}
                name={name}
                value={value}
                disabled={disabled}
                onChange={onChange}
            >
                {
                    options?.map(o => (<option value={o.value}>{o.name}</option>))
                }
            </select>
            {
                error &&
                <p className="mt-2 text-sm text-red-600" id="email-error">
                    {error}
                </p>
            }
        </>

    )
}

import { useReactOidc } from '@axa-fr/react-oidc-context';
import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom';
import Loader from '../../../../components/Loader';
import MenuContextList from '../../../../components/MenuContextList';
import GenericModal from '../../../../components/Modals/genericModal';
import { GroupTableContext } from '../../../../contexts';
import { CoreButton } from '@metaforcelabs/metaforce-core';
import Sidebar from './sidebar';

export default function GroupUsers() {
  const { loading, usersInGroup, userStatuses, userRoles, group } = useContext(GroupTableContext);
  const [confirmRemoveGroupUsersModalOpen, setConfirmRemoveGroupUsersModalOpen] = useState(false)
  const tableContext = useContext(GroupTableContext);
  const [selectedUsers, setSelectedUsers] = useState([])
  const [openSidebar, setOpenSidebar] = useState(false);
  const { oidcUser } = useReactOidc();

  const toggleSelectUser = (user) => {
    setSelectedUsers(prev => {
      const idx = selectedUsers.findIndex(x => x === user);
      if (idx === -1) {
        return [...prev, user];
      }
      return [...prev.filter(item => item !== user)];
    })
  }

  const handleSidebar = () => {
    if (openSidebar) {

    }

    setOpenSidebar(!openSidebar);
  };

  const toggleAllUsers = () => {
    selectedUsers.length === usersInGroup.length ?
      setSelectedUsers([]) : setSelectedUsers(usersInGroup)
  }

  const openAddUser = () => {
    // open modal so user can search and add user to group
    setOpenSidebar(true);
  }

  const openRemoveUsers = () => {
    // open modal to check if users are to be deleted
    setConfirmRemoveGroupUsersModalOpen(true);
  }

  const removeConfirmed = async () => {

    tableContext.onUsersRemoved(selectedUsers);
    setSelectedUsers([]);
    setConfirmRemoveGroupUsersModalOpen(false);
  }

  const onUserRemoved = async (user) => {
    // Remove user from group
    var users = [];
    users.push(user);
    tableContext.onUsersRemoved(users);
  }

  const TableRow = ({ user }) => {
    return (
      <tr key={user.id}>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          <input
            id={user.id}
            aria-describedby="select-description"
            name="selected"
            type="checkbox"
            checked={selectedUsers.findIndex(i => i === user) !== -1}
            onChange={() => toggleSelectUser(user)}
            className="focus:ring-indigo-500 h-4 w-4 text-brand-pink border-gray-300 rounded cursor-pointer"
          />
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {user.name}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {user.email}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 hidden sm:table-cell">
          {userRoles.find(s => s.roleId === user.roleId)?.roleName}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 hidden sm:table-cell">
          {userStatuses.find(s => s.id === user.statusId)?.name}
        </td>
        <td className="px-6 py-4 text-right text-sm font-medium">
          {
            (
              (
                <MenuContextList element={user} actions={[{
                  name: "Remove",
                  disabled: selectedUsers.length > 0,
                  onClick: () => onUserRemoved(user)
                }]} />
              )
            )
          }
        </td>
      </tr>
    );
  }

  return (
    <>
      <div className="relative z-0">
        <div className="-my-2 sm:-mx-6 lg:-mx-8 overflow-x-auto">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow-sm overflow-hidden border border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th colSpan={6}>

                      <div className="flex flex-row justify-between items-end">
                        <div className="flex flex-row">
                          <div>
                            <span className='text-gray-900 hidden ml-3 py-0.5 px-2.5 text-s font-normal md:inline-block'>
                              Total
                            </span>
                          </div>
                          <div>
                              <div>
                                <span className='bg-gray-100 text-gray-900 hidden ml-1 py-0.5 px-2.5 rounded-full text-s font-medium md:inline-block'>
                                  {usersInGroup.length || 0}
                                </span>
                              </div>
                          </div>
                        </div>
                        <div className="mr-3 mt-2">
                          {
                            selectedUsers.length === 0 ? (
                              
                              <CoreButton 
                                htmlType="button"
                                onClick={e => {
                                  openAddUser();
                                }}
                                label={<span>Add members</span>}
                              />
                            ) : (
                              <CoreButton type="button"
                                onClick={e => {
                                  openRemoveUsers();
                                }}                                
                                label={<span>Remove</span>}
                                htmlClassNames="bg-white text-gray-700 hover:bg-gray-50 border-gray-300"
                              />
                                
                            )
                          }
                        </div>
                      </div>

                    </th>
                  </tr>
                  {
                    usersInGroup?.length !== 0 ?
                    (
                      <tr>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          <input
                            id="selectAll"
                            aria-describedby="select-description"
                            name="selectAll"
                            type="checkbox"
                            onClick={e => toggleAllUsers()}
                            checked={usersInGroup.length > 0 && selectedUsers.length === usersInGroup.length}
                            className="focus:ring-indigo-500 h-4 w-4 text-brand-pink border-gray-300 rounded cursor-pointer"
                          />
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Name
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Email
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Role
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Status
                        </th>
                      </tr>
                    ):
                    (
                      <tr>
                        <th colSpan={6}  scope="col" className="py-2">
                        </th>
                      </tr>
                    )

                  }
                </thead>
                <tbody className="bg-white divide-y divide-gray-20">
                  {

                    !loading && (usersInGroup.map((u) => {
                      return <TableRow user={u} key={u.id} />
                    }))
                  }
                  {
                    loading && (<tr>
                      <td colSpan={6}>
                        <div className="text-center py-6"><Loader /></div>
                      </td>
                    </tr>)
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <GenericModal
        title={`Do you want to remove users from ${group?.name} ?`}
        setOpen={setConfirmRemoveGroupUsersModalOpen}
        open={confirmRemoveGroupUsersModalOpen}
        confirmButtonText="Remove users"
        showCancelButton={true}
        onConfirm={e => {
          removeConfirmed();
        }}
        onCancel={e => {
          setConfirmRemoveGroupUsersModalOpen(false);
        }}
      >
        <div className="flex flex-row justify-center py-6">
          <div className="ml-4">
            <div className="text-sm text-gray-600 flex items-center">
              <div>
                <div className="flex flex-col">
                  <div>Please confirm that you want to remove the selected {selectedUsers.length === 1 ? 'user' : 'users'} from group</div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </GenericModal>

      <Sidebar
        open={openSidebar}
        setOpen={handleSidebar}
      />

    </>
  );
}

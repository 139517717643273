import {BaseUrl, get, post, put} from "./api";

export const getRetentionPoliciesApi = async () => {
  return get('api/RetentionPolicies', null, BaseUrl.Centerpoint);
}

export const postRetentionPoliciesApi = async (data) => {
  return post('api/RetentionPolicies', data, BaseUrl.Centerpoint);
}

export const updateRetentionPoliciesApi = async (data) => {
  return put('api/RetentionPolicies', data, null, BaseUrl.Centerpoint);
}


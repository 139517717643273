import React, { useState, Fragment } from "react";
import { format } from "date-fns";

import "./styles.scss";
import Properties from "./Tabs/properties";
import { useClassNames } from "../../../../hooks/useClassNames";
import GenericSidebar from "../../../../components/Modals/genericSidebar";
import { SidebarTextbox } from "../../../../components/SidebarTextbox";
import { SidebarToggle } from "../../../../components/SidebarToggle";

export default function Sidebar({ open, setOpen, webhookSettings, setActiveModalItem, onSubmit }) {
  const [activeTab, setActiveTab] = useState("properties");
  const [errors, setErrors] = useState({});
  const { classNames } = useClassNames();

  const handleOpen = () => {
    if (open)
      setActiveTab('properties')
    setOpen(!open)
  }

  const handleChange = e => {
    const { name, value } = e.target;
    setActiveModalItem(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleToggleChange = (name, value) => {
    setActiveModalItem(prevState => ({
        ...prevState,
        [name]: value
    }));
  };

  return (
    <>
      {webhookSettings && (
        <GenericSidebar
          open={open}
          setOpen={handleOpen}
          title={`Editing`}
        >
          <form className="h-full flex flex-col">
            <div className="flex-shrink-0">
              <div className="mt-5 border-b border-gray-200">
                <div className="px-6">
                  <nav
                    className="-mb-px flex space-x-6"
                    x-descriptions="Tab component"
                  >
                    {[
                      {
                        name: "Properties",
                        tab: "properties",
                        current: activeTab === "properties",
                      },
                    ]
                      .map((tab) => (
                        <a
                          key={tab.name}
                          onClick={() => setActiveTab(tab.tab)}
                          className={classNames(
                            tab.current
                              ? "border-black text-black"
                              : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                            "py-4 w-1/2 px-1 text-center border-b-2 font-medium text-sm cursor-pointer"
                          )}
                        >
                          {tab.name}
                        </a>
                      ))}
                  </nav>
                </div>
              </div>
            </div>

            <div className="flex w-full overflow-y-auto h-full">
                <div className="w-full">
                    <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                        <div className={"py-5"}>
                            <SidebarTextbox
                                name="name"
                                value={webhookSettings.name}
                                label={"Name"}
                                onChange={handleChange}
                                error={errors.name}
                            />
                            <SidebarTextbox
                                name="url"
                                value={webhookSettings.url}
                                label={"Webhook Endpoint"}
                                onChange={handleChange}
                                error={errors.url}
                            />
                            <SidebarToggle
                                value={webhookSettings.isActive}
                                label={"Is Active"}
                                onChange={value => handleToggleChange('isActive', value)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex-shrink-0 px-4 border-t bg-gray-50 border-gray-200 py-5 sm:px-6">
                <div className="space-x-3 flex justify-end">
                    <button
                        type="button"
                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 "
                        onClick={() => setOpen(false)}
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-brand-pink hover:bg-brand-pink-hover"
                        onClick={onSubmit}
                    >
                        Ok
                    </button>
                </div>
            </div>

          </form>
        </GenericSidebar>
      )}
    </>
  );
}

import React, { useEffect, useRef } from 'react';
import { PhotographIcon, XIcon } from '@heroicons/react/outline';

const PictureElement = ({ value, name, setFieldValue, isSubmitting }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current = document.getElementById(name);
  });

  const handleUserImageChange = (event, setFieldValue) => {
    const file = event.target.files[0];
    // if (file.size > 550000) {
    //     tailwindToast.error("Maximum file size 500 KB");
    //     return;
    // }
    var reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result.replace('data:', '').replace(/^.+,/, '');
      const imageData = `data:${file.type};base64,${base64String}`;
      setFieldValue(event.target.name, imageData);
    };
    reader.readAsDataURL(file);
  };

  return (
    <>
      <div className="relative">
        <div
          className="inline-block h-16 w-16 overflow-hidden swatch cursor-default"
          //   style={{ backgroundColor: value }}
          //   onClick={() => toggle(true)}
        >
          {value && <img className="h-full" src={value} alt="Default" />}
          {!value && <PhotographIcon className="h-full text-gray-600" />}
        </div>
        {value && (
          <div
            className="absolute bg-white -top-1 -right-1 cursor-pointer p-0.5 rounded-full border border-gray-600 shadow"
            onClick={(e) => {
              setFieldValue(name, null);
              inputRef.current.value = '';
            }}
          >
            <XIcon className="h-3 w-3 text-gray-600" />
          </div>
        )}
      </div>
      <div className="ml-4 flex">
        <div className="relative bg-white py-2 px-3 border border-blue-gray-300 rounded-md shadow-sm flex items-center cursor-pointer hover:bg-blue-gray-50 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-blue-gray-50 focus-within:ring-blue-500">
          <label
            htmlFor="user-photo"
            className="cursor-pointer relative text-sm font-medium text-blue-gray-900"
          >
            <span className="cursor-pointer">Change</span>
            <span className="sr-only"> user photo</span>
          </label>
          <input
            id={name}
            name={name}
            type="file"
            onInput={(e) => {
              handleUserImageChange(e, setFieldValue);
            }}
            // value={value}
            disabled={isSubmitting}
            className="cursor-pointer absolute inset-0 w-full h-full opacity-0 border-gray-300 rounded-md"
          />
        </div>
        {/* <button
                    type="button"
                    onClick={e => {
                        setFieldValue("name", null)
                    }}
                    disabled={isSubmitting}
                    className="disabled:opacity-50 ml-3 bg-transparent py-2 px-3 border border-transparent rounded-md text-sm font-medium text-blue-gray-900 hover:text-blue-gray-700 focus:outline-none focus:border-blue-gray-300 focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-gray-50 focus:ring-blue-500"
                >
                    Remove
                </button> */}
      </div>
    </>
  );
};

export default PictureElement;
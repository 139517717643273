import React from "react";

export default function SidebasTextboxPrefixed({ prefix, label, placeholder, value, defaultValue, name, onChange, disabled, error, onBlur = null }) {
    return (
        <>
            <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-1 sm:gap-3 sm:px-6 sm:py-1">
                <div>
                    <label
                        htmlFor={name}
                        className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                    >
                        {label}
                    </label>
                </div>
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-brand-pink sm:max-w-md">
                    <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">{prefix}</span>
                    <input
                        type="text"
                        name={name}
                        id={name}
                        value={value}
                        onChange={onChange}
                        disabled={disabled}
                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        placeholder={placeholder}
                    />
                </div>
                {
                    error &&
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                        {error}
                    </p>
                }
            </div >
        </>
    )
}
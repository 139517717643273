import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { getNotificationTemplate, getNotificationTemplateTypes, getTemplateLanguages, updateNotificationTemplate } from '../../../../api/notifications';
import GenericModal from '../../../../components/Modals/genericModal';
import { tailwindToast } from '../../../../components/Toast/tailwindToast';
import PlaceHoldersModalContent from './placeHoldersModalContent';
import { ContentWrapper, CoreButton, PageHeader } from '@metaforcelabs/metaforce-core';
import FormikRadioButton from '../../../../components/Form/Formik/FormikRadioButton';

export default function Notifications() {

    const validators = {
        required: (value) => {
            if (!value || value.length < 1) {
                return 'Required';
            }
        }
    }

    const [loadingTypes, setLoadingTypes] = useState(true)
    const [loadingTemplate, setLoadingTemplate] = useState(true);
    const [templateTypes, setTemplateTypes] = useState([]);
    const [selectedTemplateType, setSelectedTemplateType] = useState(null)
    const [notificationTemplate, setNotificationTemplate] = useState(null);
    const [languages, setLanguages] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState(null);
    const [workingTemplate, setWorkingTemplate] = useState(null);
    const [workingTemplateOrg, setWorkingTemplateOrg] = useState(null);
    const [saveError, setSaveError] = useState(null);
    const [placeholdersModalOpen, setPlaceholdersModalOpen] = useState(false);
    const [viewPlaceHolders, setViewPlaceHolders] = useState([]);

    const loadBaseData = async () => {
        try {
            const types = await getNotificationTemplateTypes();
            const languages = await getTemplateLanguages();
            setTemplateTypes(types);
            setLanguages(languages);
            const first = types[0];
            if (first) {
                setSelectedTemplateType(types[0].notificationTemplateTypeId)
            }
            setLoadingTypes(false);
        } catch (error) {
            tailwindToast.error("Failed to load notification templates");
        }
    }
    const loadTemplate = async () => {
        setLoadingTemplate(true);
        let template = await getNotificationTemplate(selectedTemplateType);
        if (!template) {
            const defaultTemplate = {
                notificationTemplateTypeId: selectedTemplateType,
                templateContent: {}
            }

            template = defaultTemplate;
        }
        setNotificationTemplate(template);
    }
    const setLangTemplate = () => {
        if (!notificationTemplate) return;
        let langTemplate = notificationTemplate.templateContent[selectedLanguage];
        if (!langTemplate) {
            langTemplate = {
                subject: '',
                body: '',
                messageHtml: true,
            }
        }
        setWorkingTemplateOrg({ ...langTemplate });
        setWorkingTemplate({ ...langTemplate })
        setLoadingTemplate(false);
    }
    useEffect(() => {
        loadBaseData();
    }, [])
    useEffect(() => {
        if (!selectedLanguage) {
            const firstLang = languages[0];
            if (firstLang) {
                setSelectedLanguage(firstLang.lang);
            }
            return;
        }
        setLangTemplate();

    }, [notificationTemplate])
    useEffect(() => {
        if (selectedTemplateType) {
            loadTemplate();
        }

    }, [selectedTemplateType]);
    useEffect(() => {
        setLangTemplate();
    }, [selectedLanguage])

    const handleTemplateChange = (notificationTemplateTypeId) => {
        setSelectedTemplateType(notificationTemplateTypeId);
    }
    const handleLangChange = (lang) => {
        setSelectedLanguage(lang);
    }
    const updateTempalteState = (subject, body, messageHtml) => {
        setWorkingTemplate(pre => {
            return { ...pre, body: body, subject: subject, messageHtml: messageHtml };
        });
        setWorkingTemplateOrg(pre => {
            return { ...pre, body: body, subject: subject, messageHtml: messageHtml };
        });
        setNotificationTemplate(prev => {
            return {
                ...prev, templateContent: {
                    ...prev.templateContent,
                    [selectedLanguage]: {
                        ...prev.templateContent[selectedLanguage],
                        body: body, subject: subject, messageHtml: messageHtml,
                    }
                }
            }
        })

    }
    const handleSaveLangTemplate = async ({ subject, body, messageHtml }) => {
        const request = { subject, body, language: selectedLanguage, messageHtml };
        setSaveError(null);
        try {
            await updateNotificationTemplate(selectedTemplateType, request);
            updateTempalteState(subject, body, messageHtml);
            tailwindToast.success("Template updated");
        } catch (err) {

            const { status, data } = err.response
            if (status === 400) {
                setSaveError(data);
                setWorkingTemplate(pre => {
                    return { ...pre, body: body, subject: subject, messageHtml: messageHtml };
                })
                // err.isHandled = true;
            } else {
                tailwindToast.error("Failed to save template");
            }
        }
    }
    const openPlaceHolders = (field) => {
        const templateTypeDef = templateTypes.find(x => x.notificationTemplateTypeId === +selectedTemplateType);
        if (!templateTypeDef.notificationPlaceHolders) {
            setViewPlaceHolders([]);
            setPlaceholdersModalOpen(true);
            return;
        }
        const placeHolders = templateTypeDef.notificationPlaceHolders.filter(x => x.targetField && x.targetField?.includes(field));
        setViewPlaceHolders(placeHolders);
        setPlaceholdersModalOpen(true);
    }
    const TemplateForm = () => {
        return (
            <Formik
                initialValues={workingTemplate}
                enableReinitialize={true}
                onSubmit={async (values, { setSubmitting }) => {
                    await handleSaveLangTemplate(values);
                }}
                validate={async values => {
                    const errors = {};
                    const subjectError = validators.required(values.subject);
                    if (subjectError) {
                        errors.subject = subjectError;
                    }
                    const bodyError = validators.required(values.body);
                    if (bodyError) {
                        errors.body = bodyError;
                    }
                    return errors;
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    resetForm,
                    validateForm,
                    setFieldValue
                    /* and other goodies */
                }) => (
                    <form onSubmit={e => {
                        e.preventDefault();
                        handleSubmit(e)
                    }} onReset={e => {
                        setWorkingTemplate(workingTemplateOrg);
                        resetForm();
                        setSaveError(null);
                    }} className="mt-4">
                        {/* <label className="block text-md font-medium text-gray-700 sm:mt-px sm:pt-2">E-mail Message</label> */}
                        <div className="grid grid-cols-2">
                            <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 mt-4">Subject</label>
                            <a className="text-sm font-medium text-indigo-600 hover:text-indigo-900 cursor-pointer col-end-3 place-self-end" onClick={e => openPlaceHolders("subject")}>Placeholders</a>
                        </div>
                        <div className="mt-2">
                            <input type="text" name="subject" placeholder="Subject..." required autoComplete="off"
                                value={values.subject}
                                onChange={handleChange}
                                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                        </div>
                        <div className="mt-1 text-red-400">
                            {errors.subject}
                        </div>
                        <div className="mt-6 mb-4">
                            <label className="block text-sm font-medium text-gray-700">Body Format</label>
                            <div className="mt-2 flex space-x-4">
                                <FormikRadioButton
                                    name={"messageHtml"}
                                    label={"HTML"}
                                    value={true}
                                    styles={"cursor-pointer"}
                                    errors={errors}
                                    onChange={e => {
                                        setFieldValue("messageHtml", true)
                                    }}
                                />
                                <FormikRadioButton
                                    name={"messageHtml"}
                                    label={"Text"}
                                    value={false}
                                    errors={errors}
                                    onChange={e => {
                                        setFieldValue("messageHtml", false)
                                    }}
                                />
                            </div>
                        </div>
                        <div className="grid grid-cols-2">
                            <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 mt-4">Body</label>
                            <a className="text-sm font-medium text-indigo-600 hover:text-indigo-900 cursor-pointer col-end-3 place-self-end" onClick={e => openPlaceHolders("body")}>Placeholders</a>
                        </div>
                        <div className="mt-2">
                            <textarea name="body" rows={7} placeholder="Message..."
                                value={values.body}
                                onChange={handleChange}
                                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" ></textarea>
                            <div className="mt-1 text-red-400">
                                {errors.body}
                            </div>
                        </div>
                        <div className="mt-1 text-red-400">
                            {saveError}
                        </div>
                        <div className="sm:grid sm:grid-cols-5 mt-5">
                            <div className="col-start-4 sm:pr-3">
                                <CoreButton label={<div>Cancel</div>} type='secondary' htmlType='reset' htmlClassNames='w-full' />
                            </div>
                            <div className='sm:pl-3 mt-3 sm:mt-0'>
                                <CoreButton label={<div>Save</div>} htmlType='submit' disabled={isSubmitting} htmlClassNames='w-full' />
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        )
    }
    return (
        <>
            <ContentWrapper>
                <PageHeader title="Notifications" />
                <div className="grid grid-cols-2">

                </div>
                <section className="mt-4">
                    <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Template</label>
                    <div className="mt-2">
                        {
                            !loadingTypes && templateTypes && (<select name="template"
                                onChange={e => handleTemplateChange(e.target.value)}
                                value={selectedTemplateType}
                                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            >
                                {

                                    templateTypes.map(t => {
                                        return <option key={t.notificationTemplateTypeId} value={t.notificationTemplateTypeId}>{t.displayName}</option>
                                    })
                                }
                            </select>)
                        }
                    </div>
                </section>
                {
                    !loadingTypes && !loadingTemplate && workingTemplate && (
                        <section className="mt-2 md:mt-4">
                            <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Language</label>
                            <div className="mt-2">
                                <select name="language"
                                    onChange={e => handleLangChange(e.target.value)}
                                    value={selectedLanguage}
                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                >
                                    {
                                        languages.map(l => {
                                            return <option key={l.lang} value={l.lang}>{l.name}</option>
                                        })
                                    }
                                </select>
                            </div>
                            <TemplateForm></TemplateForm>
                        </section>
                    )
                }
            </ContentWrapper>
            <GenericModal open={placeholdersModalOpen} setOpen={setPlaceholdersModalOpen}
                onConfirm={e => setPlaceholdersModalOpen(false)}
                showCancelButton={false}
                confirmButtonText={'Close'}
                title="Placeholders">
                <PlaceHoldersModalContent placeHolders={viewPlaceHolders}></PlaceHoldersModalContent>
            </GenericModal>
        </>
    )
}


import { get, post, BaseUrl } from "./api";

export const getInteractEnvironments = async () => {
    return await get(
        `api/InteractSOAPClient/environments`,
        null,
        BaseUrl.Centerpoint
    );
}

export const getSoapSettingsByCustomerId = async () => {
    return await get(
        `api/InteractSOAPClient/all`,
        null,
        BaseUrl.Centerpoint
    );
}

export const getSoapSettings = async (id) => {
    return await get(
        `api/InteractSOAPClient/${id}`,
        null,
        BaseUrl.Centerpoint
    );
}

export const createSoapSettings = async (settings) => {
    return await post(
        `api/InteractSOAPClient`,
        settings,
        null,
        BaseUrl.Centerpoint
    );
}

export const updateSoapSettings = async (settings) => {
    return await post(
        `api/InteractSOAPClient/update`,
        settings,
        null,
        BaseUrl.Centerpoint
    );
}

export const deleteSoapSettings = async (ids) => {
    return await post(
        `api/InteractSOAPClient/delete`,
        ids,
        null,
        BaseUrl.Centerpoint
    );
}
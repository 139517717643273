import { Field, Formik } from 'formik';
import React, { useEffect, useState } from 'react'
import CheckboxField from '../../../../components/CheckboxField';
import { useToastAction } from '../../../../hooks/useToastAction';
import * as yup from "yup";
import { getCustomerAuditLogForwardConfiguration, updateCustomerAuditLogForwardConfiguration } from '../../../../api/customer';
import TextField from '../../../../components/TextField';
import DropDownField from '../../../../components/DropdownField';
import { CheckCircleIcon, XIcon, } from '@heroicons/react/outline';
import { useClassNames } from '../../../../hooks/useClassNames';
import RadioGroupField from '../../../../components/RadioGroupField';
import CheckBoxCardField from '../../../../components/CheckboxCardField';
import { ContentWrapper, CoreButton, PageHeader } from '@metaforcelabs/metaforce-core';

const StatusBanner = ({ statusText, type = 'success' }) => {

    const { classNames } = useClassNames();
    const [showBanner, setShowBanner] = useState(false);


    const getTextColor = () => {
        switch (type) {
            case 'error': return 'text-red-400';
            case 'warn': return 'text-yellow-400';
            case 'success':
            default: return 'text-green-400';
        }
    }

    const getBgColor = () => {
        switch (type) {
            case 'error': return 'bg-red-50';
            case 'warn': return 'bg-yellow-50';
            case 'success':
            default: return 'bg-green-50';
        }
    }

    return showBanner ? (
        <div className={classNames("rounded-md p-4", getBgColor())}>
            <div className="flex">
                <div className="flex-shrink-0">
                    <CheckCircleIcon className={classNames("h-5 w-5", getTextColor())} aria-hidden="true" />
                </div>
                <div className="ml-3">
                    <p className="text-sm font-medium text-green-800">{statusText}</p>
                </div>
                <div className="ml-auto pl-3">
                    <div className="-mx-1.5 -my-1.5">
                        <button
                            onClick={e => setShowBanner(false)}
                            type="button"
                            className="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                        >
                            <span className="sr-only">Dismiss</span>
                            <XIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    ) : (<></>)
}


export default function AuditForward() {

    const [configuration, setConfiguration] = useState({});
    const saveAction = useToastAction();
    const loadAction = useToastAction();

    const loadData = () => {
        loadAction.execute(async () => {
            const serverConfig = await getCustomerAuditLogForwardConfiguration();
            console.log(serverConfig);
            setConfiguration(serverConfig);
        }, "Failed to load");
    }

    const handleSave = (values, onComplete) => {
        saveAction.execute(async () => {
            try {
                await updateCustomerAuditLogForwardConfiguration(values);
                setConfiguration(values);
                onComplete();
            } catch (error) {
                onComplete();
                throw error
            }
        }, "Save failed", "Configuration saved")
    }

    useEffect(() => {
        loadData();
    }, [])

    const authenticationTypes = {
        none: 'none',
        clientCredentials: 'client-credentials'
    }

    const buildRestApiConfigurationSchema = (values) => {
        const schema = {
            url: yup.string().nullable().required('Required')
        };

        if (values.authenticationType === authenticationTypes.clientCredentials) {
            return {
                ...schema,
                clientId: yup.string().nullable().required('Required'),
                clientSecret: yup.string().nullable().required('Required'),
                tokenEndpoint: yup.string().nullable().required('Required')
            }
        }
        else if (values.authenticationType === authenticationTypes.none) {
            return schema;
        }
    }

    const buildValidationSchema = (values) => {
        let schema = {};
        if (values.useConsoleForward) {
            schema = {
                ...schema,
                messagePrefix: yup.string().nullable()
            };
        }
        if (values.useRestApiForward) {
            const restApiSchemaProperties = buildRestApiConfigurationSchema(values);
            schema = { ...schema, ...restApiSchemaProperties };
        }
        return yup.object().shape(schema);

    }

    return (
        <ContentWrapper>
          <PageHeader title="Auditlog Forwarding" description="Configure forwarding of audit log events"/>
          <StatusBanner statusText={'Test message'} />
          <div className="pb-12">
              <div className="">
                  <Formik
                      initialValues={configuration}
                      enableReinitialize={true}
                      initialTouched={{
                          clientId: false,
                          clientSecret: false,
                          tokenEndpoint: false
                      }}
                      validate={async (values) => {
                          const errors = {};
                          if (!values.enableAuditForwarding) {
                              return errors;

                          }

                          const schema = buildValidationSchema(values);
                          try {
                              await schema.validate(values, { abortEarly: false });
                          } catch (error) {

                              error?.inner?.forEach(element => {
                                  errors[element.path] = element.errors[0];
                              });
                          }
                          return errors;
                      }}
                      onSubmit={async (values, { setSubmitting }) => {
                          await handleSave(values, () => {
                              setSubmitting(false);
                          });

                      }}
                  >
                      {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          resetForm,
                          validateForm,
                          setFieldValue,
                          setFieldTouched
                          /* and other goodies */
                      }) => (
                          <form
                              onSubmit={e => {
                                  e.preventDefault();
                                  handleSubmit(e)
                              }}
                              onReset={resetForm} >

                              <div>
                                  <div className="space-y-8 pt-6">
                                      <ConfigSection name="">
                                          <ConfigSubSection name={"Status"}>
                                              <div className="grow mt-4 sm:mt-0 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12 w-full min-w-0">
                                                  <div className="sm:col-span-10">
                                                      <CheckboxField disabled={false} value={values?.enableAuditForwarding} label={"Enabled"} name={"enableAuditForwarding"} error={errors?.enableAuditForwarding}
                                                          onChange={e => {
                                                              handleChange({ target: { name: e.target.name, value: e.target.checked } })
                                                          }}
                                                      />
                                                  </div>
                                              </div>
                                          </ConfigSubSection>
                                          {
                                              values?.enableAuditForwarding && (
                                                  <ConfigSubSection name="Type">
                                                  <div className='mt-4 sm:mt-0 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-9 w-full min-w-0'>
                                                      <div className='col-span-3'>
                                                          <CheckBoxCardField
                                                              name="useRestApiForward"
                                                              label={"Rest API"}
                                                              value={values?.useRestApiForward}
                                                              description={"Forward eventlogs to a Rest API"}
                                                              onChange={handleChange}
                                                          />
                                                      </div>
                                                      <div className='col-span-3'>
                                                          <CheckBoxCardField
                                                              name="useConsoleForward"
                                                              label={"Console"}
                                                              value={values?.useConsoleForward}
                                                              onChange={handleChange}
                                                              description={"Write JSON formatted eventlogs to the Console"} />
                                                      </div>
                                                  </div>
                                              </ConfigSubSection>
                                              )
                                          }
                                      </ConfigSection>
                                      {
                                          values?.enableAuditForwarding && values.useConsoleForward && (
                                              <ConfigSection name="Console Configuration">
                                                  <ConfigSubSection name="Message">
                                                      <div className="mt-4 sm:mt-0 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12 w-full min-w-0">
                                                          <div className="sm:col-span-10">
                                                              <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                                                                  Message Prefix
                                                              </label>
                                                              <div className="mt-1">
                                                                  <TextField
                                                                      type="text"
                                                                      value={values?.messagePrefix || ""}
                                                                      error={errors?.messagePrefix}
                                                                      disabled={isSubmitting}
                                                                      id="messagePrefix"
                                                                      name="messagePrefix"
                                                                      onChange={handleChange}
                                                                      onBlur={handleBlur}
                                                                      touched={touched?.messagePrefix}
                                                                      allTouched={touched}
                                                                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                                  />
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </ConfigSubSection>

                                              </ConfigSection>
                                          )
                                      }
                                      {
                                          values?.enableAuditForwarding && values?.useRestApiForward && (
                                              <ConfigSection name="Rest API Configuration">
                                                  <ConfigSubSection name={"Endpoint"}>
                                                      <div className="mt-4 sm:mt-0 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12 w-full min-w-0">
                                                          <div className="sm:col-span-10 col-span-12">
                                                              <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                                                                  URL
                                                              </label>
                                                              <div className="mt-1">
                                                                  <TextField
                                                                      type="text"
                                                                      value={values?.url || ""}
                                                                      disabled={isSubmitting}
                                                                      error={errors?.url}
                                                                      id="url"
                                                                      name="url"
                                                                      onChange={handleChange}
                                                                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                                  />
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </ConfigSubSection>
                                                  <ConfigSubSection name={'Authentication'}>
                                                      <div className="mt-4 sm:mt-0 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12 w-full min-w-0">
                                                          <div className="sm:col-span-8">
                                                              <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                                                                  Type
                                                              </label>
                                                              <div className="mt-1">
                                                                  <DropDownField
                                                                      name={"authenticationType"}
                                                                      value={values?.authenticationType || ""}
                                                                      error={errors?.authenticationType}
                                                                      onChange={e => handleChange({ target: { name: e.target.name, value: e.target.value } })}
                                                                      options={[
                                                                          { value: authenticationTypes.none, name: 'None' },
                                                                          { value: authenticationTypes.clientCredentials, name: 'OAuth 2.0 Client Credentials' }
                                                                      ]}
                                                                  />
                                                              </div>
                                                          </div>
                                                          {
                                                              values.authenticationType === authenticationTypes.clientCredentials && (
                                                                  <>
                                                                      <div className="sm:col-span-10">
                                                                          <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                                                                              Client ID
                                                                          </label>
                                                                          <div className="mt-1">
                                                                              <TextField
                                                                                  type="text"
                                                                                  value={values?.clientId || ""}
                                                                                  error={errors?.clientId}
                                                                                  disabled={isSubmitting}
                                                                                  id="clientId"
                                                                                  name="clientId"
                                                                                  onChange={handleChange}
                                                                                  onBlur={handleBlur}
                                                                                  touched={touched?.clientId}
                                                                                  allTouched={touched}
                                                                                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                                              />
                                                                          </div>
                                                                      </div>
                                                                      <div className="sm:col-span-10">
                                                                          <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                                                                              Client Secret
                                                                          </label>
                                                                          <div className="mt-1">
                                                                              <TextField
                                                                                  type="password"
                                                                                  value={values?.clientSecret || ""}
                                                                                  error={errors?.clientSecret}
                                                                                  disabled={isSubmitting}
                                                                                  id="clientSecret"
                                                                                  name="clientSecret"
                                                                                  onChange={handleChange}
                                                                                  onBlur={handleBlur}
                                                                                  touched={touched?.clientSecret}
                                                                                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                                              />
                                                                          </div>
                                                                      </div>
                                                                      <div className="sm:col-span-10">
                                                                          <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                                                                              Token Endpoint
                                                                          </label>
                                                                          <div className="mt-1">
                                                                              <TextField
                                                                                  type="text"
                                                                                  value={values?.tokenEndpoint || ""}
                                                                                  error={errors?.tokenEndpoint}
                                                                                  disabled={isSubmitting}
                                                                                  id="tokenEndpoint"
                                                                                  name="tokenEndpoint"
                                                                                  onChange={handleChange}
                                                                                  onBlur={handleBlur}
                                                                                  touched={touched?.tokenEndpoint}
                                                                                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                                              />
                                                                          </div>
                                                                      </div>
                                                                      <div className="sm:col-span-10">
                                                                          <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                                                                              Scope
                                                                          </label>
                                                                          <div className="mt-1">
                                                                              <TextField
                                                                                  type="text"
                                                                                  value={values?.scope || ""}
                                                                                  error={errors?.scope}
                                                                                  disabled={isSubmitting}
                                                                                  id="scope"
                                                                                  name="scope"
                                                                                  onChange={handleChange}
                                                                                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                                              />
                                                                          </div>
                                                                      </div>
                                                                  </>
                                                              )
                                                          }
                                                      </div>
                                                  </ConfigSubSection>
                                              </ConfigSection>
                                          )
                                      }
                                  </div>
                                  <div className="mt-5 border-gray-200">
                                      <div className="flex justify-end">
                                          <CoreButton label={<>Save</>} htmlType="submit" disabled={saveAction.isExecuting}/>
                                      </div>
                                  </div>
                              </div>
                          </form>)}
                  </Formik>
              </div>
            </div>
          </ContentWrapper>
    )
}

const ConfigSection = ({ name, children }) => {

    return (
        <div className='border-b border-gray-200'>
            <h3 className='mb-4 text-base font-semibold leading-7 text-gray-900 sm:text-lg sm:truncate'>
                {name}
            </h3>
            {children}
        </div>
    )
}

const ConfigSubSection = ({ name, children }) => {

    return (
        <div className='sm:flex py-6 border-t border-gray-200'>
            <div className='mr-12 flex-none sm:w-44 text-sm font-semibold leading-7 text-gray-900 sm:text-base sm:truncate'>
                {name}
            </div>
            {children}
        </div>
    )
}


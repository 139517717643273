
import { get, post, BaseUrl } from "./api";

export const getWebEditorSettingsByCustomerId = async () => {
    return await get(
        `api/WebEditorSettings/all`,
        null,
        BaseUrl.Centerpoint
    );
}

export const getWebEditorSettings = async (id) => {
    return await get(
        `api/WebEditorSettings/${id}`,
        null,
        BaseUrl.Centerpoint
    );
}

export const getInteractGeneralSettings = async () => {
    return await get(
        `api/WebEditorSettings/general`,
        null,
        BaseUrl.Centerpoint
    );
}

export const updateInteractGeneralSettings = async (settings) => {
    return await post(
        `api/WebEditorSettings/general`,
        settings,
        null,
        BaseUrl.Centerpoint
    );
}


export const createWebEditorSettings = async (settings) => {
    return await post(
        `api/WebEditorSettings`,
        settings,
        null,
        BaseUrl.Centerpoint
    );
}

export const updateWebEditorSettings = async (settings) => {
    return await post(
        `api/WebEditorSettings/update`,
        settings,
        null,
        BaseUrl.Centerpoint
    );
}

export const deleteWebEditorSettings = async (ids) => {
    return await post(
        `api/WebEditorSettings/delete`,
        ids,
        null,
        BaseUrl.Centerpoint
    );
}
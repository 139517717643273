import { useReactOidc } from '@axa-fr/react-oidc-context';
import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ManageTeamContext } from '../../../../contexts';




export default function UserTable({ users }) {
    const { oidcUser } = useReactOidc();
    
    const TableRow = ({ user }) => {
        const manageTeamContext = useContext(ManageTeamContext);
        return (
            <tr className="odd:bg-white even:bg-gray-100">
                <td className="px-6 m-0py-4 text-left text-sm font-medium text-gray-900 w-1/5">
                    {user.name}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-left text-sm text-gray-500 w-1/5">
                    {user.email}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-left text-sm text-gray-500 w-1/5">
                    {
                        manageTeamContext?.statuses.find(s => s.id === user.statusId)?.name
                    }
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-left text-sm text-gray-500 text-sm font-medium w-1/5">
                    
                {manageTeamContext?.roles.find(r => r.roleId === user.roleId)?.isReadOnly ? (
                    <p className="font-semibold hover:text-gray-900 ">{manageTeamContext?.roles.find(r => r.roleId === user.roleId)?.roleName}</p>
                    ) : 
                    (<Link to={`/admin/team/role/${user.roleId}`} className="text-indigo-600 hover:text-indigo-900 cursor-pointer">
                    {manageTeamContext?.roles.find(r => r.roleId === user.roleId)?.roleName}
                    </Link>)
                }

                </td>
                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium w-1/5">
                    {user.id !== oidcUser.profile.sub && (
                        <Link to={`/admin/team/manage/${user.id}`} className="text-indigo-600 hover:text-indigo-900 cursor-pointer">Edit</Link>
                    )}
                </td>
            </tr>
        );
    }

    return (
        <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200 table-with-overflow">
                            <thead className="bg-gray-200">
                                <tr>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/5">
                                        Name
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/5">
                                        Email
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/5">
                                        Status
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/5">
                                        Role
                                    </th>
                                    <th scope="col" className="px-6 py-3 w-1/5">
                                        <span className="sr-only">Edit</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    users.map((u, i) => {
                                        return <TableRow user={u} key={u.name} />
                                    })
                                }

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

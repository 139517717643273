import { BaseUrl, get, put } from "./api";

export const getNotificationTemplateTypes = async () => {
    return await get(`api/CustomerNotifications/NotificationTypes`, null,
        BaseUrl.Centerpoint);
}
export const getTemplateLanguages = async () => {
    return await get(`api/CustomerNotifications/TemplateLanguages`, null,
        BaseUrl.Centerpoint);
}
export const getNotificationTemplate = async (notificationTemplateTypeId) => {
    return await get(`api/CustomerNotifications/NotificationTemplate/${notificationTemplateTypeId}`, null,
        BaseUrl.Centerpoint);
}
export const updateNotificationTemplate = async (notificationTemplateTypeId, request) => {
    return await put(`api/CustomerNotifications/NotificationTemplate/${notificationTemplateTypeId}`, request, null,
        BaseUrl.Centerpoint);
}
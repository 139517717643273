export const formatCardNumber = (brand, number) => {
    const formatedNumber = Capitalize(brand) + '****' + number;
    return formatedNumber;
}

export const Capitalize = (str) => {
    if (str?.length > 0)
        return str.charAt(0).toUpperCase() + str.slice(1);

    return str;
}
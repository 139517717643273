import React from 'react'
import { Disclosure } from '@headlessui/react'
import { config } from '../../utils/config'

export default function Error() {
    return (
        <div className="min-h-screen bg-white">
        <Disclosure as="nav" className="bg-white border-b border-gray-200">
          {({ open }) => (
            <>
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex justify-between h-16">
                  <div className="flex">
                    {/* <Menu as="div" className="relative w-8">
                      {({ open }) => (
                        <>
                          <div>
                            <Menu.Button className={`bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none absolute -inset-y-0`}>
                              <span className="sr-only">Menu</span>
                              <ViewGridIcon className="h-8 w-8 hover:bg-gray-200" aria-hidden="true" />
                            </Menu.Button>
                          </div>
                          <Transition
                            show={open}
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items
                              static
                              className="origin-top-left absolute left-0 top-16 mt-2 w-64 rounded-md shadow-lg py-2 pl-6 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                            >
                              {menuNavigation.map((item) => (
                                <Menu.Item key={item.name}>
                                  {() => (
                                    <a href={item.href}>
                                      <div className="py-2">
                                        <span className="inline-block align-middle">
                                          { item.name === 'CenterPoint' && <BadgeCheckIcon className={`h-6 w-6 ${item.color}`} aria-hidden="true" /> }
                                          { item.name === 'DMXL' && <CodeIcon className={`h-6 w-6 ${item.color}`} aria-hidden="true" /> }
                                          { item.name === 'eDialog' && <AnnotationIcon className={`h-6 w-6 ${item.color}`} aria-hidden="true" /> }
                                          { item.name === 'Dashboard' && <TemplateIcon className={`h-6 w-6 ${item.color}`} aria-hidden="true" /> }
                                        </span>
                                        <span className="inline-block align-middle px-4 py-2 text-sm text-gray-500 font-semibold">
                                          {item.name}
                                        </span>
                                      </div>
                                    </a>
                                  )}
                                </Menu.Item>
                              ))}
                            </Menu.Items>
                          </Transition>
                        </>
                      )}
                    </Menu> */}
                    <img
                      className="w-32 m-2 ml-12"
                      src={config.logoMain}
                      alt="CenterPoint Logo"
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </Disclosure>
  
        <div className="py-10 bg-gray-200">
          <header>
            <div className="max-w-screen-lg mx-auto px-4 sm:px-6 lg:px-8">
              <h1 className="text-3xl mt-3 mb-6">
                  <span className="text-red-400 font-bold">Error page</span>
              </h1>
            </div>
          </header>
          <main>
            <div className="max-w-screen-lg mx-auto sm:px-6 lg:px-8">
              {/* Replace with your content */}
                This is error page
              {/* /End replace */}
            </div>
          </main>
        </div>
      </div>
    )
}
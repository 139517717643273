import React from 'react'
import { toast } from 'react-toastify'
import ToastContent from './toastContent';


export const toastTypes = {
    error: 'error',
    info: 'info',
    success: 'success'
}
export function error(message) {
    createToast(toastTypes.error, message);
}
export function info(message) {
    createToast(toastTypes.info, message);
}
export function success(message) {
    createToast(toastTypes.success, message);
}
function createToast(type, message) {
    toast(<ToastContent message={message} type={type} />, {  })
}
export const tailwindToast = {
    error: error,
    info:info,
    success:success,
    toastTypes: toastTypes
}
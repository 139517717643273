
import React from 'react'
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function SkeletonTile( { actionIdx } ) {
    return (
<div
            className={classNames(
                actionIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
                actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
                actionIdx === 2 ? 'sm:rounded-bl-lg' : '',
                actionIdx === 3 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '',
                'relative group bg-white p-6'
            )}
        >
            <div>
                <span
                    className="rounded-lg inline-flex p-3 ring-4 ring-white bg-gray-200 animate-pulse "
                >
                    <span className="h-6 w-6" aria-hidden="true" />
                </span>
            </div>
            <div className="flex flex-col mt-4">
                <h3 className="text-lg font-medium w-3/6 bg-gray-200 h-8 rounded-lg animate-pulse ">
                    
                </h3>
                <p className="mt-4 text-sm text-gray-500 w-9/12 bg-gray-200 h-6  rounded-lg animate-pulse ">
                    
                </p>
            </div>
            <span
                className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
                aria-hidden="true"
            >
                <div className="flex space-x-4 h-8 w-16 bg-gray-200 rounded-lg animate-pulse ">
                </div>
            </span>
        </div>
    )
}

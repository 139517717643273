import { CogIcon } from "@heroicons/react/outline";
import React from "react";
import ActionTile from "./actionTile";


export const BillingConfigurationTile = () => {

    const title = 'Payment methods';
    const text = 'Change payment method';
    const href = 'paymentmethods';
    const iconForeground = 'text-indigo-700';
    const iconBackground = 'bg-indigo-50';

    return (
        <ActionTile title={title} text={text} href={href} isExternal={false}  icon={CogIcon} iconBackground={iconBackground} iconForeground={iconForeground} actionIdx={3}></ActionTile>
    )
}
export default BillingConfigurationTile
import { get, BaseUrl, post, destroy, put } from './api';

export const getGroups = async () => {
    return await get(`api/CustomerGroup/Groups`, null,
        BaseUrl.Centerpoint);
}

export const getGroup = async (groupId) => {
    return await get(`api/CustomerGroup/Group/${groupId}`, null,
        BaseUrl.Centerpoint);
}

export const saveGroup = async (group) => {
    return await put(`api/CustomerGroup/Group`, group , null,
        BaseUrl.Centerpoint);
}

export const addGroup = async (group) => {
    return await post(`api/CustomerGroup/Group`, group , null,
        BaseUrl.Centerpoint);
}

export const getUsersInGroup = async (groupId) => {
    return await get(`api/CustomerGroup/Users/${groupId}`, null,
        BaseUrl.Centerpoint);
}

export const addUsersToGroup = async (groupId, users) => {
    return await post(`api/CustomerGroup/Group/${groupId}/Users/Add`, users , null,
        BaseUrl.Centerpoint);
}

export const removeUsersFromGroup = async (groupId, users) => {
    return await post(`api/CustomerGroup/Group/${groupId}/Users/Remove`, users , null,
        BaseUrl.Centerpoint);
}

export const deleteGroup = async (groupId) => {
    return await destroy(`api/CustomerGroup/Group/${groupId}`, null,
        BaseUrl.Centerpoint);
}

export const validateGroupName = async (groupName) => {
    return await post(`api/CustomerGroup/ValidateGroupName/`, groupName, null,
        BaseUrl.Centerpoint);
}
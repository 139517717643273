import { XCircleIcon } from "@heroicons/react/outline";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import * as yup from "yup";

import { useToastAction } from "../../../../hooks/useToastAction";

import { SidebarSelect } from "../../../../components/SidebarSelect";
import { SidebarTextbox } from "../../../../components/SidebarTextbox";
import { SidebarPassword } from "../../../../components/SidebarPassword";

export default function Properties({ privateConnection, allPrivateConnections, allCustomerEnvironments, onChange, setOpen, onSubmit }) {
    const activeState = "Active";
    const inactiveState = "Inactive";

    const [errors, setErrors] = useState({});
    const [environmentOptions, setEnvironmentOptions] = useState([]);

    const loadAction = useToastAction();

    const loadBaseData = async () => {
        loadAction.execute(async () => {
            const environments = allCustomerEnvironments.filter(({ value }) =>
                allPrivateConnections.find(connection => connection.customerEnvironment === value) === undefined ||
                privateConnection.customerEnvironment === value);
            setEnvironmentOptions(environments);
            if (!privateConnection.id) {
                privateConnection.customerEnvironment = environments[0].value;
            }
        }, "Failed to load")
    }

    useEffect(() => {
        loadBaseData();
    }, [])

    let schema = yup.object().shape({
        customerEnvironment: yup.string().required("Customer Environment is required"),
        baseAddress: yup.string().required("BaseAddress is required"),
        clientId: yup.string().required("ClientId is required"),
        clientSecret: yup.string().required("ClientSecret is required"),
        state: yup.string().required("State is required").min(1, "State is required"),
    });

    const validate = async () => {
        var isValid = false

        await schema
            .validate(privateConnection, { abortEarly: false })
            .then(function () {
                isValid = true;
                setErrors({});
            })
            .catch(function (err) {
                var newErrors = {}

                err?.inner?.forEach(element => {
                    newErrors[element.path] = `${element.errors[0]}`
                });

                setErrors(newErrors);
            });

        return isValid;
    }

    const handleSubmit = async () => {
        var isValid = await validate()

        if (isValid) {
            privateConnection.customerEnvironment = Number(privateConnection.customerEnvironment);
            privateConnection.state = Number(privateConnection.state);
            onSubmit();
        }
    }

    return (
        <>
            <div className="flex w-full overflow-y-auto h-full">
                <div className="w-full">

                    <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                        <div className={"py-5"}>
                            <SidebarSelect
                                name="customerEnvironment"
                                value={privateConnection.customerEnvironment}
                                label={"Customer Environment"}
                                onChange={onChange}
                                error={errors.customerEnvironment}
                                options={environmentOptions}
                            />

                            <SidebarTextbox
                                name="baseAddress"
                                value={privateConnection.baseAddress}
                                label={"Base Address"}
                                onChange={onChange}
                                error={errors.baseAddress}
                            />

                            <SidebarTextbox
                                name="clientId"
                                value={privateConnection.clientId}
                                label={"Client Id"}
                                onChange={onChange}
                                error={errors.clientId}
                            />

                            <SidebarPassword
                                name="clientSecret"
                                value={privateConnection.clientSecret}
                                label={"Client Secret"}
                                onChange={onChange}
                                error={errors.clientSecret}
                            />

                            <SidebarSelect
                                name="state"
                                value={privateConnection.state}
                                label={"State"}
                                onChange={onChange}
                                error={errors.state}
                                options={[
                                    { name: activeState, value: 1 },
                                    { name: inactiveState, value: 2 },
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* Action buttons */}
            <div className="flex-shrink-0 px-4 border-t bg-gray-50 border-gray-200 py-5 sm:px-6">
                <div className="space-x-3 flex justify-end">
                    <button
                        type="button"
                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 "
                        onClick={() => setOpen(false)}
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-brand-pink hover:bg-brand-pink-hover"
                        onClick={handleSubmit}
                    >
                        Ok
                    </button>
                </div>
            </div>
        </>
    );
}

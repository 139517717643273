import { useFormikContext } from "formik"
import CheckBoxCardField from "../../../../../components/CheckboxCardField";

const BankIdAuthorizationMethods = () => {
  const formik = useFormikContext();
  return (
    <>
      <div className="mt-8 space-y-4  sm:border-b border-b-gray-200 "></div>
      <div className="sm:pt-3 grid grid-cols-12 gap-4">
          <div className="col-span-12 sm:col-span-4">
            <label className="text-sm font-medium text-gray-700 sm:flex items-center">
              Available Authentication Methods
            </label>
          </div>
          <div className="col-span-12 sm:col-span-8">
            <div className='grid grid-cols-4 gap-4 text-sm'>
                <CheckBoxCardField 
                  label={'BankID (Norwegian)'} 
                  value={formik?.values?.availableAuthMethods?.no_bankId} 
                  name={'availableAuthMethods.no_bankId'} 
                  onChange={(e) => formik.setFieldValue('availableAuthMethods.no_bankId', e.target.value)}
                />
                <CheckBoxCardField 
                  label={'BankID (Swedish)'} 
                  value={formik?.values?.availableAuthMethods?.se_bankid} 
                  name={'availableAuthMethods.se_bankid'} 
                  onChange={(e) => formik.setFieldValue('availableAuthMethods.se_bankid', e.target.value)}
                />
                <CheckBoxCardField 
                  label={'MitID (Danish)'} 
                  value={formik?.values?.availableAuthMethods?.dk_mitid} 
                  name={'availableAuthMethods.dk_mitid'} 
                  onChange={(e) => formik.setFieldValue('availableAuthMethods.dk_mitid', e.target.value)}
                />
            </div>
          </div>
      </div>
    </>
  )
}

export default BankIdAuthorizationMethods;
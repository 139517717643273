import React, { useEffect, useState } from 'react';
import { addPaymentMethod, getPaymentMethodList } from '../../../../api/billing';
import { useClassNames } from '../../../../hooks/useClassNames';
import { useToastAction } from '../../../../hooks/useToastAction';
import CreditCardIcon from './creditCardIcon';
import PaymentMethodTable from './paymentMethodTable';
import { ContentWrapper, CoreButton, PageHeader } from '@metaforcelabs/metaforce-core';

export default function PaymentMethods() {
  const [paymentMethodList, setPaymentMethodList] = useState([]);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState([]);
  const loadActionPaymentList = useToastAction()
  const loadActionAddPaymentMethod = useToastAction()
  const { classNames } = useClassNames();

  const loadPaymentMethodList = async () => {
    loadActionPaymentList.execute(async () => {
      const paymentMethodListData = await getPaymentMethodList();
      setPaymentMethodList(paymentMethodListData);
      const defaultPaymentMethodData = paymentMethodListData.find(({ isDefault }) => isDefault === true);
      setDefaultPaymentMethod(defaultPaymentMethodData);
    }, 'Failed to load payment methods')
  }

  const loadData = async () => {
    loadPaymentMethodList();
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleAddPaymentMethod = async (event) => {
    loadActionAddPaymentMethod.execute(async () => {
      var response = await addPaymentMethod();
      window.location.href = response.url;
    }, 'Failed to add payment method'
    )

  }

  return (
    <ContentWrapper>
    <PageHeader title="Payment methods" />
        <div className="mt-6">
          <label className="block text-sm font-medium text-gray-700 sm:mr-2">Default payment method</label>
        </div>

        <div className="bg-white shadow rounded-lg max-w-full md:max-w-xl">
          <div className="px-4 mt-4 py-5">
            <div className="mt-2 mb-2">
              <div className="rounded-md px-4 flex flex-col md:flex-row justify-between">
                <div className="flex flex-row">
                  {
                    !defaultPaymentMethod
                      ? <div>No payment methods registered.</div>
                      : <>
                        <div>
                          {
                            loadActionPaymentList.isExecuting ? (<span className="rounded-lg inline-flex  ring-4 ring-white bg-gray-200 animate-pulse">
                              <span className="h-11 w-14" aria-hidden="true" />
                            </span>) : (<CreditCardIcon type={defaultPaymentMethod?.card?.brand} size="3x" />
                            )
                          }
                        </div>
                        <div className="ml-4">
                          <div className={classNames("mt-1 text-sm font-medium text-gray-900",
                            loadActionPaymentList.isExecuting ? ' w-28 h-4 rounded rounded bg-gray-200 animate-pulse' : '')}>
                            {!loadActionPaymentList.isExecuting && (<span> Ending with {!loadActionPaymentList.isExecuting && (defaultPaymentMethod?.card?.last4)}</span>)}
                          </div>

                          <div className={classNames("text-sm text-gray-600 flex items-center",
                            loadActionPaymentList.isExecuting ? 'w-20 h-3 rounded bg-gray-200 animate-pulse mt-2' : '')}>
                            {!loadActionPaymentList.isExecuting && (<span>Expires {defaultPaymentMethod?.card?.expMonth + '/' + defaultPaymentMethod?.card?.expYear} </span>)}
                          </div>
                        </div>
                      </>
                  }
                </div>
                <div className="mt-4 sm:mt-0 flex justify-end items-center">
                  {
                    !loadActionPaymentList.isExecuting && (
                    <CoreButton label={<div>Add new card</div>} htmlType="button" onClick={handleAddPaymentMethod} />
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-6 flex flex-col sm:flex-row sm:justify-between sm:items-center">
          <label className="block text-sm font-medium text-gray-700 sm:mr-2">Cards</label>
        </div>

        <div className="mt-4">
          <PaymentMethodTable
            paymentMethods={paymentMethodList}
            loading={loadActionPaymentList.isExecuting}
            onMakeDefaultEvent={() => loadData()}
            onDeleteEvent={() => loadData()}
          />
        </div>

      </ContentWrapper>
  )
}

import { useContext } from "react";
import { AuthenticatedRoutesContext } from "../contexts";

export const useCustomerCurrency = () => {
    const authContext = useContext(AuthenticatedRoutesContext);

    return {
        customerCurrency: authContext?.customerInfo?.currency
    }

}
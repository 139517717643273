import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { useDebounceCallback } from '@react-hook/debounce';
import { addUsersToGroup, getGroup, getUsersInGroup, removeUserFromGroup, removeUsersFromGroup, saveGroup, validateGroupName } from '../../../../api/groups';
import { getTeamUsers, getUserRoles, getUserStatuses } from '../../../../api/manageTeam';
import { GroupTableContext } from '../../../../contexts';
import { useToastAction } from '../../../../hooks/useToastAction';
import GroupUsers from './groupUsers';
import { ContentWrapper, PageHeader } from '@metaforcelabs/metaforce-core';

export default function Group() {

    const { groupId } = useParams();
    const [isLoaded, setIsLoaded] = useState(false);
    const [group, setGroup] = useState({});
    const [userStatuses, setUserStatuses] = useState([]);
    const [usersInGroup, setUsersInGroup] = useState([]);
    const [usersAtCustomer, setUsersAtCustomer] = useState([]);
    const [selectableUsers, setSelectableUsers] = useState([]);
    const [userRoles, setUserRoles] = useState([]);
    const loadAction = useToastAction();
    const saveUserOnGroupAction = useToastAction();
    const saveGroupAction = useToastAction();
    const [error, setError] = useState('');

    const [saveState, setSaveState] = useState('')

    const saveGroupName = async () => {
        setError('');
        if (!group) {
            return;
        }
        saveGroupAction.execute(async () => {
            var validationRequest = { groupName: group.name };
            const validationResult = await validateGroupName(validationRequest);
            if (validationResult?.error)
            {
                setError(validationResult.error);
            }
            else
            {
                await saveGroup(group);
            }
        }, "Save failed")
    }

    const debounceSave = useDebounceCallback(saveGroupName, 1000);

    const loadDefaultData = async () => {
        setIsLoaded(false);
        loadAction.execute(async () => {
            let groupData;
            if (groupId === undefined)
                groupData = { name: '', id: '' }
            else
                groupData = await getGroup(groupId);
            setGroup(groupData);

            const statuses = await getUserStatuses();
            setUserStatuses(statuses);

            const usersInGroupCollection = await getUsersInGroup(groupId);
            setUsersInGroup(usersInGroupCollection || []);

            const usersAtCustomerCollection = await getTeamUsers();
            setUsersAtCustomer(usersAtCustomerCollection || []);

            const roles = await getUserRoles();
            setUserRoles(roles);

            setIsLoaded(true);
        }, 'Failed to load')
    }

    const handleNameChange = ({ name, value }) => {
        setError('');
        const isChange = group.name.trim() !== value.trim();
        
        setGroup(prev => {
            return { ...prev, [name]: value };
        })
        if (isChange)
            setSaveState(new Date().getTime().toString())
    }

    const handleUsersAdded = (users) => {
        saveUserOnGroupAction.execute(async () => {
            await addUsersToGroup(group.id, users);
            const usersInGroupCollection = await getUsersInGroup(groupId);
            setUsersInGroup(usersInGroupCollection || []);
        }, 'Failed to save')
    }

    const handleUsersRemoved = (users) => {
        saveUserOnGroupAction.execute(async () => {
            await removeUsersFromGroup(group.id, users);
            const usersInGroupCollection = await getUsersInGroup(groupId);
            setUsersInGroup(usersInGroupCollection || []);
        }, 'Failed to save')
    }

    useEffect(() => {
        if (isLoaded) {
            return;
        }
        loadDefaultData();
    }, [groupId])
    useEffect(() => {
        if (isLoaded) {
            debounceSave();
        }
    }, [saveState])
    useEffect(() => {
        const selectable = usersAtCustomer.filter(a => !usersInGroup.some(b => a.id === b.id));
        setSelectableUsers(selectable);
    }, [usersInGroup, usersAtCustomer])


    return (
        <GroupTableContext.Provider
            value={{
                loading: loadAction.isExecuting ,
                usersInGroup: usersInGroup || [],
                usersAtCustomer: usersAtCustomer || [],
                selectableUsers: selectableUsers || [],
                userStatuses: userStatuses || [],
                userRoles: userRoles || [],
                group: group || [],
                onUsersAdded: (users) => {
                    handleUsersAdded(users);
                },
                onUsersRemoved: (users) => {
                    handleUsersRemoved(users);
                }
            }}
        >
          <ContentWrapper>
            <div>
                <div>
                    <PageHeader title="Group" />
                  <div className="mt-4">
                      Group name:
                  </div>
                    <div>
                        <div>
                            <input type="text" name="name" id="name"
                                onChange={e => handleNameChange(e.target)}
                                value={group?.name}
                                className="shadow-sm focus:ring-indigo-500 w-full w-1/2 sm:w-96 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md" />
                        </div>
                        {
                            <p className="mt-2 text-sm text-red-600" id="groupname-error">
                                {error}
                            </p>
                        }
                    </div>
                    <div className="mt-10">
                        <GroupUsers />
                    </div>
                </div>
            </div>
            {/* {
                !loadAction.isExecuting && (
                    <div className="mt-4">
                        <div className="flex justify-center sm:justify-start">
                            <button type="button"
                                onClick={e => {
                                    history.goBack();
                                }}
                                className="inline-flex w-1/2 sm:w-48 justify-center items-center px-6 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-gray-600 bg-gray-300 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-4">
                                Back
                            </button>
                            <button type="button"
                                onClick={e => {
                                    saveGroupData();
                                }}
                                disabled={saveAction.isExecuting}
                                className="inline-flex w-1/2 sm:w-48 justify-center items-center px-6 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-brand-pink hover:bg-brand-pink-hover disabled:opacity-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                <span>Save</span>
                            </button>
                        </div>
                    </div>
                )
            } */}
          </ContentWrapper>
        </GroupTableContext.Provider>
    )
}


import { useDebounceCallback } from "@react-hook/debounce";
import React from "react";
import { useCallback, useRef, useState } from "react";
import { HexColorInput, HexColorPicker } from "react-colorful";
import useClickOutside from "../../hooks/useClickOutside";

import './styles.scss';

export default function ColorPicker({ name, value, alignRight, onChange }) {
    value = value || '#fff'
    const [color, setColor] = useState(value);
    const popover = useRef();
    const [isOpen, toggle] = useState(false);
    const close = useCallback(() => toggle(false), []);
    useClickOutside(popover, close);
  
    const debounced = useDebounceCallback(
      (value) => {
        onChange({ target: { name: name, value: value } })
      },
      100
    );
  
    let leftAlign = alignRight ? '-170px' : '0';
  
    const handleChange = value => {
      setColor(value)
      debounced(value)
    };
  
    return (
      <div className="picker">
        <div
          className="swatch h-10 w-10"
          style={{ backgroundColor: value }}
          onClick={() => toggle(true)}
        />
  
        {isOpen && (
          <div style={{ left: leftAlign }} className="z-50 popover rounded-md border border-gray-300 text-gray-500 p-3 bg-white" ref={popover}>
            <HexColorPicker color={value} onChange={handleChange} />
            <div>
              <div className="mt-2 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <span className="text-gray-500 sm:text-sm">#</span>
                </div>
                <HexColorInput className='block w-full pl-7 rounded-md border border-gray-300 text-gray-500' color={value} onChange={handleChange} />
              </div>
              <button
                type="button"
                onClick={close}
                className="inline-flex mt-3 items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Close
              </button>
            </div>
  
          </div>
        )}
      </div>
    );
  }
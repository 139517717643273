import React from "react";

export default function PlansIcon({ className }) {
    return (
        <svg className={className} viewBox="0 0 112 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="32" y="1" width="48" height="98" rx="4" stroke="currentColor" stroke-width="2" />
            <path d="M5 14H32V87H5C2.79086 87 1 85.2091 1 83V18C1 15.7909 2.79086 14 5 14Z" stroke="currentColor" stroke-width="2" />
            <path d="M107 87H80V14H107C109.209 14 111 15.7909 111 18V83C111 85.2091 109.209 87 107 87Z" stroke="currentColor" stroke-width="2" />
            <path d="M51.6857 13.7553C52.771 13.7553 53.733 13.0564 54.0684 12.0242C54.519 10.6373 56.481 10.6373 56.9316 12.0242C57.267 13.0564 58.229 13.7553 59.3143 13.7553C60.7726 13.7553 61.3789 15.6213 60.1992 16.4785C59.3211 17.1164 58.9536 18.2473 59.289 19.2795C59.7397 20.6664 58.1523 21.8196 56.9726 20.9625C56.0945 20.3245 54.9055 20.3245 54.0274 20.9625C52.8477 21.8196 51.2603 20.6664 51.711 19.2795C52.0464 18.2473 51.6789 17.1164 50.8008 16.4785C49.6211 15.6213 50.2274 13.7553 51.6857 13.7553Z" stroke="currentColor" />
            <line x1="39" y1="34.5" x2="72" y2="34.5" stroke="currentColor" />
            <line x1="39" y1="60.5" x2="72" y2="60.5" stroke="currentColor" />
            <line x1="39" y1="51.5" x2="72" y2="51.5" stroke="currentColor" />
            <line x1="39" y1="34.5" x2="72" y2="34.5" stroke="currentColor" />
            <line x1="39" y1="60.5" x2="72" y2="60.5" stroke="currentColor" />
            <line x1="39" y1="42.5" x2="72" y2="42.5" stroke="currentColor" />
            <line x1="81" y1="36.5" x2="105" y2="36.5" stroke="currentColor" />
            <line x1="81" y1="59.5" x2="105" y2="59.5" stroke="currentColor" />
            <line x1="81" y1="51.5385" x2="105" y2="51.5385" stroke="currentColor" />
            <line x1="81" y1="36.5" x2="105" y2="36.5" stroke="currentColor" />
            <line x1="81" y1="59.5" x2="105" y2="59.5" stroke="currentColor" />
            <line x1="81" y1="43.5769" x2="105" y2="43.5769" stroke="currentColor" />
            <line x1="8" y1="37.5" x2="32" y2="37.5" stroke="currentColor" />
            <line x1="8" y1="60.5" x2="32" y2="60.5" stroke="currentColor" />
            <line x1="8" y1="52.5385" x2="32" y2="52.5385" stroke="currentColor" />
            <line x1="8" y1="37.5" x2="32" y2="37.5" stroke="currentColor" />
            <line x1="8" y1="60.5" x2="32" y2="60.5" stroke="currentColor" />
            <line x1="8" y1="44.5769" x2="32" y2="44.5769" stroke="currentColor" />
            <rect x="43" y="77" width="25" height="6" rx="1" fill="currentColor" />
            <path d="M95.6291 24.3136L95.4782 24.4301C94.7854 24.9648 94.4963 25.8728 94.7522 26.7097L94.8297 26.9631C95.1201 27.9125 94.0192 28.6738 93.2334 28.0672C92.5068 27.5063 91.4932 27.5063 90.7666 28.0672C89.9808 28.6738 88.8799 27.9125 89.1703 26.9631L89.2478 26.7097C89.5037 25.8728 89.2146 24.9648 88.5218 24.4301L88.3709 24.3136C87.5718 23.6967 88.0063 22.3734 89.0632 22.3734C89.9535 22.3734 90.7514 21.7927 91.0134 20.9358C91.3111 19.9626 92.6889 19.9626 92.9866 20.9358C93.2486 21.7927 94.0465 22.3734 94.9368 22.3734C95.9937 22.3734 96.4282 23.6967 95.6291 24.3136Z" stroke="currentColor" />
            <path d="M23.6291 24.3136L23.4782 24.4301C22.7854 24.9648 22.4963 25.8728 22.7522 26.7097L22.8297 26.9631C23.1201 27.9125 22.0192 28.6738 21.2334 28.0672C20.5068 27.5063 19.4932 27.5063 18.7666 28.0672C17.9808 28.6738 16.8799 27.9125 17.1703 26.9631L17.2478 26.7097C17.5037 25.8728 17.2146 24.9648 16.5218 24.4301L16.3709 24.3136C15.5718 23.6967 16.0063 22.3734 17.0632 22.3734C17.9535 22.3734 18.7514 21.7927 19.0134 20.9358C19.3111 19.9626 20.6889 19.9626 20.9866 20.9358C21.2486 21.7927 22.0465 22.3734 22.9368 22.3734C23.9937 22.3734 24.4282 23.6967 23.6291 24.3136Z" stroke="currentColor" />
        </svg>
    )
}
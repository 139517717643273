import React, { useState, useEffect, useRef } from "react";
import { useClassNames } from "../../../../hooks/useClassNames";
import GenericSidebar from "../../../../components/Modals/genericSidebar";
import Search from "../Group/Tabs/search";
import { useToastAction } from "../../../../hooks/useToastAction";
import { addGroup, addUsersToGroup, validateGroupName } from "../../../../api/groups";

export default function CreateSidebar({ open, setOpen, usersAtCustomer }) {
    const handleOpen = () => {
        setOpen(!open)
    }

    return (
        <>
            {(
                <GenericSidebar
                    open={open}
                    setOpen={handleOpen}
                    title={`Create group`}
                >
                    <SideBarContent usersAtCustomer={usersAtCustomer} setOpen={setOpen} open={open} />

                </GenericSidebar>

            )}
        </>
    );
}

const SideBarContent = ({ usersAtCustomer, setOpen, open }) => {
    const { classNames } = useClassNames();
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [group, setGroup] = useState({ name: '', id: '' });
    const saveAction = useToastAction();
    const groupNameRef = useRef(null);
    const [error, setError] = useState('');

    const handleChange = ({ name, value }) => {
        setError('');
        setGroup(prev => {
            return { ...prev, [name]: value };
        })
    }

    const saveGroupData = async () => {
        setError('');
            saveAction.execute(async () => {
                var validationRequest = { groupName: group.name };
                const validationResult = await validateGroupName(validationRequest);
                if (validationResult?.error)
                {
                    setError(validationResult.error);
                }
                else
                {
                    const groupId = await addGroup(group);
                    await addUsersToGroup(groupId, selectedUsers);
                    setSelectedUsers([]);
                    setOpen(false)
                }                
            }, 'Failed to save')
    }

    useEffect(() => {
        if (open && groupNameRef.current) {
            groupNameRef.current.focus();
        }
    }, [open]);
    return (
        <>
            <div className="flex flex-col ml-4 mr-4 h-full">
                <div className="mt-8">
                    <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Name</label>
                    <input type="text" name="name" id="name"
                        onChange={e => handleChange(e.target)}
                        ref={groupNameRef}
                        autoFocus={true}
                        value={group?.name}
                        placeholder="Group name"
                        className="shadow-sm focus:ring-indigo-500 w-full focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md" />
                </div>
                {
                <p className="mt-2 text-sm text-red-600" id="groupname-error">
                    {error}
                </p>
                }                
                <div className="mt-4">
                    <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Add users</label>
                    <Search
                        usersAtCustomer={usersAtCustomer}
                        selectedUsers={selectedUsers}
                        setSelectedUsers={setSelectedUsers}
                    />
                </div>
            </div>


            {/* Action buttons */}
            <div className="flex-shrink-0 px-4 border-t bg-gray-50 border-gray-200 py-5 sm:px-6">
                <div className="space-x-3 flex justify-end">
                    <button
                        type="button"
                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={() => setOpen(false)}
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        //className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-brand-pink hover:bg-brand-pink-hover"
                        className={classNames("inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-brand-pink hover:bg-brand-pink-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50")}
                        onClick={saveGroupData}
                        disabled={group.name === ""}
                    >
                        Create
                    </button>
                </div>
            </div>
        </>
    )
}
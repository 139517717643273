import React, { useContext } from 'react';
import { Field, useFormik, useFormikContext } from 'formik';
import TextField from '../../../../../components/TextField';
import { RadioGroup } from '@headlessui/react';
import { CoreButton, useClassNames, useToastAction } from '@metaforcelabs/metaforce-core';
import { InformationCircleIcon, PencilIcon, TrashIcon } from '@heroicons/react/outline';
import logoImages, { ScriveLogo } from '../../../../../assets/images/logos/logos';
import { CheckCircleIcon as CheckCircleIconOutline } from '@heroicons/react/outline';
import { testCustomerBankIdAuthenticationProvider } from '../../../../../api/integration';
import { BankIdAuthenticationIntegrationContext } from '../../../../../contexts';
import { CheckCircleIcon } from '@heroicons/react/solid';
import BankIdAuthorizationMethods from './BankIdAuthorizationMethods';

const ProviderTypes = {
  verified: 'verified',
  scrive: 'scrive'
};

const Provider = ({ customerProvider, setCustomerProvider, bankIdAuthenticationProviders, formValues }) => {
  const { createRequestFromValues } = useContext(BankIdAuthenticationIntegrationContext);
  const testAction = useToastAction();
  const generateAccessKeysAction = useToastAction();
  const formik = useFormikContext();
  const { classNames } = useClassNames();

  const handleTestCredentials = () => {
    handleResetTestValue();
    testAction.execute(async () => {
      const request = createRequestFromValues(formValues)
      const res = await testCustomerBankIdAuthenticationProvider(request);
      formik.setFieldValue('testSuccessful', true);
    }, 'Test failed');
  }

  const handleTestToken = () => {
    handleResetTokenTestValue();
    testAction.execute(async () => {
      const request = createRequestFromValues(formValues)
      const res = await testCustomerBankIdAuthenticationProvider(request);
      formik.setFieldValue('tokenTestSuccessful', true);
    }, 'Test failed');
  }

  const handleResetTestValue = () => {
    formik.setFieldValue('testSuccessful', false);
  }

  const handleResetTokenTestValue = () => {
    formik.setFieldValue('tokenTestSuccessful', false);
  }

  return (
    <div className="space-y-6 sm:space-y-5">
      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-45 sm:pt-5 sm:border-b border-b-gray-200 sm:pb-6">
        <label htmlFor="" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
          Provider
        </label>

        <div className="mt-1 sm:col-span-2 sm:mt-0">
          <Field name="provider">
            {({ field }) => (
              <ProviderSelect name={field.name} label={''} value={field.value} onChange={e => {
                handleResetTestValue();
                field.onChange(e)
              }} providerList={bankIdAuthenticationProviders} />
            )}
          </Field>
        </div>
      </div>

      {formValues.provider === ProviderTypes.verified && (
        <>
          <VerifiedProviderForm currentProviderCredentials={customerProvider?.customCredentials} onResetTestValue={handleResetTestValue} />
          <div className={classNames('flex justify-end')}>
            <CoreButton
              htmlClassNames={classNames('flex justify-start items-start focus:!ring-0 focus:!ring-transparent', formValues.testSuccessful ? 'bg-green-500 hover:bg-green-500' : 'bg-blue-600 hover:bg-blue-500')}
              label={formValues.testSuccessful ? <div className=''>Test Successful <CheckCircleIconOutline className='h-6 inline-block' /></div> : testAction.isExecuting ? <div>Testing Credentials....</div> : <div>Test Credentials</div>}
              htmlType="button"
              onClick={e => {
                if (!formValues.testSuccessful) {
                  handleTestCredentials();
                }
              }}
            />
          </div>
        </>
      )}
      {formValues.provider === ProviderTypes.scrive && (
        <>
          <ScriveProviderForm customCredentials={customerProvider?.customCredentials} onResetTestValue={handleResetTokenTestValue} />
          <div className={classNames('flex justify-end')}>
            <CoreButton
              htmlClassNames={classNames('flex justify-start items-start focus:!ring-0 focus:!ring-transparent', formValues.tokenTestSuccessful ? 'bg-green-500 hover:bg-green-500' : 'bg-blue-600 hover:bg-blue-500')}
              label={formValues.tokenTestSuccessful ? <div className=''>Test Successful <CheckCircleIconOutline className='h-6 inline-block' /></div> : testAction.isExecuting ? <div>Testing Token....</div> : <div>Test Token</div>}
              htmlType="button"
              onClick={e => {
                if (!formValues.tokenTestSuccessful) {
                  handleTestToken();
                }
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

const ScriveProviderForm = ({ customCredentials, onResetTestValue }) => {
  return (
    <>
      <div className="sm:pt-3 mt-8 space-y-4">
        
        <div className="grid grid-cols-12">
          <div className="col-span-12 sm:col-span-4">
            <label className="sm:pt-2 text-sm font-medium text-gray-700 sm:flex items-center">
              Bearer token
            </label>
          </div>

          <div className="col-span-12 sm:col-span-8">
            <Field name="bearerToken">
              {({ field, form: { errors, touched } }) => (
                <TextField
                  type="password"
                  name={field.name}
                  id="bearerToken"
                  value={field.value}
                  error={touched.bearerToken ? errors.bearerToken : false}
                  onChange={e => {
                    onResetTestValue()
                    field.onChange(e)
                  }}
                />
              )}
            </Field>
          </div>
        </div>
        <BankIdAuthorizationMethods />
      </div>
    </>
  );
}

const VerifiedProviderForm = ({ currentProviderCredentials, onResetTestValue }) => {
  return (
    <>
      <div className="sm:pt-3 mt-8 space-y-4">
        <div className="grid grid-cols-12">
          <div className="col-span-12 sm:col-span-4">
            <label className="sm:pt-2 text-sm font-medium text-gray-700 sm:flex items-center">
              UserId
            </label>
          </div>

          <div className="col-span-12 sm:col-span-8">
            <Field name="clientId">
              {({ field, form: { errors, touched } }) => (
                <TextField
                  name={field.name}
                  id="clientId"
                  value={field.value}
                  error={touched.clientId ? errors.clientId : false}
                  onChange={e => {
                    onResetTestValue()
                    field.onChange(e)
                  }}
                />
              )}
            </Field>
          </div>
        </div>

        <div className="sm:pt-3 grid grid-cols-12">
          <div className="col-span-12 sm:col-span-4">
            <label className="text-sm font-medium text-gray-700 sm:flex items-center">
              Password
            </label>
          </div>

          <div className="col-span-12 sm:col-span-8">
            <Field name="clientSecret">
              {({ field, form: { errors, touched } }) => (
                <>
                  <TextField
                    type="password"
                    name={field.name}
                    id="clientSecret"
                    value={field.value}
                    error={touched.clientSecret ? errors.clientSecret : false}
                    onChange={e => {
                      onResetTestValue()
                      field.onChange(e)
                    }}
                  />

                  <div>
                    <span className="text-sm text-gray-500">
                      {currentProviderCredentials?.isSet && !field.value && 'This value is hidden'}
                    </span>
                  </div>
                </>
              )}
            </Field>
          </div>
        </div>

        <div className="sm:pt-3 grid grid-cols-12">
          <div className="col-span-12 sm:col-span-4">
            <label className="text-sm font-medium text-gray-700 sm:flex items-center">
              CompanyId
            </label>
          </div>

          <div className="col-span-12 sm:col-span-8">
            <Field name="companyId">
              {({ field, form: { errors, touched } }) => (
                <TextField
                  name={field.name}
                  id="companyId"
                  value={field.value}
                  error={touched.companyId ? errors.companyId : false}
                  onChange={e => {
                    onResetTestValue()
                    field.onChange(e)
                  }}
                />
              )}
            </Field>
          </div>
        </div>
        <BankIdAuthorizationMethods />
      </div>
    </>
  );
};

export default Provider;

const ProviderSelect = ({ name, value, label, providerList, onChange }) => {
  const { classNames } = useClassNames();
  const handleChange = (newValue) => {
    onChange({ target: { name: name, value: newValue } });
  }

  const renderProviderIcon = (providerId) => {

    switch (providerId) {
      case ProviderTypes.verified: return <div>
        <img className='h-8' src={logoImages.verified} alt='provider logo' />
      </div>;
      case ProviderTypes.scrive: return <div>
        <ScriveLogo />
      </div>;
      default:
        return <div><PencilIcon className='h-7 w-8' /></div>
    }

  }

  return (
    <RadioGroup value={value} onChange={handleChange}>
      <RadioGroup.Label className="text-base font-semibold leading-6 text-gray-900">
        {label}
      </RadioGroup.Label>

      <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
        {providerList.map((provider) => (
          <RadioGroup.Option
            key={provider.providerId}
            value={provider.providerId}
            className={({ active }) =>
              classNames(
                active ? 'border-indigo-600 ring-2 ring-indigo-600' : 'border-gray-300',
                'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none'
              )
            }
          >
            {({ checked, active }) => (
              <>
                <span className="flex flex-1">
                  <span className="flex flex-col">
                    <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                      {provider.name}
                    </RadioGroup.Label>
                    <RadioGroup.Description as="span" className="mt-1 flex items-center text-sm text-gray-500">
                      {provider.description}
                    </RadioGroup.Description>
                    <RadioGroup.Description as="span" className="mt-6 h-full flex justify-start items-end">
                      <div className=''>
                        {renderProviderIcon(provider.providerId)}
                      </div>
                    </RadioGroup.Description>
                  </span>
                </span>
                <CheckCircleIcon
                  className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-indigo-600')}
                  aria-hidden="true"
                />
                <span
                  className={classNames(
                    active ? 'border' : 'border-2',
                    checked ? 'border-indigo-600' : 'border-transparent',
                    'pointer-events-none absolute -inset-px rounded-lg'
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>)
}
import React from "react";

export const badgeTypes = {
    success: "success",
    error: "error",
    warn:"warn",
    info: "info",
    default: "default"
}
export const Badge = ({
    type = badgeTypes.success, // 'success', 'error', 'warn'
    text,
  }) => {
    const colorClasses = () => {
        switch (type) {
            case badgeTypes.success:
              return "bg-green-100 text-green-800";
            case badgeTypes.error:
              return 'bg-red-100 text-red-800';
            case badgeTypes.warn:
              return "bg-yellow-100 text-yellow-800";
            case badgeTypes.info:
                return "bg-blue-200 text-blue-800";
            default:
                return "bg-gray-200 text-gray-800";              
          }
    }
    return (
      <span className={`inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium ${colorClasses()}`}>
        {text}
      </span>
    );
  }
import React, { useState, useEffect, useContext } from "react";
import { v4 as uuidv4 } from 'uuid';
import { useParams } from "react-router-dom";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import { format } from "date-fns";

import "./styles.scss";
import { SearchIcon } from "@heroicons/react/outline";
import { useClassNames } from "../../../hooks/useClassNames";
import Sidebar from "./sidebar";
import PublishConfirmation from "./publishConfirmation";
import DeleteConfirmation from "./deleteConfirmation";
import { getAttributesForEnvironment, publish, load, save, deleteDocuments } from "../../../api/attributeStore";
import LoadMenu from "./loadMenu";
import { AuthenticatedRoutesContext } from "../../../contexts";
import ActivateConfirmation from "./activateConfirmation";
import { ContentWrapper, PageHeader } from "@metaforcelabs/metaforce-core";

export default function AttributeStore() {
  const authContext = useContext(AuthenticatedRoutesContext);
  const { environment } = useParams();
  const [documentsToShow, setDocumentsToShow] = useState([]);
  const [allDocuments, setAllDocuments] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [lastPublished, setLastPublished] = useState(Date.now);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [activeDocument, setActiveDocument] = useState();
  const [selectAll, setSelectAll] = useState(false);
  const [onlyShowEdit, setOnlyShowEdit] = useState(false);
  const [onlyShowActive, setOnlyShowActive] = useState(false);
  const classNames = useClassNames();
  const [openPublishConfirmation, setOpenPublishConfirmation] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [openActivateConfirmation, setOpenActivateConfirmation] = useState(false);

  const getEnviromentForApi = () => {
    switch (environment) {
      case "production":
        return 3;
      case "test":
        return 2;
      case "development":
      default:
        return 1
      // code block
    }
  }

  const enviromentForApi = getEnviromentForApi();

  useEffect(() => {
    const date = new Date();
    date.setDate(date.getDate() - 1);

    setOnlyShowActive(false);
    setOnlyShowEdit(false);

    setLastPublished(date)
    fetchDocuments();
  }, [environment]);

  useEffect(() => {
    setDocumentsToShow(allDocuments);
  }, [allDocuments]);

  useEffect(() => {

    setCsvData(documentsToShow
      .map(row => ({
        ...row,
        updatedAtFormatted: format(new Date(row.updatedAt), "MM/dd/yyyy - HH:mm"),
        updatedByFormatted: row.updatedByName || 'System',
        activeAtFormatted: row.active.toString(),
      }))
    );
  }, [documentsToShow]);

  const fetchDocuments = async () => {
    const date = new Date();
    date.setDate(date.getDate() + 1);

    const data = await getAttributesForEnvironment(enviromentForApi);
    setLastPublished(data.lastPublished)
    setAllDocuments(data ? data.documents : [])
  }

  const handleSidebar = () => {
    if (openSidebar) {
      setActiveDocument(null);
    }

    setOpenSidebar(!openSidebar);
  };

  const handleSelectAll = () => {
    documentsToShow.forEach((item) => {
      item.selected = !selectAll;
    });

    setDocumentsToShow([...documentsToShow])
    setSelectAll(!selectAll)
  }

  const handleItemSelected = (document) => {
    documentsToShow.forEach((item) => {
      if (item.id === document.id) {
        item.selected = !document.selected
      }
    });

    setDocumentsToShow([...documentsToShow])
  }

  const handleCreate = async () => {
    const newDocument = {
      "id": uuidv4(),
      "type": "",
      "category": "",
      "name": "",
      "topic": "",
      "internalOnly": "",
      "changesAllowed": "",
      "retentionPolicy": "",
      "adminCompany": "",
      "plexmode": "",
      "colormode": "",
      "postmode": "",
      "distributionMethod": "",
      "comment": "",
      "active": true,
      "updatedAt": new Date(),
      "updatedBy": "You",
      "selected": false
    }

    setActiveDocument(newDocument);
    setOpenSidebar(true);
  }

  const handleLoad = async (environmentToLoad) => {
    const data = await load(enviromentForApi, environmentToLoad)
    setAllDocuments(data.documents)

    toast.success("Documents loaded")
  }

  const handleShowOnlyEdited = () => {

    if (!onlyShowEdit) {
      setDocumentsToShow(
        allDocuments.filter(m => m.hasChanged && (onlyShowActive ? m.active : true))
      )
    }
    else {
      setDocumentsToShow(
        allDocuments.filter(m => onlyShowActive ? m.active : true)
      )
    }

    setOnlyShowEdit(!onlyShowEdit);
  }

  const handleShowOnlyActive = () => {
    if (!onlyShowActive) {
      setDocumentsToShow(
        allDocuments.filter(m => m.active && (onlyShowEdit ? m.hasChanged : true))
      )
    }
    else {
      setDocumentsToShow(
        allDocuments.filter(m => onlyShowEdit ? m.hasChanged : true)
      )
    }

    setOnlyShowActive(!onlyShowActive);
  }

  const handleSave = async () => {
    const documentsToSave = [];
    documentsToSave.push(activeDocument);

    const data = await save(documentsToSave)
    setAllDocuments(data.documents)
    setOpenSidebar(false)

    toast.success("Document saved")
  }

  const handleDocumentRow = (key) => {
    setOpenSidebar(true);
    setActiveDocument(allDocuments.find((m) => m.id === key));
  };

  const handlePublish = async () => {
    var selectedDocuments = documentsToShow.filter(m => m.selected);
    await publish(enviromentForApi, selectedDocuments)
    fetchDocuments();

    toast.success("Documents published")
  }

  const handleDelete = async () => {
    var documentsToDelete = documentsToShow.filter(m => m.selected);
    await deleteDocuments(documentsToDelete)
    fetchDocuments();

    toast.success("Documents deleted")
  }

  const handleActivation = async (activate) => {
    var documentsToActivate = documentsToShow.filter(m => m.selected);
    documentsToActivate.forEach((item) => item.active = activate);
    const data = await save(documentsToActivate);
    setAllDocuments(data.documents)

    toast.success(`Documents ${activate ? "activated" : "deactivated"}`)
  }

  const headers = [
    { label: "Type", key: "type" },
    { label: "Category", key: "category" },
    { label: "Name", key: "name" },
    { label: "Topic", key: "topic" },
    { label: "Internal Only", key: "internalOnly" },
    { label: "Changes Allowed", key: "changesAllowed" },
    { label: "Retention Policy", key: "retentionPolicy" },
    { label: "Admin Company", key: "adminCompany" },
    { label: "PlexMode", key: "plexmode" },
    { label: "ColorMode", key: "colormode" },
    { label: "PostMode", key: "postmode" },
    { label: "DistributionMethod", key: "distributionMethod" },
    { label: "Comment", key: "comment" },
    { label: "UpdatedAt", key: "updatedAtFormatted" },
    { label: "UpdatedBy", key: "updatedByFormatted" },
    { label: "Active", key: "activeAtFormatted" },
  ];

  const search = (e) => {
    const { value } = e.target;
    const regExp = new RegExp(value, "i");
    setDocumentsToShow(
      value
        ? allDocuments.filter(
          (m) =>
            m.type.toString().match(regExp) ||
            m.category.toString().match(regExp) ||
            m.name.toString().match(regExp) ||
            m.retentionPolicy.toString().match(regExp) ||
            m.adminCompany.toString().match(regExp) ||
            m.topic.toString().match(regExp) ||
            m.distributionMethod.toString().match(regExp)
        )
        : allDocuments
    );

    setOnlyShowEdit(false);
    setOnlyShowActive(false);
  };

  return (
    <>
      <ContentWrapper>
          <PageHeader 
            title={'Documents - ' + environment} 
            optionalSideElement={
              <div className="flex items-center gap-6">
              <div className="relative flex items-start">
                <div className="ml- text-sm">
                  <label htmlFor="comments" className="font-medium text-gray-700">
                    Only show active
                  </label>
                </div>
                <div className="flex items-center ml-3 h-5">
                  <input
                    id="onlyShowActive"
                    aria-describedby="onlyShowActive-description"
                    name="onlyShowActive"
                    type="checkbox"
                    checked={onlyShowActive}
                    onChange={() => handleShowOnlyActive(true)}
                    className="focus:ring-brand-pink h-4 w-4 text-brand-pink border-gray-300 rounded"
                  />
                </div>
              </div>
              <div className="relative flex items-start">
                <div className="ml- text-sm">
                  <label htmlFor="comments" className="font-medium text-gray-700">
                    Only show edited
                  </label>
                </div>
                <div className="flex items-center ml-3 h-5">
                  <input
                    id="onlyShowEdit"
                    aria-describedby="onlyShowEdit-description"
                    name="onlyShowEdit"
                    type="checkbox"
                    checked={onlyShowEdit}
                    onChange={() => handleShowOnlyEdited(true)}
                    className="focus:ring-brand-pink h-4 w-4 text-brand-pink border-gray-300 rounded"
                  />
                </div>
              </div>
  
              <div className="w-96 mt-3 sm:mt-0 sm:ml-4">
                <label htmlFor="search" className="sr-only">
                  Search
                </label>
                <div className="relative">
                  <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                    <SearchIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    id="search"
                    name="search"
                    className="block w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-brand-pink focus:border-brand-pink sm:text-sm"
                    placeholder="Search"
                    type="search"
                    onChange={search}
                  />
                </div>
              </div>
            </div>
            }
          />

        <div className="mt-10 pb-5 sm:flex sm:items-center sm:justify-between">
          <div className="mt-3 flex sm:mt-0">
            {
              environment !== 'production' &&
              <button
                type="button"
                className="mr-2 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-brand-pink hover:bg-brand-pink-hover "
                onClick={() => setOpenPublishConfirmation(true)}
              >
                Publish
              </button>
            }
            {
              environment === 'development' &&
              <button
                type="button"
                className="mr-2 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-brand-pink hover:bg-brand-pink-hover "
                onClick={() => setOpenActivateConfirmation(true)}
              >
                Activate/Deactivate
              </button>
            }
            {
              environment === 'development' &&
              <button
                type="button"
                onClick={handleCreate}
                className="mr-2 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-brand-pink hover:bg-brand-pink-hover "
              >
                New
              </button>
            }
            {
              environment === 'development' &&
              <button
                type="button"
                onClick={() => setOpenDeleteConfirmation(true)}
                className="mr-2 inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
              >
                Delete
              </button>
            }
            <CSVLink
              data={csvData}
              headers={headers}
              separator={";"}
              filename={"attribute-export-file.csv"}
              className="mr-2 inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 "
            >
              Export to Excel
            </CSVLink>

            {
              environment !== 'production' &&
              <LoadMenu environment={enviromentForApi} handleLoad={handleLoad} />
            }

          </div>
        </div>

        {/* Start main area*/}
        <div className="shadow-sm overflow-y-hidden overflow-x-auto border border-gray-200 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  <input
                    id="selectAll"
                    name="selectAll"
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleSelectAll}
                    className="focus:ring-brand-pink h-4 w-4 text-brand-pink border-gray-300 rounded"
                  />
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Type
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Category
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Topic
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Retention Policy
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Admin Company
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Distribution Method
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Active
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {documentsToShow &&
                documentsToShow.map((document) => (
                  <tr
                    key={document.id}
                    className={classNames.classNames(
                      document.id === activeDocument?.id
                        ? "bg-gray-100"
                        : "",
                      "hover:bg-gray-100 cursor-pointer",
                      document.hasChanged
                        ? "bg-yellow-100"
                        : ""
                    )}
                  >
                    <td className="px-6 w-12 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      <input
                        id="selected"
                        name="selected"
                        type="checkbox"
                        checked={document.selected || false}
                        onChange={() => handleItemSelected(document)}
                        className="focus:ring-brand-pink h-4 w-4 text-brand-pink border-gray-300 rounded"
                      />
                    </td>
                    <td className="px-6 py-4 text-sm font-medium text-gray-900" onClick={() => handleDocumentRow(document.id)}>
                      {document.type}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500" onClick={() => handleDocumentRow(document.id)}>
                      {document.category}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500" onClick={() => handleDocumentRow(document.id)}>
                      {document.name}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500" onClick={() => handleDocumentRow(document.id)}>
                      {document.topic}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500" onClick={() => handleDocumentRow(document.id)}>
                      {document.retentionPolicy}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500" onClick={() => handleDocumentRow(document.id)}>
                      {document.adminCompany}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500" onClick={() => handleDocumentRow(document.id)}>
                      {document.distributionMethod}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500" onClick={() => handleDocumentRow(document.id)}>
                      {document.active.toString()}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        {/* End main area */}
      </ContentWrapper>

      <Sidebar
        open={openSidebar}
        setOpen={handleSidebar}
        document={activeDocument}
        allDocuments={allDocuments}
        setDocument={setActiveDocument}
        onSubmit={handleSave}
      />

      <PublishConfirmation
        open={openPublishConfirmation}
        moveFromEnvironment={environment}
        setOpen={setOpenPublishConfirmation}
        onSubmit={handlePublish}
      />

      <DeleteConfirmation
        open={openDeleteConfirmation}
        setOpen={setOpenDeleteConfirmation}
        onSubmit={handleDelete}
      />

      <ActivateConfirmation
        open={openActivateConfirmation}
        environment={environment}
        setOpen={setOpenActivateConfirmation}
        onSubmit={handleActivation}
        disabled={documentsToShow.filter(m => m.selected).length === 0 ? true : false}
      />
    </>
  );
}

import React, { useState, Fragment } from "react";
import { useParams } from "react-router-dom";
import { Formik } from 'formik';

import "./styles.scss";
import KivraServiceForm from "./Tabs/kiwraserviceform";
import SftpServiceForm from "./Tabs/sftpserviceform";
import SendgridServiceForm from "./Tabs/sendgridserviceform";
import TwilioServiceForm from "./Tabs/twilioserviceform";
import ElksServiceForm from "./Tabs/elksserviceform";
import SignicatServiceForm from "./Tabs/signicatserviceform";
import DigipostServiceForm from "./Tabs/digipostserviceform";
import PspServiceForm from "./Tabs/pspserviceform";
import ViewpointServiceForm from "./Tabs/viewpointserviceform";
import { History } from "./Tabs/history";
import { Changes } from "./Tabs/changes";
import { XIcon } from "@heroicons/react/outline";
import { Dialog, Transition } from "@headlessui/react";
import { useClassNames } from "../../../hooks/useClassNames";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { ServiceSetting } from "./defines";

const countries = [
  { name: 'All' },
  { name: 'Sweden' },
  { name: 'Norway' },
]

export default function Sidebar({ open, setOpen, activeSetting, onSave,getAllSettings }) {
  const { key, setting } = activeSetting || { key: null, setting: null };
  const { environment } = useParams();
  const [activeTab, setActiveTab] = useState("properties");
  const classNames = useClassNames();
  //const [settings,setSettings] = useState(allSettings);

  const myRef = React.useRef({})

  const canEdit = environment !== "production";

  const handleSave = (values) => {
    console.log(values);
    onSave({ key, setting: values });
  }

  const closeDialog = (close) => {
    setOpen(close);
  }

  const renderChanges = (setting) =>{
    let settings = getAllSettings();
      if(settings!=null && settings.draftVsPublished != null){    
        let list = settings.draftVsPublished.differences[setting.settingsId];
        return <Changes changes={list} />
      }
  }
  

  const renderActiveTab = () => {

    switch (activeTab) {
      case "properties":
        if (setting.settingsId === ServiceSetting.kivra)
          return <KivraServiceForm setting={setting} onSubmit={handleSave} formId="serviceform" reference={myRef} closeHandler={closeDialog} />;
        else if (setting.settingsId === ServiceSetting.sftp)
          return <SftpServiceForm setting={setting} onSubmit={handleSave} formId="serviceform" reference={myRef} closeHandler={closeDialog} countries={countries} />;
        else if (setting.settingsId === ServiceSetting.sendgrid)
          return <SendgridServiceForm setting={setting} onSubmit={handleSave} formId="serviceform" reference={myRef} closeHandler={closeDialog} countries={countries} />;
        else if (setting.settingsId === ServiceSetting.twilio)
          return <TwilioServiceForm setting={setting} canEdit={canEdit} onSubmit={handleSave} formId="serviceform" reference={myRef} closeHandler={closeDialog} countries={countries} />;
        else if (setting.settingsId === ServiceSetting.elks)
          return <ElksServiceForm setting={setting} canEdit={canEdit} onSubmit={handleSave} formId="serviceform" reference={myRef} closeHandler={closeDialog} countries={countries} />;
        else if (setting.settingsId === ServiceSetting.signicat)
          return <SignicatServiceForm setting={setting} canEdit={canEdit} onSubmit={handleSave} formId="serviceform" reference={myRef} closeHandler={closeDialog} countries={countries} />;
        else if (setting.settingsId === ServiceSetting.digipost)
          return <DigipostServiceForm setting={setting} canEdit={canEdit} onSubmit={handleSave} formId="serviceform" reference={myRef} closeHandler={closeDialog} countries={countries} />;
        else if (setting.settingsId === ServiceSetting.psp)
          return <PspServiceForm setting={setting} canEdit={canEdit} onSubmit={handleSave} formId="serviceform" reference={myRef} closeHandler={closeDialog} countries={countries} />;
        else if (setting.settingsId === ServiceSetting.viewpoint)
          return <ViewpointServiceForm setting={setting} canEdit={canEdit} onSubmit={handleSave} formId="serviceform" reference={myRef} closeHandler={closeDialog} countries={countries} />;
        else {
          alert('Not implemented yet!');
          return <></>;
        }
      case "history":
        return <History history={setting.history} />;
      case "compare":
        return renderChanges(setting);
      default:
        return <></>;
    }
  };

  return (
    <>
      {setting && (
        <Formik>
          <Transition.Root show={open} as={Fragment}>
            <Dialog
              as="div"
              static
              className="fixed inset-0 overflow-hidden z-50"
              open={open}
              onClose={setOpen}
            >
              <div className="absolute inset-0 overflow-hidden">
                <Dialog.Overlay className="absolute inset-0" />

                <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <div className="w-screen max-w-lg">
                      <form className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                        <div className="flex-shrink-0">
                          {/* Header */}
                          <div className="px-4 py-6 bg-brand-pink sm:px-6">
                            <div className="flex items-start justify-between space-x-3">
                              <div className="space-y-1">
                                <Dialog.Title className="text-lg font-medium text-white">
                                  Editing - {setting.displayName.toUpperCase()}
                                </Dialog.Title>
                              </div>
                              <div className="h-7 flex items-center">
                                <button
                                  type="button"
                                  className="bg-red-400 rounded-md text-white hover:text-gray-500 focus:outline-none cursor-pointer"
                                  onClick={() => setOpen(false)}
                                >
                                  <span className="sr-only">Close panel</span>
                                  <XIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="mt-5 border-b border-gray-200">
                            <div className="px-6">
                              <nav
                                className="-mb-px flex space-x-6"
                                x-descriptions="Tab component"
                              >
                                {[
                                  {
                                    name: "Properties",
                                    tab: "properties",
                                    current: activeTab === "properties",
                                  },
                                  {
                                    name: "History",
                                    tab: "history",
                                    current: activeTab === "history",
                                  },
                                  {
                                    name: "Compare",
                                    tab: "compare",
                                    current: activeTab === "compare",
                                  }
                                ]
                                  .filter((m) => !m.hide)
                                  .map((tab) => (
                                    <a
                                      key={tab.name}
                                      onClick={() => setActiveTab(tab.tab)}
                                      className={classNames.classNames(
                                        tab.current
                                          ? "border-black text-black"
                                          : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                        "py-4 w-1/2 px-1 text-center border-b-2 font-medium text-sm cursor-pointer"
                                      )}
                                    >

                                      {tab.name}
                                    </a>
                                  ))}
                              </nav>
                            </div>
                          </div>
                        </div>
                        <div className="flex w-full overflow-y-auto h-full">
                          {renderActiveTab()}
                        </div>
                        {/* Action buttons */}
                        <div className="flex-shrink-0 px-4 border-t bg-gray-50 border-gray-200 py-5 sm:px-6">
                          <div className="space-x-3 flex justify-end">
                            <button
                              type="button"
                              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 "
                              onClick={() => setOpen(false)}
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              className={activeTab === "history" ? "inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-300" : "inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-brand-pink hover:bg-brand-pink-hover"}
                              onClick={() => { myRef.current.handleClick() }}
                              form="serviceform"
                              disabled={activeTab === "history"}
                            >
                              Ok
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        </Formik>
      )}
    </>
  );
}

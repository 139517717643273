
import { get, BaseUrl, post } from './api';

export const getPlanTiers = async () => {
    return await get(`api/Plan/PlanTiers`, null,
        BaseUrl.Centerpoint);
}
export const getPlanTier = async (planId) => {
    return await get(`api/Plan/PlanTiers/${planId}`, null,
        BaseUrl.Centerpoint);
}

export const getPlanStarted = async (request)=>{
    return await post(`api/CustomerPlan/started`, request, null,
        BaseUrl.Centerpoint);
}

export const startCustomerPlan = async (planId) => {
    return await post(`api/CustomerPlan/Start`, { planId }, null,
        BaseUrl.Centerpoint);
}
export const issueCustomerInvoice = async () => {
    return await get(`api/CustomerPlan/IssueInvoice`, null,
        BaseUrl.Centerpoint);
}

export const getCustomerPlanCurrent = async () => {
    return await get(`api/CustomerPlan/current`, null, BaseUrl.Centerpoint);
}
 import { useClassNames } from "../../hooks/useClassNames";
import { useState } from "react";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";

/**
 * @param {*} SidebarTextbox 
 */
export const SidebarPassword = ({ label, value, name, onChange, disabled, error }) => {
  const classNames = useClassNames();
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <>
      <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-1 sm:gap-3 sm:px-6 sm:py-1">
        <div>
          <label
            htmlFor="project_name"
            className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
          >
            {label}
          </label>
        </div>
        <div className="sm:col-span-2">
          <div className="relative">
            <input
              type={passwordShown ? "text" : "password"}
              name={name}
              value={value}
              disabled={disabled}
              onChange={onChange}
              className={classNames.classNames(
                "block w-full shadow-sm sm:text-sm focus:ring-brand-pink focus:border-brand-pink border-gray-300 rounded-md disabled:opacity-50 ",
                error
                  ? "placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 border-red-300 text-red-900 "
                  : ""
              )}
            />
            <div className="absolute inset-y-0 right-0 flex items-center mr-3" onClick={togglePassword}>
              {passwordShown ? (<EyeOffIcon className="h-5 w-5 text-gray-400"  />) :
                (<EyeIcon className="h-5 w-5 text-gray-400" />)
              }
            </div>
          </div>
          {
            error &&
            <p className="mt-2 text-sm text-red-600" id="email-error">
              {error}
            </p>
          }
        </div>
      </div>
    </>
  );
}
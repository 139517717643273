import React, { useEffect, useState } from 'react';
import {
  getAllUserFoldersFlatApi,
  getDigitalSigningConfigurationApi,
  getDigitalSigningProviders,
  updateCustomerDigitalSigningProvider,
  updateDigitalSigningArchiveIntegrationApi
} from '../../../../api/integration';
import { useToastAction } from '../../../../hooks/useToastAction';
import Provider from './components/Provider';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import Storage from './components/Storage';
import { toast } from 'react-hot-toast';
import Loader from '../../../../components/Loader';
import { tailwindToast } from '../../../../components/Toast/tailwindToast';
import { ContentWrapper, CoreButton, PageHeader } from '@metaforcelabs/metaforce-core';
import { DigitalSigningIntegrationContext } from '../../../../contexts';
import { ExclamationIcon } from '@heroicons/react/solid';

const StoringTypes = {
  None: 0,
  Optional: 1,
  Mandatory: 2
};

const ProviderTypes = {
  verified: 'verified',
  signicat: 'signicat',
  scrive: 'scrive'
};

const CredentialsType = {
  Signicat: 1,
  Verified: 2,
  Scrive: 3,
};

const initialFormValues = {
  provider: '',
  clientId: '',
  clientSecret: '',
  companyId: '',
  defaultNotificationEmail: '',
  storingType: StoringTypes.None,
  foldersIds: [],
  showDownloadSignedFileFromProviderWhenStatusIsCompleted: false,
  testSuccessful: false,
};

export default function SigningIntegration() {
  const [digitalSigningProviders, setDigitalSigningProviders] = useState([]);
  const [customerProvider, setCustomerProvider] = useState(null);
  const [folders, setFolders] = useState([]);
  const [initialFolderIds, setInitialFolderIds] = useState([]);
  const [formValues, setFormValues] = useState(initialFormValues);

  const loadAction = useToastAction();
  const saveProviderAction = useToastAction();
  const getAllUserFolders = useToastAction();
  const saveStoringTypeAction = useToastAction();

  const checkIfAtLeastOneIsChoosen = ( choosen ) => {
    if(choosen) {
        const filteredArray = Object.values(choosen)?.filter(element => element === true);
        if(filteredArray.length > 0) return true;
    };
    return false
  }

  const loadData = () => {
    loadAction.execute(async () => {
      const allUserFolders = await getAllUserFoldersFlatApi();
      const providers = await getDigitalSigningProviders();
      const { archiveIntegration, provider } = await getDigitalSigningConfigurationApi();

      const customerFolders = allUserFolders
        .filter((userFolder) =>
          archiveIntegration.archiveFolderIds.some(
            (archiveFolderId) => archiveFolderId === userFolder.id
          )
        )
        .map((userFolder) => userFolder.id);

      setFormValues({
        accessKeys: provider.customCredentials.accessKeys,
        provider: provider.providerId,
        clientId: provider.customCredentials.clientId || '',
        clientSecret: '',
        defaultNotificationEmail: provider.customCredentials.defaultNotificationEmail || '',
        companyId: provider.customCredentials.companyId || '',
        storingType: archiveIntegration.archiveType,
        foldersIds: customerFolders,
        availableSignAuthMethods: provider.availableSignAuthMethods,
        testSuccessful: false,
        showDownloadSignedFileFromProviderWhenStatusIsCompleted:
          archiveIntegration.showDownloadSignedFileFromProviderWhenStatusIsCompleted
      });

      setFolders(allUserFolders);
      setInitialFolderIds(archiveIntegration.archiveFolderIds);
      setDigitalSigningProviders(providers);
      setCustomerProvider(provider);
    }, 'Failed to load');
  };

  const handleOnStoringSave = (values) => {
    const hasFolderWithoutAccessGroup = values.foldersIds.find((fId) => {
      const folder = folders.find((f) => f.id === fId);

      if (folder) {
        return folder.accessGroups.length === 0;
      }

      return false;
    });

    if (parseInt(values.storingType) !== StoringTypes.None && !hasFolderWithoutAccessGroup) {
      toast.error('You need to have at least default, free access folder selected');
    } else {
      saveStoringTypeAction.execute(async () => {
        await updateDigitalSigningArchiveIntegrationApi({
          archiveType: parseInt(values.storingType),
          archiveFolderIds: values.foldersIds,
          showDownloadSignedFileFromProviderWhenStatusIsCompleted:
            values.showDownloadSignedFileFromProviderWhenStatusIsCompleted
        });

        setInitialFolderIds(values.foldersIds);
      }, 'Failed to save  storage settings ');
    }
  };

  const fetchAllUserFoldersFlat = () => {
    getAllUserFolders.execute(async () => {
      const getAllUserFoldersResult = await getAllUserFoldersFlatApi();

      setFolders(getAllUserFoldersResult);
    }, 'Failed to load folders');
  };

  const handleOnProviderSave = (values) => {
    saveProviderAction.execute(async () => {
      const request = createRequestFromValues(values);
      await updateCustomerDigitalSigningProvider(request);
    }, 'Failed to save provider settings');
  };

  const createRequestFromValues = (values) => {
    return {
      providerId: values.provider,
      credentialsType:
        values.provider === ProviderTypes.verified || values.provider === ProviderTypes.scrive
          ? CredentialsType.Verified
          : CredentialsType.Signicat,
      availableSignAuthMethods: values.availableSignAuthMethods,
      customCredentials: {
        clientId: values.clientId,
        accessKeys: values.accessKeys,
        clientSecret: values.clientSecret,
        companyId: values.companyId,
        defaultNotificationEmail: values.defaultNotificationEmail
      }
    };
  }

  const handleOnStoringTypeChange = async (value) => {
    if (parseInt(value) !== StoringTypes.None) {
      fetchAllUserFoldersFlat();
    }
  };

  const handleOnSave = async (values) => {
    await Promise.all([handleOnProviderSave(values), handleOnStoringSave(values)]);
    tailwindToast.success('Save successful');
  };

  useEffect(() => {
    loadData();
  }, []);

  const validationSchema = yup.object().shape({
    clientId: yup
      .string()
      .trim()
      .when('provider', {
        is: (value) => value === ProviderTypes.verified,
        then: yup.string('Text only').required('Required'),
        otherwise: yup.string()
      }),
    testSuccessful: yup.bool().when('provider', {
      is: (value) => value === ProviderTypes.verified,
      then: yup.bool().isTrue('Test has to be run successfully in order to save Digital Signing settings')
    }),
    clientSecret: yup
      .string()
      .trim()
      .when('provider', {
        is: (value) => value === ProviderTypes.verified || value === ProviderTypes.scrive,
        then: yup.string('Text only').test('isSetPassword', 'Required', (value) => {
          if (customerProvider && customerProvider.customCredentials.isSet) {
            return true;
          } else {
            if (value && value.length > 0) {
              return true;
            }
          }

          return false;
        }),
        otherwise: yup.string()
      }),
    defaultNotificationEmail: yup
      .string()
      .trim()
      .when('provider', {
        is: (value) => value === ProviderTypes.signicat,
        then: yup.string().email("Must be valid email").required('Required'),
        otherwise: yup.string().email()
      }),
    companyId: yup
      .string()
      .trim()
      .when('provider', {
        is: (value) => value === ProviderTypes.verified,
        then: yup.string('Text only').required('Required'),
        otherwise: yup.string()
      }),
    foldersIds: yup.array().when('storingType', {
      is: (value) => {
        return parseInt(value) !== StoringTypes.None;
      },
      then: yup.array().min(1, 'Required'),
      otherwise: yup.array()
    })
  });

  return (
    <DigitalSigningIntegrationContext.Provider
      value={{
        createRequestFromValues: createRequestFromValues
      }}
    >
      <ContentWrapper>
        <PageHeader title="Digital Signing" description={<div>Configure your digital signing provider to be used with CenterPoint applications</div>} />
        {loadAction.isExecuting && (
          <Loader overlay className="fixed z-50 inset-0 flex items-center" />
        )}

        <Formik
          initialValues={formValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={(values, { setSubmitting }) => {
            handleOnSave(values);
          }}
        >
          {(props) => (
            <Form>
              <div className="space-y-6 sm:space-y-6">
                <div className="">
                  <Provider
                    customerProvider={customerProvider}
                    setCustomerProvider={setCustomerProvider}
                    digitalSigningProviders={digitalSigningProviders}
                    formValues={props.values}
                    isLoading={saveProviderAction.isExecuting}
                  />
                  <div className="flex w-100 justify-end mt-3 tracking-wide font-medium text-red-500 text-xs">
                    {props.errors.testSuccessful}
                  </div>
                </div>

                <div className="sm:border-t sm:border-gray-200 sm:pt-6">
                  <Storage
                    folders={folders}
                    initialFolderIds={initialFolderIds}
                    formikProps={props}
                    onStoringTypeChange={handleOnStoringTypeChange}
                  />
                </div>

                <div className="flex justify-end sm:border-t sm:border-gray-200 sm:pt-6">
                  <CoreButton htmlType='submit' label={<div>Save</div>} disabled={
                    saveProviderAction.isExecuting || saveStoringTypeAction.isExecuting 
                    || (props.values.provider === 'scrive' && (!props.values.accessKeys || !checkIfAtLeastOneIsChoosen(props.values.availableSignAuthMethods)))} 
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </ContentWrapper>
    </DigitalSigningIntegrationContext.Provider>
  );
}

import React from "react"
import { Link } from "react-router-dom"
import InvoiceStatusBadge from "../../Common/invoiceStatusBadge"

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


export const ActionTile = ({ title, text, href, isExternal, icon: ActionIcon, iconBackground, iconForeground, lastInvoiceStatus, lastInvoiceDateDue, actionIdx }) => {
    return (
        <div
            key={title}
            className={classNames(
                actionIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
                actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
                actionIdx === 2 ? 'sm:rounded-bl-lg' : '',
                actionIdx === 3 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '',
                'relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500'
            )}
        // className="relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500 rounded border"
        >
            <div>
                <span
                    className={classNames(
                        iconBackground,
                        iconForeground,
                        'rounded-lg inline-flex p-3 ring-4 ring-white'
                    )}
                >
                    <ActionIcon className="h-6 w-6" aria-hidden="true" />
                </span>
            </div>
            <div className="mt-8">
                <h3 className="text-lg font-medium">
                    <ActionLink isExternal={isExternal} href={href} title={title} />
                </h3>
                <p className="mt-2 text-sm text-gray-500">
                    {text}
                </p>
            </div>
            <span
                className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
                aria-hidden="true"
            >
                <div className="flex space-x-4">
                    {
                        lastInvoiceStatus && (
                            <InvoiceStatusBadge status={lastInvoiceStatus} dueDate={lastInvoiceDateDue} />
                        )
                    }
                    <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                    </svg>
                </div>
            </span>
        </div>
        
    )
}

export const ActionLink = ({ isExternal, href, title }) => {
    if (isExternal) {
        return (
            <a href={href} target="_blank" rel="noreferrer" className="focus:outline-none">
                <span className="absolute inset-0" aria-hidden="true" />
                {title}
            </a>
        )
    }
    else {
        return (
            <Link
                to={href}
                key={title}
                className="cursor-pointer focus:outline-none"
            >
                <span className="absolute inset-0" aria-hidden="true" />
                {title}
            </Link>
        )
    }
}

export default ActionTile
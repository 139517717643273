import React, { useState, useEffect, useContext } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./checkout";
import { useToastAction } from "../../../../hooks/useToastAction";
import { getSignupInvoicePaymentIntent } from "../../../../api/billing";
import { formatThousand } from "../../../../utils/numbers";
import { useClassNames } from "../../../../hooks/useClassNames";
import { AuthenticatedRoutesContext } from "../../../../contexts";
import { ContentWrapper, PageHeader } from "@metaforcelabs/metaforce-core";


// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.


export default function Payments() {
  const [clientSecret, setClientSecret] = useState("");
  const [formLoaded, setFormLoaded] = useState(false);
  const [paymentIntent, setPaymentIntent] = useState([]);
  const loadPaymentIntent = useToastAction();
  const { classNames } = useClassNames();
  const authContext = useContext(AuthenticatedRoutesContext)
  const stripePromise = loadStripe(authContext.stripePk);

  const getPaymentIntentForSignupInvoice = async () => {
    loadPaymentIntent.execute(async () => {
      const paymentIntentData = await getSignupInvoicePaymentIntent()
      setPaymentIntent(paymentIntentData);
      setClientSecret(paymentIntentData.clientSecret);
      console.log(paymentIntentData);
    }, 'Failed to get payment intent for signup invoice')
  }


  useEffect(() => {
    getPaymentIntentForSignupInvoice();
  }, []);

  const appearance = {
    theme: 'stripe',
  };

  return (

    <ContentWrapper>
      <div className="px-4 sm:px-6 lg:px-8">
        <PageHeader title="Payment" description="Please pay to start using our services" />
        <div className="bg-white shadow rounded-lg max-w-full md:max-w-xl">
          <div className="px-4 mt-4 py-5">
            <div className="mt-2 mb-2">
              <div className="rounded-md px-4 flex flex-col md:flex-row justify-between">
                <div className="flex flex-row">
                  {

                    <div className={classNames("mt-1 text-sm font-medium text-gray-900",
                      (loadPaymentIntent.isExecuting || !formLoaded) ? " w-28 h-4 rounded rounded bg-gray-200 animate-pulse" : "")}>
                      {!loadPaymentIntent.isExecuting && formLoaded && (<span> To be paid {!loadPaymentIntent.isExecuting && (formatThousand(paymentIntent?.amountDue))} {paymentIntent?.currency.toUpperCase()} </span>)}
                    </div>

                  }
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white shadow rounded-lg max-w-full md:max-w-xl">
          <div className="px-4 mt-4 py-4">
            {
              (loadPaymentIntent.isExecuting || !formLoaded) && (

                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 mt-8">
                    <div className="w-full h-12  rounded bg-gray-200 animate-pulse"></div>
                  </div>

                  <div className="col-span-3 mt-2">
                    <div className="w-full h-12 rounded bg-gray-200 animate-pulse"></div>
                  </div>

                  <div className="col-span-3 mt-2">
                    <div className="w-full h-12 rounded bg-gray-200 animate-pulse"></div>
                  </div>
                  <div className="col-span-6 mt-2">
                    <div className="w-full h-12 rounded bg-gray-200 animate-pulse"></div>
                  </div>
                  <div className="col-span-6 mt-2">
                    <div className="w-full h-8 rounded bg-gray-200 animate-pulse"></div>
                  </div>
                  <div className="col-span-6 mt-2 justify-items-center">
                    <div className="flex w-full justify-center">
                      <div className="w-28 h-12 rounded bg-gray-200 animate-pulse">

                      </div>
                    </div>
                  </div>
                </div>
              )
            }
            {!loadPaymentIntent.isExecuting && clientSecret && (
              <div className={classNames(formLoaded ? "" : "hidden")}>
                <Elements options={{ clientSecret, appearance }} stripe={stripePromise}>
                  <CheckoutForm setFormLoaded={setFormLoaded} formLoaded={formLoaded} planId={paymentIntent?.metaData?.planId} />
                </Elements>
              </div>
            )}
          </div>
        </div>
      </div>
    </ContentWrapper>

  );
}
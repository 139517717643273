import { XCircleIcon } from "@heroicons/react/outline";
import { useParams } from "react-router-dom";
import { SidebarSelect } from "../../../../components/SidebarSelect";
import { SidebarTextArea } from "../../../../components/SidebarTextArea";
import { SidebarTextbox } from "../../../../components/SidebarTextbox";

export default function Properties({ document, canEdit }) {
  return (
    <div className="w-full">
      {/* {!canEdit && (
        <div className="rounded-md bg-red-50 p-4 m-4 mb-0">
          <div className="flex">
            <div className="flex-shrink-0">
              <XCircleIcon
                className="h-5 w-5 text-red-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between">
              <p className="text-sm text-red-700">
                Unable to edit document in production. Please copy document to
                development.
              </p>
            </div>
          </div>
        </div>
      )} */}
      <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
        <div className={"py-5"}>
          <SidebarTextbox
            value={document.type}
            label={"Type"}
            disabled={!canEdit}
          />
          <SidebarTextbox
            value={document.name}
            label={"Name"}
            disabled={!canEdit}
          />

          <SidebarSelect
            value={document.category}
            label={"Category"}
            disabled={!canEdit}
            options={[
              { name: "Försäkringsbesked", value: "Försäkringsbesked" },
              { name: "Pensionsbesked", value: "Pensionsbesked" },
              { name: "Utbetalningsspec", value: "Utbetalningsspec" },
              { name: "Kontrolluppgift", value: "Kontrolluppgift" },
              { name: "Förmånsbeskrivning", value: "Förmånsbeskrivning" },
              { name: "Förmånsöversikt", value: "Förmånsöversikt" },
              { name: "Överskottsbrev", value: "Överskottsbrev" },
              { name: "Utbetalningsbrev", value: "Utbetalningsbrev" },
              {
                name: "Uppsägningsbrev INDIVID",
                value: "Uppsägningsbrev INDIVID",
              },
              {
                name: "Uppsägningsbrev FÖRETAG",
                value: "Uppsägningsbrev FÖRETAG",
              },
              {
                name: "Årligt försäkringsbesked",
                value: "Årligt försäkringsbesked",
              },
              { name: "Kundavtal", value: "Kundavtal" },
              { name: "Bokföringsuppgift", value: "Bokföringsuppgift" },
              { name: "Årsbesked", value: "Årsbesked" },
              { name: "Rådgivning", value: "Rådgivning" },
              { name: "Flytt", value: "Flytt" },
              { name: "SPP Konsult", value: "SPP Konsult" },
              { name: "Fondhandel", value: "Fondhandel" },
              { name: "Försäkringsbevis", value: "Försäkringsbevis" },
              { name: "Avisering", value: "Avisering" },
              { name: "Återköp", value: "Återköp" },
              { name: "Betalningspåminnelse", value: "Betalningspåminnelse" },
              { name: "Autogirouppdrag", value: "Autogirouppdrag" },
              { name: "Bekräftelsebrev", value: "Bekräftelsebrev" },
              { name: "Valcentral", value: "Valcentral" },
              { name: "Nollbrev", value: "Nollbrev" },
              { name: "Inbetalning", value: "Inbetalning" },
              { name: "Livsfall", value: "Livsfall" },
              { name: "Deklarationsunderlag", value: "Deklarationsunderlag" },
              { name: "Utbetalning", value: "Utbetalning" },
              { name: "Brev", value: "Brev" },
              { name: "Extra brevutskick", value: "Extra brevutskick" },
            ]}
          />
        </div>
        <div className={"py-5"}>
          <SidebarSelect
            value={document.type}
            label={"Retention Policy"}
            disabled={!canEdit}
            options={[
              { name: "InsuranceChange", value: "InsuranceChange" },
              { name: "FinalPay", value: "FinalPay" },
              { name: "On Signal", value: "On Signal" },
              { name: "Move", value: "Move" },
              { name: "Repurchase", value: "Repurchase" },
              { name: "BeneficiaryChange", value: "BeneficiaryChange" },
              { name: "ZeroLetter", value: "ZeroLetter" },
              { name: "DC_TenYears", value: "DC_TenYears" },
              { name: "DeathHandling", value: "DeathHandling" },
              { name: "DC_TenYears ", value: "DC_TenYears " },
              { name: "DC_SixMonths", value: "DC_SixMonths" },
              { name: "Transfer", value: "Transfer" },
            ]}
          />
          <SidebarTextbox
            value={document.category}
            label={"Admin Company"}
            disabled={!canEdit}
          />
          <SidebarSelect
            value={document.plexmode}
            label={"Plexmode"}
            disabled={!canEdit}
            options={[
              { name: "Duplex", value: 0 },
              { name: "Simplex", value: 2 },
            ]}
          />
          <SidebarSelect
            value={document.colormode}
            label={"Colormode"}
            disabled={!canEdit}
            options={[{ name: "Colour", value: 0 }]}
          />
          <SidebarSelect
            value={document.postmode}
            label={"Postmode (A/B)"}
            disabled={!canEdit}
            options={[
              { name: "A", value: "A" },
              { name: "B", value: "B" },
            ]}
          />
          <SidebarSelect
            value={document.distributionMethod}
            label={"Distribution method"}
            disabled={!canEdit}
            options={[
              { name: "ByCustomerPreference", value: "ByCustomerPreference" },
              { name: "MandatoryPrint", value: "MandatoryPrint" },
              { name: "MustReachCustomer", value: "MustReachCustomer" },
            ]}
          />
          <SidebarTextArea
            value={document.comment}
            label={"Comments"}
            disabled={!canEdit}
          />
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { format } from "date-fns";

import "./styles.scss";
import { useClassNames } from "../../../hooks/useClassNames";
import Sidebar from "./sidebar";

import { TableHeader } from "./Components/tableheader";
import { TableColumn } from "./Components/tablecolumn";
import { Toggle } from "./Components/toggle";
import { SearchListBox } from "./Components/countrieslistbox"
import { getSettingsForEnvironment, saveMfdxSettings,publishMfdxSettings } from "../../../api/mfdxsettings";
import { Environment } from "./defines";
import { Prompt } from 'react-router'
import { NavPromptContainer } from "../../../components/Modals/navPromptModal";
import { tailwindToast } from "../../../components/Toast/tailwindToast";
import { getCountries } from '../../../api/country';
import { getCustomerInfo } from '../../../api/customer';
import { Badge } from '../../../components/Badge';

export default function Mfdx() {
  
  const { environment } = useParams();
  const [mfdxSettings, setMfdxSettings] = useState({});
  const [settingsToShow, setSettingsToShow] = useState({})
  const [activeSetting, setActiveSetting] = useState(null);
  const [openSidebar, setOpenSidebar] = useState(false);
  //const [toggleEnabled, setToggleEnabled] = useState(true)
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedCountryCode, setSelectedCountryCode] = useState();
  const { classNames } = useClassNames();
  

  const senderEnvironment = [
    { name: 'Integration' },
    { name: 'Maintenance' },
    { name: 'Development' },
  ];

  const getSettingObjectsAsArr = (settingsObj) => {
    return Object.keys(settingsObj)
      .map((key) => { return { key: key, setting: settingsToShow[key] } }).filter(({ setting }) => {
        return !!setting?.settingsId
      })
  }

  const getAllSettings = () => {
    return mfdxSettings;
  }

  const loadData = async () => {
    try {
      var env = Environment.dev;

      if (environment == "production") {
        env = Environment.prod;
      }
      else if (environment == "test") {
        env = Environment.test;
      }
      const serverCountries = await getCountries();
      const serverCustomer = await getCustomerInfo();
      let countryCode = serverCustomer.country["code"];
      setSelectedCountryCode(countryCode);
      setSelectedCountry(serverCountries.find(c => c.code ===countryCode).name);
      const serverSettings = await getSettingsForEnvironment(env);
      setMfdxSettings(serverSettings);
      setSettingsToShow(serverSettings);

    } catch (error) {
      tailwindToast.error("Failed to load data");
    }
  }

  useEffect(() => {
    loadData();
  }, [environment]);


  const hasCountry = (service) => {
    return ((service.countryCodes[0] === "*") || service.countryCodes.includes(selectedCountryCode));
  }

  const isDirty = () => {
    if (!settingsToShow || !mfdxSettings) {
      return false;
    }

    return getSettingObjectsAsArr(settingsToShow).some(({ setting }) => setting.isDirty);
  }

  const isDraft = () => {
    if (!settingsToShow || !mfdxSettings) {
      return false;
    }

    return getSettingObjectsAsArr(settingsToShow).some(({ setting }) => setting.isDraft);
  }

  // const handleSelectCountry = ({ name, value }) => {
  //   setSelectedCountry(value);
  // }

  const handleSidebar = () => {
    if (openSidebar) setActiveSetting(null);

    setOpenSidebar(!openSidebar);
  };

  const handleServiceRow = (key) => {
    setActiveSetting({ key, setting: settingsToShow[key] });
    setOpenSidebar(true);
  };

  const renderStateCol = (setting) =>{
    if(setting  != null && setting.history!=null && setting.history.length>0)
    {
      if(setting.isDraft)
        return <Badge type="badgeTypes.warn" text="Draft" />;
      else
        return <Badge type="badgeTypes.success" text="Published" />;
    }
  }


  const handleSaveSetting = async ({ key, setting }) => {
    setSettingsToShow(prev => {
      return { ...prev, [key]: setting }
    });

    settingsToShow[key] = setting;

    try {
      const updatedSettings = await saveMfdxSettings(settingsToShow);
      setMfdxSettings(updatedSettings);
      setSettingsToShow(updatedSettings);
      tailwindToast.success("Settings saved")
    }
    catch (err) {
      tailwindToast.error("Failed to save settings");
    }
  }

  const handleSaveEnvironmentSettings = async () => {
    try {
      const updatedSettings = await saveMfdxSettings(settingsToShow);
      setMfdxSettings(updatedSettings);
      setSettingsToShow(updatedSettings);
      tailwindToast.success("Settings saved")
    }
    catch (err) { 
      tailwindToast.error("Failed to save settings");
    }
  }

  const handlePublishEnvironmentSettings = async () => {
    try {
      const updatedSettings = await publishMfdxSettings(settingsToShow);
      setMfdxSettings(updatedSettings);
      setSettingsToShow(updatedSettings);
      tailwindToast.success("Settings published")
    }
    catch (err) { 
      tailwindToast.error("Failed to publish settings");
    }
  }

  const getLastPublished = () => {
    if(settingsToShow.lastPublishedDate!=null)
      return format(Date.parse(settingsToShow.lastPublishedDate), "MM/dd/yyyy - HH:mm");
  }

  const showPublishedInfo = () => {
    if(settingsToShow.lastPublishedDate!=null)
      return <span className="block text-sm font-medium text-gray-700 sm:mr-2 ml-10 mb-2">Last published: {getLastPublished()} by {settingsToShow.lastPublisher}</span>;
  }

  return (
    <>
      <Prompt
        id="nav-prompt-container"
        when={isDirty()}
        message={location => "You have unsaved changes, are you sure you want to leave?"}
      />
      <div className="flex-1 relative z-0 flex overflow-hidden max-w-screen-2xl">

        <main className="flex-1 relative z-0 p-4 overflow-y-auto focus:outline-none ">
          <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Mfdx services - {environment}
            </h3>
          </div>
          <div className="mt-5 grid grid-cols-12">
            <div className="col-span-4 flex flex-col sm:flex-row sm:items-center">
              <label className="block text-sm font-medium text-gray-700 sm:mr-2">Country: {selectedCountry}</label>
            </div>
            <div className="col-span-4 flex items-end justify-end">
              <button
                type="button"
                className={"inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-brand-pink hover:bg-brand-pink-hover disabled:opacity-50"}
                disabled={!isDraft()}
                onClick={handlePublishEnvironmentSettings}
              >
                Publish
              </button>
              {showPublishedInfo()}
            </div>
          </div>
          {/* Start main area*/}
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="mt-4 shadow-sm overflow-hidden border border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <TableHeader text="Status"/>
                      <TableHeader text="service" />
                      <TableHeader text="State" />
                      <TableHeader text="Last Changed" />
                      <TableHeader text="Last Changed by" />
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">

                    {settingsToShow &&
                      getSettingObjectsAsArr(settingsToShow)
                        .filter(({ setting }) => hasCountry(setting))
                        .map(({ key, setting }) => (
                          <tr
                            key={key}
                            className={classNames(
                              "hover:bg-gray-100 cursor-pointer"
                            )}
                            onClick={() => handleServiceRow(key)}
                          >
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                <span>
                                  { 
                                    setting.isActive
                                    ? <Badge type="badgeTypes.success" text="Active" />
                                    : <Badge type="badgeTypes.error" text="Inactive" />
                                  } 
                                </span>
                            </td>
                            <TableColumn value={setting.displayName} />
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                <span>
                                  {renderStateCol(setting)}
                                </span>
                                
                            </td>
                            <TableColumn value={setting.insertTimeStamp == null ? "" : format(Date.parse(setting.insertTimeStamp), "MM/dd/yyyy - HH:mm")} />
                            <TableColumn value={setting.updatedBy} />
                          </tr>
                        ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </main>
      </div>

      <Sidebar
        open={openSidebar}
        setOpen={handleSidebar}
        activeSetting={activeSetting}
        onSave={handleSaveSetting}
        getAllSettings={getAllSettings}
      />
    </>
  );
}

import React, { useEffect, useState } from 'react'
import { useRouteMatch } from "react-router";
import { useDebounce } from '@react-hook/debounce'
import { useToastAction } from '../../hooks/useToastAction';
import { useClassNames } from "../../hooks/useClassNames";
import { tailwindToast } from '../..//components/Toast/tailwindToast';

import Loader from '../Loader';

/**
 * @param {*} SidebarFileUpload 
 */
export const SidebarFileUpload = ({ label, fileExtension, onChange, error }) => {
    const { classNames } = useClassNames();
    const [draggingFile, setDraggingFile] = useState(false);
    const [toggleDraggingDebounce, setToggleDraggingDebounce] = useDebounce(null, 100);

    const loadFileAction = useToastAction();

    useEffect(() => {
        if (toggleDraggingDebounce) {
            setDraggingFile(false);
        }
    }, [toggleDraggingDebounce]);
    
    const checkFileExtension = (file) => {
        const extension = file.name.split('.').pop().toLowerCase();
        const supportedExtention = fileExtension && (fileExtension[0] === '.' ? fileExtension.substring(1).toLowerCase() : fileExtension.toLowerCase());
        if (supportedExtention && extension !== supportedExtention) {
            tailwindToast.error(`Failed to load file because file extension ${extension} is not supported.`);
            return false;
        }
        return true;
    }

    const handleFileDrop = (e) => {
        let file = e.dataTransfer.files[0]
        if (checkFileExtension(file)) {
            readInputFile(file);
        }
        e.preventDefault();
    }

    const handleFileInputChange = async (e) => {
        let file = e.target.files[0];
        if (checkFileExtension(file)) {
            readInputFile(file);
        } 
    };

    const readInputFile = (file) => {
        loadFileAction.execute(async () => {
            onChange(file);
        }, "Failed to load file");
    }

    return (
        <>
            <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-1 sm:gap-3 sm:px-6 sm:py-1">
                <div>
                    <label
                        htmlFor="project_name"
                        className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                    >
                        {label}
                    </label>
                </div>
                <div className="sm:col-span-2">

                    <div onDrop={handleFileDrop}
                        onDragOver={e => {
                            setDraggingFile(true);
                            setToggleDraggingDebounce(new Date().getMilliseconds());
                            e.preventDefault();
                        }}
                        onDragEnter={e => {
                            e.preventDefault();
                        }}
                        className={classNames("w-full flex justify-center px-6 pt-5 pb-6 border-2 border-dashed rounded-md", draggingFile ? "border-indigo-600" : "border-gray-300")}>
                        <div className="space-y-1 text-center">
                            {
                                !loadFileAction.isExecuting &&
                                (<svg
                                    className={classNames("mx-auto h-12 w-12", draggingFile ? "text-indigo-600" : "text-gray-400")}
                                    stroke="currentColor"
                                    fill="none"
                                    viewBox="0 0 48 48"
                                    aria-hidden="true"
                                >
                                    <path
                                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>)
                            }
                            {
                                loadFileAction.isExecuting && (
                                    <Loader />
                                )
                            }
                            <div className="flex text-sm text-gray-600">
                                <label
                                    htmlFor="file-upload"
                                    className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-300"
                                >
                                    <span>Upload a file</span>
                                    <input id="file-upload" disabled={loadFileAction.isExecuting} accept={fileExtension} name="file-upload" type="file" className="sr-only" onChange={handleFileInputChange} />
                                </label>
                                <p className="pl-1">or drag and drop</p>
                            </div>
                            {fileExtension && (<p className="text-xs text-gray-500">{fileExtension}</p>)}
                        </div>
                    </div>
                    {
                        error &&
                        <p className="mt-2 text-sm text-red-600" id="email-error">
                            {error}
                        </p>
                    }
                </div>

            </div>

        </>
    );
}
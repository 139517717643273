import { ArrowRightIcon, UserIcon } from '@heroicons/react/outline'
import { FormIcon, HighlightCard, HighlightCardVariants, SmallItemCard, StoryCard, WorkflowIcon } from '@metaforcelabs/metaforce-core'
import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { config } from '../../utils/config'
import { WorkflowDisplayArt } from '../../layouts/DefaultLayoutV2/workflowDisplayArt'
import StudioDisplayArt from '../../layouts/DefaultLayoutV2/studiDisplayArt'
import { AuthenticatedRoutesContext, DefaultLayoutV2Context } from '../../contexts'
import useIsNotActiveCustomer from '../../hooks/useIsNotActiveCustomer'
import { Section, sectionVariants } from '../../layouts/DefaultLayoutV2/section'
import { Disclosure } from '@headlessui/react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import { CompanyCreateArt } from '../../layouts/DefaultLayoutV2/companyCreateArt'
import { useIsAdmin } from '../../hooks/useIsAdmin'
import PlansIcon from '../../components/Icons/plansIcon'
import { cdnImages } from '../../utils/cdnImages'
import { eLearning } from '../../utils/eLearning'

export default function Overview() {
    const authContext = useContext(AuthenticatedRoutesContext);
    const { hasProductAccess, hasCompany, } = useContext(DefaultLayoutV2Context)
    const isNotActiveCustomer = useIsNotActiveCustomer();
    const navigate = useHistory();

    const [profileOverview, setProfileOverview] = useState({
        userFullName: authContext.centerPointUser.fullName,
        userImage: authContext.centerPointUser.userProfileImage,
        companyName: '',
        orgNumber: '',
        country: {},
        currentPlan: {},
        isNotActiveCustomer: isNotActiveCustomer
    });

    const handleNavigate = (url) => {
        window.open(url, '_blank', "noreferrer");
    }

    const navigateInternal = (route) => {
        navigate.push(route);
    }

    return (
        <main>
            <Section className={"sm:py-6"} variant={sectionVariants.secondary}>
                <WelcomeSection profileOverview={profileOverview} />
            </Section>
            <Section variant={sectionVariants.secondary}>
                <OverviewSection />
            </Section>
            {
                hasCompany ? (
                    <HasCompanyOverview onNavigate={handleNavigate} onNavigateInternal={navigateInternal} />
                ) : (
                    <>
                        <Section
                        >
                            <HighlightCard
                                variant={HighlightCardVariants.blue}
                                header='Create your first company'
                                body='To get started first create a company. This is where you will manage your forms and workflows.'
                                buttonText='Get started'
                                onClick={() => navigateInternal('/company/create')}
                                rightContent={<CompanyCreateArt />}
                            />
                        </Section>

                    </>
                )
            }

            <Section
                title={'eLearning'}
            >
                <div className='flex space-x-4'>
                    <div className='max-w-xs'>
                        <StoryCard
                            imageUrl={cdnImages.learn1}
                            leftHeader={<div className='bg-white rounded-sm'>
                                <WorkflowIcon className='h-8 w-8' />
                            </div>}
                            body='Learn how to create workflows to digitalize your communication processes.'
                            linkText={
                                <Link to={{ pathname: eLearning.workflow }} target="_blank" rel="noreferrer">Learn more</Link>
                            }
                        />
                    </div>

                    <div className='max-w-xs'>
                        <StoryCard
                            imageUrl={cdnImages.learn2}
                            leftHeader={<div className='bg-white rounded-sm'>
                                <FormIcon className='h-8 w-8' />
                            </div>}
                            body='Learn how to leverage smartforms to make your digital communication great.'
                            linkText={
                                <Link to={{ pathname: eLearning.smartform }} target="_blank" rel="noreferrer">Learn more</Link>
                            }
                        />
                    </div>
                </div>
            </Section>

        </main >
    )
}

const HasCompanyOverview = ({ onNavigate, onNavigateInternal }) => {
    const { hasPlan } = useContext(DefaultLayoutV2Context)
    const isAdmin = useIsAdmin();
    return hasPlan ? (<>
        <Section
            requireFeature="DA">

            <HighlightCard
                variant={HighlightCardVariants.yellow}
                header='Create forms that make your data work for you.'
                body='Create forms that are easy to use and easy to manage. Super fast. Super easy. Super smart.'
                buttonText='Get started'
                onClick={() => onNavigate(config.productUrl.smartforms)}
                rightContent={<StudioDisplayArt />}
            />
        </Section>
        <Section
            requireFeature="WF"
        >
            <HighlightCard
                variant={HighlightCardVariants.green}
                header='Create workflows that make your data work for you.'
                body='Automate smart workflows for your forms and documents. Super fast. Super easy. Super smart.'
                buttonText='Get started'
                onClick={() => onNavigate(config.productUrl.workflow + "?new=true")}
                rightContent={<WorkflowDisplayArt />}
            />
        </Section>
    </>) : isAdmin ? (
        <Section
        >
            <>
                <HighlightCard
                    variant={HighlightCardVariants.green}
                    header='To get started, choose a plan'
                    body='To get started, choose a plan that suits you. You can always change it later.'
                    buttonText='Choose a plan'
                    onClick={() => onNavigateInternal('/admin/billing/plans')}
                    rightContent={
                        <div className='flex justify-end text-gray-700'>
                            <PlansIcon className="h-32" />
                        </div>
                    }
                >
                </HighlightCard>
            </>
        </Section>
    ) : <></>
}

const WelcomeSection = ({ profileOverview }) => {
    const { hasCompany } = useContext(DefaultLayoutV2Context)
    const userFullName = (profileOverview?.userFullName.trim()) ? profileOverview?.userFullName : 'Centerpoint User';
    return (
        <div className='flex items-center space-x-4'>
            <div className='h-12 w-12'>
                {profileOverview.userImage && (
                    <img
                        className="inline-block h-full w-full rounded-full"
                        src={profileOverview.userImage}
                        alt=""
                    />
                )}
                {!profileOverview.userImage && (
                    <UserIcon className="rounded-full border-2 border-gray-900 " aria-hidden="true" />
                )}
            </div>
            <div>
                <div className='text-gray-500 font-medium leading-5 text-sm'>{hasCompany ? 'Welcome back!' : 'Welcome!'}</div>
                <div className='font-bold text-2xl leading-none'>{userFullName}</div>
            </div>
        </div>
    )
}

const OverviewSection = ({ }) => {
    const { hasProductAccess } = useContext(DefaultLayoutV2Context)

    const studioAccess = hasProductAccess("DA");
    const workflowAccess = hasProductAccess("WF");

    return (
        <>
            {
                (studioAccess || workflowAccess) && (
                    <>
                        <div>
                            <SectionHead>Your overview</SectionHead>
                        </div>
                        <div className='mt-2 grid gap-x-2 sm:gap-x-4 lg:gap-x-6 grid-cols-2 sm:grid-cols-3  lg:grid-cols-4'>
                            {
                                studioAccess && (<Link
                                    to={{ pathname: config.productUrl.smartforms }}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="hover:shadow-sm"
                                >
                                    <SmallItemCard
                                        body={"Studio"}
                                        leftHeader={<FormIcon className='h-6 w-6' />}
                                        rightHeader={<ArrowRightIcon className='h-6 w-6 transform -rotate-45' />}
                                    // onClick={handleFormsCardClick}
                                    />
                                </Link>)
                            }
                            {
                                workflowAccess && (
                                    <Link
                                        to={{ pathname: config.productUrl.workflow }}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="hover:shadow-sm"
                                    >
                                        <SmallItemCard
                                            body={"Workflow"}

                                            leftHeader={<WorkflowIcon className='h-6 w-6' />}
                                            rightHeader={<ArrowRightIcon className='h-6 w-6 transform -rotate-45' />}
                                        // onClick={handleFormsCardClick}
                                        />
                                    </Link>
                                )
                            }
                        </div>
                    </>
                )
            }
        </>
    )
}



const SectionHead = ({ children }) => {
    return (
        <h4 className='font-medium text-gray-700'>{children}</h4>
    )
}



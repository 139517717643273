import React, { useState } from 'react';
import { deletePaymentMethod, setPaymentMethodAsDefault } from '../../../../api/billing';
import Loader from '../../../../components/Loader';
import MenuContextList from '../../../../components/MenuContextList';
import GenericModal from '../../../../components/Modals/genericModal';
import { useToastAction } from '../../../../hooks/useToastAction';
import { formatCardNumber } from '../../../../utils/creditCard';
import CreditCardIcon from './creditCardIcon';

export default function PaymentMethodTable({ paymentMethods, loading, onMakeDefaultEvent, onDeleteEvent }) {

    const loadActionUpdate = useToastAction()
    const [confirmDeleteCardModalOpen, setConfirmDeleteCardModalOpen] = useState(false)
    const [activeRow, setActiveRow] = useState()

    const handleMakeDefault = async (paymentMethodId) => {
        loadActionUpdate.execute(async () => {
            await setPaymentMethodAsDefault(paymentMethodId);
            onMakeDefaultEvent();
        }, 'Failed to set payment method as default')

    }
    const handleDelete = async (paymentMethod) => {
        setActiveRow(paymentMethod);
        setConfirmDeleteCardModalOpen(true);
    }

    const deleteDonfirmed = async () => {
        loadActionUpdate.execute(async () => {
            await deletePaymentMethod(activeRow.paymentMethodId);
            setConfirmDeleteCardModalOpen(false);
            setActiveRow(null);
            onDeleteEvent();
        }, 'Failed to delete payment method')
    }

    const TableRow = ({ paymentMethod }) => {
        return (
            <tr key={paymentMethod.paymentMethodId}>
                <td className="px-6 py-4 mt-2 whitespace-nowrap text-l text-gray-500 hidden sm:table-cell">
                    <CreditCardIcon type={paymentMethod.card.brand} size={"lg"} />
                </td>
                <td className="pl-6 pr-6 sm:pr-8 md:pr-16 lg:pr-20 py-4 whitespace-nowrap text-sm font-medium text-gray-500 text-right">
                    {paymentMethod.card.expMonth + '/' + paymentMethod.card.expYear}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {formatCardNumber(paymentMethod.card.brand, paymentMethod.card.last4)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 hidden sm:table-cell">
                    {paymentMethod.billing.name}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    {
                        !paymentMethod.isDefault && (
                            <>
                                <MenuContextList element={paymentMethod} actions={[
                                    {
                                        name: "Set as default",
                                        onClick: () => handleMakeDefault(paymentMethod.paymentMethodId)
                                    }, {
                                        name: "Delete",
                                        onClick: () => handleDelete(paymentMethod)
                                    }
                                ]
                                } />
                            </>
                        )
                    }

                </td>
            </tr>
        );
    }

    return (
        <>
            <div className="relative z-0">
                <div className="-my-2 sm:-mx-6 lg:-mx-8 overflow-x-auto">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow-sm overflow-hidden border border-gray-200 sm:rounded-lg">

                            <table className="min-w-full divide-y divide-gray-200">

                                <thead className="bg-gray-50">
                                    <tr>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider  hidden sm:table-cell">
                                            Brand
                                        </th>
                                        <th scope="col" className="w-28 pl-6 pr-6 sm:pr-8 md:pr-16 lg:pr-20 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-right">
                                            Expiration
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Card number
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden sm:table-cell">
                                            Name
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">

                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-20">
                                    {
                                        !loading && (paymentMethods.map((u, i) => {
                                            return <TableRow paymentMethod={u} key={u.paymentMethodId} />
                                        }))
                                    }
                                    {
                                        loading && (<tr>
                                            <td colSpan="5">
                                                <div className="text-center py-6"><Loader /></div>
                                            </td>
                                        </tr>)
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <GenericModal
                title={`Do you want to remove this card ?`}
                setOpen={setConfirmDeleteCardModalOpen}
                open={confirmDeleteCardModalOpen}
                confirmButtonText={"Delete"}
                showCancelButton={true}
                onConfirm={e => {
                    deleteDonfirmed();
                }}
                onCancel={e => {
                    setConfirmDeleteCardModalOpen(false);
                }}
            >
                <div className="flex flex-row justify-center py-6">
                    <div>
                        <CreditCardIcon type={activeRow?.card?.brand} size="2x" />
                    </div>
                    <div className="ml-4">
                        <div className="mt-1 text-sm font-medium text-gray-900">
                            Ending with {activeRow?.card?.last4}
                        </div>

                        <div className="text-sm text-gray-600 flex items-center">
                            Expires {activeRow?.card?.expMonth + '/' + activeRow?.card?.expYear}
                        </div>
                    </div>
                </div>
            </GenericModal>
        </>
    );
}

import { Disclosure } from "@headlessui/react";
import { CenterpointLogo } from "@metaforcelabs/metaforce-core";
import React from "react";
import { Section, sectionVariants } from "../../layouts/DefaultLayoutV2/section";
import { config } from '../../utils/config';

export default function Authenticating({children}) {
    return (
        <div className="relative min-h-screen flex flex-col bg-brand-blue bg-opacity-3">
            <div>
                <header className='sticky top-0 z-10'>
                    <Disclosure as="nav" className=" border-b border-gray-200 relative z-40">
                        {({ open }) => (
                            <>
                                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                                    <div className="flex justify-between h-16">
                                        <div className="flex">
                                            <div className="flex-shrink-0 flex items-center">
                                                <div>
                                                    {/* <img className="h-8 w-auto" src={config.logoMain} alt="Metaforce" /> */}
                                                    <CenterpointLogo size={6} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </Disclosure>
                </header>
            </div>

            <div
                //     style={{
                //         minHeight: '500px'
                // }}
                className="flex-grow bg-white">
                <Section className={"sm:py-6"} variant={sectionVariants.secondary}>
                    {children}
                </Section>
            </div>
            <footer className="self-end min-h-[48rem] w-full bg-white">
                <div className="max-w-7xl mx-auto lg:px-8 sm:h-[140px]">


                    <div className="bg-black text-white px-4 sm:px-6 lg:px-8 py-6 sm:py-8">
                        <div className="flex justify-between items-center mr-4">
                            <div>
                                <CenterpointLogo size={6} colorClass="text-inherit" />
                            </div>
                            
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}
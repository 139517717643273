import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';
import { Switch, Transition } from '@headlessui/react'
import { toast } from "react-toastify";
import { tailwindToast } from '../../../components/Toast/tailwindToast';
import Sidebar from "./sidebar";
import { format } from "date-fns";
import { useToastAction } from '../../../hooks/useToastAction';

import * as ViewpointApi from "../../../api/viewpoint";
import * as InteractApi from "../../../api/interact";
import GenericModal from '../../../components/Modals/genericModal';

import "./styles.scss";
import { useClassNames } from "../../../hooks/useClassNames";
import { ContentWrapper, CoreButton, PageHeader } from "@metaforcelabs/metaforce-core";


export default function Viewpoint() {
  const [openSidebar, setOpenSidebar] = useState(false);
  const [viewpointConfiguration, setViewpointConfiguration] = useState({});
  const [allPrivateConnections, setAllPrivateConnections] = useState([]);
  const [activePrivateConnection, setActivePrivateConnection] = useState();
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [selectedPrivateConnections, setSelectedPrivateConnections] = useState([])
  const [allCustomerEnvironments, setAllCustomerEnvironments] = useState([])

  const { classNames } = useClassNames();
  const toggleButtonClassNames = (...classes) => {
    return classes.filter(Boolean).join(' ');
  }

  const loadAction = useToastAction();
  const saveAction = useToastAction();
  const deleteAction = useToastAction();

  const loadBaseData = async () => {
    loadAction.execute(async () => {
      const environments = await InteractApi.getInteractEnvironments();
      setAllCustomerEnvironments(environments);
      
      let configuration = await ViewpointApi.getConfiguration();
      if (configuration === '') {
        configuration = {
          usePrivateStorage: false,
        };
        await ViewpointApi.createConfiguration(configuration);
      }
      setViewpointConfiguration(configuration);
      if (configuration.usePrivateStorage) {
        const connections = await ViewpointApi.getConnectionsByCustomerId();
        setAllPrivateConnections(connections);
      } else {
        setAllPrivateConnections([]);
      }
    }, "Failed to load")
  }

  useEffect(() => {
    loadBaseData();
  }, [])

  const updateViewpointConfiguration = async (value) => {
    const configuration = await ViewpointApi.getConfiguration();
    if (configuration.usePrivateStorage !== value) {
      configuration.usePrivateStorage = value;
      await ViewpointApi.updateConfiguration(configuration);
    }
    setViewpointConfiguration(configuration);
    if (configuration.usePrivateStorage) {
      const connections = await ViewpointApi.getConnectionsByCustomerId();
      setAllPrivateConnections(connections);
    } else {
      setAllPrivateConnections([]);
    }
    setSelectedPrivateConnections([]);
  }

  const handleSidebar = () => {
    if (openSidebar) {
      setActivePrivateConnection(null);
    }

    setOpenSidebar(!openSidebar);
  };

  const toggleSelectPrivateConnection = (connection) => {
    setSelectedPrivateConnections(prev => {
      const idx = selectedPrivateConnections.findIndex(x => x === connection);
      if (idx === -1) {
        return [...prev, connection];
      }
      return [...prev.filter(item => item !== connection)];
    })
  }

  const toggleAllPrivateConnections = () => {
    selectedPrivateConnections.length === allPrivateConnections.length ?
      setSelectedPrivateConnections([]) : setSelectedPrivateConnections(allPrivateConnections)
  }

  const handleCreate = async () => {
    const newPrivateConnection = {
      name: "",
      baseAddress: "",
      clientId: "",
      clientSecret: "",
      state: 1,
      selected: false
    }

    if (allCustomerEnvironments.find(({ value }) => allPrivateConnections.find(connection => connection.customerEnvironment === value) === undefined)) {
      setActivePrivateConnection(newPrivateConnection);
      setOpenSidebar(true);
    } else {
      toast.error("It can not create a new Viewpoint connection because all customer environments are already used.");
    }
  }

  const handleSave = async () => {
    const errorMessage = await ViewpointApi.checkConnection(activePrivateConnection);
    if (errorMessage) {
      tailwindToast.error(`Error establishing database connection! ${errorMessage}`);
      return;
    }

    if (activePrivateConnection.id) {
      const privateConnection = await ViewpointApi.getConnection(activePrivateConnection.id);
      if (privateConnection && privateConnection.modifiedTime !== activePrivateConnection.modifiedTime) {
        tailwindToast.error("It can not save because Viewpoint connection has been updated by others. Please refresh the page.");
        return;
      }
    }

    setOpenSidebar(false)

    saveAction.execute(async () => {
      if (activePrivateConnection.id) {
        await ViewpointApi.updateConnection(activePrivateConnection);
      } else {
        await ViewpointApi.createConnection(activePrivateConnection);
      }
      await loadBaseData();
    }, "Failed to save Viewpoint connection", "Viewpoint connection saved")
  }

  const openDelete = async () => {
    var privateConnectionIdsToDelete = selectedPrivateConnections.map(privateConnection => privateConnection.id);
    if (privateConnectionIdsToDelete.length > 0) {
      setOpenDeleteConfirmation(true);
    }
  }

  const handleDelete = async () => {
    deleteAction.execute(async () => {
      setOpenDeleteConfirmation(false);
      var privateConnectionIdsToDelete = selectedPrivateConnections.map(privateConnection => privateConnection.id);
      setSelectedPrivateConnections([]);
      await ViewpointApi.deleteConnections(privateConnectionIdsToDelete)
      await loadBaseData();
    }, "Failed to delete Viewpoint connections", "Viewpoint connections deleted")
  }

  const handlePrivateConnectionRow = (privateConnection) => {
    setOpenSidebar(true);
    setActivePrivateConnection(privateConnection);
  };

  return (
    <>
      <ContentWrapper>
          <PageHeader title="ViewPoint Setup" description="You will store your documents into public share as default. Please select Private if private document databases are required. A private connection will have additional cost per month." />
          <Switch.Group as="div" className="flex items-center mb-10 mt-10">
            <span className="mr-6">
              <Switch.Label as="span" className="text-md font-medium text-gray-900" passive>
                Private document database
              </Switch.Label>
            </span>
            <Switch
              checked={viewpointConfiguration.usePrivateStorage}
              onChange={(value) => updateViewpointConfiguration(value)}
              className="flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"

            >
              <span aria-hidden="true" className="pointer-events-none absolute bg-white w-full h-full rounded-md" />
              <span
                aria-hidden="true"
                className={toggleButtonClassNames(
                  viewpointConfiguration.usePrivateStorage ? 'bg-indigo-600' : 'bg-gray-200',
                  'pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200'
                )}
              />
              <span
                aria-hidden="true"
                className={toggleButtonClassNames(
                  viewpointConfiguration.usePrivateStorage ? 'translate-x-5' : 'translate-x-0',
                  'pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200'
                )}
              />
            </Switch>
          </Switch.Group>

          {/* Start main area*/}
          {viewpointConfiguration.usePrivateStorage && (
            <Transition
              show={viewpointConfiguration.usePrivateStorage}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="shadow-sm overflow-hidden border border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        <input
                          id="selectAll"
                          name="selectAll"
                          type="checkbox"
                          onClick={e => toggleAllPrivateConnections()}
                          checked={allPrivateConnections.length > 0 && selectedPrivateConnections.length === allPrivateConnections.length}
                          className="focus:ring-brand-pink h-4 w-4 text-brand-pink border-gray-300 rounded"
                        />
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Customer Environment
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Created
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Created by
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        State
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {allPrivateConnections &&
                      allPrivateConnections.map((privateConnection) => (
                        <tr
                          key={privateConnection.id}
                          className={classNames(
                            privateConnection.id === activePrivateConnection?.id
                              ? "bg-gray-100"
                              : "",
                            "hover:bg-gray-100 cursor-pointer"
                          )}
                        >
                          <td className="px-6 w-12 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                            <input
                              id="selected"
                              name="selected"
                              type="checkbox"
                              checked={selectedPrivateConnections.findIndex(i => i === privateConnection) !== -1}
                              onChange={() => toggleSelectPrivateConnection(privateConnection)}
                              className="focus:ring-brand-pink h-4 w-4 text-brand-pink border-gray-300 rounded"
                            />
                          </td>
                          <td className="px-6 py-4 text-sm font-medium text-gray-900" onClick={() => handlePrivateConnectionRow(privateConnection)}>
                            {allCustomerEnvironments.find(({ value }) => value === privateConnection.customerEnvironment)?.name}
                          </td>
                          <td className="px-6 py-4 text-sm text-gray-500" onClick={() => handlePrivateConnectionRow(privateConnection)}>
                            {format(
                              new Date(privateConnection.createdTime),
                              "MM/dd/yyyy - HH:mm"
                            )}
                          </td>
                          <td className="px-6 py-4 text-sm text-gray-500" onClick={() => handlePrivateConnectionRow(privateConnection)}>
                            {privateConnection.createdByName}
                          </td>
                          <td className="px-6 py-4 text-sm text-gray-500" onClick={() => handlePrivateConnectionRow(privateConnection)}>
                            {privateConnection.state === 1 ? "Active" : "Inactive"}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <div className='mt-10 flex items-center'>
                <div className="flex-1" />
                {
                  selectedPrivateConnections.length > 0 && (
                  <CoreButton
                    label={<div>Delete</div>}
                    htmlType="button"
                    onClick={() => openDelete()}
                  />
                  )
                }
                <CoreButton
                    label={<div>New</div>}
                    htmlType="button"
                    onClick={handleCreate}
                  />
              </div>

            </Transition>
          )}
          {/* End main area */}
        </ContentWrapper>

      <Sidebar
        open={openSidebar}
        setOpen={handleSidebar}
        privateConnection={activePrivateConnection}
        allPrivateConnections={allPrivateConnections}
        setPrivateConnection={setActivePrivateConnection}
        allCustomerEnvironments={allCustomerEnvironments}
        onSubmit={handleSave}
      />

      <GenericModal open={openDeleteConfirmation} setOpen={setOpenDeleteConfirmation}
        onConfirm={handleDelete}
        onCancel={() => setOpenDeleteConfirmation(false)}
        showCancelButton={true}
        confirmButtonText={'Delete'}
        title="Delete private database connections">
        <div className="mt-4">
          <p className="text-sm text-gray-500">
            Are you sure you want to delete the selected private database connections?
          </p>
        </div>
      </GenericModal>
    </>
  );
}

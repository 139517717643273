import { useClassNames } from '../../../../../hooks/useClassNames';
import ColorPicker from '../../../../../components/ColorPicker';
import CheckboxField from '../../../../../components/CheckboxField';
import { Field } from 'formik';
import React from 'react';
import PictureElement from './PictureElement';

const FieldMaker = ({
  type,
  text,
  name,
  canReset,
  values,
  defaultValues,
  errors,
  touched,
  handleChange,
  setFieldValue,
  isSubmitting,
  fieldClass
}) => {
  const { classNames } = useClassNames();

  const onResetDefaultValueClick = () => {
    const defValue = defaultValues[name] || "";
    setFieldValue(name, defValue)
  }

  const inputFieldMaker = () => {
    switch (type) {
      case 'ColorPicker':
        return (
          <>
            <div className="flex items-center">
              <label htmlFor={name} className="block text-sm font-medium text-gray-700 mr-5">
                {text}
              </label>
              <div className="ml-auto">
                <ColorPicker
                  name={name}
                  onChange={handleChange}
                  alignRight={false}
                  value={values[name]}
                />
              </div>
            </div>

            {errors[name] && touched[name] && (
              <div className="text-sm text-red-500">{errors[name]}</div>
            )}
          </>
        );
      case 'PictureElement':
        if (values.useCustomLogin) {
          return (
            <>
              <div className="sm:col-span-3">
                <label
                  htmlFor="organizational-number"
                  className="block text-sm font-medium text-gray-700"
                >
                  {text}
                </label>

                <div className="mt-1 flex items-center">
                  <PictureElement
                    value={values[name]}
                    name={name}
                    setFieldValue={setFieldValue}
                    isSubmitting={isSubmitting}
                  />
                </div>

                {errors[name] && touched[name] && (
                  <div className="text-sm text-red-500">{errors[name]}</div>
                )}
              </div>
            </>
          );
        }
        break;
      case 'CheckboxField':
        return (
          <>
            <CheckboxField
              disabled={false}
              value={values[name]}
              label={text}
              name={name}
              error={errors[name]}
              onChange={(e) => {
                handleChange({
                  target: {
                    name: e.target.name,
                    value: e.target.checked
                  }
                });
              }}
            />
          </>
        );
      default:
        return (
          <>
            <label htmlFor={name} className="block text-sm font-medium text-gray-700">
              {text}
            </label>
            <Field
              type="text"
              disabled={isSubmitting}
              id={name}
              name={name}
              className={`mt-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md ${fieldClass}`}
            />

            {errors[name] && touched[name] && (
              <div className="text-sm text-red-500">{errors[name]}</div>
            )}
          </>
        );
    }
  };
  

  return (
    <div className={classNames(type === 'Text') ? 'grid grid-cols-1' : 'grid grid-cols-8 gap-4'}>
      <div className="col-span-4">{inputFieldMaker()}</div>

      {canReset && (
        <div className={classNames('flex items-center col-span-4', type === 'Text' && 'mt-1')}>
          <span
            className="hover:underline cursor-pointer text-sm font-semibold text-indigo-600"
            onClick={(e) => onResetDefaultValueClick()}
          >
            Reset to default
          </span>
        </div>
      )}
    </div>
  );
};

export default FieldMaker;

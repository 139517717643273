import { get, BaseUrl, put, post } from './api';

export const getInvoiceListForYear = async (year) => {
    return await get(`api/Billing/Invoices/${year}`, null,
        BaseUrl.Centerpoint);
}

export const getInvoiceList = async () => {
    return await get(`api/Billing/Invoices`, null,
        BaseUrl.Centerpoint);
}

export const getInvoiceStatuses = async () => {
    return await get(`api/Billing/InvoiceStatuses`, null,
        BaseUrl.Centerpoint);
}

export const getBillingOverview = async () => {
    return await get(`api/Billing/Overview`, null,
        BaseUrl.Centerpoint);
}

export const getPaymentMethodList = async () => {
    return await get(`api/Billing/PaymentMethods`, null,
        BaseUrl.Centerpoint);
}

export const getDefaultPaymentMethod = async () => {
    return await get(`api/Billing/DefaultPaymentMethod`, null,
        BaseUrl.Centerpoint);
}

export const addPaymentMethod = async () => {
    return await post(`api/Billing/AddPaymentMethod`, null,
        BaseUrl.Centerpoint);
}

export const deletePaymentMethod = async (paymentMethodId) => {
    return await put(`api/Billing/DeletePaymentMethod/${paymentMethodId}`, null,
        BaseUrl.Centerpoint);
}

export const setPaymentMethodAsDefault = async (paymentMethodId) => {
    return await put(`api/Billing/SetPaymentMethodAsDefault/${paymentMethodId}`, null,
        BaseUrl.Centerpoint);
}

export const addPaymentIntent = async () => {
    return await put(`api/Billing/create-payment-intent`, null,
        BaseUrl.Centerpoint);
}

export const getSignupInvoicePaymentIntent = async () => {
    return await get(`api/Billing/SignupInvoicePaymentIntent`, null,
        BaseUrl.Centerpoint);
}
import React from 'react';
import signicat from './Signicat-logo.png';
import verified from './verified-logo.png';

export const signicatLogoImage = signicat;
export const verifiedLogoImage = verified;
export const ScriveLogo = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="125" height="24" viewBox="0 0 125 24" class="scriveLogo___kreTy"><path d="M122.32 24c-1.47 0-2.79-1.23-2.79-2.69a2.74 2.74 0 012.73-2.68 2.78 2.78 0 012.73 2.73 2.65 2.65 0 01-2.67 2.64zM99.65 13.66a8.16 8.16 0 007.9 6.71c3.2 0 5.96-2.06 7.27-4.7h3.96A11.9 11.9 0 01107.55 24a11.85 11.85 0 01-11.73-11.93c0-6.55 5.25-11.84 11.73-11.84s11.78 5.4 11.78 11.95c0 .49-.08.95-.14 1.48H99.65zm7.9-9.63a8.03 8.03 0 00-7.77 5.87h15.59a8.1 8.1 0 00-7.82-5.87zM96.85.38l-9.12 23.39h-.15-2.93-.03l-5.17-13.13c-1.35-3.47-3.38-6.92-8.37-6.92V.15c6.27 0 9.78 3.77 11.51 8.22l3.56 9.09L92.84.36h4.07l-.1.02h.04zM64.1 0h3.83v23.77H64.1V0zM52.62 12.48V23.76H48.8V12.02C48.8 4.65 54.97.15 60.32.15h.41v3.7h-.41c-3.54 0-7.7 2.67-7.7 8.63zM35.58 20.2a7.9 7.9 0 007.34-4.62h3.83a11.83 11.83 0 01-11.17 8.32 11.82 11.82 0 01-11.73-11.89C23.85 5.45 29.1.16 35.58.16c5.26 0 9.67 3.69 11.17 8.43h-3.83c-1.31-2.63-4.14-4.73-7.34-4.73a8.1 8.1 0 00-8.09 8.15 8.15 8.15 0 008.09 8.19zM11.5 23.9c-1.03.01-2.08-.06-3.13-.19a14.57 14.57 0 01-3-.69 9.53 9.53 0 01-2.61-1.36 6.42 6.42 0 01-1.88-2.24 8.64 8.64 0 01-.89-3.31h4.24a4.1 4.1 0 001.55 3c.54.44 1.27.8 2.19 1.06.91.28 2.05.42 3.41.43 1.51-.01 3.45-.19 4.85-.81a3.7 3.7 0 001.41-1.05c.35-.43.55-1.1.57-1.75 0-.51-.1-.93-.31-1.29a2.2 2.2 0 00-1.09-.9 9.19 9.19 0 00-2.35-.58c-2.51-.32-5.02-.66-7.55-1a9.28 9.28 0 01-2.3-.61 6.81 6.81 0 01-1.95-1.2A5.36 5.36 0 01.81 7.32c0-1.49.43-2.78 1.26-3.85A7.7 7.7 0 015.6 1.02C7.14.46 8.97.17 11.07.16c1.85.01 3.45.2 4.79.57 1.33.36 2.44.87 3.34 1.54a7.24 7.24 0 012.03 2.37A8 8 0 0122 7.65h-4.23a3.6 3.6 0 00-.81-2.11 5.32 5.32 0 00-2.22-1.49c-1-.38-2.21-.57-3.71-.58-.69 0-1.39.05-2.08.16-.7.11-1.32.3-1.92.55-.58.26-1.03.61-1.4 1.05-.34.43-.52.98-.52 1.64-.02.49.08.91.29 1.27.19.36.57.67 1.11.91 1.63.72 7.33 1.23 9.89 1.57.82.11 1.59.32 2.32.62.73.3 1.37.7 1.95 1.2.56.51 1 1.08 1.34 1.76a6.49 6.49 0 01-.8 6.3c-2.15 2.76-6.4 3.4-9.71 3.4z"></path></svg>
}
const logoImages = {
    signicat: signicatLogoImage,
    verified: verifiedLogoImage
}
export default logoImages;
import { CheckCircleIcon } from '@heroicons/react/outline'
import React, { useState } from 'react'
import { useClassNames } from '../../hooks/useClassNames';

export default function CheckBoxCardField({ name, label, value, description, onChange }) {
    const [active, setActive] = useState(false);
    const { classNames } = useClassNames();
    const checked = !!value;
    // const [checked, setChecked] = useState(value);

    return (
        <div
            // value={option.value}
            onClick={e => {
                onChange({ target: { name: name, value: !checked } });
            }}
            onFocus={e => setActive(true)}
            onBlur={e => setActive(false)}
            className={
                classNames(
                    checked ? 'border-transparent' : 'border-gray-300',
                    active ? 'border-indigo-500 ring-2 ring-indigo-500' : '',
                    'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none'
                )
            }
        >
            <>
                <span className="flex flex-1">
                    <span className="flex flex-col">
                        <span className="block text-sm font-medium text-gray-900">
                            {label}
                        </span>
                        <span className="mt-1 flex items-center text-sm text-gray-500">
                            {description}
                        </span>
                        {/* <RadioGroup.Description as="span" className="mt-6 text-sm font-medium text-gray-900">
                      {mailingList.users}
                    </RadioGroup.Description> */}
                    </span>
                </span>
                <CheckCircleIcon
                    className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-indigo-600')}
                    aria-hidden="true"
                />
                <span
                    className={classNames(
                        active ? 'border' : 'border-2',
                        checked ? 'border-indigo-500' : 'border-transparent',
                        'pointer-events-none absolute -inset-px rounded-lg'
                    )}
                    aria-hidden="true"
                />
            </>
        </div>
    )
}

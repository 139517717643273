
import { get, post, destroy, BaseUrl} from './api';

export const getAttributesForEnvironment = async (environment) => {
    return await get(
        `api/attributestore/document/${environment}`,
        null,
        BaseUrl.Centerpoint
    );
}
 
export const compareDocument = async (document) => {
    return await get(
        `api/attributestore/document/compare/${document.id}`,
        null,
        BaseUrl.Centerpoint
    );
}

export const save = async (selectedDocuments) => {
    return await post(
        `api/attributestore/document`, 
        selectedDocuments, 
        null,
        BaseUrl.Centerpoint
    );
}

export const publish = async (environment, selectedDocuments) => {
    return await post(
        `api/attributestore/document/publish/${environment}`, 
        selectedDocuments, 
        null,
        BaseUrl.Centerpoint
    );
}

export const deleteDocuments = async (documentsToDelete) => {
    return await post(
        `api/attributestore/document/delete`, 
        documentsToDelete, 
        null,
        BaseUrl.Centerpoint
    );
}

export const load = async (environment, environmentToLoad) => {
    return await post(
        `api/attributestore/document/load/${environment}/${environmentToLoad}`,
        null,
        BaseUrl.Centerpoint
    );
}

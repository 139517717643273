import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { getUserStatuses } from '../../../../api/manageTeam';
import { getRole, getRoleTemplate, getUsersInRole, saveRole } from '../../../../api/roles';
import { RoleTableContext } from '../../../../contexts';
import { useToastAction } from '../../../../hooks/useToastAction';
import RoleFeatures from './Tabs/roleFeatures';
import RoleUsers from './Tabs/roleUsers';
import { ContentWrapper, CoreButton, PageHeader } from '@metaforcelabs/metaforce-core';

export default function Role() {
    const { roleId } = useParams();
    const [isLoaded, setIsLoaded] = useState(false);
    const [role, setRole] = useState({});
    const loadAction = useToastAction();
    const saveAction = useToastAction();
    const history = useHistory();
    const [userStatuses, setUserStatuses] = useState([]);
    const [usersInRole, setUsersInRole] = useState([]);
    const [activeTab, setActiveTab] = useState("features");

    const loadDefaultData = async () => {
        setIsLoaded(false);
        loadAction.execute(async () => {
            try {
                let roleData;
                if (roleId === undefined)
                    roleData = await getRoleTemplate();
                else
                    roleData = await getRole(roleId);
                setRole(roleData);

                const statuses = await getUserStatuses();
                setUserStatuses(statuses);

                const usersInRoleCollection = await getUsersInRole(roleId);
                setUsersInRole(usersInRoleCollection || []);                

            } catch (error) {
                console.log(error);
            }
            setIsLoaded(true);
        }, 'Failed to load')
    }

    const handleFeatureAccessChange = (productCode, featureCode, hasAccess) => {
        setRole(prev => {
            const idx = prev.productFeatureAccessSelections.findIndex(x => x.productCode === productCode);
            if (idx === -1) {
                return { ...prev };
            }

            const product = { ...prev.productFeatureAccessSelections[idx] };
            // Check if it is usage feature
            if (product.usageFeature.featureCode === featureCode) {
                // Switch all additional features
                product.featureSelections = [...product.featureSelections.map(fs => ({ ...fs, hasAccess: hasAccess }))]
                product.usageFeature = { ...product.usageFeature, hasAccess: hasAccess };
            }
            else {
                if (!product.usageFeature.hasAccess) {
                    product.usageFeature = { ...product.usageFeature, hasAccess: true };
                }
                product.featureSelections.find(x => x.featureCode === featureCode).hasAccess = hasAccess;
                product.usageFeature = { ...product.usageFeature, featureName: product.featureSelections.find(x => x.featureCode === featureCode).featureName };
            }
            prev.productFeatureAccessSelections[idx] = product;
            return { ...prev, productFeatureAccessSelections: [...prev.productFeatureAccessSelections] }
        })
    }

    const saveRoleData = async () => {
        saveAction.execute(async () => {
            await saveRole(role);
            history.goBack();
        }, 'Failed to save')
    }

    const handleChange = ({ name, value }) => {
        setRole(prev => {
            return { ...prev, [name]: value };
        })
    }

    const tabs = [
        { name: 'Features', tab: 'features', href: '#', current: activeTab === "features" },
        { name: 'Users', tab: 'users', href: '#', count: usersInRole?.length || "0", current: activeTab === "users", },
    ]

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const renderActiveTab = () => {
        switch (activeTab) {
            case "features":
                return <RoleFeatures />;
            case "users":
                return <RoleUsers />;
            default:
                return <></>;
        }
    };

    useEffect(() => {
        if (isLoaded) {
            return;
        }
        console.log('load');
        loadDefaultData();
    }, [roleId])
    return (
        <RoleTableContext.Provider
            value={{
                productFeatureAccesses: role?.productFeatureAccessSelections || [],
                loading: loadAction.isExecuting,
                usersInRole: usersInRole || [],
                userStatuses: userStatuses || [],
                onFeatureAccessChange: (productCode, featureCode, hasAccess) => {
                    handleFeatureAccessChange(productCode, featureCode, hasAccess);
                }
            }}
        >
        <ContentWrapper>
          <div>
              <div>
                  <PageHeader title='Role' />

                  <div className="mt-4">
                      Role name:
                  </div>
                  <div>
                      <input type="text" name="roleName" id="roleName"
                          onChange={e => handleChange(e.target)}
                          value={role?.roleName}
                          className="shadow-sm focus:ring-indigo-500 w-full sm:w-1/2 xl:w-1/4 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md" />
                  </div>
              </div>
          </div>

          <div className="mt-4">

              <div className="hidden sm:block">
                  <div className="border-b border-gray-200">
                      <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                          {tabs.map((tab) => (
                              <button
                                  key={tab.name}
                                  onClick={() => setActiveTab(tab.tab)}
                                  className={classNames(
                                      tab.current
                                          ? 'border-indigo-500 text-indigo-600'
                                          : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200 cursor-pointer',
                                      'whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm'
                                  )}
                              >
                                  {tab.name}
                                  {tab.count ? (
                                      <span
                                          className={classNames(
                                              tab.current ? 'bg-indigo-100 text-indigo-600' : 'bg-gray-100 text-gray-900',
                                              'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                                          )}
                                      >
                                          {tab.count}
                                      </span>
                                  ) : null}
                              </button>
                          ))}
                      </nav>
                  </div>
              </div>

          </div>
          <div className="mt-4">
              {renderActiveTab()}
          </div>
          {
              !loadAction.isExecuting && activeTab === "features" && (
                  <div className="mt-10">
                      <div className="flex gap-4 justify-center sm:justify-end">
                          <CoreButton 
                            label={<div>Back</div>}
                            type="secondary"
                            onClick={e => {
                              history.goBack();
                            }}
                            htmlType="button"
                            htmlClassNames="px-10"
                          />
                          <CoreButton 
                            label={<div>Save</div>}
                            onClick={e => {
                              saveRoleData();
                            }}
                            htmlType="button"
                            htmlClassNames="px-10"
                          />
                      </div>
                  </div>
              )
          }

        </ContentWrapper>

        </RoleTableContext.Provider >
    )
}

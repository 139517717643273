import React, { useState, Fragment, useContext, useRef, useEffect } from "react";
import "./styles.scss";
import { useClassNames } from "../../../../hooks/useClassNames";
import GenericSidebar from "../../../../components/Modals/genericSidebar";
import Search from "./Tabs/search";
import { GroupTableContext } from "../../../../contexts";

export default function Sidebar({ open, setOpen }) {
  const { group, usersInGroup, usersAtCustomer, selectableUsers } = useContext(GroupTableContext);
  const { classNames } = useClassNames();
  const tableContext = useContext(GroupTableContext);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const handleOpen = () => {
    if (open) {
      setSelectedUsers([]);
    }
    setOpen(!open)
  }

  const handleSave = async () => {
    tableContext.onUsersAdded(selectedUsers);
    handleOpen();
  }

  return (
    <>
      {group && (
        <GenericSidebar
          open={open}
          setOpen={handleOpen}
          title={`Add members to - ${group.name}`}
        >

          <div className="flex flex-col ml-4 mr-4 h-full">
            <div className="mt-12">
              <Search
                usersAtCustomer={selectableUsers}
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
                focusOnOpen={true}
              />
            </div>
          </div>

          {/* Action buttons */}
          <div className="flex-shrink-0 px-4 border-t bg-gray-50 border-gray-200 py-5 sm:px-6">
            <div className="space-x-3 flex justify-end">
              <button
                type="button"
                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={handleOpen}
              >
                Cancel
              </button>
              <button
                type="button"
                //className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-brand-pink hover:bg-brand-pink-hover"
                className={classNames("inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-brand-pink hover:bg-brand-pink-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50")}
                onClick={handleSave}
                disabled={selectedUsers.length === 0}
              >
                Save
              </button>
            </div>
          </div>
        </GenericSidebar>

      )}
    </>
  );
}


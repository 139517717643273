import { useRouteMatch } from "react-router";
import { NavLink } from "react-router-dom";
import { useClassNames } from "../../hooks/useClassNames";

/**
 * @param {*} SidebarTextbox 
 */
export const SidebarTextArea = ({ label, value, onChange, name, disabled }) => {
  return (
    <>
      <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-1 sm:gap-4 sm:px-6 sm:py-1">
          <div>
            <label
              htmlFor="project_name"
              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
            >
              {label}
            </label>
          </div>
          <div className="sm:col-span-2">
            <textarea
              id="project_description"
              name={name}
              onChange={onChange}
              rows={3}
              disabled={disabled}
              className="block w-full shadow-sm sm:text-sm focus:ring-brand-pink focus:border-brand-pink border-gray-300 rounded-md disabled:opacity-50 "
              value={value}
            />
          </div>
        </div>
    </>
  );
}
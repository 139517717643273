import { Link, useLocation } from 'react-router-dom';
import DateTimeParser from '../../../utils/DateTimeParser';
import { ClipboardCheckIcon } from '@heroicons/react/solid';

const ReleasePackageLink = ({ content, target }) => {
  const { pathname } = useLocation();
  return <Link to={{pathname: '/admin/releasePackageDetails/' + target, state: {previousPage: pathname}}}>{content}
  </Link>;
};

export default function ReleasePackagesTable({ releasePackages }) {
  const ReleasePackagesTableRow = ({ releasePackage }) => {
    return (
      <tr className="odd:bg-white even:bg-gray-100" key={'releasePackageEntry_' + releasePackage.packageId}>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
          <ReleasePackageLink
            target={releasePackage.packageId}
            content={releasePackage.packageVersion}
          />
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          <ReleasePackageLink
            target={releasePackage.packageId}
            content={DateTimeParser.toLocaleDateString(releasePackage.packageDate)}
          />
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          <ReleasePackageLink
            target={releasePackage.packageId}
            content={releasePackage.packageType === 0 ? 'Centerpoint' : 'Interact'}
          />
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          <ReleasePackageLink
            target={releasePackage.notificationRecipients}
            content={releasePackage.notificationRecipients === 'all' ? 'Public' : 'Private'}
          />
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-sm font-bold">
          <div className="flex">
            <div>
              <ClipboardCheckIcon className="h-7 w-7 text-yellow-400 mr-3" />
            </div>
            <div className="my-auto max-w-screen-sm whitespace-pre-line">
              <ReleasePackageLink
                target={releasePackage.packageId}
                content={releasePackage.packageGeneralInfo}
              />
            </div>
          </div>
        </td>
      </tr>
    );
  };

  return (
    <div className="flex flex-col">
      <div className="my-3 overflow-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-200">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Version
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                  >
                    Publish Date
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                  >
                    Package Type
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    State
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    General Info
                  </th>
                </tr>
              </thead>
              <tbody>
                {releasePackages.map((packageElement, i) => {
                  return <ReleasePackagesTableRow releasePackage={packageElement} key={packageElement.packageId} />;
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

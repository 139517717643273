export const SubmitButton = ({
  text,
  disabled,
  className,
  id
}) => {
  return (
    <button
      type="submit"
      id={id}
      disabled={disabled === true}
      className={`inline-flex items-center px-4 py-2 border border-transparent font-medium rounded text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 null text-sm ${className}`}
    >
      {text}
    </button>
  )
}
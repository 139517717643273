import { Field } from 'formik';
export function checkBoxInput(label, values, debug) {
    return <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-1 sm:px-6 sm:py-1 mt-2">
        <label for={values.name}>{label}</label>
        <Field name={values.name} type="checkBox" className="form-checkbox text-green-500 " checked={values.isActive}  >
        </Field>
        {
            (debug)=>{
                if(debug===true)
                    return(<pre>{JSON.stringify(values, null, 2)}</pre>);
            }
        }
    </div>;
}
import { get, post, BaseUrl} from './api';
export const saveMfdxSettings = async (document) => {
    return await post(
        `api/mfdx/settings`, 
        document, 
        null,
        BaseUrl.Centerpoint
    );
}

export const publishMfdxSettings = async (document) => {
    return await post(
        `api/mfdx/settings/publish`, 
        document, 
        null,
        BaseUrl.Centerpoint
    );
}
export const getSettingsForEnvironment = async (environment) => {
    return await get(
        `api/mfdx/settings/${environment}`,
        null,
        BaseUrl.Centerpoint
    );
}
import React, { useEffect, useState } from 'react';
import { getInvoiceListForYear, getInvoiceStatuses } from '../../../../api/billing';
import InvoiceTable from './invoiceTable';
import { generateArrayOfYears } from '../../../../utils/dateUtilities';
import { useToastAction } from '../../../../hooks/useToastAction';
import { useHistory, useParams } from 'react-router';
import { useLocation } from 'react-use';
import { ContentWrapper, PageHeader } from '@metaforcelabs/metaforce-core';

export default function Invoices() {
    const [invoiceList, setInvoiceList] = useState([]);
    const [filteredInvoiceList, setFilteredInvoiceList] = useState([]);
    const [invoiceStatuses, setInvoiceStatuses] = useState([]);
    const [years, setYears] = useState([]);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
    const [selectedStatus, setSelectedStatus] = useState();
    const loadAction = useToastAction();
    const location = useLocation();
    const history = useHistory();


    const loadInvoiceList = async () => {

        loadAction.execute(async () => {
            const invoiceListData = await getInvoiceListForYear(selectedYear);
            setFilteredInvoiceList(invoiceListData);
            setInvoiceList(invoiceListData);
            filterInvoices();
        }, 'Failed to load invoices')
    }

    const loadData = async () => {

        const yearList = generateArrayOfYears(3)
        yearList.sort()
        setYears(yearList);

        const invoiceStatusesData = await getInvoiceStatuses();
        setInvoiceStatuses(invoiceStatusesData);
    };

    const handleYearChange = async ({ name, value }) => {
        setSelectedYear(value);
    }

    const handleStatusChange = async ({ name, value }) => {
        setSelectedStatus(value);
    }

    const filterInvoices = () => {
        let filterResult = invoiceList.filter(x => {
            return statusPredicate(x, selectedStatus);
        });
        setFilteredInvoiceList(filterResult);
    }

    const statusPredicate = (invoice, status) => {
        if (!status) return true;

        const invoiceObj = invoiceStatuses.find(x => x.id === +status);
        if (!invoiceObj) return false;

        return invoice.statusId === invoiceObj.id;
    }

    useEffect(() => {
        filterInvoices();
    }, [invoiceList]);

    useEffect(() => {
        filterInvoices();
    }, [selectedStatus]);

    useEffect(() => {
        loadInvoiceList();
    }, [selectedYear]);

    useEffect(() => {
        loadData();
        const queryParams = new URLSearchParams(location.search);
        const statusId = queryParams.get('statusId')
        if (!statusId) return;
        else{
            queryParams.delete('statusId');
            history.replace({
                search: queryParams.toString()
            });
            setSelectedStatus(statusId);
        }
    }, []);

    return (
        <ContentWrapper>
            <PageHeader title="Invoice overview" />
            <div className="mt-4 flex flex-col sm:flex-row sm:justify-between sm:items-center">
                <label className="block text-sm font-medium text-gray-700 sm:mr-2">Invoice overview</label>

                <div className="flex flex-col sm:flex-row sm:items-center">
                    <label className="block text-sm font-medium text-gray-700 sm:mr-2">Status:</label>
                    <select className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        onChange={e => handleStatusChange(e.target)}
                        value={selectedStatus}>
                        <option value="">All</option>
                        {
                            invoiceStatuses.map(invoiceStatus => {
                                return <option value={invoiceStatus.id}>{invoiceStatus.name}</option>
                            })
                        }
                    </select>
                </div>
                <div className="flex flex-col sm:flex-row sm:items-center">
                    <label className="block text-sm font-medium text-gray-700 sm:mr-2">Year:</label>
                    <select className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        onChange={e => handleYearChange(e.target)}
                        value={selectedYear}>
                        {
                            years.map(year => {
                                return <option value={year}>{year}</option>
                            })
                        }
                    </select>
                </div>

            </div>

            <div className="mt-4">
                <InvoiceTable invoices={filteredInvoiceList} loading={loadAction.isExecuting} invoiceStatuses={invoiceStatuses} />
            </div>

        </ContentWrapper>
    )
}

import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition, Menu } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/outline'
import { useParams } from 'react-router-dom';
import { ChevronDownIcon } from '@heroicons/react/solid'
import { useClassNames } from "../../../hooks/useClassNames";
import LoadConfirmation from './loadConfirmation';

export default function LoadMenu({handleLoad, environment}) {
  const [openLoadFromTestConfirmation, setOpenLoadFromTestConfirmation] = useState(false);
  const [openLoadFromProductionConfirmation, setOpenLoadFromProductionConfirmation] = useState(false);
  const classNames = useClassNames();

  const handleOnLoadFromTest = () => {
    handleLoad(2)
    setOpenLoadFromTestConfirmation(false)
  }

  const handleOnLoadFromProduction = () => {
    handleLoad(3)
    setOpenLoadFromProductionConfirmation(false)
  }

  return (
    <>
      <Menu as="div" className="relative inline-block text-left z-1">
        {({ open }) => (
          <>
            <div>
              <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                Load
                <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
              </Menu.Button>
            </div>

            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                static
                className="z-5 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        type="button"
                        onClick={() => setOpenLoadFromProductionConfirmation(true)}
                        className={classNames.classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm cursor-pointer'
                        )}
                      >
                        Production
                      </a>
                    )}
                  </Menu.Item>
                  {
                    environment !== 2 &&
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          type="button"
                          onClick={() => setOpenLoadFromTestConfirmation(true)}
                          className={classNames.classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm cursor-pointer'
                          )}
                        >
                          Test
                        </a>
                      )}
                    </Menu.Item>
                  }
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>

      <LoadConfirmation
        open={openLoadFromProductionConfirmation}
        setOpen={setOpenLoadFromProductionConfirmation}
        onSubmit={handleOnLoadFromProduction}
        loadFromEnvironment="Production"
      />

      <LoadConfirmation
        open={openLoadFromTestConfirmation}
        setOpen={setOpenLoadFromTestConfirmation}
        onSubmit={handleOnLoadFromTest}
        loadFromEnvironment="Test"
      />
      
    </>
  )
}
import React from 'react';
import Loader from '../../../../components/Loader';
import { useTableSort } from '../../../../hooks/useTableSort';
import { formatThousand } from '../../../../utils/numbers';
import InvoiceStatusBadge from '../Common/invoiceStatusBadge';

export default function InvoiceTable({ invoices, loading, invoiceStatuses }) {
    const tableSort = useTableSort(invoices)

    const TableRow = ({ invoice }) => {
        return (
            <tr key={invoice.invoiceNo} className="odd:bg-white even:bg-gray-100 even:bg-opacity-60">
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {invoice.invoiceNo}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {
                        new Intl.DateTimeFormat('en-GB', {
                            year: "numeric",
                            month: "short",
                            day: "2-digit"
                        }).format(new Date(invoice.dueDate))}
                </td>
                <td className="pl-6 pr-6 sm:pr-8 md:pr-16 lg:pr-20  w-28 py-4 whitespace-nowrap text-sm font-medium text-gray-500 text-right">
                    {formatThousand(invoice.amountDue)} {invoice.currency.toUpperCase()}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <InvoiceStatusBadge status={invoiceStatuses?.find(s => s.id === invoice.statusId)?.name} dueDate = {invoice.dueDate} />
                    {/* {invoiceStatuses?.find(s => s.id === invoice.statusId)?.name} */}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <a target="_blank" rel="noreferrer" href={invoice.invoiceUrl}
                        className="text-black underline hover:text-gray-700">View</a>
                </td>
            </tr>
        );
    }

    return (
        <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-200">
                                <tr>
                                    <th scope="col" className="cursor-pointer px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                        onClick={() =>
                                            tableSort.orderBy("invoiceNo")
                                        }>
                                        Invoice number {tableSort.getSortIcon("invoiceNo")}
                                    </th>
                                    <th scope="col" className="cursor-pointer px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                        onClick={() =>
                                            tableSort.orderBy("dueDate")
                                        }>
                                        Due date {tableSort.getSortIcon("dueDate")}
                                    </th>
                                    <th scope="col" className="cursor-pointer w-28 pl-6 pr-6 sm:pr-8 md:pr-16 lg:pr-20 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-right"
                                        onClick={() =>
                                            tableSort.orderBy("amountDue")
                                        }>
                                        Amount {tableSort.getSortIcon("amountDue")}
                                    </th>
                                    <th scope="col" className="cursor-pointer px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                        onClick={() =>
                                            tableSort.orderBy("status")
                                        }>
                                        Status {tableSort.getSortIcon("status")}
                                    </th>
                                    <th scope="col" className="relative px-6 py-3">
                                        <span className="sr-only">View</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    !loading && (tableSort?.tableData?.map((u, i) => {
                                        return <TableRow invoice={u} key={u.invoiceNumber} />
                                    }))
                                }
                                {
                                    loading && (<tr>
                                        <td colSpan="5">
                                            <div className="text-center py-6"><Loader/></div>
                                        </td>
                                    </tr>)
                                }                                
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

import { useRouteMatch } from "react-router";
import { NavLink } from "react-router-dom";
import { useClassNames } from "../../hooks/useClassNames";

/**
 * @param {*} SidebarTextbox 
 */
export const SidebarCheckbox = ({ label, name, value, onChange, disabled, error }) => {
  const { classNames } = useClassNames();

  return (
    <>
      <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-1 sm:gap-3 sm:px-6 sm:py-1">
        <div className="sm:col-span-2">
          <div className="relative flex items-start">
            <div className="flex items-center h-5">
              <input
                type="checkbox"
                name={name}
                checked={value}
                disabled={disabled}
                onChange={onChange}
                className={classNames(
                  "block focus:ring-brand-pink focus:border-brand-pink h-4 w-4 text-brand-pink border-gray-300 rounded disabled:opacity-50 ",
                  error
                    ? "placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 border-red-300"
                    : ""
                )}
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor="centraldistribution" className="font-medium text-gray-700">
                {label}
              </label>
            </div>
          </div>
          {
            error &&
            <p className="mt-2 text-sm text-red-600" id="email-error">
              {error}
            </p>
          }
        </div>
      </div>
    </>
  );
}
export const ServiceSetting =
{
    digipost : 1,
    elks : 2,
    kivra : 3,
    psp : 4,
    sendgrid : 5,
    sftp : 6,
    signicat : 7,
    twilio : 8,
    viewpoint : 9
}

export const Environment =
{
    test: 1,
    prod: 2,
    dev: 3
}
import {useToastAction} from "../../../../../hooks/useToastAction";
import * as DigitalArchiveApi from "../../../../../api/digitalarchive";
import {useEffect, useState} from "react";
import {useClassNames} from "../../../../../hooks/useClassNames";
import MenuContextList from "../../../../../components/MenuContextList";
import {SidebarTextbox} from "../../../../../components/SidebarTextbox";
import GenericSidebar from "../../../../../components/Modals/genericSidebar";
import {SidebarToggle} from "../../../../../components/SidebarToggle";
import Loader from "../../../../../components/Loader";
import {ChevronDownIcon, ChevronUpIcon} from "@heroicons/react/outline";
import { PageHeader } from "@metaforcelabs/metaforce-core";

const initialSelectedItemValues = {
  name: '',
  presentationLabel: '',
  order: '',
  displayInColumn: false,
  isSearchable: false,
  isSortable: false
}

const MetadataColumns = () => {
  const getAllMappingAction = useToastAction();
  const editMappingAction = useToastAction();
  const {classNames} = useClassNames();

  const [mappings, setMappings] = useState([]);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [selectedItem, setSelectedItem] = useState(initialSelectedItemValues);

  const fetchAllMappings = (id, updateState) => {
    getAllMappingAction.execute(async () => {
      const getAllMappingsResult = await DigitalArchiveApi.getAllMappingsApi();

      setMappings(getAllMappingsResult);
    }, "Failed to load mappings")
  }

  const handleEdit = (element) => {
    setSelectedItem(element);
    setOpenSidebar(true);
  };

  const handleSidebar = () => {
    if (openSidebar) {
      setSelectedItem(initialSelectedItemValues);
    }
    setOpenSidebar(!openSidebar);
  };

  const handleOnChange = (name, value) => {
    setSelectedItem(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  const handleOnSaveClick = async () => {
    editMappingAction.execute(async () => {
      await DigitalArchiveApi.editMappingApi([{
        id: selectedItem.id,
        name: selectedItem.name,
        order: selectedItem.order,
        presentationLabel: selectedItem.presentationLabel,
        displayInColumn: selectedItem.displayInColumn,
        isSearchable: selectedItem.isSearchable,
        isSortable: selectedItem.isSortable
      }]);

      await fetchAllMappings();

      setOpenSidebar(false)
      setSelectedItem(initialSelectedItemValues);
    }, "Failed to edit metadata", "Metadata edited")
  }

  const swapOrder = (newIndex) => {
    const currentItemIndex = mappings.findIndex((m) => m.id === selectedItem.id);
    let newOrderingMappings = JSON.parse(JSON.stringify(mappings));

    if (newIndex > 0) {
      const nextItem = mappings[currentItemIndex + 1];

      newOrderingMappings = newOrderingMappings.map(m => {
        if (m.id === selectedItem.id) {
          m.order += 1;
        } else if (m.id === nextItem.id) {
          m.order -= 1;
        }

        return m;
      })
    } else {
      const prevItem = mappings[currentItemIndex - 1];

      newOrderingMappings = newOrderingMappings.map(m => {
        if (m.id === selectedItem.id) {
          m.order -= 1;
        } else if (m.id === prevItem.id) {
          m.order += 1;
        }

        return m;
      })
    }

    editMappingAction.execute(async () => {
      await DigitalArchiveApi.editMappingApi(newOrderingMappings);
      setMappings(newOrderingMappings);
    }, "Failed to edit metadata", "Metadata edited")
  }

  const handleOnListItemClick = (item) => {
    setSelectedItem(item);
  }

  useEffect(() => {
    fetchAllMappings();
  }, []);

  return (
    <>
    <PageHeader
      type="secondary"
      title="Metadata columns"
      description="You can manage metadata columns that are avaliable to your company in archive."
    />
      <div className="inline-flex">
        <button
          type="button"
          className="flex whitespace-nowrap justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-pink sm:mt-0 sm:col-start-1 sm:text-sm"
          disabled={!selectedItem || mappings.findIndex(m => m.id === selectedItem.id) < 1}
          onClick={() => swapOrder(-1)}
        >
          Move up
          <ChevronUpIcon className="ml-2 w-5 h-5"/>
        </button>

        <button
          type="button"
          className="ml-3 flex whitespace-nowrap justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-pink sm:mt-0 sm:col-start-1 sm:text-sm"
          disabled={!selectedItem || mappings.findIndex(m => m.id === selectedItem.id) === mappings.length - 1}
          onClick={() => swapOrder(1)}
        >
          Move down
          <ChevronDownIcon className="ml-2 w-5 h-5"/>
        </button>
      </div>

      <div className="py-2 align-middle inline-block min-w-full overflow-hidden">
        <div className="relative shadow-sm border border-gray-200 sm:rounded-lg">
          {editMappingAction.isExecuting && (
            <div className="absolute flex justify-center items-center h-full w-full text-center bg-opacity-30 bg-gray-200"><Loader/></div>
          )}

          <table className="min-w-full divide-y divide-gray-200 table-with-overflow">
            <thead className="bg-gray-50">
            <tr className="mx-0 px-0">
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/6"
              >
                Archive column
              </th>

              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/6"
              >
                Label
              </th>

              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/6"
              >
                Is visible
              </th>

              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/6"
              >
                Is searchable
              </th>

              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/6"
              >
                Is sortable
              </th>

              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider  w-1/12"
              >
              </th>
            </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
            {getAllMappingAction.isExecuting ? (
              <tr>
                <td colSpan="5">
                  <div className="text-center py-6"><Loader/></div>
                </td>
              </tr>
            ) : (
              mappings.sort((a, b) => a.order - b.order).map((map, index) => (
                <tr
                  key={map.id}
                  className={classNames(
                    "hover:bg-gray-100 cursor-pointer",
                    selectedItem.name === map.name && 'bg-gray-100',
                    "mx-0 px-0"
                  )}
                  onClick={() => handleOnListItemClick(map)}
                >
                  <td className="px-6 py-4 text-sm text-gray-500 w-1/6">
                    {map.name}
                  </td>

                  <td className="px-6 py-4 text-sm text-gray-500 w-1/6">
                    {map.presentationLabel}
                  </td>

                  <td className="px-6 py-4 text-sm text-gray-500 w-1/6">
                    {map.displayInColumn ? 'Yes' : 'No'}
                  </td>

                  <td className="px-6 py-4 text-sm text-gray-500 w-1/6">
                    {map.isSearchable ? 'Yes' : 'No'}
                  </td>

                  <td className="px-6 py-4 text-sm text-gray-500 w-1/6">
                    {map.isSortable ? 'Yes' : 'No'}
                  </td>

                  <td className="px-6 py-4 text-sm text-gray-500 w-1/12">
                    <div className="relative">
                      <MenuContextList
                        actions={[
                          {
                            name: "Edit",
                            onClick: () => {
                              handleEdit(map);
                            }
                          }
                        ]}
                        isBottomUp={mappings?.length > 1 && index === mappings?.length - 1}
                      />
                    </div>
                  </td>
                </tr>
              ))
            )}
            {mappings?.length < 2 && (<tr className="my-10"></tr>)}
            </tbody>
          </table>
        </div>
      </div>

      <GenericSidebar
        open={openSidebar}
        setOpen={handleSidebar}
        title="Editing"
      >
        <div className="flex w-full overflow-y-auto h-full">
          <div className="w-full">
            <div className="py-6 space-y-6 sm:py-2 sm:space-y-3 sm:divide-y sm:divide-gray-200">
              <SidebarTextbox
                name="Archive column"
                defaultValue={selectedItem.name}
                label="Archive column"
                disabled
              />
            </div>
            <div className="py-6 space-y-6 sm:py-2 sm:space-y-3 sm:divide-y sm:divide-gray-200">
              <SidebarTextbox
                name="label"
                defaultValue={selectedItem.presentationLabel}
                label="Label"
                disabled={selectedItem.isSystem}
                onChange={(e) => handleOnChange('presentationLabel', e.target.value)}
              />
            </div>

            <div className="py-6 space-y-6 sm:py-2 sm:space-y-3 sm:divide-y sm:divide-gray-200">
              <SidebarToggle
                value={selectedItem.displayInColumn}
                label={"Is visible"}
                onChange={value => handleOnChange('displayInColumn', value)}
              />
            </div>

            <div className="py-6 space-y-6 sm:py-2 sm:space-y-3 sm:divide-y sm:divide-gray-200">
              <SidebarToggle
                value={selectedItem.isSearchable}
                label={"Is searchable"}
                onChange={value => handleOnChange('isSearchable', value)}
              />
            </div>

            <div className="py-6 space-y-6 sm:py-2 sm:space-y-3 sm:divide-y sm:divide-gray-200">
              <SidebarToggle
                value={selectedItem.isSortable}
                label={"Is sortable"}
                onChange={value => handleOnChange('isSortable', value)}
              />
            </div>
          </div>
        </div>

        <div className="flex-shrink-0 px-4 border-t bg-gray-50 border-gray-200 py-5 sm:px-6">
          <div className="space-x-3 flex justify-end">
            <button
              type="button"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 "
              onClick={() => setOpenSidebar(false)}
            >
              Cancel
            </button>

            <button
              type="button"
              className={classNames(
                "inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-brand-pink hover:bg-brand-pink-hover",
                editMappingAction.isExecuting || editMappingAction.isExecuting && 'bg-brand-pink-hover'
              )}
              disabled={editMappingAction.isExecuting}
              onClick={handleOnSaveClick}
            >
              Submit
            </button>
          </div>
        </div>
      </GenericSidebar>
    </>
  )
}

export default MetadataColumns;
import { CheckCircleIcon, ClipboardCopyIcon, XCircleIcon } from '@heroicons/react/outline';
import { Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { createApiClient, deleteApiClient, disableApiClient, enableApiClient, getApiClients } from '../../../../api/apiClients';
import CheckboxField, { ToggleSwitchField } from '../../../../components/CheckboxField';
import CopyToClipboardField from '../../../../components/CopyToClipboardField';
import GenericModal from '../../../../components/Modals/genericModal';
import { useClassNames } from '../../../../hooks/useClassNames';
import { useToastAction } from '../../../../hooks/useToastAction';
import ApiClientForm from './apiClientForm';
import MenuContextList from '../../../../components/MenuContextList';
import DeleteApiClientModal from './deleteApiClientModal';
import { Badge, badgeTypes } from '../../../../components/Badge';
import { ContentWrapper, CoreButton, PageHeader } from '@metaforcelabs/metaforce-core';

export default function ApiClients() {
  const [deleteClient, setDeleteClient] = useState();
  const [showDeletemodal, setShowDeletemodal] = useState(false)
  const [apiClients, setApiClients] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showCreatedModal, setShowCreatedModal] = useState(false);
  const [createdClient, setCreatedClient] = useState(null);
  const { classNames } = useClassNames();
  const loadAction = useToastAction();
  const createAction = useToastAction();
  const copyAction = useToastAction();
  const apiClientAction = useToastAction();
  const [secretIsMouseOver, setSecretIsMouseOver] = useState(false);
  const [confirmClose, setConfirmClose] = useState(false);
  const confirmCloseRef = useRef(confirmClose);
  confirmCloseRef.current = confirmClose;

  const clientTypes = {
    standard: 1,
    externalSystem: 2,
    audit: 4,
    metaTool: 5
};

const clientTypeNames = {
    [clientTypes.standard]: 'Standard',
    [clientTypes.externalSystem]: 'External System',
    [clientTypes.audit]: 'Audit',
    [clientTypes.metaTool]: 'MetaTool'
};

  const loadData = async () => {
    loadAction.execute(async () => {
      const serverClients = await getApiClients();
      setApiClients(serverClients);
    });
  };

  const handleCreateApiClient = async (values, onComplete) => {
    createAction.execute(async () => {
      const client = await createApiClient(values);
      setCreatedClient(client);
      onComplete();
    });
  };

  const resetConfirm = () => {
    if (confirmCloseRef.current) {
      setConfirmClose(false);
    }
  };

  const handleCloseCreatedClient = () => {
    loadData();
    setCreatedClient(null);
  }

  const handleDisableClient = async (client) => {
    apiClientAction.execute(async () => {
      await disableApiClient(client.clientId);
      updateClientInMemory(client.clientId, false);
    }, 'Failed to disable')
  }

  const handleEnableClient = async (client) => {
    apiClientAction.execute(async () => {
      await enableApiClient(client.clientId);
      updateClientInMemory(client.clientId, true);
    }, 'Failed to enable')
  }

  const updateClientInMemory = (clientId, enabled) => {

    const idx = apiClients.findIndex(x => x.clientId === clientId);
    const updated = { ...apiClients[idx], enabled: enabled };
    setApiClients(prev => {
      prev.splice(idx, 1, updated);
      return [...prev];
    });
  }

  const handleDeleteClient = (client) => {
    setDeleteClient(client);
    setShowDeletemodal(true);
  }


  const handleConfirmDeleteApiClient = () => {
    apiClientAction.execute(async () => {
      if (!deleteClient) {
        return;
      }
      await deleteApiClient(deleteClient?.clientId);

      const idx = apiClients.findIndex(x => x.clientId === deleteClient?.clientId);
      setApiClients(prev => {
        prev.splice(idx, 1);
        return [...prev];
      });
      setShowDeletemodal(false);

    }, 'Failed to delete')
  }

  const handleCancelDelete = () => {
    setShowDeletemodal(false);
    setDeleteClient(null);
  }


  useEffect(() => {
    loadData();
  }, []);

  return (
    <ContentWrapper>
      <PageHeader 
        title="API Clients" 
        optionalSideElement={          
          <CoreButton 
            htmlType="button" 
            label={<>Create New</>} 
            onClick={() => setShowCreateModal(true)} 
          />} 
      />
      <div className="pt-3 mb-4">
        {createdClient && (
          <div className="my-8 max-w-2xl border border-gray-200 rounded-lg shadow p-4 relative">
            <div className="absolute top-4 right-4">
              <button
                type="button"
                onClick={() => {
                  if (!confirmClose) {
                    setConfirmClose(true);
                    setTimeout(() => {
                      resetConfirm();
                    }, 3000);
                  } else {
                    setConfirmClose(false);
                    handleCloseCreatedClient();
                  }
                }}
                className="inline-flex items-center px-4 py-2 shadow-sm font-medium rounded-md hover:bg-brand-pink-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 text-sm bg-brand-pink text-white"
              >
                {confirmClose ? <span>Confirm { }</span> : 'Close'}
              </button>
            </div>
            <div className="space-y-2 mb-4">
              <h3 className="text-lg font-medium text-gray-900">Created Client</h3>
              <h4 className="text-md font-medium text-gray-500 italic">
                Client secret will only be visible this one time. Copy it somewhere safe
              </h4>
            </div>

            <div className="space-y-2">
              <div>
                <label htmlFor="firstName" className="block text-sm font-medium text-blue-gray-900">
                  Client Name
                </label>
                <div className="mt-1 p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 disabled:opacity-50 block w-full sm:text-sm border border-gray-300 rounded-md">
                  {createdClient?.clientName}
                </div>
              </div>

              <div>
                <label htmlFor="firstName" className="block text-sm font-medium text-blue-gray-900">
                  Client ID
                </label>
                <CopyToClipboardField
                  value={createdClient?.clientId}
                  onCopy={() => copyAction.execute(() => { }, '', 'Copied to clipboard')} />
              </div>

              <div>
                <label htmlFor="firstName" className="block text-sm font-medium text-blue-gray-900">
                  Client Secret
                </label>
                <CopyToClipboardField
                  value={createdClient?.clientSecret}
                  onCopy={() => copyAction.execute(() => { }, '', 'Copied to clipboard')} />
              </div>
              <div>
                <label htmlFor="firstName" className="block text-sm font-medium text-blue-gray-900">
                  Mutual TLS
                </label>
                <div className="mt-1 p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 disabled:opacity-50 block w-full sm:text-sm border border-gray-300 rounded-md">
                  {createdClient?.useMtls ? 'Yes' : 'No'}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-col relative">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 ">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg ">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-200">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Client Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Client ID
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Mutual TLS
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Type
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >

                    </th>
                    {/* <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Role
                                        </th>
                                        <th scope="col" className="relative px-6 py-3">
                                            <span className="sr-only">Edit</span>
                                        </th> */}
                  </tr>
                </thead>
                <tbody>
                  {apiClients.map((client, i) => {
                    return (
                      <tr className="odd:bg-white even:bg-gray-100">
                        <td className={classNames("px-6 py-4 whitespace-nowrap text-sm font-medium", client.enabled ? "text-gray-900":"text-gray-400")}>
                          {client.clientName}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {client.enabled ? <Badge type={badgeTypes.success} text={'Enabled'} />: <Badge type={badgeTypes.default} text={'Disabled'} />}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {client.clientId}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {client.useMtls ? <CheckCircleIcon className='h-5 w-5 text-green-600' /> : <XCircleIcon className='h-5 w-5 text-gray-400' />}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {clientTypeNames[client.clientType] || 'Unkown type'}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          <MenuContextList element={client} actions={[

                            client.enabled ?
                              {
                                name: "Disable",
                                onClick: () => handleDisableClient(client)
                              } :
                              {
                                name: "Enable",
                                onClick: () => handleEnableClient(client)
                              },
                            {
                              name: "Delete",
                              onClick: () => handleDeleteClient(client)
                            },
                          ]
                          } />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <GenericModal
        setOpen={setShowCreatedModal}
        open={!!showCreatedModal}
        confirmButtonText={'Create'}
        cancelButtonText={'Cancel'}
        onCancel={() => setShowCreatedModal(false)}
        onConfirm={() => { }}
        showCancelButton={false}
        showConfirmButton={false}
        title={'Created API Client'}
      ></GenericModal>
      <GenericModal
        setOpen={setShowCreateModal}
        open={showCreateModal}
        confirmButtonText={'Create'}
        cancelButtonText={'Cancel'}
        onCancel={() => setShowCreateModal(false)}
        onConfirm={() => { }}
        showCancelButton={false}
        showConfirmButton={false}
        title={'Create API Client'}
      >
        <ApiClientForm onCreate={(values, onSubmit) => {
          handleCreateApiClient(values, () => {
            setShowCreateModal(false);
            onSubmit();
          })
        }}

        />
      </GenericModal>
      <DeleteApiClientModal open={showDeletemodal} setOpen={setShowDeletemodal} onConfirm={handleConfirmDeleteApiClient} onCancel={handleCancelDelete} client={deleteClient} />
    </ContentWrapper>
  );
}

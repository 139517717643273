import React from 'react'
import ReactDOM from 'react-dom';
import GenericModal from './genericModal'

const containerId = "nav-prompt-container";

export function promptNav(message, callback, confirmText = "Leave") {
    const container = document.getElementById(containerId);
    const withCleanup = (answer) => {
        ReactDOM.unmountComponentAtNode(container);
        callback(answer)
    }
    ReactDOM.render(
        <NavPromptModal
            open={true}
            message={message}
            confirmText={confirmText}
            onConfirm={()=> withCleanup(true)}
            onCancel={()=> withCleanup(false)}
        />
        , container)
}

export function NavPromptModal({ open, message, onConfirm, onCancel,confirmText }) {
    return (
        <GenericModal
            open={open}
            setOpen={() => { }}
            onConfirm={onConfirm}
            onCancel={onCancel}
            confirmButtonText={confirmText}
            cancelButtonText={"Cancel"}
            showCancelButton={true}
            title={"Warning"} >
            <div className="w-full py-4">{message}</div>
        </GenericModal>
    )
}
export function NavPromptContainer() {
    return (
        <div id={containerId}>
        </div>
    )
}

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export default function CreditCardIcon({ type, ...props }) {
    switch (type) {
        case 'visa': return (<FontAwesomeIcon size={props.size} icon={['fab', 'cc-visa']} className="text-blue-800 w-full h-full" />)
        case 'mastercard': return (<FontAwesomeIcon size={props.size} icon={['fab', 'cc-mastercard']} className="text-red-600 w-full h-full" />)
        default: return (<FontAwesomeIcon icon={['fab', 'cc-' + type]} className="w-full h-full" {...props} />)
      }
}

export const config = {
    appTitle: window._env_.REACT_APP_APP_TITLE || 'Metaforce - CenterPoint',
    apiBaseUrl: window._env_.REACT_APP_API_BASE_URL,
    tokenStorageKey: '@MetaForce:userToken',
    identityBaseUrl: window._env_.REACT_APP_IDENTITY_BASE_URL,
    centerpointBaseUrl: window._env_.REACT_APP_CENTERPOINT_BASE_URL,
    centerpointUiBaseUrl: window._env_.REACT_APP_CENTERPOINT_UI_BASE_URL,
    productUrl: {
        archive: window._env_.REACT_APP_PRODUCT_URL_ARCHIVE|| 'https://archive.stage.metaforcelabs.com',
        smartforms: window._env_.REACT_APP_PRODUCT_URL_SMARTFORMS || 'https://smartforms.stage.metaforcelabs.com/admin/dashboard',
        textlibrary: window._env_.REACT_APP_PRODUCT_URL_TEXTLIBRARY || 'https://textlibrary.stage.metaforcelabs.com/',
        signing: window._env_.REACT_APP_PRODUCT_URL_SIGNING || 'https://digitalsigning.stage.metaforcelabs.com/',
        viewpoint: window._env_.REACT_APP_PRODUCT_URL_VIEWPOINT || 'https://viewpoint.stage.metaforcelabs.com/',
        audit: window._env_.REACT_APP_PRODUCT_URL_AUDIT || 'https://audit.stage.metaforcelabs.com/',
        workflow: window._env_.REACT_APP_PRODUCT_URL_WORKFLOW || 'https://workflow.stage.metaforcelabs.com/',
        smartdocs: window._env_.REACT_APP_PRODUCT_URL_SMARTDOCS || 'https://smartdocs.stage.metaforcelabs.com/',
        support: window._env_.REACT_APP_PRODUCT_URL_SUPPORT || 'https://support.stage.metaforcelabs.com/',
        pingdoxAdmin: window._env_.REACT_APP_PRODUCT_URL_PINGDOX || 'https://pingdoxadmin.stage.metaforcelabs.com/',
        interactAdmin: window._env_.REACT_APP_PRODUCT_URL_INTERACTADMIN || 'https://interactadmin.stage.metaforcelabs.com/',
    },
    logoMain: window._env_.REACT_APP_APP_LOGO_MAINURL || 'https://cdn2.hubspot.net/hubfs/4814774/Metaforce%20logo%20web.png',
    logoCenterpointMain: window._env_.REACT_APP_APP_LOGO_CENTERPOINT_MAINURL || 'https://identity.stage.metaforcelabs.com/images/centerpoint.svg',
    favicon: window._env_.REACT_APP_APP_FAVICON || '/favicon.ico',
    docsUrl: window._env_.REACT_APP_DOCUMENTATION_URL + window._env_.REACT_APP_DOCUMENTATION_PATH_CENTERPOINT,
    docsUrlAdministration: window._env_.REACT_APP_DOCUMENTATION_URL + window._env_.REACT_APP_DOCUMENTATION_PATH_ADMINISTRATION
}

export const oidcConfig = {
    client_id: 'centerpoint_web',
    redirect_uri: window._env_.REACT_APP_REDIRECT_URI,
    response_type: 'code',
    post_logout_redirect_uri: window._env_.REACT_APP_POST_LOGOUT_REDIRECT_URI,
    scope: 'openid profile email api.internal offline_access',
    authority: window._env_.REACT_APP_AUTHORITY,
    silent_redirect_uri: window._env_.REACT_APP_SILENT_REDIRECT_URI,
    automaticSilentRenew: true,
    loadUserInfo: true,
    // metadata: {
    //     authorization_endpoint:"/connect/authorize",
    //     token_endpoint: "/connect/token"
    // }
    //extraQueryParams:{
    //    loginBrand:'sebsenterprises'
    //}
  };
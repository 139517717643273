import { RadioGroup } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/solid'
import { ContentWrapper, CoreButton, PageHeader, useClassNames, useToastAction } from '@metaforcelabs/metaforce-core'
import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { getInteractGeneralSettings, updateInteractGeneralSettings } from '../../../../api/webEditor'

const configSources = [{
    id: 1,
    name: 'Centerpoint',
    description: 'Default option. Webeditor queries CenterPoint for its configuration'
},
{
    id: 2,
    name: 'Interact Admin',
    description: 'Optional. Webeditor queries Interact Admin for its configuration'

}]

export default function InteractGeneral({ }) {
    const loadAction = useToastAction();
    const saveAction = useToastAction();
    const [settings, setSettings] = useState(null)

    const load = () => {
        loadAction.execute(async () => {
            const srvSettings = await getInteractGeneralSettings();
            console.log(srvSettings);
            setSettings(srvSettings);
        }, 'Failed to laod');
    }

    useEffect(() => {
        load();
    }, [])

    const handleSubmit = (values) => {
        saveAction.execute(async () => {
            console.log(values)
            const data = await updateInteractGeneralSettings(values);
            setSettings(data);
        },'Failed to save', "Settings saved");
    }

    const { classNames } = useClassNames();

    return settings && (
        <>
            <ContentWrapper>
                <PageHeader title="Interact General Settings" description="" />
                <div className="">
                    <Formik
                        enableReinitialize={true}
                        initialValues={{
                            webEditorConfigurationSource: settings?.webEditorConfigurationSource
                        }}
                        onSubmit={(values) => {
                            handleSubmit(values)
                        }}
                    >
                        {
                            ({ values, ...props }) => (
                                <Form>
                                    <div className="space-y-6 sm:space-y-6">
                                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-45 sm:pt-5 sm:border-b border-b-gray-200 sm:pb-6">
                                            <label htmlFor="" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                WebEditor configuration source
                                            </label>

                                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                <RadioGroup value={values?.webEditorConfigurationSource} onChange={value => {
                                                    props.handleChange({ target: { name: 'webEditorConfigurationSource', value: value } });
                                                }}>
                                                    <RadioGroup.Label className="text-base font-semibold leading-6 text-gray-900">
                                                        {/* asdasd */}
                                                    </RadioGroup.Label>

                                                    <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                                                        {configSources.map((provider) => (
                                                            <RadioGroup.Option
                                                                key={provider.id}
                                                                value={provider.id}
                                                                className={({ active }) =>
                                                                    classNames(
                                                                        active ? 'border-indigo-600 ring-2 ring-indigo-600' : 'border-gray-300',
                                                                        'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none'
                                                                    )
                                                                }
                                                            >
                                                                {({ checked, active }) => (
                                                                    <>
                                                                        <span className="flex flex-1">
                                                                            <span className="flex flex-col">
                                                                                <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                                                                                    {provider.name}
                                                                                </RadioGroup.Label>
                                                                                <RadioGroup.Description as="span" className="mt-1 flex items-center text-sm text-gray-500">
                                                                                    {provider.description}
                                                                                </RadioGroup.Description>
                                                                                <RadioGroup.Description as="span" className="mt-6 h-full flex justify-start items-end">
                                                                                    {/* <div className=''>
                                                                                {renderProviderIcon(provider.providerId)}
                                                                            </div> */}
                                                                                </RadioGroup.Description>
                                                                            </span>
                                                                        </span>
                                                                        <CheckCircleIcon
                                                                            className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-indigo-600')}
                                                                            aria-hidden="true"
                                                                        />
                                                                        <span
                                                                            className={classNames(
                                                                                active ? 'border' : 'border-2',
                                                                                checked ? 'border-indigo-600' : 'border-transparent',
                                                                                'pointer-events-none absolute -inset-px rounded-lg'
                                                                            )}
                                                                            aria-hidden="true"
                                                                        />
                                                                    </>
                                                                )}
                                                            </RadioGroup.Option>
                                                        ))}
                                                    </div>
                                                </RadioGroup>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="flex justify-end sm:pt-6">
                                        <CoreButton htmlType='submit' label={<div>Save</div>} disabled={loadAction.isExecuting} />
                                    </div>
                                </Form>
                            )
                        }
                    </Formik>

                </div>
            </ContentWrapper>

        </>
    )
}

import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { getGroups } from '../../../../api/groups';
import { getTeamUsers } from '../../../../api/manageTeam';
import { useToastAction } from '../../../../hooks/useToastAction';
import GroupsTable from './groupsTable';
import CreateSidebar from './createSidebar';
import { ContentWrapper, PageHeader } from '@metaforcelabs/metaforce-core';

export default function Roles() {
    const [isLoaded, setIsLoaded] = useState(false);
    const [customerGroups, setCustomerGroups] = useState([]);
    const [openSidebar, setOpenSidebar] = useState(false);
    const [usersAtCustomer, setUsersAtCustomer] = useState([]);
    const loadAction = useToastAction()
    const history = useHistory();

    const loadDefaultData = async () => {
        loadAction.execute(async () => {
            const customerGroupsData = await getGroups();
            setCustomerGroups(customerGroupsData || []);
            const usersAtCustomerCollection = await getTeamUsers();
            setUsersAtCustomer(usersAtCustomerCollection || []);
            setIsLoaded(true);
        }, 'Failed to load')
    }

    const handleSidebar = () => {
        if (openSidebar) {            
            loadDefaultData();
        }

        setOpenSidebar(!openSidebar);
    }; 

    const openAddGroup = () => {
        setOpenSidebar(true);
      }    

    useEffect(() => {
        if (isLoaded) {
            return;
        }
        loadDefaultData();
    }, []);


    return (
          <ContentWrapper>
            <div>
                <div className="flex flex-row justify-between items-end">
                    <PageHeader 
                      title="Groups" 
                      optionalSideElement={
                        <button type="button"
                          onClick={e => {
                              openAddGroup();
                            }}                            
                          className="inline-flex items-center px-4 py-2 shadow-sm font-medium rounded-md hover:bg-brand-pink-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 text-sm bg-brand-pink text-white"
                          >
                          New group
                      </button>
                      }
                    />
                </div>                    

            </div>
            <div className="mt-4">
                {
                    isLoaded && customerGroups?.length > 0 ? (
                            <GroupsTable
                            groups={customerGroups}
                            loading={loadAction.isExecuting}
                            onEditEvent={() => loadDefaultData()}
                            onDeleteEvent={() => loadDefaultData()}
                        />
                    ) :
                    (
                        <div>
                            <p>You do not have any access groups.</p>
                            <p>Click "New group" to create an access group.</p>
                        </div>
                    )
                }

            </div>
            <CreateSidebar
                open={openSidebar}
                setOpen={handleSidebar}
                usersAtCustomer={usersAtCustomer}
            />
          </ContentWrapper>
    )
}

import React from 'react'
import { useReactOidc } from '@axa-fr/react-oidc-context';
import { setAuthHeader } from '../../api/api';
import { config } from '../../utils/config';
export default function CenteredLayout({ nav, subNav, children }) {
    const { oidcUser} = useReactOidc();
    setAuthHeader(oidcUser.access_token);
    return (
        <div className="min-h-screen flex justify-center text-center bg-brand-background font-brand-font">
            <div className="flex-1 flex flex-col justify-center max-w-full py-6 px-2 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                <div>
                    <img id="logo" src={config.logoCenterpointMain} alt="CenterPoint" className="inline-block mb-14" />
                </div>
                <div className="flex justify-center bg-white rounded-25px w-495px mx-auto max-w-full">
                    <div className="my-6 w-345px max-w-full pt-7 pb-10 px-1">
                        {children}
                    </div>
                </div>
                <div class="text-13px font-bold mt-20">&copy; Metaforce</div>
            </div>
        </div>
    )
}



import { SidebarTextbox } from '../../../../../components/SidebarTextbox';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { PlusIcon, TrashIcon } from '@heroicons/react/outline';
import GenericSidebar from '../../../../../components/Modals/genericSidebar';
import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { useClassNames } from '../../../../../hooks/useClassNames';
import { SidebarSelect } from '../../../../../components/SidebarSelect';

const initFolderValues = {
  name: '',
  accessGroups: '',
  retentionPolicyId: ''
};

const EditFolder = ({ folder, open, toggleOpen, allAccessGroups, retentionPolicies, onSave }) => {
  const { classNames } = useClassNames();

  const allAccessGroupsListRef = useRef(null);
  const selectedAccessGroupsListRef = useRef(null);

  const [activeModelItem, setActiveModalItem] = useState(initFolderValues);
  const [selectedAccessGroups, setSelectedAccessGroups] = useState([]);
  const [activeDragDestination, setActiveDragDestination] = useState(null);
  const [activeSelectedItem, setActiveSelectedItem] = useState(null);
  const [activeSourceItem, setActiveSourceItem] = useState(null);
  const [activeDragItem, setActiveDragItem] = useState(null);

  const handleSidebar = () => {
    toggleOpen(!open);
    setActiveSelectedItem(null);
    setActiveSourceItem(null);
    setActiveModalItem(initFolderValues);
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setActiveModalItem((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleDragEnd = ({ destination, source, ...result }) => {
    setActiveDragItem(null);
    setActiveDragDestination(null);
    let items = [...selectedAccessGroups];
    if (source?.droppableId === 'selected' && destination?.droppableId === 'delete') {
      items.splice(source.index, 1);
      items = [...items];
      setSelectedAccessGroups(items);

      return;
    }

    if (!destination) {
      return;
    }

    if (source.droppableId === 'source' && destination.droppableId === 'selected') {
      const item = allAccessGroups[source.index];
      const existingIdx = selectedAccessGroups.findIndex((x) => x.id === item.id);
      if (existingIdx === -1) {
        items = [...items, item];
      }
    }

    updateSelectedObjects(items);
  };

  const updateSelectedObjects = (updatedList) => {
    setSelectedAccessGroups(updatedList);
  };

  const handleDragStart = (source) => {
    setActiveDragItem(source);
  };

  const handleDragUpdate = (draggable) => {
    const { destination } = draggable;
    setActiveDragDestination(destination);
  };

  const selectedItemDragged = (onActiveCssClass) => {
    if (!activeDragItem) {
      return '';
    }

    return activeDragItem?.source?.droppableId === 'selected' ? onActiveCssClass : '';
  };

  const selectedOverDelete = () => {
    return (
      activeDragItem?.source?.droppableId === 'selected' &&
      activeDragDestination?.droppableId === 'delete'
    );
  };

  const selectedAccessGroupsExists = (accessGroupId) => {
    return allAccessGroups.findIndex((x) => x.id === accessGroupId) !== -1;
  };

  const getEnabledOrSelectedRetentionOptions = (retentionPolicies, activeModelItem, includeDefault = false) => {
    const activePolicies = retentionPolicies
      .filter((policy) => {
        if (policy.id !== activeModelItem?.retentionPolicyId && policy.isDisabled) {
          return false;
        }
        return true;
      })
      .map((policy) => ({
        name: policy.name,
        value: policy.id
      }));

    if (includeDefault) {
      return [{
        name: 'None',
        value: ''
      }, ...activePolicies];
    }

    return activePolicies;

  }

  const handleRemoveSelected = () => {
    if (activeSelectedItem) {
      let clones = _.cloneDeep(selectedAccessGroups);
      const activeIdx = clones.findIndex((x) => x.id === activeSelectedItem?.id);
      let items = [...clones.filter((x) => x.id !== activeSelectedItem?.id)];

      if (items.length === 0) {
        setActiveSelectedItem(null);
      } else {
        const newSelectedIdx = activeIdx === items.length ? items.length - 1 : activeIdx;
        setActiveSelectedItem((prev) => items[newSelectedIdx]);
      }

      setSelectedAccessGroups((prev) => items);
    }
  };

  const handleAddSourceItem = () => {
    if (activeSourceItem) {
      setSelectedAccessGroups((prev) => {
        return [...prev, activeSourceItem];
      });
    }
  };

  const handleOnSave = () => {
    setActiveModalItem(initFolderValues);
    setSelectedAccessGroups([]);

    onSave({
      ...activeModelItem,
      accessGroups: [...selectedAccessGroups].map((s) => s.id)
    });
  };

  useEffect(() => {
    setActiveModalItem(folder);

    if (allAccessGroups && folder) {
      const selectedAccessGroups = allAccessGroups.filter((group) =>
        folder.accessGroups.includes(group.id)
      );
      setActiveModalItem(folder);
      setSelectedAccessGroups(selectedAccessGroups);
    } else {
      setActiveModalItem(initFolderValues);
      setSelectedAccessGroups([]);
    }
  }, [allAccessGroups, folder]);

  return (
    <GenericSidebar open={open} width="max-w-2xl" setOpen={handleSidebar} title="Add new folder">
      <div className="flex w-full overflow-y-auto h-full">
        <div className="w-full">
          <div className="py-6 space-y-6 sm:py-0 sm:divide-y sm:divide-gray-200">
            <SidebarTextbox
              name="name"
              value={activeModelItem?.name || ''}
              label="Name"
              onChange={handleOnChange}
            />

            <div className="pt-4 px-4 sm:px-6">
              <DragDropContext
                onDragEnd={handleDragEnd}
                onDragStart={handleDragStart}
                onDragUpdate={handleDragUpdate}
              >
                <div className="grid grid-cols-12">
                  <div className="col-span-5">
                    <Droppable droppableId="source" isDropDisabled={true}>
                      {(provided) => (
                        <div className="space-y-2">
                          <div className="">
                            <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                              All Access Groups
                            </label>
                          </div>

                          <div className="relative" ref={allAccessGroupsListRef}>
                            <ul
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              className="border border-gray-200 rounded-md h-40 overflow-y-auto shadow"
                            >
                              {allAccessGroups
                                .filter((item) => {
                                  return !selectedAccessGroups.some(
                                    (selectedItem) => selectedItem.id === item.id
                                  );
                                })
                                .map((item, index) => (
                                  <Draggable
                                    key={item.id}
                                    draggableId={item.id + '-source'}
                                    index={index}
                                  >
                                    {(provided, { isDragging }) => (
                                      <>
                                        <li
                                          ref={provided.innerRef}
                                          onClick={(e) => {
                                            setActiveSourceItem(item);
                                          }}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                        >
                                          <div
                                            className={classNames(
                                              'px-2 sm:px-4 py-1.5 text-gray-600 space-x-2 sm:space-x-4 text-sm sm:text-md',
                                              isDragging
                                                ? 'rounded-md border shadow-sm'
                                                : 'border-b',
                                              activeSourceItem?.id === item.id
                                                ? 'rounded-md border border-indigo-500'
                                                : ''
                                            )}
                                          >
                                            <span>{index + 1}.</span>{' '}
                                            <span className={classNames('font-semibold')}>
                                              {item.name}
                                            </span>
                                          </div>
                                        </li>
                                        {isDragging && (
                                          <div
                                            className={classNames(
                                              'px-2 sm:px-4 py-1.5 text-gray-600 space-x-2 sm:space-x-4 text-sm sm:text-md border-b',
                                              activeSourceItem?.id === item.id
                                                ? 'rounded-md border border-indigo-500'
                                                : ''
                                            )}
                                          >
                                            <span>{index + 1}.</span>{' '}
                                            <span className="font-semibold">{item.name}</span>
                                          </div>
                                        )}
                                      </>
                                    )}
                                  </Draggable>
                                ))}
                              {!provided.placeholder}
                            </ul>
                            {activeSourceItem && !activeDragItem && (
                              <SourceListActionMenu onItemAdd={handleAddSourceItem} />
                            )}
                          </div>
                        </div>
                      )}
                    </Droppable>
                  </div>

                  <div className="col-span-2">
                    <Droppable droppableId="delete">
                      {(provided, snapshot) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          className={classNames(
                            'flex-1 flex mt-4 mb-4 border-2 border-transparent border-dotted rounded items-center justify-center',
                            selectedItemDragged('border-red-200'),
                            snapshot.isDraggingOver &&
                              activeDragItem?.source?.droppableId === 'selected'
                              ? 'border-red-400'
                              : ''
                          )}
                        >
                          <TrashIcon
                            className={classNames(
                              'h-10 w-10 text-transparent',
                              selectedItemDragged('text-red-200')
                            )}
                          ></TrashIcon>
                          <span className="hidden">{provided.placeholder}</span>
                        </div>
                      )}
                    </Droppable>
                  </div>

                  <div className="col-span-5">
                    <Droppable droppableId="selected" isDropDisabled={false}>
                      {(provided, snapshot) => (
                        <div className="space-y-2">
                          <div className="">
                            <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                              Selected Objects
                            </label>
                          </div>
                          <div className="relative" ref={selectedAccessGroupsListRef}>
                            <ul
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              className="border border-gray-200 rounded-md h-40 overflow-y-auto overflow-x-hidden shadow w-full"
                            >
                              {selectedAccessGroups.map((item, index) => (
                                <Draggable
                                  key={item.id}
                                  draggableId={item.id + '-selected'}
                                  index={index}
                                >
                                  {(provided, { isDragging }) => (
                                    <li
                                      ref={provided.innerRef}
                                      onClick={(e) => setActiveSelectedItem(item)}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <div
                                        className={classNames(
                                          'px-2 sm:px-4 py-1.5 space-x-2 sm:space-x-4 text-sm sm:text-md',
                                          isDragging
                                            ? classNames(
                                              'rounded-md border shadow-sm',
                                              selectedOverDelete()
                                                ? 'inline-block px-2 py-.5 space-x-2 truncate w-16 sm:w-20'
                                                : ''
                                            )
                                            : 'border-b',
                                          selectedAccessGroupsExists(item.id)
                                            ? 'text-gray-600'
                                            : 'text-gray-400',
                                          activeSelectedItem?.id === item.id
                                            ? 'rounded-md border border-indigo-500'
                                            : ''
                                        )}
                                      >
                                        <span>{index + 1}.</span>{' '}
                                        <span className={classNames('font-semibold')}>
                                          {item.name}
                                        </span>
                                      </div>
                                    </li>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </ul>
                            {activeSelectedItem && !activeDragItem && (
                              <SelectedListActionMenu
                                onRemove={() => handleRemoveSelected()}
                              // onMoveUp={() => handleMoveUp()} onMoveDown={() => handleMoveDown()}
                              />
                            )}
                          </div>
                        </div>
                      )}
                    </Droppable>
                  </div>
                </div>
              </DragDropContext>
            </div>
          </div>

          <div className="py-6 space-y-6 sm:py-2 sm:space-y-3 sm:divide-y sm:divide-gray-200">
            <SidebarSelect
              name="retentionPolicyId"
              value={activeModelItem?.retentionPolicyId}
              label="Delete records that are older than"
              onChange={handleOnChange}
              options={getEnabledOrSelectedRetentionOptions(retentionPolicies, activeModelItem,true)}
            />
          </div>
        </div>
      </div>

      <div className="flex-shrink-0 px-4 border-t bg-gray-50 border-gray-200 py-5 sm:px-6">
        <div className="space-x-3 flex justify-end">
          <button
            type="button"
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 "
            onClick={handleSidebar}
          >
            Cancel
          </button>
          <button
            type="button"
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-brand-pink hover:bg-brand-pink-hover"
            onClick={handleOnSave}
          >
            Submit
          </button>
        </div>
      </div>
    </GenericSidebar>
  );
};

const SelectedListActionMenu = ({ onRemove, onMoveUp, onMoveDown }) => {
  return (
    <div className="absolute top-24 -left-12 grid grid-cols-2 space-y-2">
      <div className="row-span-2 flex items-center justify-center mt-2">
        <div
          className="border rounded-full p-2 text-white bg-indigo-600 hover:bg-indigo-500 shadow-sm cursor-pointer"
          onClick={(e) => onRemove()}
          title="Add Object"
        >
          <TrashIcon className="h-5 w-5 text-white" />
        </div>
      </div>
    </div>
  );
};

const SourceListActionMenu = ({ onItemAdd }) => {
  return (
    <div className={`absolute top-24 -right-12 grid grid-cols-1 grid-rows-2`}>
      <div className="row-span-2 flex items-center justify-center mt-2">
        <div
          className="border rounded-full p-2 text-white bg-indigo-600 hover:bg-indigo-500 shadow-sm cursor-pointer"
          onClick={(e) => onItemAdd()}
          title="Add Object"
        >
          <PlusIcon className="h-5 w-5 text-white" />
        </div>
      </div>
    </div>
  );
};

export default EditFolder;
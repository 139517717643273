import React, { useState, Fragment } from "react";
import { useParams } from "react-router-dom";
import { format } from "date-fns";

import "./styles.scss";
import Properties from "./Tabs/properties";
import { History } from "./Tabs/history";
import { XIcon } from "@heroicons/react/outline";
import { Dialog, Transition } from "@headlessui/react";
import { useClassNames } from "../../../hooks/useClassNames";
import { Compare } from "./Tabs/compare";

export default function Sidebar({ open, setOpen, document, allDocuments, setDocument, onSubmit }) {
  const { environment } = useParams();
  const [activeTab, setActiveTab] = useState("properties");
  const classNames = useClassNames();

  const canEdit = environment === "development";

  const handleChange = e => {
    const { name, value } = e.target;
    console.log(value)
    
    setDocument(prevState => ({
        ...prevState,
        [name]: value
    }));
  };

  const handleOpen = () => {
    if(open)
      setActiveTab('properties')
    
    setOpen(!open)
  }

  const renderActiveTab = () => {
    switch (activeTab) {
      case "properties":
        return <Properties allDocuments={allDocuments} document={document} onChange={handleChange} canEdit={canEdit} setOpen={handleOpen} onSubmit={onSubmit} />;
      case "history":
        return <History document={document} setOpen={handleOpen} onSubmit={onSubmit} />;
      case "compare":
        return <Compare document={document} setOpen={handleOpen} onSubmit={onSubmit} />;
      default:
        return <></>;
    }
  };

  return (
    <>
      {document && (
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed inset-0 overflow-hidden z-50"
            open={open}
            onClose={handleOpen}
          >
            <div className="absolute inset-0 overflow-hidden">
              <Dialog.Overlay className="absolute inset-0" />

              <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <div className={
                    classNames.classNames(
                      activeTab === 'compare'
                        ? 'max-w-5xl'
                        : 'max-w-lg',
                      'w-screen '
                    )}>
                    <form className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                      <div className="flex-shrink-0">
                        {/* Header */}
                        <div className="px-4 py-6 bg-brand-pink sm:px-6">
                          <div className="flex items-start justify-between space-x-3">
                            <div className="space-y-1">
                              <Dialog.Title className="text-lg font-medium text-white">
                                Editing - {document.type}
                              </Dialog.Title>
                              <p className="text-sm text-white">
                                Last updated by{" "}
                                <span className={"font-bold"}>
                                  {document.updatedByName}{" "}
                                </span>{" "}
                                at{" "}
                                <span className={"font-bold"}>
                                  {format(
                                    new Date(document.updatedAt),
                                    "MM/dd/yyyy - HH:mm"
                                  )}
                                </span>
                              </p>
                            </div>
                            <div className="h-7 flex items-center">
                              <button
                                type="button"
                                className="bg-red-400 rounded-md text-white hover:text-gray-500 focus:outline-none cursor-pointer"
                                onClick={handleOpen}
                              >
                                <span className="sr-only">Close panel</span>
                                <XIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="mt-5 border-b border-gray-200">
                          <div className="px-6">
                            <nav
                              className="-mb-px flex space-x-6"
                              x-descriptions="Tab component"
                            >
                              {[
                                {
                                  name: "Properties",
                                  tab: "properties",
                                  current: activeTab === "properties",
                                },
                                {
                                  name: "History",
                                  tab: "history",
                                  current: activeTab === "history",
                                },
                                {
                                  name: "Compare",
                                  tab: "compare",
                                  current: activeTab === "compare",
                                },
                              ]
                                .filter((m) => !m.hide)
                                .map((tab) => (
                                  <a
                                    key={tab.name}
                                    onClick={() => setActiveTab(tab.tab)}
                                    className={classNames.classNames(
                                      tab.current
                                        ? "border-black text-black"
                                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                      "py-4 w-1/2 px-1 text-center border-b-2 font-medium text-sm cursor-pointer"
                                    )}
                                  >
                                    {tab.name}
                                  </a>
                                ))}
                            </nav>
                          </div>
                        </div>
                      </div>

                      
                      {renderActiveTab()}
                      
                    </form>
                  </div>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      )}
    </>
  );
}

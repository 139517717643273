
export default function SmsGridComponent({ label, children }) {
    return (
      <div className="grid grid-cols-12">
        <div className="col-span-12 sm:col-span-4">
          <label className="sm:pt-2 text-sm font-medium text-gray-700 sm:flex items-center">
            {label}
          </label>
        </div>
        <div className="col-span-12 sm:col-span-8">
          {children}
        </div>
      </div>
    )
  }
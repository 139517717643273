import { ClipboardListIcon } from "@heroicons/react/outline";
import React from "react";
import ActionTile from "./actionTile";


export const InvoiceOverviewTile = () => {

    const title = 'Invoice overview';
    const text = 'View all your invoices';
    const href = 'invoices';
    const iconForeground = 'text-yellow-700';
    const iconBackground = 'bg-yellow-50';

    return (
        <ActionTile title={title} text={text} href={href} isExternal={false} icon={ClipboardListIcon} iconBackground={iconBackground} iconForeground={iconForeground} actionIdx={2}></ActionTile>
    )
}
export default InvoiceOverviewTile
import React, { useContext, useState } from 'react';
import Loader from '../../../../../components/Loader';
import { RoleTableContext } from '../../../../../contexts';
import { useClassNames } from '../../../../../hooks/useClassNames';

export default function RoleFeatures({ }) {
    const { loading, productFeatureAccesses } = useContext(RoleTableContext);

    return (
        <>
            <div>
                <div className="-my-2 sm:-mx-6 lg:-mx-8 overflow-x-auto">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow-sm overflow-hidden border border-gray-200 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-200 bg-white">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Product access
                                        </th>
                                        <th scope="col" colSpan={5} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Additional features
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-20">
                                    {
                                        !loading && (productFeatureAccesses?.map((u, i) => {
                                            return <TableRow productFeatureAccess={u} key={u.productCode} />
                                        }))
                                    }
                                    {
                                        loading && (<tr>
                                            <td>
                                                <div className="text-center py-6"><Loader /></div>
                                            </td>
                                        </tr>)
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
const TableRow = ({ productFeatureAccess }) => {
    return (
        <tr>
            {

                <TableRowItemUsage feature={productFeatureAccess?.usageFeature}
                    productCode={productFeatureAccess?.productCode}
                    productName={productFeatureAccess?.productName}
                    key={productFeatureAccess?.usageFeature.featureCode} />

            }
            {
                productFeatureAccess?.featureSelections?.map((u, i) => {
                    return <TableRowItem feature={u}
                        productCode={productFeatureAccess?.productCode}
                        productName={productFeatureAccess?.productName}
                        key={u.featureCode} />
                })
            }

        </tr>
    );
}

const TableRowItemUsage = ({ feature, productCode, productName }) => {
    const { classNames } = useClassNames();
    const tableContext = useContext(RoleTableContext);

    const id = productCode + feature.featureCode;

    const handleItemChange = ({ checked }) => {
        tableContext.onFeatureAccessChange(productCode, feature.featureCode, checked)
    }

    return (

        <td className="px-3 py-2 text-sm text-gray-500">
            <div className='flex flex-row justify-left items-center'>
                <div className='px-1'>
                    <label className="block text-sm">
                        <input
                            id={id}
                            name="selected"
                            type="checkbox"

                            checked={feature?.hasAccess}
                            onChange={e => handleItemChange(e.target)}
                            className="focus:ring-brand-pink h-4 w-4 text-brand-pink border-gray-300 rounded cursor-pointer"
                        />
                    </label>
                </div>
                <div className={classNames(
                    feature?.isUsageFeature
                        ? "font-semibold "
                        : "",
                    "px-1 block sm:pt-1 items-center"
                )}>
                    <label className="cursor-pointer" htmlFor={id}>
                        {productName}
                    </label>
                </div>
            </div>
        </td>

    );
}

const TableRowItem = ({ feature, productCode, productName }) => {
    const { classNames } = useClassNames();
    const tableContext = useContext(RoleTableContext);

    const id = productCode + feature.featureCode;

    const handleItemChange = ({ checked }) => {
        tableContext.onFeatureAccessChange(productCode, feature.featureCode, checked)
    }

    return (

        <td className="px-3 py-2 text-sm text-gray-500">
            <div className='flex flex-row justify-left items-center'>
                <div className='px-1'>
                    <label className="block text-sm">
                        <input
                            id={id}
                            name="selected"
                            type="checkbox"

                            checked={feature?.hasAccess}
                            onChange={e => handleItemChange(e.target)}
                            className="focus:ring-brand-pink h-4 w-4 text-brand-pink border-gray-300 rounded cursor-pointer"
                        />
                    </label>
                </div>
                <div className={classNames(
                    feature?.isUsageFeature
                        ? "font-semibold "
                        : "",
                    "px-1 block sm:pt-1 items-center"
                )}>
                    <label className="cursor-pointer" htmlFor={id}>
                        <TableRowItemText featureName={feature?.featureName}
                            productName={productName}
                            isUsageFeature={feature?.isUsageFeature} />
                    </label>
                </div>
            </div>
        </td>

    );
}

const TableRowItemText = ({ featureName, productName, isUsageFeature }) => {

    return isUsageFeature ? productName : featureName;
}
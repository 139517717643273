import { Formik } from 'formik';
import React, { useState } from 'react'

export default function CompanyContactsForm({ onValidSubmit, onGoToPrev }) {
    return (
        <Formik
            initialValues={{
                homePageUri: null,
                contactEmail: null
            }
            }
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                onValidSubmit(values);
            }}
        >
            {({
                values,
                handleChange,
                handleSubmit,
                /* and other goodies */
            }) => (
                <form onSubmit={handleSubmit}>
                    <div className="mt-2">
                        <label>Home page</label>
                        <input type="text" name="homePageUri"
                            value={values.homePageUri}
                            onChange={handleChange}
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-gray-300 focus:border-gray-300 sm:text-sm" />
                    </div>
                    <div className="mt-2">
                        <label>Contact E-mail</label>
                        <input type="email" name="contactEmail"
                            value={values.contactEmail}
                            onChange={handleChange}
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-gray-300 focus:border-gray-300 sm:text-sm" />
                    </div>
                    <div className="mt-4 grid grid-cols-2 gap-4">
                        <button type="button"
                            name="backBtn"
                            onClick={e => onGoToPrev()}
                            className="text-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-gray-600 bg-brand-button2 hover:bg-brand-button2-hover focus:outline-none">
                            Back
                        </button>
                        <button name="nextBtn"
                            type="submit"
                            className="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-brand-button1 hover:bg-brand-button1-hover focus:outline-none">
                            Next
                            <svg xmlns="http://www.w3.org/2000/svg" className="ml-2 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                            </svg>
                        </button>
                    </div>
                </form>)
            }
        </Formik>

    )
}

import { useEffect, useState } from "react";
import { getMyUser } from "../api/myUser";

export const useCenterPointMyUser = () => {
    const [myUser, setMyUser] = useState(null);

    const loadFromServer = async () => {
        const serverUser = await getMyUser();
        console.log(serverUser);
        setMyUser(serverUser);
    }

    useEffect(() => {
        loadFromServer();
    }, [])

    return myUser;
}
import React, { useEffect, useState } from 'react';
import { getTeamUsers, getUserRoles, getUserStatuses } from '../../../../api/manageTeam';
import { tailwindToast } from '../../../../components/Toast/tailwindToast';
import { ManageTeamContext } from '../../../../contexts';
import FilterPanel from './filterPanel';
import UserTable from './userTable';
import { ContentWrapper, PageHeader } from '@metaforcelabs/metaforce-core';

export default function ManageTeam() {
  const [teamUsers, setTeamUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [userStatuses, setUserStatuses] = useState([]);
  const loadData = async () => {
    try {
      const users = await getTeamUsers();
      setTeamUsers(users);
      setFilteredUsers(users);
      const roleList = await getUserRoles();
      setRoles(roleList);
      const statuses = await getUserStatuses();
      setUserStatuses(statuses);
    } catch (error) {
      tailwindToast.error('Failed to load');
    }
  };
  const filterUsers = ({ name, email, role }) => {
    console.log({ name, email, role });
    let filterResult = teamUsers.filter((x) => {
      return namePredicate(x, name) && emailPredicate(x, email) && rolePredicate(x, role);
    });
    console.log(filterResult);
    setFilteredUsers(filterResult);
  };
  const namePredicate = (user, name) => {
    return !name || user.name.toLowerCase().includes(name.toLowerCase());
  };
  const emailPredicate = (user, email) => {
    return !email || user.email.toLowerCase().includes(email.toLowerCase());
  };
  const rolePredicate = (user, role) => {
    if (!role) return true;

    const roleObj = roles.find((x) => x.roleId === role);
    if (!roleObj) return false;

    return user.roleId === roleObj.roleId;
  };
  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
    <ContentWrapper>
      <PageHeader title={'Manage'} />
      <div className="px-4">
        <ManageTeamContext.Provider value={{ roles: roles, statuses: userStatuses }}>
          <section>
            <FilterPanel onSearchUsers={(e) => filterUsers(e)} />
          </section>
          <section className="mt-4">
            <UserTable users={filteredUsers} />
          </section>
        </ManageTeamContext.Provider>
      </div>
    </ContentWrapper>
    </>
  );
}

import React, { useEffect, useState } from 'react';
import { CoreButton, useClassNames, useToastAction } from '@metaforcelabs/metaforce-core';
import { Form, Formik, setNestedObjectValues } from 'formik';
import * as yup from 'yup';
import FormikInput from '../../../../../components/Form/Formik/FormikInput';
import FormikClientSecret from '../../../../../components/Form/Formik/FormikClientSecret';
import { CheckCircleIcon as CheckCircleIconOutline } from '@heroicons/react/outline';
import SmsGridComponent from './SmsGridComponent';
import { SmsProviderAuthType } from '../../../../../utils/constants';
import { tailwindToast } from '../../../../../components/Toast/tailwindToast';
import { testCustomerSmsProvider } from '../../../../../api/integration';


export default function BearerAuthProviderForm({ customerProvider, onSave }) {
    const [formValues, setFormValues] = useState({});
    const [testSuccessful, setTestSuccessful] = useState(false);
    const [testVerifiedDate, setTestVerifiedDate] = useState();
    const { classNames } = useClassNames();

    const testAction = useToastAction();

    useEffect(() => {
        if (!customerProvider)
            return;
        setFormValues(customerProvider);
        setTestSuccessful(false);
        if (customerProvider.updatedDate) {
            setTestVerifiedDate(new Date(customerProvider.updatedDate));
        } else {
            setTestVerifiedDate(null);
        }
    }, [customerProvider])

    const validationSchema = yup.object().shape({
        clientSecret: yup
            .string()
            .trim()
            .test('clientSecret', 'Required', (value) => {
                if (!value) {
                    if (customerProvider && customerProvider.isSet)
                        return true;
                    else
                        return false;
                }
                return true;
            }),
    });

    const handleResetTestResult = () => {
        setTestSuccessful(false);
        setTestVerifiedDate(null);
    }

    const handleTest = async (formikProps) => {
        testAction.execute(async () => {
            const errors = await formikProps.validateForm();
            if (Object.keys(errors).length > 0) {
                formikProps.setTouched(setNestedObjectValues(errors, true));
                return;
            }

            const request = {
                ...formikProps.values,
                credentialType: SmsProviderAuthType.bearer,
            }

            const result = await testCustomerSmsProvider(request);
            if (!result) {
                tailwindToast.error('Test failed');
            }

            setTestSuccessful(result);
            setTestVerifiedDate(result ? new Date() : null);
        }, 'Test failed');
    }

    const handleSave = (values) => {
        const request = {
            ...values,
            credentialType: SmsProviderAuthType.bearer,
        }
        onSave(request);
    }

    return (
        <Formik
            initialValues={formValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting }) => {
                handleSave(values);
            }}
        >
            {(props) => (
                <Form>
                    <div className="space-y-6 sm:space-y-6">
                        <SmsGridComponent label={"Bearer token"}>
                            <FormikClientSecret
                                name={'clientSecret'}
                                isCredentialsSet={(!customerProvider?.clientSecret && customerProvider?.isSet) ? true : false}
                                formikProps={props}
                                onChange={() => handleResetTestResult()}
                            />
                        </SmsGridComponent>

                        <SmsGridComponent label={""}>
                            <div className="flex">
                                <p className="flex-1 text-sm font-medium text-gray-500">{testVerifiedDate ? `Credentials verified ${testVerifiedDate.toLocaleDateString()}` : ''}</p>
                                <div className="">
                                    <CoreButton htmlType='button'
                                        htmlClassNames={classNames('flex justify-start items-start focus:!ring-0 focus:!ring-transparent', testSuccessful ? 'bg-green-500 hover:bg-green-500' : 'bg-blue-600 hover:bg-blue-500')}
                                        label={testSuccessful ? <div className=''>Test Successful <CheckCircleIconOutline className='h-6 inline-block' /></div> : testAction.isExecuting ? <div>Testing Credentials....</div> : <div>Test Credentials</div>}
                                        onClick={() => handleTest(props)}
                                    />
                                </div>
                            </div>
                        </SmsGridComponent>

                        <div className="flex justify-end sm:border-t sm:border-gray-200 sm:pt-6">
                            <CoreButton htmlType='submit' label={<div>Save</div>}
                                disabled={testAction.isExecuting || !testSuccessful}
                            />
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

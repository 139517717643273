import {BaseUrl, get, post} from "./api";

export const getWebhooksByCustomerId = async () => {
  return await get(
    `api/DigitalArchiveWebhooks/all`,
    null,
    BaseUrl.Centerpoint
  );
}

export const deleteWebhooksDefinitions = async (ids) => {
  return await post(
    `api/DigitalArchiveWebhooks/delete`,
    ids,
    null,
    BaseUrl.Centerpoint
  );
}

export const createWebhook = async (settings) => {
  return await post(
    `api/DigitalArchiveWebhooks`,
    settings,
    null,
    BaseUrl.Centerpoint
  );
}

export const updateWebhook = async (settings) => {
  return await post(
    `api/DigitalArchiveWebhooks/update`,
    settings,
    null,
    BaseUrl.Centerpoint
  );
}

export const getFolders = async (parentId) => {
  const path = parentId ? `api/DigitalArchiveFolders/all/${parentId}` : `api/DigitalArchiveFolders/all`

  return await get(
    path,
    null,
    BaseUrl.Centerpoint
  );
}

export const addFolders = async (data) => {
  return await post(
    `api/DigitalArchiveFolders`,
    data,
    null,
    BaseUrl.Centerpoint
  );
}

export const updateFolder = async (data) => {
  return await post(
    `api/DigitalArchiveFolders/update`,
    data,
    null,
    BaseUrl.Centerpoint
  );
}

export const deleteFolder = async (data) => {
  return await post(
    `api/DigitalArchiveFolders/delete`,
    data,
    null,
    BaseUrl.Centerpoint
  );
}

export const getAllMappingsApi = async (data) => {
  return await get(
    `api/DigitalArchiveMetadataMapping/allMappings`,
    null,
    BaseUrl.Centerpoint
  );
}

export const editMappingApi = async (data) => {
  return await post(
    `api/DigitalArchiveMetadataMapping/update`,
    data,
    null,
    BaseUrl.Centerpoint
  );
}

export const getAccessGroupsApi = async () => {
  return await get(
    `api/DigitalArchiveFolders/accessGroups`,
    null,
    BaseUrl.Centerpoint
  );
}

export const getCurrentMigrationStatusApi = async () => {
  return await get(
    `api/DigitalArchiveMigration/GetCurrentMigrationStatus`,
    null,
    BaseUrl.Centerpoint
  );
}

export const migrateApi = async () => {
  return await post(
    `api/DigitalArchiveMigration/Migrate`,
    null,
    null,
    BaseUrl.Centerpoint
  );
}
import {useClassNames} from '../../hooks/useClassNames';
import {ChevronLeftIcon} from '@heroicons/react/outline';
import {useEffect, useRef, useState} from 'react';
import List from './components/List';
import useCountChildren from './hooks/useCountChildren';

const MultipleSelectField = ({
                               label,
                               customClassNames,
                               parentId,
                               folders,
                               options,
                               defaultOptionText,
                               required,
                               onChange,
                               onParentIdChange,
                               ...props
                             }) => {
  const {classNames} = useClassNames();

  const [countChildren, setFolders] = useCountChildren();

  const wrapperElement = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const handleOnToggleClick = () => {
    setIsOpen((value) => !value);
  };

  const handleOnClearClick = () => {
    onChange({
      parentId: null,
      foldersIds: []
    });
    setSearchValue('');
  };

  const handleOnToggleMouseUp = (e) => {
    if (isOpen && wrapperElement.current && !wrapperElement.current.contains(e.target)) {
      handleOnToggleClick();
    }
  };

  const handleOnSelectAll = () => {
    const children = countChildren(parentId);
    const valuesFiltered = props.values.filter((v) => children.includes(v));

    if (children.length === valuesFiltered.length) {
      let foldersIds = props.values.filter((v) => !children.includes(v));
      if (!parentId) {
        foldersIds = foldersIds.filter((v) => v !== null);
      }

      onChange({
        parentId: parentId,
        foldersIds
      });
    } else {
      let foldersIds = props.values.filter((v) => !children.includes(v)).concat(children);

      if (!parentId) {
        foldersIds = [...foldersIds, null];
      }

      onChange({
        parentId: parentId,
        foldersIds
      });
    }
  };

  const checkIsSelectedAll = () => {
    let children = countChildren(parentId);
    let valuesFiltered = props.values.filter((v) => children.includes(v));

    return children.length === valuesFiltered.length;
  };

  useEffect(() => {
    setFolders(folders);
  }, [folders]);

  useEffect(() => {
    document.addEventListener('mouseup', handleOnToggleMouseUp);

    return () => {
      document.removeEventListener('mouseup', handleOnToggleMouseUp);
    };
  });

  return (
    <>
      <div ref={wrapperElement} className={classNames('relative', customClassNames)}>
        {/*<p className="block text-sm font-medium text-gray-700">*/}
        {/*  {label}*/}
        {/*  {required && <span className="text-red-400"> *</span>}*/}
        {/*</p>*/}

        <div className="mt-1">
          {/*<div*/}
          {/*  className="flex px-3 py-2 bg-white shadow-sm block w-full sm:text-sm cursor-pointer border border-gray-300 rounded-md focus:ring-gray-400 focus:border-gray-400">*/}
          {/*  <div className="flex-grow" onClick={handleOnToggleClick}>*/}
          {/*    {props.values.length > 0*/}
          {/*      ? `${props.values.length} selected items`*/}
          {/*      : defaultOptionText || ''}*/}
          {/*  </div>*/}
          {/*  <div className="flex">*/}
          {/*    {props.values.length > 0 && (*/}
          {/*      <XIcon className="w-5 h-5 text-gray-500" onClick={handleOnClearClick}/>*/}
          {/*    )}*/}
          {/*    <ChevronDownIcon className="w-5 h-5 text-gray-500" onClick={handleOnToggleClick}/>*/}
          {/*  </div>*/}
          {/*</div>*/}

          <div
            className={classNames(
              'flex-col w-full text-base bg-white border-0 border-gray-300 focus:outline-none focus:ring-gray-400 focus:border-gray-400 sm:text-sm rounded-md',
              // isOpen ? 'visible' : 'invisible'
            )}
          >
            <div className="py-3">
              <input
                type="text"
                placeholder="Type"
                onChange={(e) => setSearchValue(e.target.value.toLowerCase())}
                className="shadow-sm block w-full sm:text-sm border-gray-300 rounded-md focus:ring-gray-400 focus:border-gray-400"
              />
            </div>

            <div className="flex flex-col">
              {parentId && (
                <div
                  className="flex items-center mb-2 px-3 cursor-pointer"
                  onClick={() => onParentIdChange()}
                >
                  <ChevronLeftIcon className="mr-2 h-4 w-4"/>
                  Go level up
                </div>
              )}

              {/*<div*/}
              {/*  className="flex items-center mb-2 px-3 cursor-pointer"*/}
              {/*  onClick={handleOnSelectAll}*/}
              {/*>*/}
              {/*  {checkIsSelectedAll() ? (*/}
              {/*    <CheckIcon className="mr-2 h-4 w-4 border border-gray-500 rounded"/>*/}
              {/*  ) : (*/}
              {/*    <span*/}
              {/*      className="mr-2 block border h-4 w-4 text-indigo-600 border-gray-500 rounded focus:ring-indigo-500"/>*/}
              {/*  )}*/}
              {/*  <p>Select all</p>*/}
              {/*</div>*/}

              <List
                parentId={parentId}
                options={options}
                folders={folders}
                values={props.values}
                searchValue={searchValue}
                onChange={onChange}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MultipleSelectField;

import React from 'react'
import GenericModal from '../../../../components/Modals/genericModal';

export default function DeleteApiClientModal({ open, setOpen, onConfirm, onCancel, client }) {
    return (
        <GenericModal
            title={`Do you want to delete this API Client?`}
            setOpen={setOpen}
            open={open}
            confirmButtonText={'Confirm'}
            showCancelButton={true}
            onConfirm={onConfirm}
            onCancel={onCancel}
        >
            <div className="flex flex-row justify-center py-6">
                <div className="ml-4">
                    <div className="text-sm text-gray-600 flex items-center">
                        <div>
                            Client Name: <span className='font-semibold'>{client?.clientName}</span>
                        </div>
                    </div>
                </div>
            </div>
        </GenericModal>
    )
}

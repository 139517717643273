import { SmsProviderTypes } from "../../../../../utils/constants";
import BasicAuthProviderForm from "./BasicAuthProviderForm";
import BearerAuthProviderForm from "./BearerAuthProviderForm";

export default function SmsProviderForm({ customerProvider, onSave }) {
    switch (customerProvider?.providerId) {
        case SmsProviderTypes.elks:
        case SmsProviderTypes.clickSend:
        case SmsProviderTypes.textmagic:
            // case SmsProviderTypes.messente:
            return (
                <BasicAuthProviderForm
                    customerProvider={customerProvider}
                    onSave={onSave}
                />
            )
        // case SmsProviderTypes.airship:
        // case SmsProviderTypes.sender:
        //     return (
        //         <BearerAuthProviderForm
        //             customerProvider={customerProvider}
        //             onSave={onSave}
        //         />
        //     )
        // case SmsProviderTypes.amio:
        //     //channel id and bearer token
        //     return <></>
        default:
            return <></>
    }
}

import useCountChildren from './useCountChildren';
import {useEffect} from 'react';

export default function useOptionHandler(parentId, folders, formValues) {
  const [countChildren, setFolders] = useCountChildren();

  const onClick = (option, isClickCheckbox) => {
    let result = {
      parentId: null,
      foldersIds: []
    };

    if (Array.isArray(option)) {
      result.parentId = null;
      result.foldersIds = [];
    } else {
      const folder = folders.find((f) => f.id === option.value);
      const index = formValues.findIndex((v) => v === option.value);
      let values = [...formValues];

      if (option.value === null) {
      }

      const hasChildren = countChildren(option.value);

      if (hasChildren.length === 0 || isClickCheckbox) {
        result.parentId = parentId;
        if (index >= 0) {
          // if (hasChildren.length > 0) {
          //   values = values.filter((v) => ![...hasChildren, option.value].includes(v));
          // } else {
          values = values.filter((v) => v !== option.value);
          // }
        } else {
          // values = [...values, option.value, ...hasChildren];
          values = [...values, option.value];
        }
      } else {
        result.parentId = folder ? folder.id : null;
      }

      result.foldersIds = values;
    }

    return result;
  };

  useEffect(() => {
    setFolders(folders);
  }, [folders]);

  return {
    onClick
  };
}

import { useClassNames } from "@metaforcelabs/metaforce-core";
import { Field, ErrorMessage } from "formik";
import React from "react";

export default function FormikRadioButton({
    value,
    name,
    label,
    defaultChecked,
    errors,
    styles,
    onClick,
    onBlur,
    onChange,
    required = false,
    disabled
}) {

    const { classNames } = useClassNames();

    const hasError = errors && errors[name];
    const errorClasses = 'ring-red-500 border-red-500 focus:ring-red-500 focus:border-red-500'

    return (
        <div key={value} className="flex items-center">
            <Field
                name={name}
                type="radio"
                value={value}
                onClick={onClick}
                onBlur={onBlur}
                onChange={onChange}
                defaultChecked={defaultChecked}
                className={classNames("h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600", styles ? styles : "", hasError ? errorClasses : '')}
            />
            <label htmlFor={value} className={classNames("ml-3 block text-sm font-medium leading-6")}>
                {label}
            </label>
            <ErrorMessage name={name} component="div" className="text-red-500" />
        </div>
    )
}
import React, { useState, Fragment } from "react";
import { format } from "date-fns";

import "./styles.scss";
import Properties from "./Tabs/properties";
import { useClassNames } from "../../../../hooks/useClassNames";
import GenericSidebar from "../../../../components/Modals/genericSidebar";
import './styles.scss'
export default function Sidebar({ open, setOpen, webEditorSettings, setWebEditorSettings, allWebEditorSettings, allCustomerEnvironments, onSubmit }) {
  const [activeTab, setActiveTab] = useState("properties");
  const { classNames } = useClassNames();
  const handleOpen = () => {
    if (open)
      setActiveTab('properties')

    setOpen(!open)
  }

  const renderActiveTab = () => {
    switch (activeTab) {
      case "properties":
        return <Properties webEditorSettings={webEditorSettings} allWebEditorSettings={allWebEditorSettings} allCustomerEnvironments={allCustomerEnvironments}
          setOpen={handleOpen} setWebEditorSettings={setWebEditorSettings} onSubmit={onSubmit} />;
      //   case "history":
      //     return <History document={document} setOpen={handleOpen} onSubmit={onSubmit} />;
      default:
        return <></>;
    }
  };

  const getSideBarContentStyle = (headerHeight) => {
    return `calc(100% - ${headerHeight}px - ${74 + 79}px)`;
  }

  return (
    <>
      {webEditorSettings && (
        <GenericSidebar
          open={open}
          setOpen={handleOpen}
          title={`Editing`}
          subTitle={
            webEditorSettings.updatedByName && webEditorSettings.updatedDate && (
              <p className="text-sm text-white">
                Last updated by{" "}
                <span className={"font-bold"}>
                  {webEditorSettings.updatedByName}{" "}
                </span>{" "}
                at{" "}
                <span className={"font-bold"}>
                  {format(
                    new Date(webEditorSettings.updatedDate),
                    "MM/dd/yyyy - HH:mm"
                  )}
                </span>
              </p>
            )
          }
        >
          {({ headerHeight }) =>
          (
            <form className="flex flex-col" style={{
              height: getSideBarContentStyle(headerHeight)
            }}>
              <div className="flex-shrink-0">
                <div className="mt-5 border-b border-gray-200">
                  <div className="px-6">
                    <nav
                      className="-mb-px flex space-x-6"
                      x-descriptions="Tab component"
                    >
                      {[
                        {
                          name: "Properties",
                          tab: "properties",
                          current: activeTab === "properties",
                        },
                      ]
                        .map((tab) => (
                          <a
                            key={tab.name}
                            onClick={() => setActiveTab(tab.tab)}
                            className={classNames(
                              tab.current
                                ? "border-black text-black"
                                : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                              "py-4 w-1/2 px-1 text-center border-b-2 font-medium text-sm cursor-pointer"
                            )}
                          >
                            {tab.name}
                          </a>
                        ))}
                    </nav>
                  </div>
                </div>
              </div>

              {renderActiveTab()}

            </form>
          )
          }
        </GenericSidebar>

      )}
    </>
  );
}

import {useState} from 'react';

export default function useCountChildren() {
  const [folders, setFolders] = useState([]);

  const countChildren = (parentId) => {
    const children = folders.filter((f) => f.parentId === parentId);
    const result = [];

    for (let i = 0; i < children.length; i++) {
      const child = children[i];

      countChildren(child.id);

      result.push(child.id);
    }

    return result;
  };

  return [countChildren, setFolders];
}

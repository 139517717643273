import React, { useState, Fragment } from "react";
import { format } from "date-fns";

import "./styles.scss";
import Properties from "./Tabs/properties";
import { useClassNames } from "../../../../hooks/useClassNames";
import GenericSidebar from "../../../../components/Modals/genericSidebar";

export default function Sidebar({ open, setOpen, soapSettings, setSoapSettings, allSoapSettings, allCustomerEnvironments, onSubmit }) {
  const [activeTab, setActiveTab] = useState("properties");
  const { classNames } = useClassNames();

  const handleOpen = () => {
    if (open)
      setActiveTab('properties')

    setOpen(!open)
  }

  const handleChange = ({ name, value }) => {
    setSoapSettings(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const getFileContentAsBase64String = file => {
    return new Promise(resolve => {
      let content = "";
      let reader = new FileReader();

      reader.onload = () => {
        content = reader.result;
        resolve(content);
      };

      reader.readAsDataURL(file);
    });
  };

  const handleFileUpload = async (file) => {
    const result = await getFileContentAsBase64String(file);

    setSoapSettings(prevState => ({
      ...prevState,
      'certificate': result.split(',')[1],
    }));
  };

  const renderActiveTab = () => {
    switch (activeTab) {
      case "properties":
        return <Properties soapSettings={soapSettings} allSoapSettings={allSoapSettings} allCustomerEnvironments={allCustomerEnvironments}
          setOpen={handleOpen} onChange={handleChange} onFileUpload={handleFileUpload} onSubmit={onSubmit} />;
      //   case "history":
      //     return <History document={document} setOpen={handleOpen} onSubmit={onSubmit} />;
      default:
        return <></>;
    }
  };

  return (
    <>
      {soapSettings && (
        <GenericSidebar
          open={open}
          setOpen={handleOpen}
          title={`Editing`}
          subTitle={
            soapSettings.updatedByName && soapSettings.updatedDate && (
              <p className="text-sm text-white">
                Last updated by{" "}
                <span className={"font-bold"}>
                  {soapSettings.updatedByName}{" "}
                </span>{" "}
                at{" "}
                <span className={"font-bold"}>
                  {format(
                    new Date(soapSettings.updatedDate),
                    "MM/dd/yyyy - HH:mm"
                  )}
                </span>
              </p>
            )
          }
        >
          <form className="h-full flex flex-col">
            <div className="flex-shrink-0">
              <div className="mt-5 border-b border-gray-200">
                <div className="px-6">
                  <nav
                    className="-mb-px flex space-x-6"
                    x-descriptions="Tab component"
                  >
                    {[
                      {
                        name: "Properties",
                        tab: "properties",
                        current: activeTab === "properties",
                      },
                    ]
                      .map((tab) => (
                        <a
                          key={tab.name}
                          onClick={() => setActiveTab(tab.tab)}
                          className={classNames(
                            tab.current
                              ? "border-black text-black"
                              : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                            "py-4 w-1/2 px-1 text-center border-b-2 font-medium text-sm cursor-pointer"
                          )}
                        >
                          {tab.name}
                        </a>
                      ))}
                  </nav>
                </div>
              </div>
            </div>

            {renderActiveTab()}

          </form>
        </GenericSidebar>

      )}
    </>
  );
}

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { deleteRole, getInvitesInRole, getUsersInRole, moveUsersToRole } from '../../../../api/roles';
import Loader from '../../../../components/Loader';
import MenuContextList from '../../../../components/MenuContextList';
import GenericModal from '../../../../components/Modals/genericModal';
import { useToastAction } from '../../../../hooks/useToastAction';

export default function RolesTable({ roles, loading, onEditEvent, onDeleteEvent }) {

    const loadActionUpdate = useToastAction()
    const [confirmDeleteRoleModalOpen, setConfirmDeleteRoleModalOpen] = useState(false)
    const [activeRow, setActiveRow] = useState()
    const [usersInRole, setUsersInRole] = useState([]);
    const [invitesInRole, setInvitesInRole] = useState([]);
    const [confirmDeleteButtonText, setConfirmDeleteButtonText] = useState("Delete role")
    const [moveToRoleId, setMoveToRoleId] = useState();

    const handleDelete = async (role) => {
        // Check if any users are connected to role, if so present dropdown so users can be moved to new role
        loadActionUpdate.execute(async () => {
            const usersInRoleCollection = await getUsersInRole(role.roleId);
            setUsersInRole(usersInRoleCollection || []);
            const invitesInRoleCollection = await getInvitesInRole(role.roleId);
            setInvitesInRole(invitesInRoleCollection || []);

            if (usersInRoleCollection.length > 0 || invitesInRoleCollection.length > 0)
                setConfirmDeleteButtonText("Move users and delete role");
            else {
                setConfirmDeleteButtonText("Delete role");
            }
        }, 'Failed fetch users connected to role')
        setActiveRow(role);
        setConfirmDeleteRoleModalOpen(true);
    }

    const deleteDonfirmed = async () => {

        if ( (usersInRole.length > 0 || invitesInRole.length > 0) && moveToRoleId === undefined) 
            return;
        
        loadActionUpdate.execute(async () => {
            if (usersInRole.length > 0 || invitesInRole.length > 0) {
                // Move users first
                await moveUsersToRole(activeRow.roleId, moveToRoleId);
            }
            await deleteRole(activeRow.roleId);
            setConfirmDeleteRoleModalOpen(false);
            setActiveRow(null);
            onDeleteEvent();
        }, 'Failed to delete role')
    }

    const handleMoveToRoleChange = async ({ name, value }) => {
        setMoveToRoleId(value);
    }

    const TableRow = ({ role }) => {
        return (
            <tr key={role.roleId}>
                <td className="px-6 py-4 text-sm text-gray-500">
                    {
                        !role.isReadOnly && (
                            <Link to={`/admin/team/role/${role.roleId}`} className="font-semibold text-indigo-600 hover:text-indigo-900 cursor-pointer">{role.roleName}</Link>
                        )
                    }
                    {
                        role.isReadOnly && (
                            <p className="font-semibold hover:text-gray-900 ">{role.roleName}</p>
                        )
                    }
                </td>
                <td className="px-6 py-4 text-sm text-gray-500 hidden sm:table-cell">
                    ({role.roleDescription})
                </td>
                <td className="px-6 py-4 text-right text-sm font-medium">
                    {
                        (
                            !role.isReadOnly && (
                                <MenuContextList element={role} actions={[
                                    {
                                        name: "Edit",
                                        href: `/admin/team/role/${role.roleId}`
                                    }, {
                                        name: "Delete",
                                        onClick: () => handleDelete(role)
                                    }
                                ]
                                } />
                            )
                        )
                    }

                </td>
            </tr>
        );
    }

    return (
        <>
            <div className="relative z-0">
                <div className="-my-2 sm:-mx-6 lg:-mx-8 overflow-x-auto">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow-sm border border-gray-200 sm:rounded-lg overflow-hidden">
                            <table className="min-w-full divide-y divide-gray-200">
                                <tbody className="bg-white divide-y divide-gray-20">
                                    {
                                        !loading && (roles.map((u, i) => {
                                            return <TableRow role={u} key={u.roleId} />
                                        }))
                                    }
                                    {
                                        loading && (<tr>
                                            <td colSpan="5">
                                                <div className="text-center py-6"><Loader /></div>
                                            </td>
                                        </tr>)
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>        
            <GenericModal
                title={`Do you want to delete this role ?`}
                setOpen={setConfirmDeleteRoleModalOpen}
                open={confirmDeleteRoleModalOpen}
                confirmButtonText={confirmDeleteButtonText}
                showCancelButton={true}
                onConfirm={e => {
                    deleteDonfirmed();
                }}
                onCancel={e => {
                    setConfirmDeleteRoleModalOpen(false);
                }}
            >
                <div className="flex flex-row justify-center py-6">
                    <div className="ml-4">
                        <div className="text-sm text-gray-600 flex items-center">
                            <div>
                                {
                                    usersInRole.length === 0 && invitesInRole.length === 0 ? (
                                        <div className="flex flex-col">
                                            <div>Role: <b> {activeRow?.roleName}</b></div>
                                        </div>
                                    ) : (
                                        <div>
                                            {
                                                usersInRole.length > 0 && (
                                                    <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                        Move {usersInRole.length} connected user(s) to new role
                                                    </label>
                                                )
                                            }
                                            {
                                                invitesInRole.length > 0 && (
                                                    <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                        Move {invitesInRole.length} invited user(s) to new role
                                                    </label>
                                                )
                                            }
                                            <div className="mt-1 col-span-3">
                                                <select name="role" id="roleMoveTo"
                                                    onChange={e => handleMoveToRoleChange(e.target)}
                                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
                                                    <option id="selectedRole" value=""></option>
                                                    {
                                                        roles
                                                            .filter((f) => f.roleId !== activeRow?.roleId)
                                                            .map(r => {
                                                                return <option value={r.roleId}>{r.roleName}</option>
                                                            })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </GenericModal>
        </>
    );
}

import axios from 'axios'
import {config} from '../utils/config'
import {apiErrorHandler} from './apiErrorHandler'

export function setAuthHeader(token) {
  axios.defaults.headers.common['Authorization'] = token ? 'Bearer ' + token : ''
}

export const BaseUrl = {
  Api: config.apiBaseUrl,
  Identity: config.identityBaseUrl,
  Centerpoint: config.centerpointBaseUrl
}

export const get = async (path, params = null, baseUrl = BaseUrl.Api) => {
  const axiosParams = params || {}
  try {
    const result = await axios({
      method: "get",
      url: `${baseUrl}/${path}`,
      ...axiosParams
    })
    return result.data
  } catch (err) {
    apiErrorHandler(err)
  }
}

export const put = async (path, data, params = null, baseUrl = BaseUrl.Api) => {
  const axiosParams = params || {}
  try {
    const result = await axios({
      method: "put",
      url: `${baseUrl}/${path}`,
      data,
      ...axiosParams
    })
    return result.data
  } catch (err) {
    apiErrorHandler(err)
  }
}

export const post = async (path, data, params = null, baseUrl = BaseUrl.Api) => {
  const axiosParams = params || {}
  try {
    const result = await axios({
      method: "post",
      url: `${baseUrl}/${path}`,
      data,
      ...axiosParams
    })
    return result.data
  } catch (err) {
    console.log(err)
    apiErrorHandler(err)
  }
}

export const destroy = async (path, params = null, baseUrl = BaseUrl.Api, data) => {
  const axiosParams = params || {}
  try {
    const result = await axios({
      method: "delete",
      url: `${baseUrl}/${path}`,
      data,
      ...axiosParams
    })
    return result.data
  } catch (err) {
    apiErrorHandler(err)
  }
}
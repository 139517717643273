import React, { useEffect, useState } from 'react';
import { useToastAction } from '../../../../hooks/useToastAction';
import { useHistory, useParams } from 'react-router-dom';
import { GetReleasePackage, SendNotification } from '../../../../api/version';
import DateTimeParser from '../../../../utils/DateTimeParser';
import { ContentWrapper, CoreButton, GenericModal, PageHeader } from '@metaforcelabs/metaforce-core';

const ReleasePackageField = ({ label, value, type }) => {
  return (
    <>
      <div class="px-4 py-6 max-w-5xl sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
        <dt class="text-sm font-medium leading-6 text-gray-900">{label}</dt>
        {(type === 'text' || type === undefined) && (
          <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            {value ? value : '-'}
          </dd>
        )}
      </div>
    </>
  );
};

const packageTypeDecode = (inputCode) => {
  if(inputCode === 0) return 'Centerpoint';
  else if(inputCode === 1) return 'Interact';
  return 'Package';
}

const DetailedChangelogElement = ({ app }) => {
  return (
    <>
      <div className="font-medium text-xl">
        <div className="flex mb-2">
          <div className="mr-2 mt-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3 8.25V18a2.25 2.25 0 002.25 2.25h13.5A2.25 2.25 0 0021 18V8.25m-18 0V6a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 6v2.25m-18 0h18M5.25 6h.008v.008H5.25V6zM7.5 6h.008v.008H7.5V6zm2.25 0h.008v.008H9.75V6z"
              />
            </svg>
          </div>
          <div>{app.name}</div>
        </div>
      </div>
      <div>{app.headline}</div>
      {app.version && (
        <>
          <div className="font-medium mt-3">Version</div>
          <div className="whitespace-pre-line">{app.version}</div>
        </>
      )}
      {app.image && (
        <>
          <div className="font-medium mt-3">Image</div>
          <div className="whitespace-pre-line">{app.image}</div>
        </>
      )}
      {app.dependencies && (
        <>
          <div className="font-medium mt-3">Dependencies</div>
          <div>{app.dependencies}</div>
        </>
      )}
      {app.subModules && (
        <>
          <div className="font-medium mt-3">Sub Modules</div>
          <div>
            {app.subModules.map((subModule) => {
              return (
                <div key={'subModule_' + subModule.name}>
                  {subModule.name} {subModule.version} ({subModule.image})
                </div>
              );
            })}
          </div>
        </>
      )}
      {app.changelogNotes && (
        <>
          <div className="font-medium mt-3">Details</div>
          <div className="whitespace-pre-line">{app.changelogNotes}</div>
        </>
      )}
    </>
  );
};

export default function ReleasePackageDetails(params) {
  let { id } = useParams();
  const history = useHistory();
  const [packageDetails, setPackageDetails] = useState(undefined);
  const [applicationsAffected, setApplicationsAffected] = useState('');
  const [applicationsAffectedChangelogData, setApplicationsAffectedChangelogData] = useState([]);
  const loadReleasePackageDetails = useToastAction();
  const [shareDetailsModalOpen, setShareDetailsModalOpen] = useState(false);
  const [customDetailsEmailRecipient, setCustomDetailsEmailRecipient] = useState('');
  const postSendNotificationAction = useToastAction();

  const handleOnSubmit = () => {
    postSendNotificationAction.execute(
      async () => {
        const request = {
          packageId: id,
          subject: 'Release Package Details for ' + packageTypeDecode(packageDetails.packageType) + ' ' + packageDetails.packageVersion,
          content: '',
          customRecipient: customDetailsEmailRecipient,
          attachReleasePackageInformation: true,
            attachDetailedChangelogs: true
        };
        const response = await SendNotification(request);
        if (!response.isSuccess && response.messages) {
          response.messages.forEach((message) => {
            console.error(message);
          });
        };
        setShareDetailsModalOpen(false);
      },
      'Failed to share the details',
      'E-mail with package details has been issued'
    );
  };


  useEffect(() => {
    loadReleasePackageDetails.execute(async () => {
      const response = await GetReleasePackage(id);
      let newListOfApplicationsAffected = '';
      let newListOfApplicationsAffectedChangelogData = [];
      Object.keys(response.changelog).forEach((appElement) => {
        if (response.changelog[appElement].included && response.changelog[appElement].isReleasing) {
          newListOfApplicationsAffected =
            response.changelog[appElement].name + ', ' + newListOfApplicationsAffected;
          newListOfApplicationsAffectedChangelogData.push(response.changelog[appElement]);
        }
      });
      setPackageDetails(response);
      setApplicationsAffected(newListOfApplicationsAffected);
      setApplicationsAffectedChangelogData(newListOfApplicationsAffectedChangelogData);
    }, 'Failed to load package');
  }, [id]);

  return (
    <>
      <ContentWrapper>
        <PageHeader 
          title="Release Package Details" 
          description={'Complete information set about this package'}
          optionalSideElement={
            <div className='grid grid-cols-2 gap-4'>
              <CoreButton 
                label="Back"
                onClick={() => history.push(params.location.state.previousPage)}
                type="secondary" 
              />
              <CoreButton
                label="Share by e-mail"
                onClick={
                  () => setShareDetailsModalOpen(true)
                }
              />
            </div>

          }
        />
        {packageDetails && (
          <div class="mt-6 border-t border-gray-100">
            <dl class="divide-y divide-gray-100">
              <ReleasePackageField
                label="Package Type"
                value={packageTypeDecode(packageDetails.packageType)}
              />
              <ReleasePackageField label="Version" value={packageDetails.packageVersion} />
              <ReleasePackageField
                label="Package Date"
                value={DateTimeParser.toLocaleDateString(packageDetails.packageDate)}
              />
              <ReleasePackageField label="General Information" value={packageDetails.packageGeneralInfo} />
              <ReleasePackageField
                label="Download Information"
                value={packageDetails.packageDownloadInfo}
              />
              <ReleasePackageField
                label="Dependencies"
                value={packageDetails.packageDependencies}
              />
              <ReleasePackageField label="Applications affected" value={applicationsAffected} />

              <div class="px-4 max-w-5xl sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt class="text-sm font-medium leading-6 text-gray-900 pt-5">Changelog</dt>
                <dd class="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  <ul class="divide-y divide-gray-100">
                    {applicationsAffectedChangelogData.length > 0 ? (
                      applicationsAffectedChangelogData.map((app) => {
                        return (
                          <li key={app.name} class="justify-between py-5 text-sm leading-6">
                            <DetailedChangelogElement app={app} />
                          </li>
                        );
                      })
                    ) : (
                      <li className="pt-5">-</li>
                    )}
                  </ul>
                </dd>
              </div>
            </dl>
          </div>
        )}
      </ContentWrapper>
      <GenericModal
          title={'Share Release Package Details'}
          setOpen={setShareDetailsModalOpen}
          open={shareDetailsModalOpen}
          confirmButtonText={'Confirm'}
          showCancelButton={true}
          onConfirm={() => {handleOnSubmit()}}
          onCancel={() => {setShareDetailsModalOpen(false)}}
        >
          <div className="justify-center content-center items-center place-content-center py-6">
              <div className="w-full mb-5">
                <div>
                  Input e-mail address for sharing details
                </div>
              </div>
              <div className="w-3/4 mx-auto">
                <input
                  id="search"
                  name="search"
                  className="block w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-brand-pink focus:border-brand-pink sm:text-sm"
                  placeholder=""
                  type="email"
                  onChange={(e) => {
                    setCustomDetailsEmailRecipient(e.target.value);
                  }}
                />
              </div>
          </div>
        </GenericModal>
    </>
  );
}

import React, { useEffect, useState } from 'react';
import { useToastAction } from '../../../../hooks/useToastAction';
import { ContentWrapper, PageHeader } from '@metaforcelabs/metaforce-core';
import { tailwindToast } from '../../../../components/Toast/tailwindToast';
import { getAllSmsProviders, getCustomerSmsProviderListViewModel, updateCustomerSmsProvider } from '../../../../api/integration';
import SmsGridComponent from './components/SmsGridComponent';
import SmsProviderForm from './components/SmsProviderForm';
import { SmsProviderTypes } from "../../../../utils/constants";
import { Select } from '../../../../components/Form/Select';

export default function SMSNotification() {
  const [customerProvider, setCustomerProvider] = useState();
  const [customerProviderList, setCustomerProviderList] = useState();
  const [providers, setProviders] = useState([]);

  const loadAction = useToastAction();
  const saveAction = useToastAction();

  const loadData = async () => {
    loadAction.execute(async () => {
      const [providersResult, providerListViewModelResult] = await Promise.all([
        getAllSmsProviders(),
        getCustomerSmsProviderListViewModel(),
      ]);
      setProviders(providersResult.map(x => ({ name: `${x.providerId} ( ${x.homePage} )`, value: x.providerId })));
      setCustomerProviderList(providerListViewModelResult);
      const currentSmsProvier = providerListViewModelResult.reduce((a, b) => {
        return new Date(a.updatedDate) > new Date(b.updatedDate) ? a : b;
      }, {});
      if (Object.keys(currentSmsProvier).length > 0) {
        setCustomerProvider(currentSmsProvier);
      } else {
        setCustomerProvider({
          providerId: SmsProviderTypes.elks,
          clientId: '',
          clientSecret: '',
        });
      }
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleProviderChange = (providerId) => {
    const found = customerProviderList.find(x => x.providerId === providerId);
    if (found) {
      setCustomerProvider(found);
    } else {
      setCustomerProvider({
        providerId: providerId,
        clientId: '',
        clientSecret: '',
      });
    }
  }

  const handleSave = async (values) => {
    saveAction.execute(async () => {
      await updateCustomerSmsProvider(values);
      tailwindToast.success('Save successful');
      await loadData();
    }, 'Save failed');
  };

  return (
    <ContentWrapper>
      <PageHeader
        title="SMS Notification service"
      />

      <div className="mt-12 space-y-6 sm:space-y-6">
        <SmsGridComponent label={"Provider"}>
          <Select
            id={'providerId'}
            name={'providerId'}
            options={providers}
            selectedValue={customerProvider?.providerId}
            onChange={handleProviderChange}
          />
        </SmsGridComponent>

        <SmsProviderForm
          customerProvider={customerProvider}
          onSave={handleSave}
        />
      </div>
    </ContentWrapper>
  );
}

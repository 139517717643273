import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  getTeamUser,
  getUserGroups,
  getUserRoles,
  getUserStatuses,
  updateTeamUser
} from '../../../../api/manageTeam';
import { tailwindToast } from '../../../../components/Toast/tailwindToast';
import { ContentWrapper, CoreButton, PageHeader } from '@metaforcelabs/metaforce-core';

const actionResultType = {
  success: 0,
  failure: 1,
  warning: 2
};

export default function User() {
  const returnPath = '/admin/team/manage';
  const history = useHistory();
  const { userId } = useParams();
  const [userRoles, setUserRoles] = useState([]);
  const [userGroups, setUserGroups] = useState([]);
  const [user, setUser] = useState({});
  const [userStatuses, setUserStatuses] = useState([]);
  const loadData = async () => {
    try {
      const user = await getTeamUser(userId);
      console.log(user);
      setUser(user);
      const roles = await getUserRoles();
      setUserRoles(roles);
      const groups = await getUserGroups();
      setUserGroups(groups);
      const statuses = await getUserStatuses();
      setUserStatuses(statuses);
    } catch (error) {
      tailwindToast.error('Failed to laod user');
    }
  };
  useEffect(() => {
    loadData();
  }, [userId]);
  const handleChange = ({ name, value }) => {
    setUser((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleItemChange = ({ id, name, value, checked }) => {
    setUser((prev) => {
      let groupIds = prev.groupIds;
      const idx = groupIds.findIndex((x) => x === id);
      console.log(groupIds[idx]);
      console.log(idx, checked);
      console.log(id);
      if (checked) groupIds.push(id);
      else {
        console.log(idx, 'To be removed');
        console.log(groupIds[idx]);
        groupIds.splice(idx, 1);
      }

      console.log(groupIds);

      return { ...prev, groupIds: groupIds };
    });
  };

  const saveUser = async () => {
    try {
      const resultSave = await updateTeamUser(userId, {
        roleId: user.roleId,
        statusId: user.statusId
      });

      if (resultSave.type === actionResultType.success) {
        history.push(returnPath);
      } else {
        tailwindToast.error(
          'License usage limit reached. Please change your license to invite new user.'
        );
      }
    } catch (error) {
      if (error.response) {
        tailwindToast.error(error.response.data);
      } else {
        tailwindToast.error('Failed to edit user');
      }
    }
  };
  return (
    <ContentWrapper>
      <div className="max-w-xl mx-auto">
        <PageHeader title="Edit User" />
        <div className="grid grid-cols-1 gap-y-4">
          {user && (
            <>
              <div>
                <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Name
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="name"
                    value={user.name}
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    disabled
                  />
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Role
                </label>
                <div className="mt-2">
                  <select
                    name="roleId"
                    value={user.roleId}
                    onChange={(e) => handleChange(e.target)}
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  >
                    {userRoles.map((r) => {
                      return <option value={r.roleId}>{r.roleName}</option>;
                    })}
                  </select>
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Status
                </label>
                <div className="mt-2">
                  <select
                    name="statusId"
                    value={user.statusId}
                    onChange={(e) => handleChange(e.target)}
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  >
                    {userStatuses.map((s) => {
                      return <option value={s.id}>{s.name}</option>;
                    })}
                  </select>
                </div>
              </div>
              <div className="grid grid-cols-2 pt-5 gap-x-4 sm:gap-x-8">
                <CoreButton 
                  type="secondary" 
                  htmlType="button" 
                  label={'Cancel'} onClick={(e) => {
                      history.push(returnPath);
                    }} 
                />
                <CoreButton 
                  htmlType="button" 
                  label={'Save'} 
                  onClick={saveUser} 
                />
              </div>
            </>
          )}
        </div>
      </div>
    </ContentWrapper>
  );
}

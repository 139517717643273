import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { getRoles } from '../../../../api/roles';
import { useToastAction } from '../../../../hooks/useToastAction';
import RolesTable from './rolesTable';
import { ContentWrapper, CoreButton, PageHeader } from '@metaforcelabs/metaforce-core';

export default function Roles() {
    const [isLoaded, setIsLoaded] = useState(false);
    const [customerRoles, setCustomerRoles] = useState([]);
    const loadAction = useToastAction()
    const history = useHistory();

    const loadDefaultData = async () => {
        setIsLoaded(false);
        loadAction.execute(async () => {
            const customerRolesData = await getRoles();
            setCustomerRoles(customerRolesData || []);
            console.log(customerRolesData);
            setIsLoaded(true);
        }, 'Failed to load')
    }

    useEffect(() => {
        if (isLoaded) {
            return;
        }
        loadDefaultData();
    }, []);


    return (

      <ContentWrapper> 
        <div>
            <div className="flex flex-row justify-between items-end">
                <PageHeader 
                  title="Roles" 
                  optionalSideElement = {
                    <CoreButton
                        label={<div>New role</div>}
                        onClick={() => history.push(`/admin/team/role`)}
                        htmlType="button"
                    />
                  }
                />
                <div>
                
                </div>
            </div>                    

        </div>
        <div className="mt-4">
            <RolesTable
                roles={customerRoles}
                loading={loadAction.isExecuting}
                onEditEvent={() => loadDefaultData()}
                onDeleteEvent={() => loadDefaultData()}
            />
        </div>
      </ContentWrapper> 
    )
}

import React from 'react'
import { scenes } from './scenes';
import { useReactOidc } from '@axa-fr/react-oidc-context';
import { setAuthHeader } from '../../api/api';
import { config } from '../../utils/config';
export default function SceneLayout({ nav, subNav, children }) {
    const { oidcUser} = useReactOidc();
    setAuthHeader(oidcUser.access_token);
    const currentScene = createSceneBg();
    function createSceneBg() {
        const month = new Date().getMonth();
        const monthlyScene = scenes[month];

        return monthlyScene;
    }
    return (
        <div className="min-h-screen bg-white flex">
            <div className="flex-1 flex flex-col justify-center py-6 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                <div className="mx-auto w-full max-w-sm lg:w-96">
                    <div>
                        <img className="max-w-xs w-7/12 sm:w-9/12 mb-8 sm:mb-12"
                            src={config.logoMain}
                            alt="CenterPoint Logo" />
                    </div>
                    <div >
                        {children}
                    </div>

                </div>
            </div>
            <div id="scene-img-container" className="hidden md:block relative w-0 flex-1">
                <img className="absolute inset-0 h-full w-full object-cover"
                    src={currentScene}
                    alt="" />
            </div>
        </div>
    )
}



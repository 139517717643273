/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationIcon } from '@heroicons/react/outline'

export const Changes = ({ changes }) => {
  
  return (
    <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle">
      <div className="bg-white px-4 pt-5 pb-4 sm:p-8 sm:pb-4">
        <div className="">
          <div className="mt-3 text-center sm:mt-0 sm:mx-2 sm:text-left">
            <Dialog.Title as="h3" className="text-lg leading-6 pb-2 font-medium text-gray-900">
              Pending changes in draft
            </Dialog.Title>
            <div className="mt-2">

              <table className="min-w-full divide-y divide-gray-200 border border-gray-200 sm:rounded-lg">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Property
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Published
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Draft
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {changes.map((change, changeIdx) => (
                    <tr key={change.id} className={changeIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{change.type}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{change.oldValue}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{change.newValue}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>


  )
}

import React from 'react'
import { Badge, badgeTypes } from '../../../../components/Badge'
import { invoiceStates } from '../../../../utils/constants'

export default function InvoiceStatusBadge({ status, dueDate }) {
    let today = new Date();
    dueDate = new Date(dueDate);
    today.setHours(0,0,0,0);
    dueDate.setHours(0,0,0,0);
    
    return (<>
        {
            status === invoiceStates.open && dueDate > today  && (<Badge text={status} type={badgeTypes.info} />)
        }
        {
            status === invoiceStates.open && dueDate <= today && (<Badge text={status} type={badgeTypes.error} />)
        }
        {
            status === invoiceStates.draft && today <= dueDate && (<Badge text={status} type={badgeTypes.info} />)
        }
        {
            status === invoiceStates.paid && (<Badge text={status} type={badgeTypes.success} />)
        }
        {
            status === invoiceStates.uncollectible && (<Badge text={status} type={badgeTypes.warn} />)
        }
        {
            status === invoiceStates.void && (<Badge text={status} type={badgeTypes.error} />)
        }
    </>)
}
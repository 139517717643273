import { useToastAction } from '../../../../hooks/useToastAction';
import { useEffect, useState } from 'react';
import {
  getRetentionPoliciesApi,
  postRetentionPoliciesApi,
  updateRetentionPoliciesApi
} from '../../../../api/retentionPolicies';
import Loader from '../../../../components/Loader';
import { useClassNames } from '../../../../hooks/useClassNames';
import MenuContextList from '../../../../components/MenuContextList';
import { PlusIcon } from '@heroicons/react/outline';
import Sidebar from './Sidebar';
import DateTimeParser from '../../../../utils/DateTimeParser';
import { ContentWrapper, CoreButton, PageHeader } from '@metaforcelabs/metaforce-core';

const initialSelectedItemValues = {
  companyId: '',
  createdDate: '',
  id: '',
  lifetimeDays: 1,
  name: '',
  updatedDate: '',
  isDisabled: false
};

const RetentionPolicies = () => {
  const retentionPoliciesAction = useToastAction();
  const saveRetentionPoliciesAction = useToastAction();
  const updateRetentionPoliciesAction = useToastAction();
  const { classNames } = useClassNames();

  const [retentionPolicies, setRetentionPolicies] = useState([]);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [selectedItem, setSelectedItem] = useState(initialSelectedItemValues);
  const [isEditMode, setIsEditMode] = useState(false);

  const fetchRetentionPolicies = (id, updateState) => {
    retentionPoliciesAction.execute(async () => {
      const retentionPoliciesResult = await getRetentionPoliciesApi();

      setRetentionPolicies(retentionPoliciesResult);
    }, 'Failed to load mappings');
  };

  const handleOnListItemClick = () => { };

  const handleEdit = (element) => {
    setSelectedItem(element);
    setIsEditMode(true);
    setOpenSidebar(true);
  };

  const handleAddNew = () => {
    setOpenSidebar(true);
  };

  const handleOnSave = (values) => {
    if (isEditMode) {
      updateRetentionPoliciesAction.execute(async () => {
        await updateRetentionPoliciesApi({
          ...selectedItem,
          ...values
        });

        fetchRetentionPolicies();

        setOpenSidebar(false);
        setIsEditMode(false);
        setSelectedItem(initialSelectedItemValues);
      });
    } else {
      saveRetentionPoliciesAction.execute(async () => {
        await postRetentionPoliciesApi(values);

        fetchRetentionPolicies();

        setOpenSidebar(false);
        setIsEditMode(false);
        setSelectedItem(initialSelectedItemValues);
      });
    }
  };

  const handleToggleOpen = (value) => {
    setIsEditMode(false);
    setOpenSidebar(value);
    setSelectedItem(initialSelectedItemValues);
  };

  const calculateLifetimeDays = (value) => {
    if (value < 365) {
      return value > 1 ? `${value} days` : `${value} day`;
    } else {
      return value > 365 ? `${Math.floor(value / 365)} years` : `${value / 365} year`;
    }
  };

  useEffect(() => {
    fetchRetentionPolicies();
  }, []);

  return (
    <ContentWrapper>
      <PageHeader title="Retention policies" optionalSideElement={
        <CoreButton
          htmlType="button"
          label={
            <div className="flex">
              <PlusIcon className="mr-2 h-4 w-5" aria-hidden="true" />
              <div className="mx-auto">Add new policy</div>
            </div>
          }
          onClick={handleAddNew}
        />
      } />

      <div className="py-8 overflow-x-auto align-middle inline-block min-w-full pb-10">
        <div className="relative shadow-sm border border-gray-200 sm:rounded-lg">
          {saveRetentionPoliciesAction.isExecuting && (
            <div className="absolute flex justify-center items-center h-full w-full text-center bg-opacity-30 bg-gray-200">
              <Loader />
            </div>
          )}
          <table className="table-auto min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Name
                </th>

                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Lifetime
                </th>

                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Disabled
                </th>

                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Created
                </th>

                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Last updated
                </th>
              </tr>
            </thead>

            <tbody className="bg-white divide-y divide-gray-200">
              {retentionPoliciesAction.isExecuting ? (
                <tr>
                  <td colSpan="5">
                    <div className="text-center py-6">
                      <Loader />
                    </div>
                  </td>
                </tr>
              ) : (
                retentionPolicies
                  .sort((a, b) => a.order - b.order)
                  .map((policy) => (
                    <tr
                      key={policy.id}
                      className={classNames(
                        'hover:bg-gray-100 cursor-pointer',
                        selectedItem.name === policy.name && 'bg-gray-100'
                      )}
                      onClick={() => handleOnListItemClick(policy)}
                    >
                      <td className="px-6 py-4 w-auto text-sm text-gray-500">{policy.name}</td>

                      <td className="px-6 py-4 text-sm text-gray-500">
                        {calculateLifetimeDays(policy.lifetimeDays)}
                      </td>

                      <td className="px-6 py-4 text-sm text-gray-500">
                        {policy.isDisabled ? 'Yes' : 'No'}
                      </td>

                      <td className="px-6 py-4 text-sm text-gray-500">
                        {DateTimeParser.toLocaleDateTimeString(policy.createdDate)}
                      </td>

                      <td className="px-6 py-4 text-sm text-gray-500">
                        {DateTimeParser.toLocaleDateTimeString(policy.updatedDate)}
                      </td>

                      <td className="px-6 py-4 w-2 text-sm text-gray-500">
                        <MenuContextList
                          actions={[
                            {
                              name: 'Edit',
                              onClick: () => {
                                handleEdit(policy);
                              }
                            }
                          ]}
                        />
                      </td>
                    </tr>
                  ))
              )}
            </tbody>
          </table>
        </div>
      </div>

      <Sidebar
        policy={selectedItem}
        open={openSidebar}
        onSave={handleOnSave}
        toggleOpen={handleToggleOpen}
      />
    </ContentWrapper>
  );
};

export default RetentionPolicies;
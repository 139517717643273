import React, {useContext, useState} from 'react'
import {ManageTeamContext} from '../../../../contexts';
import { CoreButton } from '@metaforcelabs/metaforce-core';

export default function FilterPanel({onSearchUsers}) {
  const defaultFilter = {email: '', role: '', name: ''};
  const [searchFilter, setSearchFilter] = useState(defaultFilter);
  const manageTeamContext = useContext(ManageTeamContext);
  const searchUsers = () => {
    onSearchUsers(searchFilter);
  }
  const handleChange = ({name, value}) => {
    setSearchFilter(prev => {
      const newObj = {...prev, [name]: value};
      console.log(newObj);
      return newObj;
    });
  }
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchUsers();
    }
  }
  return (
    <div className="grid lg:grid-cols-12 gap-4 w-full my-10">
      <div className="col-span-3 grid grid-cols-10 mx-5">
        <label className="block text-sm font-medium text-gray-700 col-span-3 mr-5 whitespace-nowrap sm:mt-px pt-2">
          E-Mail
        </label>
        <div className="mt-1 col-span-7">
          <input type="text" name="email" id="email"
                 onChange={e => handleChange(e.target)}
                 onKeyDown={handleKeyDown}
                 value={searchFilter.email}
                 className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"/>
        </div>
      </div>
      <div className="col-span-3 grid grid-cols-10 mx-5">
        <label className="block text-sm font-medium text-gray-700 col-span-3 lg:mx-5 sm:mt-px pt-2">
          Name
        </label>
        <div className="mt-1 col-span-7">
          <input type="text" name="name" id="name"
                 onChange={e => handleChange(e.target)}
                 onKeyDown={handleKeyDown}
                 value={searchFilter.name}
                 className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"/>
        </div>
      </div>
      <div className="col-span-3 grid grid-cols-10 mx-5">
        <label className="block text-sm font-medium text-gray-700 col-span-3 lg:mx-5 sm:mt-px pt-2">
          Role
        </label>
        <div className="mt-1 col-span-7">
          <select name="role" id="role"
                  onChange={e => handleChange(e.target)}
                  value={searchFilter.role}
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
            <option value=""></option>
            {
              manageTeamContext?.roles.map(r => {
                return <option key={r.roleId} value={r.roleId}>{r.roleName}</option>
              })
            }
          </select>
        </div>
      </div>
      <div></div>
      <div className="my-1 ml-10 mr-5 sm:mx-0 col-span-2 flex flex-col-reverse">
        <CoreButton
          label={<div>Search</div>}
          htmlType="button"
          onClick={e => {
            searchUsers();
          }}
        />
      </div>
    </div>
  );
}

import { toast } from "react-toastify";

export const apiErrorHandler = (axiosError) => {
  if(!axiosError.response) {
    throw axiosError;  
  }
  const { status, data } = axiosError.response
  if (status === 400) {
    if (data.Message === 'Unable to save record. Record has been modified by another user.') {
      toast.error(data.Message);
      axiosError.isHandled = true;
    }
  }

  throw axiosError
}
import { CheckCircleIcon, ExclamationCircleIcon, XCircleIcon } from '@heroicons/react/outline'
import React from 'react'
import { toastTypes } from './tailwindToast';

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}
function getTypeColor(type) {
    switch (type) {
        case toastTypes.success:
            return "text-green-500";
        case toastTypes.error:
            return "text-red-400";
        default: return "text-blue-400";
    }

}
export default function ToastContent({ type, message }) {
    const ToastIcon = ({ ...rest }) => {
        switch (type) {
            case toastTypes.success: return (<CheckCircleIcon {...rest} />);
            case toastTypes.error: return (<XCircleIcon {...rest} />);
            default: return (<ExclamationCircleIcon {...rest} />);
        }
    }
    return (
        <div className="w-full">
            <div className="">
                <div >
                    <ToastIcon className={
                        classNames(
                            getTypeColor(type),
                            "w-6 h-6 mr-2 inline")
                    } />
                    <span className="inline text-sm font-medium text-gray-900">
                        {
                            (!type || type === toastTypes.info) && ('Info')
                        }
                        {type && type === toastTypes.success && ('Success')}
                        {type && type === toastTypes.error && ('Error')}
                    </span>
                </div>
                <div className=" ml-8 text-sm text-gray-500">{message}</div>
            </div>

        </div>
    )
}

import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import {
  getCustomerWhiteLabel,
  getWhiteLabelDefault,
  updateCustomerWhiteLabel
} from '../../../../api/customer';
import ColorPicker from '../../../../components/ColorPicker';
import { useToastAction } from '../../../../hooks/useToastAction';
import * as yup from 'yup';
import CheckboxField from '../../../../components/CheckboxField';
import { useClassNames } from '../../../../hooks/useClassNames';
import FieldMaker from './components/FieldMaker';
import { ContentWrapper, CoreButton, PageHeader } from '@metaforcelabs/metaforce-core';

const settings = [
  {
    text: 'Colors',
    groups: [
      {
        className: 'grid grid-cols-1 gap-4',
        fields: [
          {
            name: 'bgColor',
            text: 'Background Color',
            type: 'ColorPicker',
            canReset: true
          },
          {
            name: 'headerBgColor',
            text: 'Header Background Color',
            type: 'ColorPicker',
            canReset: true
          },
          {
            name: 'cardBgColor',
            text: 'Card Background Color',
            type: 'ColorPicker',
            canReset: true
          },
          {
            name: 'textPrimaryColor',
            text: 'Text Primary Color',
            type: 'ColorPicker',
            canReset: true
          },
          {
            name: 'textSecondaryColor',
            text: 'Text Secondary Color',
            type: 'ColorPicker',
            canReset: true
          }
        ]
      }
    ]
  },
  {
    text: 'Pictures',
    groups: [
      {
        className: 'grid grid-cols-1',
        fields: [
          {
            name: 'logoUrl',
            text: 'Logo URL',
            type: 'Text',
            canReset: true
          }
        ]
      },
      {
        className: 'grid grid-cols-4 gap-y-6 gap-x-4',
        enabledWhen: 'useCustomLogin',
        fields: [
          {
            name: 'january',
            text: 'January',
            type: 'PictureElement'
          },
          {
            name: 'february',
            text: 'February',
            type: 'PictureElement'
          },
          {
            name: 'march',
            text: 'March',
            type: 'PictureElement'
          },
          {
            name: 'april',
            text: 'April',
            type: 'PictureElement'
          },
          {
            name: 'may',
            text: 'May',
            type: 'PictureElement'
          },
          {
            name: 'june',
            text: 'June',
            type: 'PictureElement'
          },
          {
            name: 'july',
            text: 'July',
            type: 'PictureElement'
          },
          {
            name: 'august',
            text: 'August',
            type: 'PictureElement'
          },
          {
            name: 'september',
            text: 'September',
            type: 'PictureElement'
          },
          {
            name: 'october',
            text: 'October',
            type: 'PictureElement'
          },
          {
            name: 'november',
            text: 'November',
            type: 'PictureElement'
          },
          {
            name: 'december',
            text: 'December',
            type: 'PictureElement'
          }
        ]
      }
    ]
  },
  {
    text: 'Login',
    groups: [
      {
        fields: [
          {
            name: 'useCustomLogin',
            text: 'Use Custom Login',
            type: 'CheckboxField'
          }
        ]
      },
      {
        enabledWhen: 'useCustomLogin',
        fields: [
          {
            name: 'loginUrl',
            text: 'Custom Login URL',
            type: 'Text',
            fieldClass: 'mb-2'
          },
        ]
      }
    ]
  }
];

export default function Branding() {
  const hexColorPattern = /^#([0-9a-f]{3}){1,2}$/i;
  const [customerBranding, setCustomerBranding] = useState();
  const [defaultValues, setDefaultValues] = useState();
  const loadAction = useToastAction();
  const saveAction = useToastAction();
  const { classNames } = useClassNames();

  const formValidationSchema = yup.object().shape({
    bgColor: yup.string().trim().matches(hexColorPattern, 'Invalid HEX color').required('Required'),
    headerBgColor: yup
      .string()
      .trim()
      .matches(hexColorPattern, 'Invalid HEX color')
      .required('Required'),
    cardBgColor: yup
      .string()
      .trim()
      .matches(hexColorPattern, 'Invalid HEX color')
      .required('Required'),
    textPrimaryColor: yup
      .string()
      .trim()
      .matches(hexColorPattern, 'Invalid HEX color')
      .required('Required'),
    textSecondaryColor: yup
      .string()
      .trim()
      .matches(hexColorPattern, 'Invalid HEX color')
      .required('Required'),
    loginUrl: yup
      .string('Text only')
      .trim()
      .when('useCustomLogin', {
        is: true,
        then: () => yup.string('Text only').min(1).required('Required'),
        otherwise: () => yup.string('Text only')
      })
  });

  const loadData = () => {
    loadAction.execute(async () => {
      const customerWhiteLabelSettings = await getCustomerWhiteLabel();
      const defaultData = await getWhiteLabelDefault();
      const mappedToFormValues = {};

      for (const prop in customerWhiteLabelSettings) {
        console.log('customerWhiteLabelSettings', customerWhiteLabelSettings[prop]);
        if (customerWhiteLabelSettings[prop] !== null && customerWhiteLabelSettings[prop] !== undefined) {
          mappedToFormValues[prop] = customerWhiteLabelSettings[prop];
        } else {
          mappedToFormValues[prop] = '';
        }
      }

      setCustomerBranding(mappedToFormValues);
      setDefaultValues(defaultData);
    }, 'Failed to load');
  };

  const handleSave = (values) => {
    saveAction.execute(
      async () => {
        await updateCustomerWhiteLabel(values);
      },
      'Save failed',
      'Brand Settings updated'
    );
  };

  const handleOnSubmit = async (values, { setSubmitting }) => {
    await handleSave(values);
    setSubmitting(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    !loadAction.isExecuting && (
      <>
        <ContentWrapper> 
          {customerBranding && (
            <Formik
              initialValues={customerBranding}
              validationSchema={formValidationSchema}
              enableReinitialize={true}
              onSubmit={handleOnSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                isSubmitting,
                resetForm,
                setFieldValue
              }) => (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit(e);
                  }}
                  onReset={resetForm}
                >
                  <div>
                    <PageHeader title="Branding Settings" />

                    <div className="space-y-8 divide-y divide-gray-200 pt-6">
                      {settings.map((setting, index) => (
                        <div
                          className={classNames(
                            'md:grid md:grid-cols-5 md:gap-6',
                            index > 0 && 'pt-8'
                          )}
                        >
                          <div className="md:col-span-1">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">
                              {setting.text}
                            </h3>
                          </div>

                          <div className="mt-5 space-y-6 md:col-span-4 md:mt-0">
                            {setting.groups
                              .filter((group) =>
                                group.enabledWhen ? values[group.enabledWhen] : true
                              )
                              .map((group) => (
                                <div className={group.className}>
                                  {group.fields.map((field) => (
                                    <>
                                      <FieldMaker
                                        type={field.type}
                                        text={field.text}
                                        name={field.name}
                                        fieldClass={field.fieldClass}
                                        canReset={field.canReset}
                                        values={values}
                                        defaultValues={defaultValues}
                                        errors={errors}
                                        touched={touched}
                                        handleChange={handleChange}
                                        setFieldValue={setFieldValue}
                                        isSubmitting={isSubmitting}
                                      />
                                    </>
                                  ))}
                                </div>
                              ))}
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className="pt-10 flex flex-row-reverse">
                        <CoreButton label={<div>Save</div>} htmlType='submit' disabled={isSubmitting} htmlClassNames='w-full sm:w-0' />
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          )}
        </ContentWrapper>
      </>
    )
  );
}

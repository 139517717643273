import { useEffect, useState } from "react";
import { useCenterPointMyUser } from "./useCenterPointMyUser";

export const useHasCompany = (myUser) => {
    const [hasCompany, setHasCompany] = useState(false);
    
    useEffect(() => {
        setHasCompany(myUser && myUser.customerIds.length > 0);

    }, [myUser])


    return hasCompany;
}
import { get, BaseUrl, put, post } from './api';

export const getTeamUsers = async () => {
    return await get(`api/ManageTeam/Users`, null,
        BaseUrl.Centerpoint);
}
export const getTeamPendingInvites = async () => {
    return await get(`api/ManageTeam/PendingInvites`, null,
        BaseUrl.Centerpoint);
}
export const getTeamPendingAndAcceptedInvites = async (daysBack) => {
    return await get(`api/ManageTeam/PendingAndAcceptedInvites/${daysBack}`, null,
        BaseUrl.Centerpoint);
}
export const getUserRoles = async () => {
    return await get(`api/ManageTeam/UserRoles`, null,
        BaseUrl.Centerpoint);
}
export const getUserGroups = async () => {
    return await get(`api/ManageTeam/UserGroups`, null,
        BaseUrl.Centerpoint);
}
export const getUserStatuses = async () => {
    return await get(`api/ManageTeam/UserStatuses`, null,
        BaseUrl.Centerpoint);
}
export const getTeamUser = async (userId) => {
    return await get(`api/ManageTeam/Users/${userId}`, null,
        BaseUrl.Centerpoint);
}
export const updateTeamUser = async (userId, data) => {
    return await put(`api/ManageTeam/Users/${userId}`, data, null,
        BaseUrl.Centerpoint);
}
export const sendUserInvite = async (request) => {
    return await post(`api/ManageTeam/InviteUser`, request, null,
        BaseUrl.Centerpoint);
}
export const removePendingInvites = async (request) => {
    return await post(`api/ManageTeam/PendingInvites/RemoveMany`, request, null,
        BaseUrl.Centerpoint);
}



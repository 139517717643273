/* This example requires Tailwind CSS v2.0+ */
import React, {useContext, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {OfficeBuildingIcon} from '@heroicons/react/outline';

import {useReactOidc} from '@axa-fr/react-oidc-context';

import './styles.scss';
import {DefaultLayoutContext} from '../../contexts';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Dashboard() {
  const { oidcUser, logout } = useReactOidc();
  const history = useHistory();
  const defaultLayoutContext = useContext(DefaultLayoutContext);
  const createCompanyTile = {
    productCode: '',
    title: 'Register a company',
    route: '/company/create',
    icon: OfficeBuildingIcon,
    iconForeground: 'text-rose-700',
    iconBackground: 'bg-rose-50',
    description:
      'Start off by registering a company from where you would like to run your Customer Communication',
    hasLicense: true,
    internalLink: true,
    requireAdminUser: true
  };

  useEffect(() => {
    if (
      defaultLayoutContext?.profileOverview.orgNumber &&
      defaultLayoutContext.customerDetails.activeProducts.length === 0 &&
      !defaultLayoutContext.profileOverview.isNotActiveCustomer
    ) {
      history.push('/plans');
    } else {
      const availableProducts = defaultLayoutContext?.products.filter(
        (x) => x.hasLicense && x.featureEnabled
      );
      // Redirect to application if user only has access to 1 product
      if (availableProducts.length === 1) {
        if (availableProducts.filter((x) => !x.internalLink).length === 1)
          window.location.replace(availableProducts[0].href);
        else history.push(availableProducts[0].route);
      }
    }
  }, []);

  // const auth = useAuth();

  const DashboardTile = ({ tileProps }) => {
    return (
      <div
        key={tileProps.title}
        className={
          'rounded-lg border border-gray-200 relative bg-white p-6 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105 hover:shadow-sm'
        }
      >
        <div>
          <span
            className={classNames(
              tileProps.iconBackground,
              tileProps.iconForeground,
              'rounded-lg inline-flex p-3 ring-4 ring-white'
            )}
          >
            <tileProps.icon className="h-10 w-10" aria-hidden="true" />
          </span>
        </div>
        <div className="mt-8">
          <h3 className="text-lg font-medium">
            <a
              href={tileProps.href}
              onClick={() => history.push(tileProps.route)}
              target="_blank"
              rel="noreferrer"
              className="focus:outline-none cursor-pointer"
            >
              {/* Extend touch target to entire panel */}
              <span className="absolute inset-0" aria-hidden="true" />
              {tileProps.title}
            </a>
          </h3>
          <p className="mt-2 text-sm text-gray-500">{tileProps.description}</p>
        </div>
        {!tileProps.internalLink && (
          <span
            className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
            aria-hidden="true"
          >
            <svg
              className="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
            </svg>
          </span>
        )}
      </div>
    );
  };

  return (
    <>
      <header>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex-1 min-w-0 mb-10">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Hello {oidcUser.profile.name}
            </h3>
            <h2 className="mb-10 text-2xl font-bold leading-7 text-gray-900 sm:text-4xl">
              Centerpoint - Leading the paperless revolution
            </h2>
          </div>
        </div>
      </header>
      <main>
        <div className="mt-5 pt-10 max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="mt-5 sm:grid sm:grid-cols-4 gap-5">
            {!defaultLayoutContext.hasCompany && (
              <DashboardTile key={createCompanyTile.title} tileProps={createCompanyTile} />
            )}
            {defaultLayoutContext.hasCompany &&
              defaultLayoutContext?.products
                .filter((m) => m.hasLicense)
                .filter((p) => p.featureEnabled)
                .map((action) => <DashboardTile key={action.title} tileProps={action} />)}
          </div>
        </div>
      </main>
    </>
  );
}

import React, { useContext } from 'react';
import { Field, useFormik, useFormikContext } from 'formik';
import TextField from '../../../../../components/TextField';
import { RadioGroup } from '@headlessui/react';
import { CoreButton, useClassNames, useToastAction } from '@metaforcelabs/metaforce-core';
import { InformationCircleIcon, PencilIcon, TrashIcon } from '@heroicons/react/outline';
import logoImages, { ScriveLogo } from '../../../../../assets/images/logos/logos';
import { CheckCircleIcon as CheckCircleIconOutline } from '@heroicons/react/outline';
import { generateAccessKeys, testCustomerDigitalSigningProvider } from '../../../../../api/integration';
import { DigitalSigningIntegrationContext } from '../../../../../contexts';
import { CheckCircleIcon } from '@heroicons/react/solid';
import SigningAuthorizationMethods from './SigningAuthorizationMethods';

const ProviderTypes = {
  verified: 'verified',
  signicat: 'signicat',
  scrive: 'scrive'
};

const Provider = ({ customerProvider, setCustomerProvider, digitalSigningProviders, formValues }) => {
  const { createRequestFromValues } = useContext(DigitalSigningIntegrationContext);
  const testAction = useToastAction();
  const generateAccessKeysAction = useToastAction();
  const formik = useFormikContext();
  const { classNames } = useClassNames();

  const handleTestGenerateAccessKeys = () => {
    handleResetTestValue();
    generateAccessKeysAction.execute(async () => {
      const request = createRequestFromValues(formValues)
      const res = await generateAccessKeys(request);
      formik.setFieldValue('testSuccessful', true);
      setCustomerProvider(res);
      formik.setFieldValue('accessKeys', res.customCredentials.accessKeys);
    }, 'Access keys generation failed', 'Access keys generated');
  }

  const handleDeleteAcessKeys = () => {
    handleResetTestValue();
    let newCustomerProvider = {
      ...customerProvider,
      customCredentials: {
        ...customerProvider.customCredentials,
        accessKeys: undefined
      }
    }
    setCustomerProvider(newCustomerProvider);
    formik.setFieldValue('accessKeys', undefined);
    formik.setFieldValue('clientId', '');
    formik.setFieldValue('clientSecret', '');
  }

  const handleTestCredentials = () => {
    handleResetTestValue();
    testAction.execute(async () => {
      const request = createRequestFromValues(formValues)
      const res = await testCustomerDigitalSigningProvider(request);
      formik.setFieldValue('testSuccessful', true);
    }, 'Test failed');
  }

  const handleResetTestValue = () => {
    formik.setFieldValue('testSuccessful', false);
  }

  return (
    <div className="space-y-6 sm:space-y-5">
      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-45 sm:pt-5 sm:border-b border-b-gray-200 sm:pb-6">
        <label htmlFor="" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
          Provider
        </label>

        <div className="mt-1 sm:col-span-2 sm:mt-0">
          <Field name="provider">
            {({ field }) => (
              <ProviderSelect name={field.name} label={''} value={field.value} onChange={e => {
                handleResetTestValue();
                field.onChange(e)
              }} providerList={digitalSigningProviders} />
            )}
          </Field>
        </div>
      </div>

      {
        formValues.provider == ProviderTypes.signicat && (
          <div className="grid grid-cols-12">
            <div className="col-span-12 sm:col-span-4">
              <label className="sm:pt-2 text-sm font-medium text-gray-700 sm:flex items-center">
                Default Notification Email
              </label>
            </div>

            <div className="col-span-12 sm:col-span-8">
              <Field name="defaultNotificationEmail">
                {({ field, form: { errors, touched } }) => (
                  <TextField
                    name={field.name}
                    id="defaultNotificationEmail"
                    value={field.value}
                    error={touched.defaultNotificationEmail ? errors.defaultNotificationEmail : false}
                    onChange={e => {
                      handleResetTestValue();
                      field.onChange(e)
                    }}
                  />
                )}
              </Field>
            </div>
          </div>
        )
      }

      {formValues.provider === ProviderTypes.verified && (
        <>
          <VerifiedProviderForm currentProviderCredentials={customerProvider?.customCredentials} onResetTestValue={handleResetTestValue} />
          <div className={classNames('flex justify-end')}>
            <CoreButton
              htmlClassNames={classNames('flex justify-start items-start focus:!ring-0 focus:!ring-transparent', formValues.testSuccessful ? 'bg-green-500 hover:bg-green-500' : 'bg-blue-600 hover:bg-blue-500')}
              label={formValues.testSuccessful ? <div className=''>Test Successful <CheckCircleIconOutline className='h-6 inline-block' /></div> : testAction.isExecuting ? <div>Testing Credentials....</div> : <div>Test Credentials</div>}
              htmlType="button"
              // disabled={formValues.testSuccessful}
              onClick={e => {
                if (!formValues.testSuccessful) {
                  handleTestCredentials();
                }

              }}
            />
          </div>
        </>
      )}
      {
        formValues.provider === ProviderTypes.scrive && (
          <>
          {!formValues.accessKeys && (
            <>
              <ScriveProviderForm customCredentials={customerProvider?.customCredentials} onResetTestValue={handleResetTestValue} />
              <div className={classNames('flex justify-end')}>
                <CoreButton
                htmlClassNames={classNames('flex justify-start items-start focus:!ring-0 focus:!ring-transparent', formValues.testSuccessful ? 'bg-green-500 hover:bg-green-500' : 'bg-blue-600 hover:bg-blue-500')}
                label={formValues.testSuccessful ? <div className=''>Access Keys generated <CheckCircleIconOutline className='h-6 inline-block' /></div> : generateAccessKeysAction.isExecuting ? <div>Generating....</div> : <div>Generate access keys</div>}
                htmlType="button"
                // disabled={formValues.testSuccessful}
                onClick={e => {
                  if (!formValues.testSuccessful) {
                    handleTestGenerateAccessKeys();
                  }
                  }}
                />
              </div>
            </>
          )}
          {formValues.accessKeys && 
            <AccessKeysPreview accessKeys={customerProvider?.customCredentials?.accessKeys} handleDeleteAcessKeys={handleDeleteAcessKeys}/>
          }
          <SigningAuthorizationMethods />
          </>
        )
      }
    </div>
  );
};

const ScriveProviderForm = ({ customCredentials, onResetTestValue }) => {
  return (
    <>
      <div className="sm:pt-3 mt-8 space-y-4">
        
      <div className="grid grid-cols-12 flex justify-end text-gray-700">
        <div className={'col-span-12 sm:col-start-5 sm:col-span-8 order-last border-gray-300 rounded-lg border px-5 py-2 inline-flex mb-3'}>
          <InformationCircleIcon className="h-9 w-9 mr-3"/>
          <div className='mt-1 flex'>
            Scrive requires generation of access keys before being used as a provider.
          </div>
        </div>
        </div>
        <div className="grid grid-cols-12">
          <div className="col-span-12 sm:col-span-4">
            <label className="sm:pt-2 text-sm font-medium text-gray-700 sm:flex items-center">
              Email
            </label>
          </div>

          <div className="col-span-12 sm:col-span-8">
            <Field name="clientId">
              {({ field, form: { errors, touched } }) => (
                <TextField
                  name={field.name}
                  id="clientId"
                  value={field.value}
                  error={touched.clientId ? errors.clientId : false}
                  onChange={e => {
                    onResetTestValue()
                    field.onChange(e)
                  }}
                />
              )}
            </Field>
          </div>
        </div>

        <div className="sm:pt-3 grid grid-cols-12">
          <div className="col-span-12 sm:col-span-4">
            <label className="text-sm font-medium text-gray-700 sm:flex items-center">
              Password
            </label>
          </div>

          <div className="col-span-12 sm:col-span-8">
            <Field name="clientSecret">
              {({ field, form: { errors, touched } }) => (
                <>
                  <TextField
                    type="password"
                    name={field.name}
                    id="clientSecret"
                    value={field.value}
                    error={touched.clientSecret ? errors.clientSecret : false}
                    onChange={e => {
                      onResetTestValue()
                      field.onChange(e)
                    }}
                  />
                </>
              )}
            </Field>
          </div>
        </div>
      </div>
    </>
  );
}

const hideStringBeginning = (inputString) => {
  if(inputString) {
    return '*'.repeat(8) + inputString.substring(8, inputString.length);
  }
}

const AccessKeysPreview = ({ accessKeys, handleDeleteAcessKeys }) => {
  return (
    <>
      <div className="mt-8 space-y-4"></div>
      <div className="sm:pt-3 grid grid-cols-12 gap-4">
          <div className="col-span-12 sm:col-span-4">
            <label className="text-sm font-medium text-gray-700 sm:flex items-center">
              Access Keys
            </label>
          </div>

          <div className="col-span-12 sm:col-span-8">
            <div className='grid sm:grid-cols-9 gap-2 text-sm'>
                <div className='col-span-2'>
                  <div className='font-medium'>
                    API Token
                  </div>
                  <div>
                    {accessKeys?.apiToken}
                  </div>
                </div>
                <div className='col-span-2'>
                  <div className='font-medium'>
                    API Secret
                  </div>
                  <div>
                    {hideStringBeginning(accessKeys?.apiSecret)}
                  </div>
                </div>
                <div className='col-span-2'>
                  <div className='font-medium'>
                    Access Token
                  </div>
                  <div>
                    {accessKeys?.accessToken}
                  </div>
                </div>
                <div className='col-span-2'>
                  <div className='font-medium'>
                    Access Secret
                  </div>
                  <div>
                    {hideStringBeginning(accessKeys?.accessSecret)}
                  </div>
                </div>
                <div>
                  <TrashIcon className={'h-5 w-5 cursor-pointer'} onClick={() => handleDeleteAcessKeys()} />
                </div>
              </div>
          </div>
      </div>
    </>
    )}

const VerifiedProviderForm = ({ currentProviderCredentials, onResetTestValue }) => {
  return (
    <>
      <div className="sm:pt-3 mt-8 space-y-4">
        <div className="grid grid-cols-12">
          <div className="col-span-12 sm:col-span-4">
            <label className="sm:pt-2 text-sm font-medium text-gray-700 sm:flex items-center">
              UserId
            </label>
          </div>

          <div className="col-span-12 sm:col-span-8">
            <Field name="clientId">
              {({ field, form: { errors, touched } }) => (
                <TextField
                  name={field.name}
                  id="clientId"
                  value={field.value}
                  error={touched.clientId ? errors.clientId : false}
                  onChange={e => {
                    onResetTestValue()
                    field.onChange(e)
                  }}
                />
              )}
            </Field>
          </div>
        </div>

        <div className="sm:pt-3 grid grid-cols-12">
          <div className="col-span-12 sm:col-span-4">
            <label className="text-sm font-medium text-gray-700 sm:flex items-center">
              Password
            </label>
          </div>

          <div className="col-span-12 sm:col-span-8">
            <Field name="clientSecret">
              {({ field, form: { errors, touched } }) => (
                <>
                  <TextField
                    type="password"
                    name={field.name}
                    id="clientSecret"
                    value={field.value}
                    error={touched.clientSecret ? errors.clientSecret : false}
                    onChange={e => {
                      onResetTestValue()
                      field.onChange(e)
                    }}
                  />

                  <div>
                    <span className="text-sm text-gray-500">
                      {currentProviderCredentials?.isSet && !field.value && 'This value is hidden'}
                    </span>
                  </div>
                </>
              )}
            </Field>
          </div>
        </div>

        <div className="sm:pt-3 grid grid-cols-12">
          <div className="col-span-12 sm:col-span-4">
            <label className="text-sm font-medium text-gray-700 sm:flex items-center">
              CompanyId
            </label>
          </div>

          <div className="col-span-12 sm:col-span-8">
            <Field name="companyId">
              {({ field, form: { errors, touched } }) => (
                <TextField
                  name={field.name}
                  id="companyId"
                  value={field.value}
                  error={touched.companyId ? errors.companyId : false}
                  onChange={e => {
                    onResetTestValue()
                    field.onChange(e)
                  }}
                />
              )}
            </Field>
          </div>
        </div>
      </div>
    </>
  );
};

export default Provider;

const ProviderSelect = ({ name, value, label, providerList, onChange }) => {
  const { classNames } = useClassNames();
  const handleChange = (newValue) => {
    onChange({ target: { name: name, value: newValue } });
  }

  const renderProviderIcon = (providerId) => {

    switch (providerId) {
      case ProviderTypes.signicat: return <div>
        <img className='h-8' src={logoImages.signicat} alt='provider logo' />
      </div>;
      case ProviderTypes.verified: return <div>
        <img className='h-8' src={logoImages.verified} alt='provider logo' />
      </div>;
      case ProviderTypes.scrive: return <div>
        <ScriveLogo />
      </div>;
      default:
        return <div><PencilIcon className='h-7 w-8' /></div>
    }

  }

  return (
    <RadioGroup value={value} onChange={handleChange}>
      <RadioGroup.Label className="text-base font-semibold leading-6 text-gray-900">
        {label}
      </RadioGroup.Label>

      <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
        {providerList.map((provider) => (
          <RadioGroup.Option
            key={provider.providerId}
            value={provider.providerId}
            className={({ active }) =>
              classNames(
                active ? 'border-indigo-600 ring-2 ring-indigo-600' : 'border-gray-300',
                'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none'
              )
            }
          >
            {({ checked, active }) => (
              <>
                <span className="flex flex-1">
                  <span className="flex flex-col">
                    <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                      {provider.name}
                    </RadioGroup.Label>
                    <RadioGroup.Description as="span" className="mt-1 flex items-center text-sm text-gray-500">
                      {provider.description}
                    </RadioGroup.Description>
                    <RadioGroup.Description as="span" className="mt-6 h-full flex justify-start items-end">
                      <div className=''>
                        {renderProviderIcon(provider.providerId)}
                      </div>
                    </RadioGroup.Description>
                  </span>
                </span>
                <CheckCircleIcon
                  className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-indigo-600')}
                  aria-hidden="true"
                />
                <span
                  className={classNames(
                    active ? 'border' : 'border-2',
                    checked ? 'border-indigo-600' : 'border-transparent',
                    'pointer-events-none absolute -inset-px rounded-lg'
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>)
}
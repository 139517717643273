import { useReactOidc } from '@axa-fr/react-oidc-context';
import React, { useContext } from 'react'
import Loader from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import { RoleTableContext } from '../../../../../contexts';

export default function RoleUsers() {
  const { loading, usersInRole, userStatuses } = useContext(RoleTableContext);
  const { oidcUser } = useReactOidc();

  const TableRow = ({ user }) => {
    return (
      <tr key={user.id}>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {user.name}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {user.email}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 hidden sm:table-cell">
          {userStatuses.find(s => s.id === user.statusId)?.name}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">

          {user.id !== oidcUser.profile.sub && (
            <Link to={`/admin/team/manage/${user.id}`} className="text-indigo-600 hover:text-indigo-900 cursor-pointer">Edit</Link>
          )}
        </td>
      </tr>
    );
  }

  return (
    <>
      <div className="relative z-0">
        <div className="-my-2 sm:-mx-6 lg:-mx-8 overflow-x-auto">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow-sm overflow-hidden border border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Name
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Email
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Status
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-20">
                  {

                    !loading && (usersInRole.map((u, i) => {
                      return <TableRow user={u} key={u.id} />
                    }))
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

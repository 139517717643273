import {useToastAction} from "../../../../../hooks/useToastAction";
import {useEffect, useState} from "react";
import * as DigitalArchiveApi from "../../../../../api/digitalarchive";

const migrationStatusEnum = {
  0: 'Not started', 1: 'In progress', 2: 'Error', 3: 'Migrated', 4: 'Requested'
};

const environmentTypeEnum = {
  0: 'Shared', 1: 'Private'
};

const migrationBlockedStatus = [1, 4];

const Migration = () => {
  const [migrationStatus, setMigrationStatus] = useState({
    currentEnvironmentType: 0, targetEnvironmentType: null, migrationStatus: 0
  });

  const getCurrentMigrationStatusAction = useToastAction();
  const migrateAction = useToastAction();

  const fetchCurrentMigrationStatus = () => {
    getCurrentMigrationStatusAction.execute(async () => {
      const getCurrentMigrationStatusResult = await DigitalArchiveApi.getCurrentMigrationStatusApi();

      setMigrationStatus(getCurrentMigrationStatusResult)
    }, 'Failed to load migration status');
  };

  const handleOnMigrateClick = () => {
    migrateAction.execute(async () => {
      await DigitalArchiveApi.migrateApi();

      const getCurrentMigrationStatusResult = await DigitalArchiveApi.getCurrentMigrationStatusApi();
      setMigrationStatus(getCurrentMigrationStatusResult)
    }, 'Failed to migrate');
  }

  useEffect(() => {
    fetchCurrentMigrationStatus();
  }, [])

  return (<>
    {!getCurrentMigrationStatusAction.isExecuting && (<div className="mb-6">
      <div className="py-5">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4">
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Status</dt>
            <dd
              className="mt-1 text-sm text-gray-900">{migrationStatusEnum[migrationStatus.migrationStatus]}</dd>
          </div>

          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Current environment</dt>
            <dd
              className="mt-1 text-sm text-gray-900">{environmentTypeEnum[migrationStatus.currentEnvironmentType]}</dd>
          </div>

          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Target environment</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {migrationStatus.targetEnvironmentType === null ? (
                <button
                  type="button"
                  onClick={handleOnMigrateClick}
                  className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-brand-pink hover:bg-brand-pink-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed"
                  disabled={migrateAction.isExecuting || migrationBlockedStatus.includes(migrationStatus.migrationStatus)}
                >
                  Migrate
                  to {environmentTypeEnum[migrationStatus.currentEnvironmentType] === environmentTypeEnum["0"] ? (
                  environmentTypeEnum["1"]
                ) : (
                  environmentTypeEnum["0"]
                )}
                </button>
              ) : environmentTypeEnum[migrationStatus.targetEnvironmentType]}
            </dd>
          </div>
        </dl>
      </div>
    </div>)}
  </>)
}

export default Migration;
import React, { useEffect, useState } from 'react';
import './styles.scss';
import { useClassNames } from '../../../../hooks/useClassNames';
import Folders from './Folders';
import Webhooks from './Webhooks';
import MetadataColumns from './MetadataColumns';
import Migration from './Migration';
import { ContentWrapper, PageHeader } from '@metaforcelabs/metaforce-core';

export default function ArchiveIntegration() {
  const [activeTab, setActiveTab] = useState();

  const { classNames } = useClassNames();

  const tabs = [
    { name: 'Webhooks', href: '#', current: activeTab === 'webhooks', tab: 'webhooks' },
    { name: 'Folders', href: '#', current: activeTab === 'folders', tab: 'folders' },
    {
      name: 'Metadata columns',
      href: '#',
      current: activeTab === 'metadataColumns',
      tab: 'metadataColumns'
    },
    { name: 'Migration', href: '#', current: activeTab === 'migration', tab: 'migration' }
  ];

  const renderActiveTab = () => {
    switch (activeTab) {
      case 'webhooks':
        return <Webhooks />;
      case 'folders':
        return <Folders />;
      case 'metadataColumns':
        return <MetadataColumns />;
      case 'migration':
        return <Migration />;
      default:
        return <></>;
    }
  };

  useEffect(() => {
    setActiveTab(tabs[0].tab);
  }, []);

  return (
    <ContentWrapper>
      <PageHeader title="Archive" description={
      <div>
        <div>Configure your Digital Archive options</div>
        {/* <div className="hidden sm:block"> */}
          <nav className="mt-5 grid grid-cols-1 sm:flex sm:space-x-4" aria-label="Tabs">
            {tabs
              .filter((m) => !m.disabled)
              .map((tab) => (
                <a
                  key={tab.name}
                  href={tab.href}
                  onClick={() => setActiveTab(tab.tab)}
                  className={classNames(
                    tab.current
                      ? 'bg-gray-200 text-gray-900'
                      : 'text-gray-900 hover:bg-gray-50 hover:text-gray-900',
                    'px-3 py-2 font-medium text-sm rounded-md'
                  )}
                  aria-current={tab.current ? 'page' : undefined}
                >
                  {tab.name}
                </a>
              ))}
          </nav>
      </div>
      } />
      

      <div className="my-6 relative flex-1">{renderActiveTab()}</div>
    </ContentWrapper>
  );
}

import React, { Fragment, forwardRef, useContext, useEffect, useState } from "react";
import { AuthenticatedRoutesContext, DefaultLayoutV2Context } from "../../contexts";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import useIsNotActiveCustomer from "../../hooks/useIsNotActiveCustomer";
import { ButtonV2, CenterpointLogo, DocumentIcon, FormIcon, SettingsIcon, WorkflowIcon, WorkflowIconNamed, useClassNames, useToastAction } from "@metaforcelabs/metaforce-core";
import { useHasCompany } from "../../hooks/useHasCompany";
import { useTryAwaitAction } from "../../hooks/useTryAwaitAction";
import { useIsAdmin } from "../../hooks/useIsAdmin";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import { changeCompany } from "../../utils/oidcUtilities";
import { DotsHorizontalIcon, MenuIcon, UserIcon, XIcon } from "@heroicons/react/outline";
import { config } from "../../utils/config";
import { configuredProducts } from "../DefaultLayout/products";
import { getCustomerDetails } from "../../api/customer";
import { getCustomerUserAccess } from "../../api/customerUser";
import { useProductAccess } from "../../hooks/useProductAccess";
import { useHasFeature } from "../../hooks/useHasFeature";

const DefaultLayoutV2 = ({ children, nav, subMenuNav }) => {
    const authContext = useContext(AuthenticatedRoutesContext);
    const { oidcUser } = useReactOidc();
    const isNotActiveCustomer = useIsNotActiveCustomer();
    const loadAction = useToastAction();
    const [isLoaded, setIsLoaded] = useState(false);
    const hasCompany = useHasCompany(authContext.centerPointUser);
    const { hasPlan } = useHasFeature();
    const { tryAwaitAction } = useTryAwaitAction();
    const [customerUserAccess, setCustomerUserAccess] = useState({});
    const isAdmin = useIsAdmin();
    const { classNames } = useClassNames();
    const [customerDetails, setCustomerDetails] = useState({});
    const { products, createActions, footerPages, hasProductAccess } = useProductAccess(oidcUser);

    const [profileOverview, setProfileOverview] = useState({
        userFullName: authContext.centerPointUser.fullName,
        userImage: authContext.centerPointUser.userProfileImage,
        companyName: authContext?.customerInfo?.name,
        orgNumber: '',
        country: {},
        currentPlan: {},
        isNotActiveCustomer: isNotActiveCustomer
    });

    useEffect(() => {
        setProfileOverview(prev => {
            return {
                ...prev,
                userFullName: authContext.centerPointUser.fullName,
                userImage: authContext.centerPointUser.userProfileImage,
                companyName: authContext?.customerInfo?.name,

            }
        })
    }, [authContext])

    const userNavigation = [
        { name: 'Peronal Settings', href: '/userprofile', show: !isNotActiveCustomer },
        {
            name: 'Change Company',
            href: '#',
            onClick: changeCompany,
            show: authContext?.centerPointUser?.customerIds?.length > 1
        },
        { name: 'New Company', href: '/company/create', show: !isNotActiveCustomer },
        {
            name: 'Sign out',
            href: '#',
            onClick: authContext.onLogout,
            show: true
        }
    ];

    const personalSettings = { name: 'Personal Settings', href: '/userprofile', show: !isNotActiveCustomer };

    const adminPage = products.find(x => x.productCode === 'AD' && x.hasLicense && x.featureEnabled);

    const otherApplications = products.filter(x => x.hasLicense && x.featureEnabled && !x.displayMainNav);

    const settingsOptions = [
        {
            name: 'Administration',
            href: '/admin/dashboard',
            body: <div className="flex items-center"> Administration</div>,
            onClick: () => { },
            show: authContext?.centerPointUser?.customerIds?.length > 0
        },
        {
            name: 'More..',
            href: '#',
            body: <div className="flex items-center"> Archive</div>,
            onClick: () => { },
            show: authContext?.centerPointUser?.customerIds?.length > 0
        },
    ]

    const navPages = [
        {
            name: 'Home',
            href: '/home',
            current: nav === 'home',
            currentNav: 'home',
            defaultPage: true
        },
        {
            name: 'Studio',
            href: config.productUrl.smartforms,
            isExternal: true,
            requireCustomer: true,
            requireAdmin: true,
            current: nav === 'studio',
            currentNav: 'studio',
            show: !isNotActiveCustomer,
            productCode: "DA",
            desktopClassNames: 'hidden lg:inline-flex'
        },
        {
            name: 'Workflow',
            productCode: "WF",
            href: config.productUrl.workflow,
            isExternal: true,
            requireCustomer: true,
            requireAdmin: true,
            current: nav === 'workflow',
            currentNav: 'workflow',
            show: !isNotActiveCustomer,
            desktopClassNames: 'hidden lg:inline-flex'
        }
    ]

    const [navigation, setNavigation] = useState([
    ]);

    useEffect(() => {
        // console.log(products)
        const accessPages = navPages.filter(x => {
            return x.defaultPage || products.find(y => y.productCode === x.productCode && y.hasLicense && y.featureEnabled);
        });
        setNavigation(accessPages);
    }, [products]);

    useEffect(() => {
        setNavigation((prevNavigation) => {
            return prevNavigation.map((prev) => {
                prev.current = prev.currentNav === nav;
                return prev;
            });
        });
    }, [nav]);

    useEffect(() => {
        if (isLoaded) {
            return;
        }
        // loadDefaultData();
    }, []);

    return (
        <div className="relative min-h-screen flex flex-col">
            <header className='sticky top-0 z-10 bg-white'>
                <Disclosure as="nav" className=" border-b border-gray-200 relative z-40">
                    {({ open }) => (
                        <>
                            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                                <div className="flex justify-between h-16">
                                    <div className="flex">
                                        <div className="flex-shrink-0 flex items-center">
                                            <Link to={'/'} className="cursor-pointer">
                                                {/* <img className="h-8 w-auto" src={config.logoMain} alt="Metaforce" /> */}
                                                <CenterpointLogo size={6} />
                                            </Link>
                                        </div>
                                        <div className="hidden sm:-my-px sm:ml-6 md:ml-12 sm:flex sm:space-x-4">
                                            {navigation.map((item) => {
                                                // if (!item.show) {
                                                //     return <></>
                                                // }

                                                if (item.isExternal) {
                                                    return (
                                                        <Link
                                                            to={{ pathname: item.href }}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            className={classNames(
                                                                item.current
                                                                    ? 'border-brand-blue text-gray-900'
                                                                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                                                'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium cursor-pointer',
                                                                item.desktopClassNames
                                                            )}
                                                            aria-current={item.current ? 'page' : undefined}
                                                        >
                                                            {item.name}
                                                        </Link>);
                                                }

                                                return (

                                                    <Link
                                                        to={item.href}
                                                        key={item.name}
                                                        className={classNames(
                                                            item.current
                                                                ? 'border-brand-blue text-gray-900'
                                                                : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                                            'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium cursor-pointer',
                                                            item.desktopClassNames
                                                        )}
                                                        aria-current={item.current ? 'page' : undefined}
                                                    >
                                                        {item.name}
                                                    </Link>
                                                );
                                            })}
                                            <div className="inline-flex items-center pt-1 text-gray-500 hover:text-gray-700 cursor-pointer">
                                                {
                                                    otherApplications.length > 0 && (
                                                        <Menu as="div" className="relative">
                                                            {({ open }) => (
                                                                <>
                                                                    <div>
                                                                        <Menu.Button
                                                                        >
                                                                            <DotsHorizontalIcon className="h-6 " />
                                                                        </Menu.Button>
                                                                    </div>
                                                                    <Transition
                                                                        show={open}
                                                                        as={Fragment}
                                                                        enter="transition ease-out duration-200"
                                                                        enterFrom="transform opacity-0 scale-95"
                                                                        enterTo="transform opacity-100 scale-100"
                                                                        leave="transition ease-in duration-75"
                                                                        leaveFrom="transform opacity-100 scale-100"
                                                                        leaveTo="transform opacity-0 scale-95"
                                                                    >
                                                                        <Menu.Items
                                                                            static
                                                                            className="origin-top-right absolute  mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                        >
                                                                            {otherApplications.map(
                                                                                (item) =>
                                                                                (
                                                                                    <Menu.Item key={item.productCode}>
                                                                                        {({ active }) => {
                                                                                            const isExternal = item.href.startsWith('http');
                                                                                            return isExternal ? (
                                                                                                <Link
                                                                                                    to={{ pathname: item.href }}
                                                                                                    target="_blank"
                                                                                                    rel="noreferrer"
                                                                                                    className={classNames(
                                                                                                        active ? 'bg-gray-100' : '',
                                                                                                        'font-medium block px-4 py-2 text-sm text-gray-700'
                                                                                                    )}
                                                                                                >
                                                                                                    {
                                                                                                        item.title
                                                                                                    }
                                                                                                </Link>
                                                                                            ) : (
                                                                                                <Link
                                                                                                    to={{ pathname: item.href }}
                                                                                                    className={classNames(
                                                                                                        active ? 'bg-gray-100' : '',
                                                                                                        'font-medium block px-4 py-2 text-sm text-gray-700'
                                                                                                    )}
                                                                                                >
                                                                                                    {
                                                                                                        item.title
                                                                                                    }
                                                                                                </Link>
                                                                                            )
                                                                                        }}
                                                                                    </Menu.Item>
                                                                                )
                                                                            )}
                                                                        </Menu.Items>
                                                                    </Transition>
                                                                </>
                                                            )}
                                                        </Menu>
                                                    )
                                                }



                                            </div>
                                        </div>
                                    </div>

                                    <div className="hidden sm:ml-6 sm:flex sm:items-center text-xs">
                                        <div className='mr-2 lg:mr-4'>
                                            <a
                                                title="Documentation link"
                                                target="_blank"
                                                rel="noreferrer"
                                                className='p-2 group flex space-x-2 text-gray-500 hover:text-brand-blue text-sm font-medium cursor-pointer nav-button align-middle inline-block rounded-full'
                                                href={config.docsUrl}
                                            >
                                                <span>Docs</span>
                                                <span className='flex text-brand-blue text-opacity-0 group-hover:text-opacity-100'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-3 h-3 self-end mb-1">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                                                    </svg>

                                                </span>
                                            </a>
                                        </div>
                                        <div className="mr-2 lg:mr-4">
                                            {/* <Menu as="div" className="relative">
                                                    {({ open }) => (
                                                        <>
                                                            <div>
                                                                <Menu.Button
                                                                >
                                                                    <SettingsIcon size={6} colorClass="text-gray-700" />
                                                                </Menu.Button>
                                                            </div>
                                                            <Transition
                                                                show={open}
                                                                as={Fragment}
                                                                enter="transition ease-out duration-200"
                                                                enterFrom="transform opacity-0 scale-95"
                                                                enterTo="transform opacity-100 scale-100"
                                                                leave="transition ease-in duration-75"
                                                                leaveFrom="transform opacity-100 scale-100"
                                                                leaveTo="transform opacity-0 scale-95"
                                                            >
                                                                <Menu.Items
                                                                    static
                                                                    className="origin-top-right absolute -right-2  mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                >
                                                                    {settingsOptions.map(
                                                                        (item) =>
                                                                            item.show && (
                                                                                <Menu.Item key={item.name}>
                                                                                    {({ active }) => (
                                                                                        <Link
                                                                                            to={item.href}
                                                                                            onClick={(e) => item.onClick?.call()}
                                                                                            className={classNames(
                                                                                                active ? 'bg-gray-100' : '',
                                                                                                'font-medium block px-4 py-2 text-sm text-gray-700'
                                                                                            )}
                                                                                        >
                                                                                            {
                                                                                                item.body ? item.body : item.name
                                                                                            }
                                                                                        </Link>
                                                                                    )}
                                                                                </Menu.Item>
                                                                            )
                                                                    )}
                                                                </Menu.Items>
                                                            </Transition>
                                                        </>
                                                    )}
                                                </Menu> */}
                                        </div>
                                        {/* <div className="float-right mr-8"> */}
                                        {/* <div className="font-semibold">user full name</div> */}
                                        {/* <div> */}
                                        {/* {profileOverview.companyName} */}
                                        {/* company - orgno */}
                                        {/* </div> */}
                                        {/* </div> */}

                                        <div className="mr-4 lg:mr-8">

                                            {
                                                createActions.length > 0 && (
                                                    <Menu as="div" className="relative">
                                                        {({ open }) => (
                                                            <>
                                                                <div>
                                                                    <Menu.Button
                                                                        disabled={authContext?.centerPointUser?.customerIds?.length < 1}
                                                                        as={CtaButton}
                                                                    >
                                                                    </Menu.Button>
                                                                </div>
                                                                <Transition
                                                                    show={open}
                                                                    as={Fragment}
                                                                    enter="transition ease-out duration-200"
                                                                    enterFrom="transform opacity-0 scale-95"
                                                                    enterTo="transform opacity-100 scale-100"
                                                                    leave="transition ease-in duration-75"
                                                                    leaveFrom="transform opacity-100 scale-100"
                                                                    leaveTo="transform opacity-0 scale-95"
                                                                >
                                                                    <Menu.Items
                                                                        static
                                                                        className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                    >
                                                                        {createActions.map(
                                                                            (item) =>
                                                                            (
                                                                                <Menu.Item key={item.name}>
                                                                                    {({ active }) => (
                                                                                        <Link
                                                                                            to={{ pathname: item.href + '?new=true' }}
                                                                                            target="_blank"
                                                                                            rel="noreferrer"
                                                                                            onClick={(e) => item.onClick?.call()}
                                                                                            className={classNames(
                                                                                                active ? 'bg-gray-100' : '',
                                                                                                'font-medium block px-4 py-2 text-sm text-gray-700'
                                                                                            )}
                                                                                        >
                                                                                            {
                                                                                                item.createActionBody ? item.createActionBody : item.name
                                                                                            }
                                                                                        </Link>
                                                                                    )}
                                                                                </Menu.Item>
                                                                            )
                                                                        )}
                                                                    </Menu.Items>
                                                                </Transition>
                                                            </>
                                                        )}
                                                    </Menu>
                                                )
                                            }
                                        </div>
                                        {/* Profile dropdown */}
                                        <div>
                                            <Menu as="div" className="relative">
                                                {({ open }) => (
                                                    <>
                                                        <div>
                                                            <Menu.Button
                                                                className={`rounded-md text-black active:ring-2 group ring-gray-200 nav-button align-middle ${open ? '' : ''
                                                                    }`}
                                                            >
                                                                <span className="sr-only">Open user menu</span>
                                                                <div className="flex">
                                                                    <div className="hidden lg:block">
                                                                        {profileOverview.userImage && (
                                                                            <img
                                                                                className="inline-block h-7 w-7 rounded-full"
                                                                                src={profileOverview.userImage}
                                                                                alt=""
                                                                            />
                                                                        )}
                                                                        {!profileOverview.userImage && (
                                                                            <UserIcon className="h-6 w-6" aria-hidden="true" />
                                                                        )}
                                                                    </div>
                                                                    <div className="lg:ml-4 hidden sm:block">
                                                                        <div className="text-12px font-medium text-gray-800 group-hover:text-brand-blue">
                                                                            <div className="text-left overflow-ellipsis overflow-hidden whitespace-nowrap w-28">
                                                                                {profileOverview.userFullName}
                                                                            </div>
                                                                        </div>
                                                                        <div className="text-10px font-medium text-gray-500">
                                                                            <div className="text-left overflow-ellipsis overflow-hidden whitespace-nowrap w-28">
                                                                                {profileOverview.companyName}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Menu.Button>
                                                        </div>
                                                        <Transition
                                                            show={open}
                                                            as={Fragment}
                                                            enter="transition ease-out duration-200"
                                                            enterFrom="transform opacity-0 scale-95"
                                                            enterTo="transform opacity-100 scale-100"
                                                            leave="transition ease-in duration-75"
                                                            leaveFrom="transform opacity-100 scale-100"
                                                            leaveTo="transform opacity-0 scale-95"
                                                        >
                                                            <Menu.Items
                                                                static
                                                                className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                            >
                                                                {userNavigation.map(
                                                                    (item) =>
                                                                        item.show && (
                                                                            <Menu.Item key={item.name}>
                                                                                {({ active }) => (
                                                                                    <Link
                                                                                        to={item.href}
                                                                                        onClick={(e) => item.onClick?.call()}
                                                                                        className={classNames(
                                                                                            active ? 'bg-gray-100' : '',
                                                                                            'font-medium block px-4 py-2 text-sm text-gray-700'
                                                                                        )}
                                                                                    >
                                                                                        {item.name}
                                                                                    </Link>
                                                                                )}
                                                                            </Menu.Item>
                                                                        )
                                                                )}
                                                            </Menu.Items>
                                                        </Transition>
                                                    </>
                                                )}
                                            </Menu>
                                        </div>

                                    </div>
                                    <div className="-mr-2 flex items-center sm:hidden">
                                        {/* Mobile menu button */}
                                        <Disclosure.Button className="bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                            <span className="sr-only">Open main menu</span>
                                            {open ? (
                                                <XIcon className="block h-6 w-6" aria-hidden="true" />
                                            ) : (
                                                <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                                            )}
                                        </Disclosure.Button>
                                    </div>
                                </div>
                            </div>
                            <Disclosure.Panel className="sm:hidden">
                                <div className="pt-2 pb-3 space-y-1">
                                    {navigation.map((item) => {
                                        if (item.isExternal) {
                                            return (
                                                <Disclosure.Button
                                                    className={classNames(
                                                        item.current
                                                            ? 'border-brand-blue text-brand-blue'
                                                            : 'border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800',
                                                        'block pl-3 pr-4 py-2 border-l-4 text-base font-medium'
                                                    )}
                                                >
                                                    <Link
                                                        key={item.name}
                                                        to={{ pathname: item.href }}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        aria-current={item.current ? 'page' : undefined}
                                                    >
                                                        {item.name}
                                                    </Link>
                                                </Disclosure.Button>
                                            )

                                        }

                                        return (
                                            (
                                                <Disclosure.Button
                                                    className={classNames(
                                                        item.current
                                                            ? 'border-brand-blue text-brand-blue'
                                                            : 'border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800',
                                                        'block pl-3 pr-4 py-2 border-l-4 text-base font-medium'
                                                    )}
                                                >
                                                    <Link
                                                        key={item.name}
                                                        to={item.href}
                                                        aria-current={item.current ? 'page' : undefined}
                                                    >
                                                        {item.name}
                                                    </Link>
                                                </Disclosure.Button>
                                            )
                                        );
                                    })}
                                </div>
                                <div className="pt-4 pb-3 border-t border-gray-200">
                                    <div className="flex items-center px-4">
                                        <div className="flex-shrink-0">
                                            {profileOverview.userImage && (
                                                <img
                                                    className="inline-block h-6 w-6 rounded-full"
                                                    src={profileOverview.userImage}
                                                    alt=""
                                                />
                                            )}
                                            {!profileOverview.userImage && (
                                                <UserIcon className="h-6 w-6" aria-hidden="true" />
                                            )}
                                        </div>
                                        <div className="ml-3">
                                            <div className="text-12px font-medium text-gray-800">
                                                {profileOverview.userFullName}
                                            </div>
                                            <div className="text-10px font-medium text-gray-500 overflow-ellipsis overflow-hidden whitespace-nowrap w-28">
                                                {profileOverview.companyName}
                                            </div>
                                        </div>
                                        {/* <button className="ml-auto bg-white flex-shrink-0 p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                          <span className="sr-only">View notifications</span>
                                          <BellIcon className="h-6 w-6" aria-hidden="true" />
                                      </button> */}
                                    </div>
                                    <div className='px-4 pt-4'>
                                        <a
                                            title="Documentation link"
                                            target="_blank"
                                            rel="noreferrer"
                                            className='flex space-x-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100'
                                            href={config.docsUrl}
                                        >
                                            <span>Docs</span>
                                            <span className='flex'>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-4 h-4 self-end mb-1">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                                                </svg>
                                            </span>
                                        </a>
                                    </div>
                                    <div className="mt-2 space-y-1">
                                        {userNavigation.map(
                                            (item) =>
                                                item.show && (
                                                    <Disclosure.Button className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100">
                                                        <Link
                                                            key={item.name}
                                                            onClick={(e) => {
                                                                item.onClick?.call();
                                                            }}
                                                            to={item.href}
                                                            className=""
                                                        >
                                                            {item.name}
                                                        </Link>
                                                    </Disclosure.Button>
                                                )
                                        )}
                                    </div>
                                </div>
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>
            </header>

            <div
                //     style={{
                //         minHeight: '500px'
                // }}
                className="flex-grow bg-white">
                <DefaultLayoutV2Context.Provider
                    value={{
                        hasProductAccess,
                        hasCompany,
                        hasPlan: hasPlan()
                    }}
                >
                    {children}
                </DefaultLayoutV2Context.Provider>

            </div>
            <footer className="self-end min-h-[48rem] w-full bg-white">
                <div className="max-w-7xl mx-auto lg:px-8">


                    <div className="bg-black text-white px-4 sm:px-6 lg:px-8 py-6 sm:py-8">
                        <div className="flex justify-between items-center mr-4">
                            <div>
                                <CenterpointLogo size={6} colorClass="text-inherit" />
                            </div>
                            <div className="flex gap-4 sm:gap-8">
                                <ul className="font-semibold text-sm space-y-2">
                                    {footerPages.map(item => (
                                        <li>
                                            {
                                                item.isExternal ? (<Link
                                                    to={{ pathname: item.href }}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    {item.title}
                                                </Link>) :
                                                    (<Link
                                                        to={item.href}
                                                    >{item.title}
                                                    </Link>
                                                    )
                                            }
                                        </li>
                                    ))}
                                </ul>
                                <ul className=" text-sm text-gray-100 space-y-2">
                                    <li>
                                        {
                                            personalSettings.show && (
                                                <Link
                                                    to={personalSettings.href}
                                                >
                                                    {personalSettings.name}
                                                </Link>
                                            )
                                        }
                                    </li>
                                    <li>
                                        {
                                            adminPage && (
                                                <Link
                                                    to={{ pathname: adminPage.href }}
                                                >
                                                    {adminPage.title}
                                                </Link>
                                            )
                                        }
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default DefaultLayoutV2;


let CtaButton = forwardRef(function (props, ref) {
    return <ButtonV2
        label={"Create"}
        htmlClassNames="lg:w-48"
        ref={ref} {...props}
    />
})
import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { useClassNames } from "../../../../hooks/useClassNames";
import { config } from "../../../../utils/config";

export default function CheckoutForm({ planId, setFormLoaded, formLoaded }) {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isPurchasing, setIsPurchasing] = useState(false);
  const { classNames } = useClassNames();



  useEffect(() => {
    if (!stripe) {
      return;
    }
    console.log('Loaded')
  }, [stripe]);

  const handleSubmit = async (e) => {

    e.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setIsPurchasing(true);
    const return_url = `${config.centerpointUiBaseUrl}/admin/billing/plans/purchase/success/${planId}`;

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: return_url,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occured.");
    }
    setIsPurchasing(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <div className={classNames("flex flex-col",
        isLoading ? "opacity-0" : "opacity-1"
      )}>
        <div className={classNames("mt-4",
          isLoading ? "opacity-0" : "opacity-1"
        )}>
          <PaymentElement id="payment-element" onReady={() => {
            setIsLoading(false);
            setTimeout(() => {
              setFormLoaded(true);
            }, 100)
          }} />
        </div>
        <div className={classNames("mt-4 mb-4 flex items-center justify-center",
          formLoaded ? "":"hidden"
        )} >
          {
            !isLoading && formLoaded && stripe && elements && (
              <button disabled={isPurchasing || isLoading || !stripe || !elements} id="submit"
                className="inline-flex items-center px-4 py-2 shadow-sm font-medium rounded-md hover:bg-brand-blue-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 text-sm bg-brand-blue text-white">
                {
                  isPurchasing ? (
                    <>
                      <svg className="animate-spin -ml-1 mr-3 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      <span>Processing payment</span>
                    </>
                  ) : (<span>Pay now and subscribe</span>)
                }

              </button>
            )
          }

        </div>
        <div>
          {/* Show any error or success messages */}
          {message && <div id="payment-message">{message}</div>}
        </div>
      </div>
    </form>
  );
}
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useToastAction } from '../../../hooks/useToastAction';
import { getDashboard } from '../../../api/dashboard';
import { adminProductId, dashboardAreas } from '../../../utils/constants';
import { AuthenticatedRoutesContext } from '../../../contexts';
import ReleasePackagesTable from './releasePackagesTable';
import { GetReleasePackages } from '../../../api/version';
import { ContentWrapper } from '@metaforcelabs/metaforce-core';
import { HighlightCard, HighlightCardVariants } from '@metaforcelabs/metaforce-core';
import { TeamDisplayArt } from '../../../layouts/AdminLayout/teamDisplayArt';
import { IntegrationDisplayArt } from '../../../layouts/AdminLayout/integrationDisplayArt';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Dashboard() {
  const history = useHistory();
  const [isLoaded, setIsLoaded] = useState(false);
  const [dashboardData, setDashboardData] = useState([]);
  const [releasePackages, setReleasePackages] = useState([]);
  const loadAction = useToastAction();
  const loadReleasePackagesData = useToastAction();
  const authContext = useContext(AuthenticatedRoutesContext);

  const loadDefaultData = async () => {
    setIsLoaded(false);
    loadAction.execute(async () => {
      const customerDashboardData = await getDashboard();
      setDashboardData(customerDashboardData || []);
      setIsLoaded(true);
    }, 'Failed to load');
  };

  const fetchReleasePackagesData = async () => {
    loadReleasePackagesData.execute(async () => {
      const request = {
        fromDate: null,
        toDate: null,
        searchString: '',
        packageState: 1,
        quantity: 5
      };
      const releasePackages = await GetReleasePackages(request);
      setReleasePackages(releasePackages || []);
    }, 'Failed to load release packages data');
  };

  useEffect(() => {
    fetchReleasePackagesData();
    if (isLoaded) {
      return;
    }
    loadDefaultData();
  }, []);

  function getAreaColor(area) {
    return 'bg-document-yellow-lightest';
  }

  const AdminDashboardTile = ({ tileProps }) => {
    const hasFeature = () => {
      if (!tileProps.requiredFeatureCode) return true;

      const product =
        authContext.centerPointUser.customerUserAccess.productFeatureAccessSelections.find(
          (x) => x.productId === adminProductId
        );
      const ret = product.featureSelections.find(
        (x) => x.featureCode === tileProps.requiredFeatureCode
      )?.hasAccess;
      return !!ret;
    };

    return (
      <div
        key={tileProps.title}
        className={classNames(
          'col-span-6 md:col-span-4 xl:col-span-3',
          getAreaColor(tileProps.area),
          'flex flex-col rounded-md border border-document-yellow-dark relative bg-white p-6 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105 hover:shadow-sm'
        )}
      >
        <div className="flex-shrink-0 flex justify-center items-stretch w-full font-medium rounded-l-md">
          <a
            className={classNames('focus:outline-none', hasFeature() ? ' cursor-pointer' : '')}
            href={tileProps.url}
            onClick={() => {
              if (hasFeature()) history.push(tileProps.route);
            }}
            target="_blank"
            rel="noreferrer"
          >
            {/* Extend touch target to entire panel */}
            <span className="absolute inset-0" aria-hidden="true" />
            {tileProps.title}
          </a>
        </div>
        <div className="mt-2">
          <p className="text-xs flex items-center justify-center ">{tileProps.descriptionSmall}</p>
        </div>
        <div className="mt-2 min-h-0 h-full flex items-center justify-center">
          <p
            className={classNames(
              'font-bold',
              tileProps.descriptionLarge.length <= 5 ? 'text-5xl' : 'text-3xl'
            )}
          >
            {tileProps.descriptionLarge}
          </p>
        </div>
      </div>
    );
  };

  return (
    <>
      <ContentWrapper>
        {!loadAction.isExecuting && isLoaded && (
          <div className="mt-5 pt-10 max-w-7xl mx-auto sm:px-6 lg:px-8">
            <h1 className="text-2xl font-bold sm:text-4xl">Administration</h1>

            <section className='mt-10'>
              <HighlightCard
                variant={HighlightCardVariants.yellow}
                header="Team"
                body={
                  <div className='w-full lg:w-150%'>
                    <p>Centerpoint allows you to organize the users that should have access to the application.
                      By inviting new users they will get invitations by e-mail and you will be able to control which parts of the solution they should have access to.
                      Utilizing Roles and Access groups creates a great structure for managing your users.</p>
                  </div>
                }
                buttonText='Organize your Team'
                onClick={() => {
                  history.push('/admin/team/manage');
                }}
                rightContent={<TeamDisplayArt />}
              />
            </section>

            <div className="mt-10 px-0 sm:px-10 grid grid-cols-12 gap-5">
              {dashboardData.map((action) => (
                <AdminDashboardTile tileProps={action} key={'adminDashboardTile_' + action.title} />
              ))}
            </div>

            <section className='mt-10'>
              <HighlightCard
                variant={HighlightCardVariants.green}
                header="Integrations"
                body={
                  <div className='w-full lg:w-150%'>
                    <p>You can integrate to Centerpoint through our REST API. You will find documentation and examples in our documentation.
                      You can also configure BankId and digital signing providers. These registrations are necessary in order to include secure access and digital signing to your Centerpoint process.
                      A digital archive setup allows you to define folders and control their access.</p>
                  </div>
                }
                buttonText='Integration'
                onClick={() => {
                  history.push('/admin/integration/apiclients');
                }}
                rightContent={<IntegrationDisplayArt />}
              />
            </section>

            {releasePackages.length > 0 && (
              <div className="mt-5 pt-10 max-w-7xl">
                <div className="underline font-semibold my-5">Latest software updates</div>
                <ReleasePackagesTable releasePackages={releasePackages} />
              </div>
            )}
          </div>
        )}
      </ContentWrapper>
    </>
  );
}
